import { useState, useCallback, useEffect } from 'react';
import { Button } from '@mui/material';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, DialogActions } from '@mui/material/';
import ReactQuill from 'react-quill';

import 'react-quill/dist/quill.snow.css';
import './CLARichTextEditor.css';

const ExpandedTextFormatter = (props) => {
  const { open, onChange, onClose, initialState, modules } = props;

  const [richTextFormatterValue, setrichTextFormatterValue] = useState();

  const handleSave = useCallback(() => {
    onChange(richTextFormatterValue);
    onClose(false);
  }, [onChange, onClose, richTextFormatterValue]);

  const handleClose = useCallback(() => {
    onClose(false);
  }, [onClose]);

  useEffect(() => {
    setrichTextFormatterValue(initialState);
  }, [initialState]);

  return (
    <Dialog
      sx={{
        '& .MuiDialog-paper': {
          width: '560px',
          height: '546px',
        },
      }}
      onClose={handleClose}
      open={open}
    >
      <DialogContent>
        <ReactQuill
          className="rte-quill"
          modules={modules}
          value={richTextFormatterValue}
          theme="snow"
          onChange={setrichTextFormatterValue}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" disableElevation onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" disableElevation onClick={handleSave}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CLARichTextEditor = (props) => {
  const { onChange, onBlur, value, placeholder, expandable, height, disabled, error, onFocus } = props;

  const [quillvalue, setQuillValue] = useState(null);
  const [open, setOpen] = useState(false);
  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const modules = {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ indent: '-1' }, { indent: '+1' }],
      ['link'],
      ['clean'],
    ],
  };

  const handleQuillChange = useCallback((value) => {
    setQuillValue(value);
    if (onChange) {
      onChange(value);
    }
  }, []);

  const handleBlur = (e) => {
    if (onBlur) {
      onBlur(e, quillvalue);
    }
  }

  const handleOnFocus = () =>{ 
    onFocus && onFocus()
  }
  
  const handleTextAreaChange = useCallback(
    (value) => {
      setQuillValue(value);
      if (onChange) {
        onChange(value);
      }
    },
    [onChange]
  );

  useEffect(() => {
    setQuillValue(value);
  }, [value])

  return (
    <>
      <div className="rte-container">
        <div className={error ? "rte-quill-container-error" : "rte-quill-container"}>
          <ReactQuill
            placeholder={placeholder}
            className="rte-quill"
            modules={modules}
            value={quillvalue}
            theme="snow"
            onChange={handleTextAreaChange}
            onFocus={handleOnFocus}
            onBlur={handleBlur}
            readOnly={disabled}
            {...(height && { style: { height: height } })}
          />
        </div>
        <div>
          {expandable && !disabled && (
            <Button
              onClick={handleClickOpen}
              variant="text"
              sx={{
                fontFamily: 'museo-sans',
                textTransform: 'none',
                color: '#76D2D3',
                fontSize: '16px',
                p: 0,
                ':hover': {
                  backgroundColor: 'inherit',
                },
              }}
            >
              Expand
            </Button>
          )}
        </div>
      </div>
      <ExpandedTextFormatter
        open={open}
        onChange={handleQuillChange}
        onClose={handleClose}
        initialState={quillvalue}
        modules={modules}
      />
    </>
  );
};

CLARichTextEditor.propTypes = {
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  expandable: PropTypes.bool,
  disabled: PropTypes.bool,
  error: PropTypes.bool
};

CLARichTextEditor.defaultProps = {
  value: '',
  placeholder: '',
  expandable: true,
  disabled: false,
  error: false,
};

export default CLARichTextEditor;
