export const AccountAssertionLevelRiskReducer = (state, action) => {
    const { type, payload } = action;
    let newState = { ...state };
    switch (type) {
        case 'EXPAND_ALL': return {
            ...newState,
            expandAll: payload.expandAll
        }
    }
};
