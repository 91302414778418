// React and other libraries
import React, { useState, useContext } from "react";
import { useParams } from "react-router-dom";
import sortBy from "lodash/sortBy";
import { useDeepCompareEffect } from "react-use";
import { IconButton, Box } from "@mui/material";

import { Comment, CommentResolved } from "@ais/assets";
import { UNDERSTANDING_OF_ENTITY } from "@ais/constants";
import {
  getRowClassName,
  scotabdsColumns,
  scotabdsTableStyles,
  CLANestedDataGrid,
} from "@ais/components";
import { UnderstandingOfEntityContext } from "@ais/contexts";
import { useKeyControlDrawerContext } from "@contexts/KeyControlDrawer/KeyControlDrawerContext";
import { useInternalControlsContext } from "@contexts/InternalControls/InternalControlsContext";
import { ConcurrentInternalControlTextbox } from "../ConcurrentInternalControlTextbox";
import { ConcurrentKeyControlsButton } from "../ConcurrentKeyControlsButton.jsx";
import { sortReviewerSignoffAsc } from '@utilities/dateHelpers.js';
import { isSignoffLogicEnabled } from '@utilities/signoffUtility.js';


import "./styles.css";

const UOEScotabdsConstants = UNDERSTANDING_OF_ENTITY.SCOTABDS;
const UOEComments = UNDERSTANDING_OF_ENTITY.COMMENTS;
const UOEInfoProcessingConstants =
  UNDERSTANDING_OF_ENTITY.INFORMATION_PROCESSING;

const renderCustomCells = (columnData, setOpenComment, onKeyControlClick) => {
  const onClick = (scotabdId) => {
    setOpenComment(scotabdId);
  };

  const cellRendering = {
    [UOEScotabdsConstants.COMMENTS_FIELD]: {
      ...columnData,
      renderCell: (props) => {
        const { ICComment, id } = props.row;
        return (
          <IconButton sx={{ p: 0 }} onClick={() => onClick(id)}>
            {!!ICComment && ICComment.length > 0 ? (
              <CommentResolved />
            ) : (
              <Comment />
            )}
          </IconButton>
        );
      },
    },
    [UOEScotabdsConstants.KEY_CONTROLS_FIELD]: {
      ...columnData,
      renderCell: (params) => {
        const isRequired = (inherentRisk = [], controlRisk = [], assertionInherentRisk = [], assertionControlRisk = []) => {
          /** InherentRisk === Significant && ControlRisk === High */
          if (
            inherentRisk.some((risk) => risk === UOEScotabdsConstants.INHERENT_RISK.SIGNIFICANT) &&
            controlRisk.some((risk) => risk === UOEScotabdsConstants.CONTROL_RISK.HIGH) || assertionInherentRisk.some((risk) => risk === UOEScotabdsConstants.INHERENT_RISK.SIGNIFICANT) &&
            assertionControlRisk.some((risk) => risk === UOEScotabdsConstants.CONTROL_RISK.HIGH)
          ) {
            return true;
          } else if (
            /** InherentRisk === Significant && ControlRisk === Moderate */
            inherentRisk.some((risk) => risk === UOEScotabdsConstants.INHERENT_RISK.SIGNIFICANT) &&
            controlRisk.some((risk) => risk === UOEScotabdsConstants.CONTROL_RISK.MODERATE) || assertionInherentRisk.some((risk) => risk === UOEScotabdsConstants.INHERENT_RISK.SIGNIFICANT) &&
            assertionControlRisk.some((risk) => risk === UOEScotabdsConstants.CONTROL_RISK.MODERATE)
          ) {
            return true;
          } else if (
            /** InherentRisk === Significant && ControlRisk === Low */
            inherentRisk.some((risk) => risk === UOEScotabdsConstants.INHERENT_RISK.SIGNIFICANT) &&
            controlRisk.some((risk) => risk === UOEScotabdsConstants.CONTROL_RISK.LOW) || assertionInherentRisk.some((risk) => risk === UOEScotabdsConstants.INHERENT_RISK.SIGNIFICANT) &&
            assertionControlRisk.some((risk) => risk === UOEScotabdsConstants.CONTROL_RISK.LOW)
          ) {
            return true;
          } else if (

            /** InherentRisk === High && ControlRisk === High */
            inherentRisk.some((risk) => risk === UOEScotabdsConstants.INHERENT_RISK.HIGH) &&
            controlRisk.some((risk) => risk === UOEScotabdsConstants.CONTROL_RISK.HIGH) || assertionInherentRisk.some((risk) => risk === UOEScotabdsConstants.INHERENT_RISK.HIGH) &&
            assertionControlRisk.some((risk) => risk === UOEScotabdsConstants.CONTROL_RISK.HIGH)
          ) {
            return true;
          } else if (
            /** InherentRisk === High && ControlRisk === Moderate */
            inherentRisk.some((risk) => risk === UOEScotabdsConstants.INHERENT_RISK.HIGH) &&
            controlRisk.some((risk) => risk === UOEScotabdsConstants.CONTROL_RISK.MODERATE) || assertionInherentRisk.some((risk) => risk === UOEScotabdsConstants.INHERENT_RISK.HIGH) &&
            assertionControlRisk.some((risk) => risk === UOEScotabdsConstants.CONTROL_RISK.MODERATE)
          ) {
            return true;
          } else if (
            /** InherentRisk === High && ControlRisk === Low */
            inherentRisk.some((risk) => risk === UOEScotabdsConstants.INHERENT_RISK.HIGH) &&
            controlRisk.some((risk) => risk === UOEScotabdsConstants.CONTROL_RISK.LOW) || assertionInherentRisk.some((risk) => risk === UOEScotabdsConstants.INHERENT_RISK.HIGH) &&
            assertionControlRisk.some((risk) => risk === UOEScotabdsConstants.CONTROL_RISK.LOW)
          ) {
            return true;
          } else if (

            /** InherentRisk === Moderate && ControlRisk === Moderate */
            inherentRisk.some((risk) => risk === UOEScotabdsConstants.INHERENT_RISK.MODERATE) &&
            controlRisk.some((risk) => risk === UOEScotabdsConstants.CONTROL_RISK.MODERATE) || assertionInherentRisk.some((risk) => risk === UOEScotabdsConstants.INHERENT_RISK.MODERATE) &&
            assertionControlRisk.some((risk) => risk === UOEScotabdsConstants.CONTROL_RISK.MODERATE)
          ) {
            return true;
          } else if (
            /** InherentRisk === Moderate && ControlRisk === Low */
            inherentRisk.some((risk) => risk === UOEScotabdsConstants.INHERENT_RISK.MODERATE) &&
            controlRisk.some((risk) => risk === UOEScotabdsConstants.CONTROL_RISK.LOW) || assertionInherentRisk.some((risk) => risk === UOEScotabdsConstants.INHERENT_RISK.MODERATE) &&
            assertionControlRisk.some((risk) => risk === UOEScotabdsConstants.CONTROL_RISK.LOW)
          ) {
            return true;
          } else if (

            /** InherentRisk === Low && ControlRisk === Moderate */
            inherentRisk.some((risk) => risk === UOEScotabdsConstants.INHERENT_RISK.LOW) &&
            controlRisk.some((risk) => risk === UOEScotabdsConstants.CONTROL_RISK.MODERATE) || assertionInherentRisk.some((risk) => risk === UOEScotabdsConstants.INHERENT_RISK.LOW) &&
            assertionControlRisk.some((risk) => risk === UOEScotabdsConstants.CONTROL_RISK.MODERATE)
          ) {
            return true;
          } else if (
            /** InherentRisk === Low && ControlRisk === Low */
            inherentRisk.some((risk) => risk === UOEScotabdsConstants.INHERENT_RISK.LOW) &&
            controlRisk.some((risk) => risk === UOEScotabdsConstants.CONTROL_RISK.LOW) || assertionInherentRisk.some((risk) => risk === UOEScotabdsConstants.INHERENT_RISK.LOW) &&
            assertionControlRisk.some((risk) => risk === UOEScotabdsConstants.CONTROL_RISK.LOW)
          ) {
            return true;
          } else {
            return false;
          }
        };

        const getProjectRiskStatus = (row) => {
          const inherentRisk = row?.ProjectRisks?.map((risk) => risk.InherentRiskAssessmentId).filter((risk) => risk);
          const controlRisk = row?.ProjectRisks?.map((risk) => risk.ControlRiskAssessmentId).filter((risk) => risk);
          const assertionInherentRiskAssessment = row?.OtherRelevantAssertionRisks?.map((risk) => risk.InherentRiskAssessmentId).filter((risk) => risk)
          const assertionControlRiskAssessment = row?.OtherRelevantAssertionRisks?.map((risk) => risk.ControlRiskAssessmentId).filter((risk) => risk)

          return isRequired(inherentRisk, controlRisk, assertionInherentRiskAssessment, assertionControlRiskAssessment)
        };

        const isKeyControlRequired = getProjectRiskStatus(params?.row);

        if(isKeyControlRequired !== null) {
          return (
            <ConcurrentKeyControlsButton
              onClick={() => onKeyControlClick(params)}
              customFormObjectId={`${params.id}-key-controls`}
              isKeyControlRequired={isKeyControlRequired}
            />
          );
        }
      },
    },
    default: columnData,
  };

  return cellRendering[columnData.field] || cellRendering.default;
};

const getStringedAccountBalanceNames = (accountBalanceTypeList) => {
  const accBalanceNamesArray =
    accountBalanceTypeList?.map((abt) => abt.AccountBalanceName) || [];
  const stringedAccBalanceNames = accBalanceNamesArray.join(", ");

  // attach parentheses at both ends when not empty string
  return stringedAccBalanceNames === ""
    ? stringedAccBalanceNames
    : `(${stringedAccBalanceNames})`;
};

export const UOEScotabdsTable = (props) => {
  const {
    auditAreaIndex,
    auditAreaId,
    scotabds,
    projectScopeAuditAreaId,
    saveProjectScopeAuditAreaFields,
    signOffList = [],
    isProjectFinalized,
  } = props;

  const { formattedInternalControls, changeScotabdLevelField } =
    useInternalControlsContext();

  // Needs confirmation: Should we assume every ICF gets only one row of value
  const scotabdsHistory = formattedInternalControls?.History[0]?.ProjectScopeAuditAreaSCOTABDHistory.filter((psaash) => psaash.AuditAreaId === auditAreaId) || [];

  const { projectFormId } = useParams();
  const {
    state: { openComment },
    setOpenComment,
  } = useContext(UnderstandingOfEntityContext);

  const [scotabdDataRows, setScotabdDataRows] = useState([]);
  const [modifiedColumns, setModifiedColumns] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);

  const handleAdditionalCollapseTrigger = (newIds) => {
    setExpandedRows(newIds);
  };

  const { openDrawer, initializeAuditAreaSCOTABDProjectKeyControl } =
    useKeyControlDrawerContext();

  const findIndexes = (scotabdId) => {
    const foundAuditAreaIndex =
      formattedInternalControls?.ProjectScopeAuditArea?.findIndex(
        (item) => item.ProjectScopeAuditAreaId === projectScopeAuditAreaId,
      );
    const foundScotabdIndex = scotabds?.findIndex(
      (item) => item.ProjectScopeAuditAreaSCOTABDId === scotabdId,
    );

    return [foundAuditAreaIndex, foundScotabdIndex];
  };

  const handleBlur = (event, scotabdId, key) => {
    const [foundAuditAreaIndex, foundScotabdIndex] = findIndexes(scotabdId);

    // Check for nullish values (null or undefined) first
    if (
      foundAuditAreaIndex == null ||
      foundAuditAreaIndex === -1 ||
      foundScotabdIndex == null ||
      foundScotabdIndex === -1
    ) {
      return;
    }

    const currentCollapsedRecord =
      formattedInternalControls?.ProjectScopeAuditArea[foundAuditAreaIndex]
        .ProjectScopeAuditAreaSCOTABDS[foundScotabdIndex];
    const currentInputValue = currentCollapsedRecord[key];

    if (currentInputValue === event.target.value) return;

    changeScotabdLevelField(
      foundAuditAreaIndex,
      foundScotabdIndex,
      key,
      event.target.value,
    );

    const otherCommentKey =
      key === UNDERSTANDING_OF_ENTITY.KEYS.ICINFOCOMMENT
        ? UNDERSTANDING_OF_ENTITY.KEYS.ICCOMMENT
        : UNDERSTANDING_OF_ENTITY.KEYS.ICINFOCOMMENT;

    const payload = {
      ProjectScopeAuditAreaSCOTABDId: scotabdId,
      [key]: event.target.value === null ? "" : event.target.value,
      [otherCommentKey]:
        currentCollapsedRecord[otherCommentKey] === null
          ? ""
          : currentCollapsedRecord[otherCommentKey],
    };

    const prevInternalControlsData = { ...formattedInternalControls };

    saveProjectScopeAuditAreaFields(payload, prevInternalControlsData);
  };

  const toggleComment = (id) => {
    setOpenComment(id);

    if (!expandedRows.includes(id)) {
      setExpandedRows([...expandedRows, id]);
    }
  };

  const updateScotabdsData = (scotabds) => {
    const latestReviewersAsc = sortReviewerSignoffAsc(signOffList);
    return scotabds.map((scotabd, index) => {
      const shouldHighlight = {
        ICComment: false,
        ICInfoProcessingAndControlActivity: false,
      };
      Object.keys(shouldHighlight).forEach((key) => {
        const _history = scotabdsHistory.find(
          (sh) => sh.ProjectScopeAuditAreaSCOTABDId === scotabd.ProjectScopeAuditAreaSCOTABDId,
        );
        if (scotabdsHistory.length && _history) {
          if (scotabd[key] !== _history[key]) {
            const dateModified = new Date(formattedInternalControls.ValidFrom).getTime();
            const isSignoffEnabled = isSignoffLogicEnabled(isProjectFinalized, dateModified);
            const _shouldHighlight =
              new Date(latestReviewersAsc[latestReviewersAsc?.length - 1]?.signOffDate).getTime() < dateModified;
            shouldHighlight[key] = _shouldHighlight && isSignoffEnabled;
          }
        }
      });
      return {
        ...scotabd,
        id: `AuditArea-${auditAreaIndex}-Scotabd-${index}`,
        index,
        stringAccountBalanceTypeList: getStringedAccountBalanceNames(scotabd.AccountBalanceTypeList),
        ExpandedPanel: (
          <ExpandedPanel
            index={index}
            scotabd={scotabd}
            auditAreaId={auditAreaId}
            auditAreaIndex={auditAreaIndex}
            handleBlur={handleBlur}
            openComment={openComment}
            shouldHighlight={shouldHighlight}
            {...props}
          />
        ),
      };
    });
  };  

  const sortScotabdsData = (scotabds) => {
    return sortBy(scotabds, UOEScotabdsConstants.DISPLAY_ORDER);
  };

  const onKeyControlClick = (rowData) => {
    const {
      ProjectScopeAuditAreaSCOTABDId,
      ProjectScopeAuditAreaSCOTABDProjectKeyControl,
    } = rowData?.row;

    openDrawer(`${rowData.id}-key-controls`);
    initializeAuditAreaSCOTABDProjectKeyControl({
      projectFormId,
      projectScopeAuditAreaId,
      internalControlData: formattedInternalControls,
      projectScopeAuditAreaSCOTABDId: ProjectScopeAuditAreaSCOTABDId,
      projectScopeAuditAreaSCOTABDProjectKeyControl:
        ProjectScopeAuditAreaSCOTABDProjectKeyControl,
    });
  };

  // Format SCOTABD rows and columns
  useDeepCompareEffect(() => {
    if (scotabds?.length) {
      const sortedScotabdsData = sortScotabdsData(scotabds);
      const formattedScotabdsData = updateScotabdsData(sortedScotabdsData);
      setScotabdDataRows(formattedScotabdsData);
      const newModifiedColumn = scotabdsColumns.map((columnData) =>
        renderCustomCells(columnData, toggleComment, onKeyControlClick),
      );

      setModifiedColumns(newModifiedColumn);
    }
  }, [scotabds, openComment, signOffList]);

  return (
    <CLANestedDataGrid
      sx={scotabdsTableStyles}
      columns={modifiedColumns}
      rows={scotabdDataRows}
      expandedRow={expandedRows}
      headerHeight={62}
      getRowClassName={(params) =>
        getRowClassName(
          params,
          scotabdDataRows,
          expandedRows,
          2,
          auditAreaIndex,
        )
      }
      handleAdditionalCollapseTrigger={handleAdditionalCollapseTrigger}
      hideFooter={true}
    />
  );
};

const ExpandedPanel = ({
  scotabd,
  auditAreaIndex,
  index,
  handleBlur,
  openComment,
  isProjectFinalized,
  shouldHighlight = {
    ICComment: false,
    ICInfoProcessingAndControlActivity: false,
  },
}) => {
  return (
    <Box sx={{ ml: "60px", my: "20px" }}>
      <ConcurrentInternalControlTextbox
        label={UOEInfoProcessingConstants.LABEL}
        placeholder={UOEInfoProcessingConstants.PLACEHOLDER}
        value={scotabd.ICInfoProcessingAndControlActivity || ""}
        maxLength={null}
        onBlur={(e) =>
          handleBlur(
            e,
            scotabd.ProjectScopeAuditAreaSCOTABDId,
            UNDERSTANDING_OF_ENTITY.KEYS.ICINFOCOMMENT,
          )
        }
        customFormObjectId={`AuditArea-${auditAreaIndex}-Scotabd-${index}`}
        disabled={isProjectFinalized}
        //shouldHighlight={shouldHighlight.ICInfoProcessingAndControlActivity}
      />
      {openComment === `AuditArea-${auditAreaIndex}-Scotabd-${index}` && (
        <ConcurrentInternalControlTextbox
          label={UOEComments.LABEL}
          placeholder={UOEComments.PLACEHOLDER}
          value={scotabd.ICComment || ""}
          nonMultilineLabelFontSize={UOEComments.NON_MULTILINE_LABEL_FONT_SIZE}
          nonMultilineLabelMarginLeft={
            UOEComments.NON_MULTILINE_LABEL_MARGIN_LEFT
          }
          onBlur={(e) =>
            handleBlur(
              e,
              scotabd.ProjectScopeAuditAreaSCOTABDId,
              UNDERSTANDING_OF_ENTITY.KEYS.ICCOMMENT,
            )
          }
          sx={{
            marginTop: "20px",
          }}
          customFormObjectId={`AuditArea-${auditAreaIndex}-Scotabd-${index}-comment`}
          disabled={isProjectFinalized}
          //shouldHighlight={shouldHighlight.ICComment}
        />
      )}
    </Box>
  );
};

export default UOEScotabdsTable;
