import React from 'react'
import { useParams } from 'react-router-dom';
import AuditAreasTable from './AuditAreas/AuditAreasTable'
import EvaluationAndConclusion from './EvaluationAndConclusion/EvaluationAndConclusion'
import IncorporateKeyControls from './IncorporateKeyControls/IncorporateKeyControls'
import styles from './styles.module.css';
import { INTERNAL_CONTROLS } from '@constants/customForm'
import { useFinalizedProject } from '@hooks/useProject';

import { useInternalControlsContext } from '@contexts/InternalControls/InternalControlsContext'; 

const { TEXT } = INTERNAL_CONTROLS.EN;

const EvaluatingTheDesign = ({ projectFormId, signOffList }) => {

  const { projectId } = useParams();
  const isProjectFinalized = useFinalizedProject(projectId);

  const { formattedInternalControls, changeFirstLevelFieldWithObject } = useInternalControlsContext();

  return (
    <>
      <div className={styles['evaluating-container']}>
        <div className={styles['evaluating-purpose']}>
          <span><b>{TEXT.PURPOSE} </b> {TEXT.PURPOSE_DESC}</span>
        </div>
        <div className={styles['evaluating-objectives']}>
          <span><b>{TEXT.OBJECTIVE} </b> {TEXT.OBJECTIVE_DESC}</span>
        </div>
        <div className={styles['evaluating-procedures']}>
          <span><b>{TEXT.PROCEDURES} </b> {TEXT.PROCEDURES_DESC}</span>
        </div>
        <div className={styles['evaluating-procedures-addition']}>{TEXT.PERFORM_PROCEDURES}</div>
        <div className={styles['evaluating-accumulate-risks']}>{TEXT.ELLIPSE}</div>

        <div className={styles['evaluating-main-content']}>
          <span className={styles['evaluating-section-title']}>{TEXT.SUMMARY_OF_KEY_CONTROLS}</span>

          <div className={styles['evaluating-table']}>
            <AuditAreasTable 
              projectScopeAuditAreas={formattedInternalControls?.ProjectScopeAuditArea ?? []} 
              isProjectFinalized={isProjectFinalized}
              signOffList={signOffList}
            />
            <IncorporateKeyControls 
              projectInternalControlProjectKeyControl={formattedInternalControls?.ProjectInternalControlProjectKeyControl ?? []}
              isProjectFinalized={isProjectFinalized} 
              signOffList={signOffList}
            />
          </div>
        </div>

        <div className={styles['evaluating-evaluation']}>
          <EvaluationAndConclusion
            internalControlData={formattedInternalControls}
            isProjectFinalized={isProjectFinalized} 
            changeFirstLevelFieldWithObject={changeFirstLevelFieldWithObject}
            projectFormId={projectFormId}
            signOffList={signOffList}
          />
        </div>
      </div>
    </>
  )
}

export default EvaluatingTheDesign
