import formApi from '@utilities/claApiForm';
import { useQuery, useQueryClient } from '@tanstack/react-query'

export const getProjectFormUsageByProjectFormId = async (projectFormId, projectId) => {
    try {
        const response = await formApi.get(`/projectformusage/${projectFormId}/${projectId}`);
        return { status: 200, data: response };
    } catch (error) {
        return { status: 400, message: error };
    }
};

export const getProjectFormUsageActiveUser = async (projectId) => {
    try {
        return await formApi.get(`/projectformusage/activeusers/${projectId}`);
    } catch (error) {
        return { status: 400, message: error };
    }
};

export const useProjectFormUsageByProjectFormId = (projectFormId, enabled = true, projectId) => {
    return useQuery(
        ['useProjectFormUsageByProjectFormId', projectFormId],
        () => getProjectFormUsageByProjectFormId(projectFormId, projectId),
        {
            isEnabled: !!projectFormId && !!enabled,
            refetchOnWindowFocus: false
        }
    );
}

export const useInvalidateProjectFormUsageByProjectFormId = () => {
    const queryClient = useQueryClient();

    return () => {
        queryClient.invalidateQueries('useProjectFormUsageByProjectFormId');
    };
};
  



const projectFormusageService = {
    getProjectFormUsageByProjectFormId,
    getProjectFormUsageActiveUser,
};

export default projectFormusageService;
