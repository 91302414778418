import { io } from "socket.io-client";
import { useRef, useEffect, useState, useMemo } from 'react';
import { stringHelper as string } from '@ais/utilities';
import PropTypes from 'prop-types';
import { SOCKET_DEFAULT_OPTIONS, SOCKET_EVENT_NAMES } from '@ais/constants';
import { SocketContext } from './SocketContext';
import { useMsal } from '@azure/msal-react';
import { useParams } from "react-router-dom";
import { useInterval } from 'react-use';

const useDefaultSocketSettings = (...args) => {
    const { current: socket } = useRef(io(...args));
    useEffect(() => {
        return () => {
            console.log('default socket settings cleanup')
            socket?.removeAllListeners();
            socket?.close();
        };
    }, [socket]);
    return [socket];
};

export const SocketProvider = ({ children, url }) => {
    const wsBaseUrl = url;
    const proxyPath = string.stripPath(wsBaseUrl);
    const { projectFormId } = useParams();
    const { accounts } = useMsal();
    const userId = accounts[0].localAccountId.toUpperCase();
    const token = accounts[0].idTokenClaims.accessToken;

    const [isSocketConnected, setIsSocketConnected] = useState(false);
    const socket = useDefaultSocketSettings(wsBaseUrl.replace(proxyPath, ''), {
        ...SOCKET_DEFAULT_OPTIONS,
        path: proxyPath + SOCKET_DEFAULT_OPTIONS.path,
        auth: {
            token: token
        },
        query: {
            projectFormId,
            userId
        }
    })[0];

    const tryReconnect = () => {
        setTimeout(() => {
            console.log('trying to reconnect')
            socket.io.open((err) => {
                console.error(err)
                if (err) {
                    tryReconnect();
                }
            });
        }, 2000);
    }

    useInterval(() => {
        if (!!isSocketConnected) {
            try {
                let timeoutId;
                timeoutId = setTimeout(() => {
                    clearTimeout(timeoutId)
                    console.log("manual-ping didn't return after 5 seconds")
                    tryReconnect();
                }, 5000)
                socket.emit('manual-ping', () => {
                    console.log('manual-ping callback');
                    clearTimeout(timeoutId)
                })
            } catch (err) {
                console.error(err)
            }
        }
        return () => {
            clearTimeout(timeoutId)
        }
    }, 10000);

    socket.io.on("close", tryReconnect);

    socket.on(SOCKET_EVENT_NAMES.CONNECT, () => {
        console.log('websocket connected')
        setIsSocketConnected(true)
    })

    socket.on(SOCKET_EVENT_NAMES.DISCONNECT, () => {
        console.log('websocket disconnected')
        setIsSocketConnected(false)
    })

    const logConnectError = (err) => {
        console.log("connect_error");
        console.log(err)
    }

    const logConnectTimeout = () => {
        console.log("connect_timeout");
    }

    const logReconnect = (attempts) => {
        console.log(`reconnect ${attempts}`);
    }

    const logReconnectAttempt = (attempts) => {
        console.log(`reconnect_attempt ${attempts}`);
    }

    const logReconnecting = (attempts) => {
        console.log(`reconnecting ${attempts}`);
    }

    const logReconnectError = (err) => {
        console.log(`reconnect_error`);
        console.log(err)
    }

    const logReconnectFailed = () => {
        console.log(`reconnect_failed`);
    }

    const ping = () => {
        console.log("ping");
    }

    const pong = (latency) => {
        console.log(`pong ${latency}`);
    }

    useEffect(() => {
        socket.on('connect_error', logConnectError);
        socket.on('connect_timeout', logConnectTimeout);
        socket.on('reconnect', logReconnect);
        socket.on('reconnect_attempt', logReconnectAttempt);
        socket.on('reconnecting', logReconnecting);
        socket.on('reconnect_error', logReconnectError);
        socket.on('reconnect_failed', logReconnectFailed);
        socket.on('ping', ping);
        socket.on('pong', pong);
        socket.connect();
        return () => {
            socket.disconnect();
        };
    }, [socket]);


    const memoized = useMemo(() => ({ socket, isSocketConnected }), [socket, isSocketConnected]);

    return (
        <SocketContext.Provider value={memoized}>{children}</SocketContext.Provider>
    )

}

SocketProvider.propTypes = {
    children: PropTypes.node
}