import React, {
    useState
} from 'react';
import { useDebounce, useUpdateEffect } from 'react-use';
import {
    Box,
    TextField
} from '@mui/material';


const CommunicationOfDeficienciesComment = (props) => {
    const { row, handleCommentSave } = props;
    const [commentState, setCommentState] = useState(row?.CommunicationComment);
    const [debouncedValue, setDebouncedValue] = useState('');

    useDebounce(
        () => {
            setDebouncedValue(commentState);
        },
        500,
        [commentState]
    );

    useUpdateEffect(() => {
        if (handleCommentSave) {
            handleCommentSave(row, 'CommunicationComment', debouncedValue);
        }
    }, [debouncedValue]);

    return (<>
        <Box sx={{ p: `20px 65px 10px 65px` }}>
            <TextField
                label={'Comment'}
                fullWidth
                multiline
                placeholder="Enter comment"
                rows={6}
                value={commentState}
                onChange={(e) => setCommentState(e.target.value)}
                InputLabelProps={{
                    shrink: true
                }}
                InputProps={{
                    notched: true
                }}
                inputProps={{
                    maxLength: 4096
                }}
            />
        </Box>
    </>
    );
}

export default CommunicationOfDeficienciesComment;