import { createContext, useContext, useRef, useMemo, useEffect } from "react"
import { stringHelper as string } from "@ais/utilities"
import { io } from "socket.io-client"
import { useOtherUsersStore } from './store/users';
import { useIdle } from 'react-use'
import { WAITING_TIME } from '@ais/constants';
import { toast } from 'react-toastify';

const ROOM_EVENTS = "room_events"
const OTHERS_EVENT = "others"

const baseUrl = process.env.REACT_APP_API_FORM_WEBSOCKET
const proxy = string.stripPath(baseUrl)

const RoomContext = createContext({})

export const RoomProvider = ({ roomId, userId, children }) => {
    const initializeConcurrentUsers = useOtherUsersStore((state) => state.initialize)
    const resetConcurrentUsers = useOtherUsersStore((state) => state.clear)
    const { current: socket } = useRef(io(new URL("/room", baseUrl).href, {
        path: proxy + "/ais",
        transports: [ "websocket" ],
        autoConnect: false,
        upgrade: false
    }))
    const isIdle = useIdle(WAITING_TIME.MINS_13);

    useEffect(() => {
        if(isIdle) {
            toast.error('Reconnect now', {
                autoClose: false,
            });
            const delay = setTimeout(() => { 
                socket.disconnect()
                resetConcurrentUsers()
                toast.dismiss();
            }, WAITING_TIME.MINS_2);
            return () => {
                clearTimeout(delay);
            };
        }
        else {
            toast.dismiss();
        }
    }, [ isIdle ])

    const onConnect = () => {
        socket.emit("subscribe_to_room_events", { roomId: roomId, projectFormId: roomId, userId }, (response) => {
            const users = response.data || []
            initializeConcurrentUsers(users)
        })
    }

    const onDisconnect = (reason) => {
        console.warn(`Room socket disconnect. Reason: `, reason)
    }

    const emit = (event, payload) => {
        socket.emit(event, payload)
    }

    const on = (event, callback) => {
        socket.on(event, callback)
    }

    const off = (event, callback) => {
        socket.off(event, callback)
    }

    useEffect(() => {
        socket.on("connect", onConnect)
        socket.on("disconnect", onDisconnect)

        socket.connect()
        
        return () => {
            socket.off("connect", onConnect)
            socket.off("disconnect", onDisconnect)
            socket.disconnect()
        }
    }, [])

    return (
        <RoomContext.Provider value={{ on, off, emit, roomId, isIdle }}>
            {children}
        </RoomContext.Provider>
    )
}

const useRoom = () => {
    const context = useContext(RoomContext)
    if(!context) {
        throw new Error("`useRoom` must be used within RoomProvider")
    }
    return context
}

export const useBroadcastEvent = () => {
    const room = useRoom()
    return ({ data: _data, ...rest }) => {
        const data = {
            ..._data,
            roomId: room.roomId
        }
        room.emit(ROOM_EVENTS, { ...rest, data })
    }
}

export const useBroadcastOthersEvent = () => {
    const room = useRoom()
    return ({ type, data }) => {
        const event = {
            type,
            data: {
                ...data,
                roomId: room.roomId
            }
        }
        room.emit(OTHERS_EVENT, event)
    }
}

export const useUpdateMyPresence = () => {
    const broadcast = useBroadcastOthersEvent()
    return ({ focusedId }) => broadcast({ type: "presence", data: { focusedId: focusedId }})
}

export const useEventListener = (callback) => {
    const room = useRoom()
    const fnc = useEffect(() => {
        room.on(ROOM_EVENTS, callback)
        return () => {
            room.off(ROOM_EVENTS, callback)
        }
    }, [])
    return fnc
}

export const useOthersListener = (callback) => {
    const room = useRoom()
    const fnc = useEffect(() => {
        room.on(OTHERS_EVENT, callback)

        return () => {
            room.off(OTHERS_EVENT, callback)
        }
    }, [])
    return fnc
}

export const useRoomIdle = () => {
    const {isIdle} = useRoom();
 
    return isIdle;
}