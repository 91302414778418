import { createContext, useReducer, useContext } from 'react';
import ProjectManagementReducer, { initialState } from './ProjectManagementReducer';

const ProjectManagementContext = createContext();

export const ProjectManagementProvider = ({ children }) => {
    const [state, dispatch] = useReducer(ProjectManagementReducer, initialState);

    /**
     * 
     * @param {number | null} activeContextMenuId 
     */
    const changeActiveContextMenuId = (activeContextMenuId) => {
        dispatch({
            type: "CHANGE_ACTIVE_CONTEXT_MENU_ID",
            payload: activeContextMenuId
        })
    };

    /**
     * @type {typeof initialState & {
     *  changeActiveContextMenuId: typeof changeActiveContextMenuId
     * }}
     */
    const value = {
        activeContextMenuId: state.activeContextMenuId,
        changeActiveContextMenuId
    }

    return <ProjectManagementContext.Provider value={value}>{children}</ProjectManagementContext.Provider>
}

export const useProjectManagementContext = () => {
	const context = useContext(ProjectManagementContext);

	if (context === undefined) {
		console.log(`useProjectManagement must be used within ProjectManagementContext.`);
	}

	return context;
};