import { useState, useEffect } from 'react';
import {  
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,  
  Typography
} from '@mui/material';
import { TAILORING_PROCEDURE_FIELD } from '@constants/forms';
import CLATextField from '@components/Forms/CLATextField/CLATextField';
import CLARichTextEditor from '@components/Forms/CLARichTextEditor/CLARichTextEditor';
import {CLADropdown, CLASelect} from '@ais/forms';
import { useFormik } from 'formik';
import * as yup from 'yup'
import styles from './CLATailoringProcedureDialog.module.css';
import { mapDefaultOptions } from '@utilities/mapDefaultOptions';
import { richTextEditorTextContent } from '@utilities/richTextEditorTextContent';
import { useProjectScopeAuditAreaSCOTABDs } from '@services/scotabd';
import { useLoading } from '@hooks/index';
import { useParams } from 'react-router-dom';

const CLATailoringProcedureDialog = (props) => {     
  const { 
    showDialog,     
    closeDialog,
    saveCustomProcedure,
    matchedCustomProcedure,        
    assertions,
    auditArea,
    projectFormId
  } = props;     
  
  const [isRequiredProcName, setRequiredProcName] = useState(false);
  const [isRequiredDesc, setRequiredDesc] = useState(false);
  const [customProcedureDialog, setCustomProcedureDialog] = useState(matchedCustomProcedure);  
  const [primaryAssertionOptions, setPrimaryAssertions] = useState(assertions);
  const [secondaryAssertionOptions, setSecondaryAssertions] = useState(assertions);
  const [scotabds, setScotabds] = useState([]);
  const setLoading = useLoading();
  const { projectId } = useParams();
  const { data: scotabdsResult, isLoading: isLoadingScotabd, isFetching: isScotabdFetching, error: scotabdError } = useProjectScopeAuditAreaSCOTABDs(projectFormId, auditArea?.id, projectId);

  useEffect(() => {
        if (isScotabdFetching) {
            setLoading(true)
        } else {
            setLoading(false)
        }
  }, [isScotabdFetching])

  useEffect(async () => {        
    if (scotabdsResult && Array.isArray(scotabdsResult.data) && scotabdsResult.data.length > 0) {            
        setScotabds(scotabdsResult?.data)
    }
  }, [isLoadingScotabd])

  useEffect(() => {
    setCustomProcedureDialog(matchedCustomProcedure)
    if(matchedCustomProcedure?.PrimaryAssertions?.length > 0){
      const filteredSecondaryAssertions = secondaryAssertionOptions.filter((assertion) => matchedCustomProcedure.PrimaryAssertions.findIndex(matched => assertion.AssertionId === matched.AssertionId) < 0);      
      setSecondaryAssertions(filteredSecondaryAssertions)      
    }
    
    if(matchedCustomProcedure?.SecondaryAssertions?.length > 0){
      const filteredPrimaryAssertions = primaryAssertionOptions.filter((assertion) => matchedCustomProcedure.SecondaryAssertions.findIndex(matched =>  assertion.AssertionId === matched.AssertionId) < 0);
      setPrimaryAssertions(filteredPrimaryAssertions)        
    }    
  }, [matchedCustomProcedure])

  const mapPrimaryAssertions = (assertions) => {
      return assertions.map(assertion => {
          const index = customProcedureDialog?.PrimaryAssertions?.length > 0 ? customProcedureDialog?.PrimaryAssertions.findIndex((pa) => pa.AssertionId === assertion) : -1;          
          if(index > 0 ) {
            return {
              ProjectFormCustomProcedurePrimaryAssertionId: customProcedureDialog.PrimaryAssertions[index].ProjectFormCustomProcedurePrimaryAssertionId,
              ProjectFormCustomProcedureId: customProcedureDialog?.ProjectFormCustomProcedureId ?? null,
              AssertionId: assertion
            }
          }
           
          return {
              ProjectFormCustomProcedurePrimaryAssertionId: null,
              ProjectFormCustomProcedureId: customProcedureDialog?.ProjectFormCustomProcedureId ?? null,
              AssertionId: assertion
          }
      })
  }

  const mapSecondaryAssertions = (assertions) => {
      return assertions.map(assertion => {
          const index = customProcedureDialog?.SecondaryAssertions?.length > 0 ? customProcedureDialog.SecondaryAssertions.findIndex((sa) => sa.AssertionId === assertion) : -1;
          if(index > 0 ) {
            return {
              ProjectFormCustomProcedureSecondaryAssertionId: customProcedureDialog.SecondaryAssertions[index].ProjectFormCustomProcedureSecondaryAssertionId,
              ProjectFormCustomProcedureId: customProcedureDialog?.ProjectFormCustomProcedureId ?? null,
              AssertionId: assertion
            }
          }
           
          return {
              ProjectFormCustomProcedureSecondaryAssertionId: null,
              ProjectFormCustomProcedureId: customProcedureDialog?.ProjectFormCustomProcedureId ?? null,
              AssertionId: assertion
          }
      })
  }

  const resetDialogState = () => {
    setPrimaryAssertions(assertions)
    setSecondaryAssertions(assertions)  
    setRequiredProcName(false)
    setRequiredDesc(false)
    setCustomProcedureDialog(matchedCustomProcedure)
    closeDialog()
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
     ...customProcedureDialog,      
     PrimaryAssertions: customProcedureDialog?.PrimaryAssertions?.length > 0 ? customProcedureDialog.PrimaryAssertions.map((pa) => {
       const index = assertions.findIndex(assertion => assertion?.AssertionId == pa.AssertionId);           
       return assertions[index]['AssertionId']
     }) : [],
     SecondaryAssertions: customProcedureDialog?.SecondaryAssertions?.length > 0 ? customProcedureDialog.SecondaryAssertions.map((sa) => {
       const index = assertions.findIndex(assertion => assertion?.AssertionId == sa.AssertionId);        
       return assertions[index]['AssertionId']}): [],
    },    
    validate: values => {    
     const error = {};   
     
     if(!values?.Description) error.Description = true;
     if(!values?.CustomProcedureName) error.CustomProcedureName = true;           
     
     if(values.Description) {        
       const txt = richTextEditorTextContent(values.Description);      
       if(txt.length <= 0) {          
         error.Description = true;
       } else {
         setRequiredDesc(false)
       };
     }

     if(!values?.CustomProcedureName || values.CustomProcedureName?.length <= 0) {        
       error.CustomProcedureName = true
     } else {
       setRequiredProcName(false)
     };

     return error;
    },
    validationSchema: yup.object({
       CustomProcedureName: yup.string().required(),                
       Description: yup.string().required(),                                
   }),
    onSubmit: values => {        
      const formattedCustomProcedure = {
          ProjectFormCustomProcedureId: customProcedureDialog?.ProjectFormCustomProcedureId ?? null,
          ProjectFormId: parseInt(projectFormId),
          CustomProcedureName: values.CustomProcedureName,
          Description: values.Description,
          AuditAreaId: auditArea?.id,
          AuditAreaName: auditArea?.auditAreaName,
          SCOTABDId: values?.SCOTABDId ?? null ,
          SignOffUser: customProcedureDialog?.SignOffUser ?? null,
          SignOffDate: customProcedureDialog?.SignOffDate ?? null,
          WorkpaperReference: customProcedureDialog?.WorkpaperReference ?? null,
          Comment: customProcedureDialog?.Comment ?? null,
          PrimaryAssertions: mapPrimaryAssertions(values.PrimaryAssertions),
          SecondaryAssertions: mapSecondaryAssertions(values.SecondaryAssertions)
      }                        
      if(!isRequiredProcName && !isRequiredDesc) {
        saveCustomProcedure(formattedCustomProcedure)
        resetDialogState();
        formik.resetForm();
      }
    },
  });   

   const getDefaultValue = (propertyName, assertion = false) => {            
    if (assertion) {      
      return formik?.values[propertyName]
        ? formik?.values[propertyName]
        : customProcedureDialog[propertyName] && customProcedureDialog[propertyName].length > 0
        ? [''] : customProcedureDialog[propertyName].map(e => e.AssertionId) ;
    }
                    
    if(propertyName === 'Description') {
      return formik?.values[propertyName]
        ? formik?.values[propertyName]
        : customProcedureDialog[propertyName] && customProcedureDialog[propertyName].length > 0
        ? customProcedureDialog[propertyName] : '' ;
    }

    return formik?.values[propertyName]
        ? formik?.values[propertyName]
        : customProcedureDialog[propertyName]
        ? '' : customProcedureDialog[propertyName]  ;
  };

  return(
    <Dialog
      open={showDialog}
      fullWidth
      scroll="paper"
      variant="cla-dialog-tailoring-procedure">      
        <DialogTitle variant="cla-dialog-tailoring-procedure">          
          <strong>{TAILORING_PROCEDURE_FIELD.TITLE}</strong>              
        </DialogTitle >
        <form onSubmit={formik.handleSubmit}>
          <DialogContent variant="cla-dialog-tailoring-procedure">            
              <Grid item xs={12}>
                <CLATextField
                  id={'CustomProcedureName'}
                  name={'CustomProcedureName'}
                  label={TAILORING_PROCEDURE_FIELD.PROCEDURE_NAME}
                  placeholder={TAILORING_PROCEDURE_FIELD.ENTER_PROCEDURE_NAME}                
                  value={getDefaultValue('CustomProcedureName')}    
                  onChange={formik.handleChange}
                  inputLableShrink={true}
                  error={isRequiredProcName}
                />
                {isRequiredProcName && <Typography
                        variant="title"
                        sx={{ fontSize: '12px', pl: 1, color: 'red', fontWeight: 400 }}>
                      Required
                    </Typography>}
              </Grid>
              <Grid container wrap="wrap" sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                  <Grid item xs={5.8}>
                      <CLATextField
                        id={'AuditAreaName'}
                        name={'AuditAreaName'}
                        label={TAILORING_PROCEDURE_FIELD.AUDIT_AREA}
                        placeholder={auditArea?.auditAreaName}
                        value={auditArea?.auditAreaName}                           
                        disabled={true}       
                        inputLableShrink={true}
                      />
                  </Grid>
                  <Grid item xs={5.7}>
                    <CLADropdown               
                        id={'SCOTABDId'}    
                        name={'SCOTABDId'}                                                
                        label={TAILORING_PROCEDURE_FIELD.SCOTABDS}
                        value={getDefaultValue('SCOTABDId')}
                        options={[...mapDefaultOptions(scotabds, 'ClassOfTransactionName', 'SCOTABDId')]}                        
                        onChange={(values) => formik?.setFieldValue('SCOTABDId', values?.target?.value)}                        
                        placeholder={TAILORING_PROCEDURE_FIELD.SELECT}                      
                    />                           
                  </Grid>    
              </Grid>
              <Grid item xs={12}>
                  <div className={styles.richtext__wrapper}>
                    <span>{TAILORING_PROCEDURE_FIELD.STEP_DESCRIPTION}</span>
                    <CLARichTextEditor                    
                      placeholder={TAILORING_PROCEDURE_FIELD.ENTER_STEP_DESCRIPTION}                    
                      value={getDefaultValue('Description')}
                      onChange={(value) => formik?.setFieldValue('Description', value)}                      
                      expandable={false} 
                      error={isRequiredDesc}                                           
                    />
                    </div>
                  {isRequiredDesc && <Typography
                      variant="title" 
                      sx={{ fontSize: '12px', pl: 1, color: 'red', fontWeight: 400 }}>
                    Required
                  </Typography>}
              </Grid>
              <Grid container wrap="wrap" sx={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
                  <Grid item xs={5.8}>                      
                    <CLASelect
                      id={'PrimaryAssertions'}
                      name={'PrimaryAssertions'}
                      label={TAILORING_PROCEDURE_FIELD.PRIMARY_ASSERTIONS}
                      defaultValues={getDefaultValue('PrimaryAssertions', true)}                               
                      menuItems={[...mapDefaultOptions(primaryAssertionOptions, 'AssertionName', 'AssertionId')]}                      
                      onChange={(values) => {                         
                        setSecondaryAssertions(assertions.filter(a => !values.includes(a.AssertionId)))
                        formik?.setFieldValue(
                          'PrimaryAssertions',
                          values?.filter((i) => i)
                          );
                        }}
                        scaleMenuItemSize={false}
                        truncateDisplay
                        />
                  </Grid>
                  <Grid item xs={5.7}>                  
                    <CLASelect
                      id={'SecondaryAssertions'}
                      name={'SecondaryAssertions'}                 
                      label={TAILORING_PROCEDURE_FIELD.SECONDARY_ASSERTIONS}   
                      defaultValues={getDefaultValue('SecondaryAssertions', true)}                                   
                      menuItems={[...mapDefaultOptions(secondaryAssertionOptions, 'AssertionName', 'AssertionId')]}                 
                      onChange={(values) => {                             
                          setPrimaryAssertions(assertions.filter(a => !values.includes(a.AssertionId)))                                                                          
                          formik?.setFieldValue(
                              'SecondaryAssertions',
                              values?.filter((i) => i)
                          );
                      }}
                      scaleMenuItemSize={false}
                      truncateDisplay
                    />
                  </Grid>
              </Grid>
          </DialogContent>
          <DialogActions variant="cla-dialog-tailoring-procedure">
            <Button variant="outlined" onClick={() => {
                resetDialogState();                
                formik.resetForm();
              }}>
              Cancel
            </Button>
            <Button
              variant="contained"
              type="submit"
              onClick={() => {
                if(!formik.isValid) {
                  if(formik.errors?.CustomProcedureName) setRequiredProcName(true);
                  if(formik.errors?.Description) setRequiredDesc(true);
                }
              }}>
              Save
            </Button>
          </DialogActions>    
        </form>  
    </Dialog>
  )
};

export default CLATailoringProcedureDialog;