import formApi from "@utilities/claApiForm";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";

const fetchProjectRiskFactor = async (ProjectRiskFactorId, ProjectFormId, ProjectScopeId, ProjectId) => {
    try {
        const body = {
            ProjectRiskFactorId,
            ProjectFormId,
            ProjectScopeId,
            ProjectId,
        };
        const { data } = await formApi.post(`/riskfactors/project/${ProjectId}`, body);
        return data;
    } catch (error) {
        console.error(error);
    }
};

const fetchMethodologyVersionRiskFactor = async (ProjectId, projectId) => {
    try {
        const { data } = await formApi.get(`/riskfactors/methodologyversion/${ProjectId}/${projectId}`);
        return data;
    } catch (error) {
        console.error(error);
    }
};

export const updateRiskFactor = async (projectId, payload) => {
    const { data } = await formApi.put(`/riskfactors/project/${projectId}/update`, payload);
    return data;
}

export const createRiskFactor = async (projectId, payload) => {
    const { data } = await formApi.post(`/riskfactors/project/${projectId}/create`, payload);
    return data;
}

// EXPORTS ====================================================================
export const useGetProjectRiskFactor = (ProjectRiskFactorId, ProjectFormId, ProjectScopeId, ProjectId) => {
    return useQuery(
        ["ProjectRiskFactor", ProjectRiskFactorId, ProjectFormId, ProjectScopeId, ProjectId],
        () => fetchProjectRiskFactor(ProjectRiskFactorId, ProjectFormId, ProjectScopeId, ProjectId),
        {
            enabled: !!ProjectRiskFactorId || !!ProjectFormId || !!ProjectScopeId || !!ProjectId,
            refetchOnWindowFocus: false,
        }
    );
};

export const useDeleteProjectRisks = (projectId) => {
    const queryClient = useQueryClient()

    return useMutation(
        (ProjectRiskFactorId) => formApi.delete(`/riskfactors/project/${projectId}`, { data: { ProjectRiskFactorId }}),
        {
            onSuccess: () => {
                return queryClient.invalidateQueries('ProjectRiskFactor');
            }
        }
    )
}

export const useUpdateRiskFactor = (projectId) => {
    const queryClient = useQueryClient()

    return useMutation(
        (payload) => updateRiskFactor(projectId, payload),
        {
            onSuccess: () => {
                return queryClient.invalidateQueries('ProjectRiskFactor');
            }
        }
    )
}

export const useCreateRiskFactor = (projectId) => {
    const queryClient = useQueryClient()

    return useMutation(
        (payload) => createRiskFactor(projectId, payload),
        {
            onSuccess: () => {
                return queryClient.invalidateQueries('ProjectRiskFactor');
            }
        }
    )
}

export const useGetMethodologyVersionRiskFactor = (ProjectId, isDataLoaded, projectId) => {
    return useQuery(
        ["MethodologyVersionRiskFactor", ProjectId],
        () => fetchMethodologyVersionRiskFactor(ProjectId, projectId),
        {
            enabled: !!ProjectId && isDataLoaded,
        }
    );
};

export const useUpdateProjectRiskFactorComment = (projectId) => {
    const queryClient = useQueryClient()

    return useMutation(
        ({ProjectRiskFactorId, Comment}) => formApi.put(`/riskfactors/project/comment/${projectId}`, { ProjectRiskFactorId, Comment }),
        {
            onSuccess: () => {
                return queryClient.invalidateQueries('ProjectRiskFactor');
            }
        }
    )
}