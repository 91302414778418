import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import PropTypes from "prop-types";

import { getProjectRiskByAuditAreaAndProjectFormId } from "@services/projectRisk.js";

import CLAFinancialStatementLevelPersuasiveRisk from '@components/Forms/RiskAssessment/FinancialStatementLevelRisk/CLAFinancialStatementLevelPersuasiveRisk';

import { useLoading } from '@hooks/index';
import { useProjectFormInstanceProvider } from '@providers';
import styles from './CLAFinancialStatementLevelRisk.module.css';
import { RISK_ASSESSMENT_FORM_INSTANCE } from '@constants/forms';
import CustomToast from '@components/CustomToast/CustomToast';

const CLAFinancialStatementLevelRisk = (props) => {
  const { EN } = RISK_ASSESSMENT_FORM_INSTANCE;
  const { projectId } = useParams();
  const setLoading = useLoading();

  const {
    financialStatementLevelRiskToggle,
    financialStatementTableRows,
    loadingProjectRiskAssessmentSummary,
    successProjectRiskAssessmentSummary,
    errorProjectRiskAssessmentSummary,
    actions: {
      riskAssessmentIdentifyModifiedAnswerAfterSignOff
    },
    setStates: {
      setFinancialStatementTableRows
    }
  } = useProjectFormInstanceProvider();

  const { projectFormId, disabled } = props;

  const fetchData = async () => {
    try {
      setLoading(true);
      const result =
        await getProjectRiskByAuditAreaAndProjectFormId(projectFormId, projectId);
      const projectRisks = result?.data ?? [];
      const persuasiveRisks = projectRisks?.filter((projectrisk) => { return projectrisk.IsPervasive === true })
      setFinancialStatementTableRows(persuasiveRisks.map((persuasiveProjectRisk, index) => {
        return {
          ProjectRiskId: persuasiveProjectRisk.ProjectRiskId,
          identifiedRisk: persuasiveProjectRisk.RiskName,
          NatureAndExtentOfPervasiveEffect: persuasiveProjectRisk.NatureAndExtentOfPervasiveEffect,
          PlannedOverallResponse: persuasiveProjectRisk.PlannedOverallResponse
        }
      }));
      setLoading(false);
    } catch (error) {
      toast.error(error.toString());
      setLoading(false);
    }
  };
  
  useEffect(() => {
    fetchData();
    riskAssessmentIdentifyModifiedAnswerAfterSignOff();
  }, [financialStatementLevelRiskToggle]);

  return (
    <div className={styles['fin-state-level-container']}>
      <div className={styles['fin-state-level-header']}>
        <div className={styles['fin-state-level-header-text']}>
          {EN.FINANCIAL_STATEMENT_LEVEL_RISK.RISK_NAME_HEADER}
        </div>
        <div className={styles['fin-state-level-header-text']}>
          {EN.FINANCIAL_STATEMENT_LEVEL_RISK.PERVASSIVE_EFFECT}
        </div>
        <div className={styles['fin-state-level-header-text']}>
          {EN.FINANCIAL_STATEMENT_LEVEL_RISK.OVERALL_RESPONSE}
        </div>
      </div>
      <div className={styles['fin-state-level-main']}>
        <div className={styles['fin-state-level-main-content']}>
          {financialStatementTableRows.map((row, index) => (
            <CLAFinancialStatementLevelPersuasiveRisk
              index={index}
              row={row}
              disabled={disabled}
            />
          ))}
        </div>
      </div>
      <CustomToast 
        error={errorProjectRiskAssessmentSummary}
        success={successProjectRiskAssessmentSummary}
        loading={loadingProjectRiskAssessmentSummary}
      />
    </div>
  )
}

CLAFinancialStatementLevelRisk.propTypes = {
  projectFormId: PropTypes.number,
  disabled: PropTypes.bool,
}

export default CLAFinancialStatementLevelRisk;