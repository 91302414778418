import { useState, useEffect } from 'react'

import {
    InternalControlTextbox,
} from "@ais/components";
import useProjectFormInstanceConcurrentLocking from '@hooks/useProjectFormInstanceConcurrentLocking'
import ConcurrentLockTooltip from '@components/ConcurrentLockTooltip/ConcurrentLockTooltip'

export const ConcurrentInternalControlTextbox = ({
    label,
    placeholder,
    maxLength = 4096,
    nonMultilineLabelFontSize,
    nonMultilineLabelMarginLeft,
    onBlur,
    onFocus,
    value,
    customFormObjectId,
    ...props
}) => {
    const [content, setContent] = useState('')

    const {
        lockingUser,
        emitLockEvent, 
        emitUnlockEvent, 
        concurrentValue,
    } = useProjectFormInstanceConcurrentLocking
        (
            customFormObjectId,
            {
                customFormObjectId
            }
        );

    useEffect(() => {
        setContent(value)
    }, [value])

    useEffect(() => {
        if(typeof concurrentValue?.value === 'string') setContent(concurrentValue?.value)
    }, [concurrentValue])

    const handleBlur = async (e) => {
        await emitUnlockEvent({ value: e.target.value });
        if(typeof onBlur === 'function') onBlur(e);
    }

    const handleFocus = async (e) => {
        await emitLockEvent({ value: e.target.value })
        if(typeof onFocus === 'function') onFocus(e);
    }

    const handleOnChange = (event) => {
        setContent(event.target.value)
    }

    return (
        <ConcurrentLockTooltip lockingUser={lockingUser}>
            <InternalControlTextbox
                label={label}
                placeholder={placeholder}
                value={content}
                maxLength={maxLength}
                nonMultilineLabelFontSize={nonMultilineLabelFontSize}
                nonMultilineLabelMarginLeft={nonMultilineLabelMarginLeft}
                onBlur={handleBlur}
                onFocus={handleFocus}
                onChange={handleOnChange}
                {...props}
            />
        </ConcurrentLockTooltip>
    )
}

export default ConcurrentInternalControlTextbox