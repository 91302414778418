import { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Grid, Stack } from '@mui/material';

import projectScopeAuditAreaCategory from '@services/forms/projectscopeauditareacategory';
import {CLASimpleDrawer} from '@ais/components';
import { useLoading, useProjectScope } from '@hooks/index';
import { FinancialStatementLineTable } from '.';
import * as Constants from '@constants/index';

export const FinancialStatementLineDrawer = (props) => {
    const { BUTTON } = Constants.TRIAL_BALANCE;
    const { projectId, projectFormId } = useParams();
    const { visible, setVisible, currentAuditArea, financialStatements, fetchData, trialBalances } = props;

    const { ProjectScopeAuditAreaId: projectScopeAuditAreaId } = currentAuditArea;
    const [selectedFinancialStatement, setSelectedFinancialStatement] = useState([]);
    const [financialStatementsInOtherAuditArea, setFinancialStatementsInOtherAuditArea] = useState([]);
    const [defaultFinancialStatement, setDefaultFinancialStatement] = useState([]);
    const setLoading = useLoading();
    const {
        fetchProjectScopeData,
      } = useProjectScope()

    const handleSave = async () => {
        setLoading(true);

        const financialStatementData = selectedFinancialStatement.map((data) => ({
            projectScopeAuditAreaCategoryId: !isNaN(data?.projectScopeAuditAreaCategoryId) ? data.projectScopeAuditAreaCategoryId : null,
            financialStatementCategoryName: data?.financialStatementCategoryName ?? '',
            balance: data?.balance,
            transactionCount: data?.transactionCount ?? null,
            isUserEnteredCategory: data?.isUserEnteredCategory ?? false,
        }));

        try {
            await projectScopeAuditAreaCategory.saveProjectScopeAuditAreaCategory(
                projectId,
                projectScopeAuditAreaId,
                projectFormId,
                financialStatementData
            );
        } catch (error) {
            console.error(error);
        } finally {
            await fetchData(projectScopeAuditAreaId);
            await fetchProjectScopeData()
            setLoading(false);
            setVisible(false);
        }
    };

    const initData = async () => {
        const found = financialStatements.find((financial) => financial.ProjectScopeAuditAreaId === projectScopeAuditAreaId);
        if (found) setDefaultFinancialStatement(found.FinancialStatementLineItems);
    };

    const handleSelectedFinancialStatements = (financialStatements) => {
        setSelectedFinancialStatement(financialStatements);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    useEffect(async () => {
        if (!financialStatements || !financialStatements.length) return;
        await initData();
    }, [financialStatements, currentAuditArea]);

    useEffect(() => {
        if (!financialStatements || !projectScopeAuditAreaId) return;
        const otherAuditAreas = financialStatements.filter(
            (auditArea) => auditArea.ProjectScopeAuditAreaId != projectScopeAuditAreaId
        );
        if (
            !!otherAuditAreas?.length &&
            JSON.stringify(financialStatementsInOtherAuditArea) != JSON.stringify(otherAuditAreas)
        )
            setFinancialStatementsInOtherAuditArea([
                ...new Set(otherAuditAreas.flatMap((auditArea) => auditArea.FinancialStatementLineItems)),
            ]);
    }, [projectScopeAuditAreaId, financialStatements]);

    const isSaveButtonDisabled = useMemo(() => {
        if (defaultFinancialStatement?.length > 0) return false;
        
        return (
            selectedFinancialStatement.length === 0 ||
            selectedFinancialStatement.some((fs) => !fs.financialStatementCategoryName)
        );
    }, [defaultFinancialStatement, selectedFinancialStatement]);

    return (
        <CLASimpleDrawer
            anchor="right"
            isVisible={visible}
            onClose={() => setVisible(false)}
            headerTitle="Financial Statement Line Items"
        >
            <Grid container justifyContent="flex-end" p={3}>
                <Grid item>
                    <Stack direction="row" spacing={3}>
                        <Button variant="outlined" onClick={handleCancel}>
                            {BUTTON.CANCEL}
                        </Button>
                        <Button variant="contained" onClick={handleSave} disabled={isSaveButtonDisabled}>
                            {BUTTON.SAVE}
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
            <FinancialStatementLineTable
                financialStatements={financialStatements?.find(data => data.ProjectScopeAuditAreaId === projectScopeAuditAreaId) ?? []}
                trialBalances={trialBalances}
                defaultFinancialStatement={defaultFinancialStatement}
                financialStatementsInOtherAuditArea={financialStatementsInOtherAuditArea}
                handleSelectedFinancialStatements={handleSelectedFinancialStatements}
            />
        </CLASimpleDrawer>
    );
};
