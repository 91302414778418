import { useContext } from 'react'
import { useParams } from "react-router-dom"

import DoNotDisturbAltIcon from '@mui/icons-material/DoNotDisturbAlt';
import Button from "@mui/material/Button"
import CLAHoveringTooltip from '@components/CLAHoveringTooltip/CLAHoveringTooltip';
import { useFinalizedProject } from "@hooks/useProject"
import { TailorProcedureContext } from '@components/Forms/state';

import * as Constants from "@constants/index"
import useTailorProceduresConcurrency from '@hooks/useTailorProceduresConcurrency';

const TailorProceduresBaseButton = ({onClick, disabled, ...props}) => (
    <Button
        data-testid="tailor-procedures-btn"
        variant="contained"
        sx={{ marginRight: "30px" }}
        disabled={disabled}
        onClick={onClick}
        {...props}>
            {disabled ? <DoNotDisturbAltIcon style={{ marginRight: 8, fontSize: 15 }}/> : null}
            { Constants.PROJECT_FORM_INSTANCE.PROCEDURE.TAILOR_PROCEDURES }
    </Button>
)

const TailorProcedureButton = ({onClick, sectionId, ...props}) => {

    const { tailorProcedure = {} } = useContext(TailorProcedureContext);
    const { projectId } = useParams();
    const {
        isOpened,
        emitOpenEvent 
    } = useTailorProceduresConcurrency({
        procedureComponentId: sectionId,
    })
    const isProjectFinalized = useFinalizedProject(projectId);

    const onClickButton = () => {
        emitOpenEvent({tailorProcedure: tailorProcedure[sectionId]})
        onClick();
    }

    return (<CLAHoveringTooltip 
        title={isOpened ? Constants.PROJECT_FORM_INSTANCE.PROCEDURE.TAILOR_PROCEDURES_OPENED : null}
        placement="top" 
        arrow
        theme={{ padding: "1.2em", lineHeight: "1em" }}
    >
        <TailorProceduresBaseButton 
            onClick={onClickButton}
            disabled={isProjectFinalized || isOpened} 
            {...props}
        />
    </CLAHoveringTooltip>)
    
}

export default TailorProcedureButton
