import React, { useState, useEffect } from 'react';
import MaterialWeakness from "./MaterialWeakness/MaterialWeakness";
import SignificantDeficiency from "./SignificantDeficiency/SignificantDeficiency";
import ManagementCommentDeficiency from "./ManagementCommentDeficiency/ManagementCommentDeficiency";

import style from "./CLACommunicationOfDeficiencies.module.css";
import { DEFICIENCY_FORM_INSTANCE } from '@constants/forms';
import { useParams } from 'react-router-dom';
import { useLoading } from '@hooks/index';
import { useGetProjectDeficiencies } from '@services/deficiencies';
import { useGetProjectDeficiencyCombinations } from '@services/deficiencyCombination';
import { useProjectFormInstanceProvider } from '../../../../providers';
import { useCommunicationStore } from './communication';

const CLACommunicationOfDeficiencies = (props) => {
    const {signOffList} = props;
    const updateLatestSignoffTime = useCommunicationStore(state => state.updateLatestSignoffTime);
    const { unitIds } = useProjectFormInstanceProvider();
    const { COMMUNICATION_OF_DEFICIENCIES } = DEFICIENCY_FORM_INSTANCE.EN;
    const [expandAll, setExpandAll] = useState(false);
    const [deficiencies, setDeficiencies] = useState([]);
    const setLoading = useLoading();
    const { projectId, projectFormId} = useParams();

    const {
        data: ProjectDeficiencies,
        isLoading: isLoadingProjectDeficiencies
    } = useGetProjectDeficiencies(null, projectId, null, unitIds, projectFormId);

    const {
        data: ProjectDeficiencyCombinations,
        isLoading: isLoadingProjectDeficiencyCombinations
    } = useGetProjectDeficiencyCombinations(projectId, unitIds, projectFormId);

    useEffect(() => {          
        let newDeficiency = [];

        if (ProjectDeficiencies && ProjectDeficiencies.status === 200) {
            newDeficiency.push(...ProjectDeficiencies.data)
        }

        if (ProjectDeficiencyCombinations && ProjectDeficiencyCombinations.status === 200) {
            const newDeficiencyIdsToBeRemoved = []
            ProjectDeficiencyCombinations.data.forEach(combination => {
                combination.Deficiencies.forEach(def => {
                    if (newDeficiency.some(newDef => newDef.ProjectDeficiencyId === def.ProjectDeficiencyId)) {
                        newDeficiencyIdsToBeRemoved.push(def.ProjectDeficiencyId);
                    }
                })
            });

            newDeficiency = newDeficiency.filter(def => !newDeficiencyIdsToBeRemoved.includes(def.ProjectDeficiencyId));
            newDeficiency.push(...ProjectDeficiencyCombinations.data)
        }

        setDeficiencies(newDeficiency)
    }, [ProjectDeficiencies, ProjectDeficiencyCombinations])

    useEffect(() => {
        if (isLoadingProjectDeficiencies || isLoadingProjectDeficiencyCombinations) setLoading(true);
        else setLoading(false);
    }, [isLoadingProjectDeficiencies, isLoadingProjectDeficiencyCombinations]);


    const handleExpandAll = () => {
        setExpandAll(true);
    }

    const handleCollapseAll = () => {
        setExpandAll(false);
    }

    useEffect(() => {
        const reviewers = signOffList.filter(signoff => signoff.signOffLevel !== 1);
        const latestSignoffTime = new Date(reviewers[reviewers?.length - 1]?.signOffDate).getTime();
        updateLatestSignoffTime(latestSignoffTime)
    }, [signOffList])

    return <div>
        <div className={style['section-header-container']}>
            <div className={style['section-header-title']}>{COMMUNICATION_OF_DEFICIENCIES.SECTION_HEADER}</div>
            <div className={style['section-header-expand-all']}>
                {expandAll ? <a href="#collapse" className={style.expand_all_link} onClick={() => handleCollapseAll()}>{COMMUNICATION_OF_DEFICIENCIES.COLLAPSE_ALL}</a> :
                    <a href="#expand" className={style.expand_all_link} onClick={() => handleExpandAll()}>{COMMUNICATION_OF_DEFICIENCIES.EXPAND_ALL}</a>
                }
            </div>
        </div>
        <div className={style['sub-section-container']}>
            <MaterialWeakness expand={expandAll} projectId={projectId} deficiencies={deficiencies} />
        </div>
        <div className={style['sub-section-container']}>
            <SignificantDeficiency expand={expandAll} projectId={projectId} deficiencies={deficiencies} />
        </div>
        <div className={style['sub-section-container']}>
            <ManagementCommentDeficiency expand={expandAll} projectId={projectId} deficiencies={deficiencies} />
        </div>
    </div>
}

export default CLACommunicationOfDeficiencies;