import moment from 'moment';
import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { get, set, del } from 'idb-keyval';

import * as Constants from '@constants/users.js';
import usersServices from "@services/users";
import convertDateTimeToUTC from '@utilities/convertDateTimeToUTC';

const isDateValid = (date, measurement, measurementValue) => {
  return moment(convertDateTimeToUTC(Date.now())).diff(moment(date), measurement) < measurementValue;
};

const customStorage = {
  getItem: async name => {
    return (await get(name)) || null;
  },
  setItem: async (name, value) => {
    await set(name, value);
  },
  removeItem: async name => {
    await del(name);
  }
};

const useUserListStore = create(
  persist(
    (set, get) => ({
      userList: [],
      addUser: user => set(state => ({
        userList: [
          ...state.userList,
          {
            ...user,
            dateCached: convertDateTimeToUTC(Date.now())
          }
        ]
      })),
      removeUser: activeDirectoryId => set(state => ({
        userList: state.userList.filter(user => user.activeDirectoryId.toUpperCase() !== activeDirectoryId.toUpperCase())
      })),
      cleanupStorage: usersCachedLifespanDays => set(state => ({
        userList: state.userList.filter(user => isDateValid(user.dateCached, 'days', usersCachedLifespanDays))
      })),
      findUser: userId => get().userList.find(user => user.activeDirectoryId.toUpperCase() === userId.toUpperCase()),
      getUserListLength: () => get().userList.length
    }),
    {
      name: 'user-list-storage',
      storage: createJSONStorage(() => customStorage),
    }
  )
);

export const useUserList = () => {
  const findUser = useUserListStore(state => state.findUser);
  const addUser = useUserListStore(state => state.addUser);
  const removeUser = useUserListStore(state => state.removeUser);
  const cleanupStorage = useUserListStore(state => state.cleanupStorage);
  const getUserListLength = useUserListStore(state => state.getUserListLength);

  const maxUserListCachedMinutes = process.env.REACT_APP_MAX_USER_LIST_CACHED_MINS ?? 24 * 60;
  const usersCachedThreshold = process.env.REACT_APP_USERS_CACHED_THRESHOLD ?? 50;
  const usersCachedLifespanDays = process.env.REACT_APP_USERS_CACHED_LIFESPAN_DAYS ?? 3;

  const cleanup = () => {
    if (getUserListLength() >= usersCachedThreshold) {
      cleanupStorage(usersCachedLifespanDays);
    }
  };
  
  const getUserById = async id => {
    if (!id) return undefined;
    
    const cachedUser = findUser(id);

    if (cachedUser) {
      if (isDateValid(cachedUser.dateCached, 'minutes', maxUserListCachedMinutes))
        return cachedUser;
      removeUser(cachedUser.activeDirectoryId);
    }

    const user = await usersServices.getbyId(id);

    if (user) {
      try{
        addUser(user);
      } catch {
        console.warn(Constants.CACHE_ERROR);
      }
    }      
    
    cleanup();

    return user;
  };

  const getUsersById = async ids => {
    const cachedUsers = [];
    const idsToCallAPI = [];

    if (!ids || ids.length === 0) return [];

    ids.forEach(id => {
      const cachedUser = findUser(id);
      if (cachedUser && isDateValid(cachedUser.dateCached, 'minutes', maxUserListCachedMinutes)) cachedUsers.push(cachedUser);
      else {
        if (cachedUser) 
          removeUser(cachedUser.activeDirectoryId)
        idsToCallAPI.push(id);
      }
    });

    const retrievedUsers = idsToCallAPI.length > 0 ? await usersServices.getByIdList(idsToCallAPI) : [];
    
    try {
      retrievedUsers.forEach(user => addUser(user))
    } catch {
      console.warn(Constants.CACHE_ERROR);
    }

    cleanup();
    
    return [...cachedUsers, ...retrievedUsers];
  }

  return {
    getUserById,
    getUsersById,
  }
}
