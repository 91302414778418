import { useEffect, useRef, useState } from 'react'
import styled from '@emotion/styled'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { Flex } from '@ais/palette'
import {CLAPerson} from '@ais/components';
import {useUserList} from '@hooks/index';
import PropTypes from 'prop-types';

const ConcurrentLockWrapper = styled(({ className, ...props }) => (
    <Tooltip 
        classes={{ popper: className }} 
        {...props}
    />
))({
    [`& .${tooltipClasses.arrow}`]: {
        color: '#ffd7d4',
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#ffd7d4',
        color: '#bc2142',
        fontWeight: '400',
        fontSize: '.6rem',
        maxWidth: 500
    },
});

const ConcurrentLockCLAPerson = styled(CLAPerson)`
  height: 40px;
  width: 40px;
  --avatar-size: 2.8rem;
  color: #565656;
`;

const ConcurrentLockTitle = ({lockingUser}) => {
    const [account, setAccount] = useState();
    const {getUserById} = useUserList();

    useEffect(() => {
        const getUserData = async () => {
            const data = await getUserById(lockingUser.userId);
            setAccount(data);
        }

        getUserData();
    }, [])

    return (<>
        <Flex direction="row">
            <span style={{ marginRight: '1rem' }}>
                <ConcurrentLockCLAPerson
                    account={account}
                    personCardInteraction='none'
                />
            </span>
            <p style={{ margin: 'auto', padding: '.1rem' }}>{lockingUser?.alternativeName} is currently<br />editing this item</p>
        </Flex>
    </>)
}

const ConcurrentLockTooltip = ({lockingUser, children, offset = [0, -120], ...props}) => {
    const areaRef = useRef(null);

    const getBoundingClientRect = () => {
        const boundingRect = areaRef?.current.getBoundingClientRect();
        const centerX = boundingRect.left + boundingRect.width / 2;
        const centerY = (boundingRect.top + boundingRect.height / 2) + 30;
        return new DOMRect(centerX, centerY, 0, 0);
    }
    
    return (
        !!lockingUser ? <ConcurrentLockWrapper
            title={<ConcurrentLockTitle lockingUser={lockingUser}/>}
            open={true}
            placement="top"
            PopperProps={{
                anchorEl: {
                    getBoundingClientRect
                },
                sx: {
                    zIndex: 100
                }
            }} 
        >
            <div ref={areaRef} style={{ pointerEvents: 'none' }}>
                <div className={props?.className} style={props?.style}>
                    {children}
                </div>
            </div>
        </ConcurrentLockWrapper>
        : <>{children}</>
    )
}

ConcurrentLockTooltip.propTypes = {
    lockingUser: PropTypes.shape({
      userId: PropTypes.string,
      alternativeName: PropTypes.string,
    }),
    children: PropTypes.node.isRequired,
    offset: PropTypes.arrayOf(PropTypes.number),
    className: PropTypes.string,
    style: PropTypes.object,
};

export default ConcurrentLockTooltip;