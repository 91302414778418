import React, {
    useEffect,
    useState,
    forwardRef,
    useImperativeHandle
} from 'react';

import {
    TableRow,
    TableCell
} from '@mui/material';

import { renderField } from './helpers';

const CLAEvaluationOfDeficienciesTableRow = forwardRef(({ row: _row, columns, handleChange, highlightedDeficiencyIds}, ref) => {
    const [row, setRow] = useState(_row)
    const returnRow = () => {
        const obj = Object.assign({}, row)
        return obj
    }

    const shouldHighlight = highlightedDeficiencyIds.includes(row.ProjectDeficiencyId);

    const renderTableCell = () => {
        return columns.map((column, index) => {
            const { type, field, attributes, maxWidth, minWidth } = column;
            const value = row[field];            

            const data = {
                type,
                value,
                field,
                row,
                handleChange,
                column
            };

            return (
                <TableCell
                    key={index}
                    className={'MuiDataGrid-columnHeader MuiDataGrid-columnHeaderTitleContainer'}
                    sx={{
                        p: '0',
                        display: 'flex',
                        maxWidth: maxWidth,
                        minWidth: minWidth,
                        flexGrow: 1,
                        border: 0,
                        alignItems: 'center'
                    }}>
                    {renderField(data, attributes, handleChange)}
                </TableCell>)
        })
    }

    useImperativeHandle(ref, () => ({
        returnRow() {
            return returnRow()
        }
    }))

    useEffect(() => {
        setRow(_row);
    }, [_row])

    return (
        <TableRow
            id={row.id}
            className={'MuiDataGrid-columnHeadersInner'}
            sx={{
                display: 'grid',
                gridAutoFlow: 'column',
                gridAutoColumns: '1fr',
                p: '0 10px',
                borderBottom: '1px solid rgba(224, 224, 224, 1)',
                ...(shouldHighlight && {
                    border: '4px dashed red'
                })
            }}
        >
            {renderTableCell()}
        </TableRow>
    );
})

export default CLAEvaluationOfDeficienciesTableRow;
