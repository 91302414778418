import { useState, useEffect } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Checkbox,
  Grid,
  OutlinedInput,
} from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import { colors } from '@ais/theme';
import { CLASelect } from '@ais/forms';
import { ButtonComponent } from '.';

export const SCOTABDsTable = (props) => {
  const { accountBalances, assertions, defaultScotabds, filteredScotabds, onChange } = props;

  const [scotabds, setScotabds] = useState([]);
  const [selectedScotabds, setSelectedScotabds] = useState([]);
  const [customScotabds, setCustomScotabds] = useState({});
  const [_accountBalances, setAccountBalances] = useState([]);
  const [_assertions, setAssertions] = useState([]);
  // ? Accounting Estimate and Disclosure
  const [aedList, setAedList] = useState([]);

  const handleCheckboxChange = (e, type) => {
    const checked = e.target.checked;
    const value = parseInt(e.target.value);
    if (checked) {
      if (type === 'scotabd') setSelectedScotabds((prevState) => [...prevState, value]);
      else if (type === 'aed') setAedList((prevState) => [...prevState, value]);
    } else {
      if (type === 'scotabd')
        setSelectedScotabds((prevState) => prevState.filter((p) => p !== value));
      else if (type === 'aed') setAedList((prevState) => prevState.filter((p) => p !== value));
    }
  };

  const handleAddCustom = () => {
    setCustomScotabds((prevState) => {
      const id = uuidv4();
      const copy = { ...prevState };
      copy[id] = {
        enabled: true,
        name: '',
        accountBalances: [],
        assertions: [],
        isAed: false,
      };
      return copy;
    });
  };

  const handleChangeCustom = (id, name, value) => {
    setCustomScotabds((prevState) => {
      const copy = { ...prevState };
      const values = copy[id];
      values[name] = value;
      copy[id] = values;
      return copy;
    });
  };

  useEffect(() => {
    setAccountBalances(
      accountBalances.map((ab) => ({
        label: ab.AccountBalanceName,
        value: ab.AccountBalanceTypeId,
      }))
    );
    setAssertions(assertions.map((a) => ({ label: a.AssertionName, value: a.AssertionId })));
  }, [accountBalances, assertions]);

  useEffect(() => {
    if (filteredScotabds?.length) setScotabds(filteredScotabds)
  }, [filteredScotabds]);

  useEffect(() => {
    const selected = [];
    const aedList = [];
    defaultScotabds
      .filter((ds) => ds.SCOTABDId)
      .forEach((ds) => {
        selected.push(ds.SCOTABDId);
        if (ds.IsAccountingEstimateAndDisclosure) aedList.push(ds.SCOTABDId);
      });
    const _customScotabds = {};
    defaultScotabds
      .filter((ds) => !ds.SCOTABDId)
      .forEach((ds, i) => {
        _customScotabds[i] = {
          enabled: true,
          name: ds.ClassOfTransactionName,
          accountBalances: [],
          assertions: [],
          accountBalances: JSON.parse(ds.AccountBalanceTypeList).map(
            (abtl) => abtl.AccountBalanceTypeId
          ),
          assertions: JSON.parse(ds.AssertionList).map((al) => al.AssertionId),
          isAed: ds.IsAccountingEstimateAndDisclosure,
          HasProjectRisk: ds.HasProjectRisk,
          HasProjectRiskFactor: ds.HasProjectRiskFactor,
          ProjectScopeAuditAreaSCOTABDId: ds.ProjectScopeAuditAreaSCOTABDId
        };
      });
    setSelectedScotabds(selected);
    setCustomScotabds(_customScotabds);
    setAedList(aedList);
  }, [defaultScotabds]);

  useEffect(() => {
    if (onChange) {
      onChange(
        selectedScotabds
          .map((ss) => ({
            ...scotabds.find((s) => s.SCOTABDId === ss),
            IsAccountingEstimateAndDisclosure: aedList.includes(ss),
          }))
          .sort((a, b) => a.SCOTABDId - b.SCOTABDId),
        Object.keys(customScotabds).map((id) => ({ id, ...customScotabds[id] }))
      );
    }
  }, [selectedScotabds, customScotabds, aedList, scotabds]);
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: colors.gray[30] }}>
              <TableCell width="8%"></TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Classes of Transactions</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Account Balances</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Assertions</TableCell>
              <TableCell width="16%" sx={{ fontWeight: 'bold' }}>
                Accounting Estimates and Related Disclosures
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredScotabds.map((fs, i) => {
              const value = fs.SCOTABDId;
              const currentScotabds = defaultScotabds.find(ss => ss.SCOTABDId === value);
              const disabled = selectedScotabds.includes(value) && (currentScotabds?.HasProjectRisk || currentScotabds?.HasProjectRiskFactor);
              return (
                <TableRow key={i}>
                  <TableCell>
                    <Checkbox
                      checked={selectedScotabds.includes(value)}
                      value={value}
                      disabled={disabled}
                      onChange={(e) => handleCheckboxChange(e, 'scotabd')}
                    />
                  </TableCell>
                  <TableCell>{fs.ClassOfTransactionName}</TableCell>
                  <TableCell>
                    {fs.AccountBalances.map((ab) => ab.AccountBalanceName).join(', ')}
                  </TableCell>
                  <TableCell>{fs.Assertions.map((as) => as.AssertionName).join(', ')}</TableCell>
                  <TableCell>
                    <Checkbox
                      checked={aedList.includes(value)}
                      value={value}
                      onChange={(e) => handleCheckboxChange(e, 'aed')}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
            {Object.keys(customScotabds).map((id, i) => {
              const disabled = (customScotabds[id]?.HasProjectRisk || customScotabds[id]?.HasProjectRiskFactor);
              return (
                <TableRow key={i}>
                  <TableCell>
                    <Checkbox
                      checked={customScotabds[id].enabled}
                      disabled={disabled}
                      onChange={(e) => {
                        const checked = e.target.checked;
                        handleChangeCustom(id, 'enabled', checked);
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <OutlinedInput
                      sx={{ padding: 0 }}
                      value={customScotabds[id].name}
                      onChange={(e) => {
                        const value = e.target.value;
                        handleChangeCustom(id, 'name', value);
                      }}
                      placeholder="Enter class of transaction"
                      inputProps={{ maxLength: 256 }}
                      fullWidth
                    />
                  </TableCell>
                  <TableCell>
                    <CLASelect
                      defaultValues={customScotabds[id].accountBalances}
                      menuItems={_accountBalances}
                      placeholder="Select"
                      truncateDisplay
                      scaleMenuItemSize={false}
                      onChange={(values) => {
                        handleChangeCustom(id, 'accountBalances', values);
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <CLASelect
                      defaultValues={customScotabds[id].assertions}
                      menuItems={_assertions}
                      placeholder="Select"
                      truncateDisplay
                      scaleMenuItemSize={false}
                      onChange={(values) => {
                        handleChangeCustom(id, 'assertions', values);
                      }}
                    />
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      checked={customScotabds[id].isAed}
                      onChange={(e) => {
                        const checked = e.target.checked;
                        handleChangeCustom(id, 'isAed', checked);
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container p={3} pb={6}>
        <Grid item xs>
          <ButtonComponent label={null} buttonName="Add custom SCOTABD" onClick={handleAddCustom} />
        </Grid>
      </Grid>
    </>
  );
};
