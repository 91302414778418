import React, { memo } from "react";

import { UnderstandingOfEntityProvider } from "@ais/contexts";
import { CommunicationOfSignificantsMattersContextProvider } from "@contexts/CommunicationOfSignificantsMattersContext";
import { FIELD_TYPES, INTERNAL_CONTROLS_CONSTANTS } from "@ais/constants"; 

import styles from "./FormView.module.css";

import PurposeObjectiveProcedure from './Sections/PurposeObjectiveProcedure/PurposeObjectiveProcedure'
import UOEAuditAreaTable from './Sections/UnderstandingOfEntity/UOEAuditAreaTable'
import EvaluatingTheDesign from './Sections/EvaluatingTheDesign/EvaluatingTheDesign'
import CommunicationOfSiginificantMatters from './Sections/CommunicationOfSiginificantMatters/CommunicationOfSiginificantMatters'

export const VFFields = memo((props) => {
    const {
        field,
        projectFormId,
        disabled: _disabled,
        isInstance,
        signOffList,
    } = props;
 
    switch (field.type) {
        case FIELD_TYPES.PURPOSE_OBJECTIVE_PROCEDURE: {
            return <PurposeObjectiveProcedure />;
        }
        // case FIELD_TYPES.COMMUNICATION_OF_SIGNIFICANT_MATTERS: 
        case FIELD_TYPES.UNDERSTANDING_OF_ENTITY: {
            return (
                <div className={styles.field__wrapper}>
                    <div className={styles.field__wrapper_inner}>
                        {isInstance ? (
                            <UnderstandingOfEntityProvider>
                                <UOEAuditAreaTable signOffList={signOffList} />
                            </UnderstandingOfEntityProvider>
                        ) : (
                            <div className={styles['vf-section-placeholder']}>
                                {INTERNAL_CONTROLS_CONSTANTS.CUSTOM_COMPONENT_ENABLED}
                            </div>
                        )}
                    </div>
                </div>
            );
        }
        case FIELD_TYPES.EVALUATING_KEY_CONTROL: {
            return (
                <>
                    {isInstance ? (
                        <EvaluatingTheDesign projectFormId={projectFormId} signOffList={signOffList}/>
                    ) : (
                        <div className={styles['vf-section-placeholder']}>
                            {INTERNAL_CONTROLS_CONSTANTS.CUSTOM_COMPONENT_ENABLED}
                        </div>
                    )}
                </>
            );
        }
        case FIELD_TYPES.COMMUNICATION_OF_SIGNIFICANT_MATTERS: {
            return (
                <>
                    {isInstance ? (
                        <CommunicationOfSignificantsMattersContextProvider>
                            <CommunicationOfSiginificantMatters signOffList={signOffList}/>
                        </CommunicationOfSignificantsMattersContextProvider>
                    ) : (
                        <div className={styles['vf-section-placeholder']}>
                            {INTERNAL_CONTROLS_CONSTANTS.CUSTOM_COMPONENT_ENABLED}
                        </div>
                    )}
                </>
            );
        }
        default: {
            return null;
        }
    }
});
