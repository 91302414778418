import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
    Collapse,
    IconButton,
    Grid,
    TableRow,
    TableCell,
    TextField,
    Box,
    Typography
} from '@mui/material';
import { sanitize } from 'dompurify';
import {
    updateProjectRiskAssessmentSummary,
    multiOpProjectRiskAuditAreaFinancialStatementProjectRisk
} from "@services/projectRisk.js";
import { useAccountAssertionLevelRiskState } from './AccountAssertionLevelRiskContext';
import { CLADropdown, CLASelect } from '@ais/forms';
import { CLATooltip } from "@ais/components";
import HelpIcon from "@mui/icons-material/Help";
import string from "@utilities/stringHelper";
import { isArrayEqual } from "@utilities/arrayHelpers";

import { CollapseIconComponent, ExpandIconComponent } from "@ais/assets";
import styles from './CLAAccountAssertionLevelRisk.module.css';
import {
    cellBorderStyles,
    wrapCellStyles,
    cellStyles,
    riskGridCell,
    txtReferenceStyles,
    selectStyles,
    financialSelectStyles
} from './muiStyles';

import { useProjectFormInstanceProvider } from '@providers';
import { RISK_ASSESSMENT_FORM_INSTANCE } from '@constants/forms';
import { PROJECT_FORM_INSTANCE } from '@constants/index';
import CustomToast from '@components/CustomToast/CustomToast';
import { useUpdateMyPresence, useRoomIdle } from "@views/RiskAssesmentSummary/RoomProvider";
import { useOthers } from '../../store/users';

import { WAITING_TIME } from '@ais/constants';

import VFRenderedFieldWrapper from "../FormView/VFRenderedFieldWrapper"
import { useQueryClient } from "@tanstack/react-query";
import { useRiskAssessment } from '../../hooks/useRiskAssessment';

const CLARiskCollapsible = (props) => {
    const {
        ACCOUNT_ASSERTION_LEVEL_RISK: CONSTANT_TEXT,
        RISK_LEVEL_INHERIT_RISK,
        RISK_LEVEL_CONTROL_RISK,
        RISK_MATERIAL_MISSTATEMENT_MATRIX: MATRIX,
        RISKS_KEYVALUE_INHERENT,
        RISKS_KEYVALUE_CONTROL,
        RISKS_KEYVALUE_RMM,
        COMMON_IDENTIFIED_RISK_TOOLTIP
    } = RISK_ASSESSMENT_FORM_INSTANCE.EN;
    const HIGHLIGHT_BORDER = PROJECT_FORM_INSTANCE.EN.HIGHLIGHT_BORDER;
    const {
        inherentRiskOptions,
        controlRiskOptions,
        isInternalControlEffectiveness,
        risk,
        riskFactorInfo,
        index,
        financialRisks,
        scotabd,
        disabled,
        isExpanded: isParentExpanded,
    } = props;

    const others = useOthers();
    const updateMyPresence = useUpdateMyPresence()
    const isIdle = useRoomIdle()
    
    const { projectId, projectFormId } = useParams();
    const { expandAll } = useAccountAssertionLevelRiskState();

    const [isExpanded, setIsExpanded] = useState(false);
    const [inherentRisk, setInherentRisk] = useState({
        inherentRiskAssessmentId: risk.inherentRiskAssessmentId,
        inherentRiskAssessmentName: risk.inherentRiskAssessmentName
    });
    const [controlRisk, setControlRisk] = useState({
        controlRiskAssessmentId: !isInternalControlEffectiveness
            ? 2
            : risk.controlRiskAssessmentId ?? 2,
        controlRiskAssessmentName: !isInternalControlEffectiveness
            ? 'High'
            : risk.controlRiskAssessmentName ?? 'High'
    });
    const [rmm, setRmm] = useState(risk?.riskOfMaterialMisstatementName);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [successProjectRiskAssessmentSummary, setSuccessProjectRiskAssessmentSummary] = useState(false);
    const [isInheritChanged, setIsInheritChanged] = useState(false);
    const [shouldHighlight, setShouldHighlight] = useState();
    const [referenceWalkThrough, setReferenceWalkThrough] = useState(null);
    const [referenceForTestOfControl, setReferenceForTestOfControl] = useState(null);
    const [riskFinancialStatement, setRiskFinancialStatement] = useState(risk?.financialStatementProjectRisk?.map(f => f.riskName) ?? []);
    const [currentUserIsActive, setCurrentUserIsActive] = useState(true);
    const queryClient = useQueryClient();

    const {
        modifiedRiskAssessmentSummaryAfterSignOff,
        auditAreaScotabdRisk,
        actions: {
            riskAssessmentIdentifyModifiedAnswerAfterSignOff
        }
    } = useProjectFormInstanceProvider();

    const riskCharacteristicsTooltip = `${COMMON_IDENTIFIED_RISK_TOOLTIP} (${risk.riskCharacteristics?.map(riskCharacteristic => riskCharacteristic.riskCharacteristicName).join(', ')})`;
    const joinEventConditionList = (condition) => Array.isArray(condition) ? condition?.map(e => e.eventConditionName).join(', ') : '';
    const riskFactorValue = () => riskFactorInfo?.map((r, index) =>
        <CLATooltip title={
            <div
                className="ql-editor"
                dangerouslySetInnerHTML={{ __html: sanitize(`<p><b>${r.riskFactorName}</b></p><p>${joinEventConditionList(r.eventCondition)}</p>`, { ADD_ATTR: ['target'] }) }}
            />
        } placement="bottom">
            <p key={index} className={styles['wrap-text']}>
                {r.riskFactorName}<br />
                {joinEventConditionList(r.eventCondition)}
            </p>
        </CLATooltip>
    );
    useEffect(() => {
        setIsExpanded(expandAll);
    }, [expandAll]);

    const { concurrencyRiskInfo, concurrencyEventReceived, setConcurrencyEventReceived } = useRiskAssessment();

    const handleRiskDropdownChanges = async (materialMisstatementRisk) => {
        const inherentRiskId = RISKS_KEYVALUE_INHERENT.find((risk) => risk.key === inherentRisk.inherentRiskAssessmentName)?.value;
        const controlRiskId = RISKS_KEYVALUE_CONTROL.find((risk) => risk.key === controlRisk.controlRiskAssessmentName)?.value;
        const materialMisstatementRiskId = RISKS_KEYVALUE_RMM.find((risk) => risk.key === materialMisstatementRisk)?.value;

        const requestBody = {
            ReferenceForWalkThrough: referenceWalkThrough ?? risk.referenceForWalkThrough,
            InherentRiskAssessmentId: inherentRiskId,
            InherentRiskAssessmentName: inherentRisk.inherentRiskAssessmentName,
            ReferenceForTestOfControl: referenceForTestOfControl ?? risk.referenceForTestOfControl,
            ControlRiskAssessmentId: controlRiskId,
            ControlRiskAssessmentName: controlRisk.controlRiskAssessmentName,
            RiskOfMaterialMisstatementId: materialMisstatementRiskId,
            RequestProjectFormId: projectFormId,
            riskInfo: risk
        }
        riskAssessmentIdentifyModifiedAnswerAfterSignOff(auditAreaScotabdRisk);
        try {
            setLoading(true)
            await updateProjectRiskAssessmentSummary(projectId, risk.projectRiskId, requestBody);
            queryClient.invalidateQueries(['ProjectScopeAuditAreaSCOTABDRiskList']); 
            setSuccessProjectRiskAssessmentSummary(true)
            setConcurrencyEventReceived(false)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setError(true)
        }
        setIsInheritChanged(false);
    };

    const applyClass = () => {
        let classList = [];
        if (shouldHighlight === HIGHLIGHT_BORDER.MODIFIED) classList.push(styles['highlight-modified']);
        return classList.join(' ');
    };

    useEffect(() => {
        if (modifiedRiskAssessmentSummaryAfterSignOff) {
            const shouldHighlight = !!modifiedRiskAssessmentSummaryAfterSignOff
                .find(signOff => signOff.projectRiskId === risk.projectRiskId && signOff.projectRiskAuditAreaId === risk.projectRiskAuditAreaId) ? HIGHLIGHT_BORDER.MODIFIED : null;

            setShouldHighlight(shouldHighlight);
        }
    }, [modifiedRiskAssessmentSummaryAfterSignOff]);

    useEffect(() => {
        if (isInheritChanged) riskFunc();
    }, [inherentRisk.inherentRiskAssessmentName, controlRisk.controlRiskAssessmentName, isInternalControlEffectiveness]);

    const rmmFunc = (inherentRisk, controlRisk) => {
        if (inherentRisk.inherentRiskAssessmentName) {
            const riskAssessmentName = !isInternalControlEffectiveness ? 'High': controlRisk.controlRiskAssessmentName
            const inherentRiskIndex = RISK_LEVEL_INHERIT_RISK.indexOf(inherentRisk.inherentRiskAssessmentName);
            const controlRiskIndex = RISK_LEVEL_CONTROL_RISK.indexOf(riskAssessmentName);
            risk.riskOfMaterialMisstatementName = MATRIX[inherentRiskIndex][controlRiskIndex];
            setRmm(MATRIX[inherentRiskIndex][controlRiskIndex]);
        }
    }

    const riskFunc = () => {
        rmmFunc(inherentRisk, controlRisk)
        handleRiskDropdownChanges(risk.riskOfMaterialMisstatementName);
    }


    const handleBlur = async (fieldValue, property) => {
        const High = 2;
        const materialMisstatementRiskId = risk.riskOfMaterialMisstatementName ?
            RISKS_KEYVALUE_RMM.find((risk) => risk.key === risk?.riskOfMaterialMisstatementName)?.value : High;

        const existingRow = {
            ReferenceForWalkThrough: referenceWalkThrough ?? risk.referenceForWalkThrough,
            InherentRiskAssessmentId: inherentRisk.inherentRiskAssessmentId ?? risk.inherentRiskAssessmentId,
            InherentRiskAssessmentName: inherentRisk.inherentRiskAssessmentName ?? risk.inherentRiskAssessmentName,
            ReferenceForTestOfControl: referenceForTestOfControl ?? risk.referenceForTestOfControl,
            ControlRiskAssessmentId: controlRisk.controlRiskAssessmentId ?? (risk.controlRiskAssessmentId ?? High),
            ControlRiskAssessmentName: controlRisk.controlRiskAssessmentName ?? risk.controlRiskAssessmentName,
            RiskOfMaterialMisstatementId: materialMisstatementRiskId,
        }

        const requestBody = {
            ...existingRow,
            [property]: fieldValue,
            RequestProjectFormId: projectFormId,
            riskInfo: risk
        }
        riskAssessmentIdentifyModifiedAnswerAfterSignOff(auditAreaScotabdRisk);
        try {
            setLoading(true)
            await updateProjectRiskAssessmentSummary(projectId, risk.projectRiskId, requestBody);
            queryClient.invalidateQueries(['ProjectScopeAuditAreaSCOTABDRiskList']); 
            setSuccessProjectRiskAssessmentSummary(true)
            setConcurrencyEventReceived(false)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setError(true)
        }
    }

    const getProcedures = (risk) => {
        const summaryProcedures = risk?.projectRiskSummaryProcedures?.map(procedure => procedure?.SummaryProcedureName);
        const customProcedures = risk?.customProcedures?.map(customProc => customProc?.customProcedureName);
        const procedures = summaryProcedures.concat(customProcedures);
        return procedures.join(', ');
    }

    useEffect(() => {
        if (!concurrencyRiskInfo || !concurrencyEventReceived) return;
        const riskInfo = concurrencyRiskInfo.riskInfo.find(concurrencyRisk => parseInt(concurrencyRisk.projectRiskId) === parseInt(risk.projectRiskId)
            && parseInt(concurrencyRisk.projectScopeAuditAreaSCOTABDId) === parseInt(risk.projectScopeAuditAreaSCOTABDId)) ?? '';
        setCurrentUserIsActive(false);
        if (!riskInfo) return;
        const {
            referenceForWalkThrough,
            referenceForTestOfControl,
            inherentRiskAssessmentId,
            inherentRiskAssessmentName,
            controlRiskAssessmentId,
            controlRiskAssessmentName,
            financialStatementProjectRisk
        } = riskInfo;
        setReferenceWalkThrough(referenceForWalkThrough)
        setReferenceForTestOfControl(referenceForTestOfControl);
        setInherentRisk({
            inherentRiskAssessmentId: inherentRiskAssessmentId,
            inherentRiskAssessmentName: inherentRiskAssessmentName ?? risk.inherentRiskAssessmentName
        });
        setControlRisk({
            controlRiskAssessmentId: controlRiskAssessmentId,
            controlRiskAssessmentName: controlRiskAssessmentName ?? risk.controlRiskAssessmentName
        });
        setRiskFinancialStatement(financialStatementProjectRisk.map(f => f.riskName))
    }, [concurrencyRiskInfo])


    useEffect(() => {
        if (isIdle) {
            const delay = setTimeout(() => {
                const _referenceWalkThrough = risk.referenceForWalkThrough ?? "";
                const _referenceForTestOfControl = risk.referenceForTestOfControl ?? "";
                setReferenceWalkThrough(_referenceWalkThrough);
                setReferenceForTestOfControl(_referenceForTestOfControl);
                setCurrentUserIsActive(true);
            }, WAITING_TIME.MINS_2);
            return () => {
                clearTimeout(delay);
            };
        }
    }, [isIdle])

    useEffect(() => {
        setInherentRisk({
            inherentRiskAssessmentId: risk.inherentRiskAssessmentId,
            inherentRiskAssessmentName: risk.inherentRiskAssessmentName
        });
        rmmFunc(risk, controlRisk)
    }, [risk.inherentRiskAssessmentName, risk.inherentRiskAssessmentId])

    useEffect(() => {
        setReferenceWalkThrough(risk?.referenceForWalkThrough);
    }, [risk.referenceForWalkThrough])

    useEffect(() => {
        setReferenceForTestOfControl(risk?.referenceForTestOfControl);
    }, [risk.referenceForTestOfControl])

    useEffect(() => {
        setControlRisk({
            controlRiskAssessmentId: risk.controlRiskAssessmentId,
            controlRiskAssessmentName: risk.controlRiskAssessmentName
        });
        rmmFunc(inherentRisk, risk)
    }, [risk.controlRiskAssessmentName, risk.controlRiskAssessmentId])

    return (<>
        <TableRow data-test="tableRowBody" className={applyClass()}>
            <TableCell sx={{ ...cellStyles }}>
                <IconButton
                    sx={{ p: '0' }}
                    onClick={() => { setIsExpanded(prevIsExpanded => !prevIsExpanded) }}>
                    {isExpanded ? <ExpandIconComponent /> : <CollapseIconComponent />}
                </IconButton>
            </TableCell>
            <TableCell title={risk.riskName} sx={{ pl: 0, ...cellStyles, ...wrapCellStyles, ...(index > 0 ? cellBorderStyles : '') }}>
                {string.truncate(risk.riskName, 18, '...')}
                {risk.defaultRiskId && <CLATooltip
                    title={
                        <div
                            className="ql-editor"
                            dangerouslySetInnerHTML={{
                                __html: sanitize(riskCharacteristicsTooltip, {
                                    ADD_ATTR: ['target'],
                                }),
                            }}
                        >
                        </div>
                    }
                    placement="top"
                >
                    <HelpIcon style={{ fontSize: '18px', paddingLeft: '3px' }} />
                </CLATooltip>}
            </TableCell>
            <TableCell sx={{ ...cellStyles, ...wrapCellStyles, ...(index > 0 ? cellBorderStyles : '') }}>
                {riskFactorValue()}
            </TableCell>
            <TableCell sx={{ ...cellStyles, ...(index > 0 ? cellBorderStyles : '') }}>
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper}
                    isLockedByUser={(others.find((user) => user.presence.focusedId === `ReferenceForWalkThrough-${risk.projectRiskId}-${scotabd.projectScopeAuditAreaId}-${scotabd.projectScopeAuditAreaSCOTABDId}`))}
                    isLocked={(others.find((user) => user.presence.focusedId === `ReferenceForWalkThrough-${risk.projectRiskId}-${scotabd.projectScopeAuditAreaId}-${scotabd.projectScopeAuditAreaSCOTABDId}`)) && isParentExpanded}
                >
                    <div>
                        <TextField
                            sx={txtReferenceStyles}
                            placeholder={CONSTANT_TEXT.ENTER_REFERENCE_WALKTHROUGH}
                            inputProps={{ maxLength: 256 }}
                            defaultValue={risk.referenceForWalkThrough ?? ''}
                            value={referenceWalkThrough}
                            onBlur={(e) => {
                                const fieldValue = e?.target?.value;
                                handleBlur(fieldValue, 'ReferenceForWalkThrough')
                                updateMyPresence({ focusedId: null, type: 'custom' })
                            }}
                            onChange={(e) => {
                                setReferenceWalkThrough(e.target.value)
                            }}
                            onFocus={() => {
                                updateMyPresence({ focusedId: `ReferenceForWalkThrough-${risk.projectRiskId}-${scotabd.projectScopeAuditAreaId}-${scotabd.projectScopeAuditAreaSCOTABDId}`, type: 'custom' })
                            }}
                            disabled={disabled}
                        />
                    </div>
                </VFRenderedFieldWrapper>
            </TableCell>
            <TableCell sx={{ ...cellStyles, width: '175px', ...(index > 0 ? cellBorderStyles : ''), ...selectStyles }}>
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper}
                    isLockedByUser={(others.find((user) => user.presence.focusedId === `InherentRisk-${risk.projectRiskId}-${scotabd.projectScopeAuditAreaId}-${scotabd.projectScopeAuditAreaSCOTABDId}`))}
                    isLocked={(others.find((user) => user.presence.focusedId === `InherentRisk-${risk.projectRiskId}-${scotabd.projectScopeAuditAreaId}-${scotabd.projectScopeAuditAreaSCOTABDId}`)) && isParentExpanded}
                >
                    <CLADropdown
                        options={inherentRiskOptions?.map(c => c.inherentRiskAssessmentName)}
                        value={inherentRisk.inherentRiskAssessmentName ?? ''}
                        onChange={(e) => {
                            setIsInheritChanged(true)
                            const inherit = inherentRiskOptions?.find(c => c.inherentRiskAssessmentName === e.target.value);
                            if (inherit) {
                                setInherentRisk({ inherentRiskAssessmentId: inherit.inherentRiskAssessmentId, inherentRiskAssessmentName: inherit.inherentRiskAssessmentName });
                                if (!isInternalControlEffectiveness) {
                                    risk.riskOfMaterialMisstatementName = inherit.inherentRiskAssessmentName;
                                }
                                risk.inherentRiskAssessmentId = inherit.inherentRiskAssessmentId;
                                risk.inherentRiskAssessmentName = inherit.inherentRiskAssessmentName
                            }
                        }}
                        onOpen={() => {
                            updateMyPresence({ focusedId: `InherentRisk-${risk.projectRiskId}-${scotabd.projectScopeAuditAreaId}-${scotabd.projectScopeAuditAreaSCOTABDId}`, type: 'custom' })
                        }}
                        onClose={(e) => {
                            updateMyPresence({ focusedId: null, type: 'custom' })
                        }}
                        isDisabled={disabled}
                    />
                </VFRenderedFieldWrapper>
            </TableCell>
            <TableCell sx={{ ...cellStyles, ...wrapCellStyles, ...(index > 0 ? cellBorderStyles : '') }} >
                {isInternalControlEffectiveness &&
                    <VFRenderedFieldWrapper
                        className={styles.field__wrapper}
                        isLockedByUser={(others.find((user) => user.presence.focusedId === `ReferenceForTestOfControl-${risk.projectRiskId}-${scotabd.projectScopeAuditAreaId}-${scotabd.projectScopeAuditAreaSCOTABDId}`))}
                        isLocked={(others.find((user) => user.presence.focusedId === `ReferenceForTestOfControl-${risk.projectRiskId}-${scotabd.projectScopeAuditAreaId}-${scotabd.projectScopeAuditAreaSCOTABDId}`)) && isParentExpanded}
                    >
                        <TextField
                            sx={txtReferenceStyles}
                            placeholder={CONSTANT_TEXT.ENTER_REFERENCE_TEST}
                            inputProps={{ maxLength: 256 }}
                            defaultValue={risk?.referenceForTestOfControl ?? ''}
                            value={referenceForTestOfControl}
                            onBlur={(e) => {
                                const fieldValue = e?.target?.value;
                                handleBlur(fieldValue, 'ReferenceForTestOfControl')
                                updateMyPresence({ focusedId: null, type: 'custom' })
                            }}
                            onChange={(e) => {
                                setReferenceForTestOfControl(e.target.value);
                            }}
                            onFocus={() => {
                                updateMyPresence({ focusedId: `ReferenceForTestOfControl-${risk.projectRiskId}-${scotabd.projectScopeAuditAreaId}-${scotabd.projectScopeAuditAreaSCOTABDId}`, type: 'custom' })
                            }}
                            disabled={disabled}
                        />
                    </VFRenderedFieldWrapper>
                }
            </TableCell>
            <TableCell sx={{ ...cellStyles, width: '135px', ...(index > 0 ? cellBorderStyles : ''), ...selectStyles }}>
                {isInternalControlEffectiveness &&
                    <VFRenderedFieldWrapper
                        className={styles.field__wrapper}
                        isLockedByUser={(others.find((user) => user.presence.focusedId === `ControlRiskAssessmentName-${risk.projectRiskId}-${scotabd.projectScopeAuditAreaId}-${scotabd.projectScopeAuditAreaSCOTABDId}`))}
                        isLocked={(others.find((user) => user.presence.focusedId === `ControlRiskAssessmentName-${risk.projectRiskId}-${scotabd.projectScopeAuditAreaId}-${scotabd.projectScopeAuditAreaSCOTABDId}`)) && isParentExpanded}
                    >
                        <CLADropdown
                            styles={{ fontSize: '14px', fontWeight: '600' }}
                            options={controlRiskOptions?.map(c => c.controlRiskAssessmentName)}
                            value={controlRisk.controlRiskAssessmentName ?? ''}
                            onChange={(e) => {
                                setIsInheritChanged(true)
                                const control = controlRiskOptions?.find(c => c.controlRiskAssessmentName === e.target.value);
                                if (control) {
                                    setControlRisk({ controlRiskAssessmentId: control.controlRiskAssessmentId, controlRiskAssessmentName: control.controlRiskAssessmentName });
                                    risk.controlRiskAssessmentId = control.controlRiskAssessmentId;
                                    risk.controlRiskAssessmentName = control.controlRiskAssessmentName
                                }
                            }}
                            onOpen={() => {
                                updateMyPresence({ focusedId: `ControlRiskAssessmentName-${risk.projectRiskId}-${scotabd.projectScopeAuditAreaId}-${scotabd.projectScopeAuditAreaSCOTABDId}`, type: 'custom' })
                            }}
                            onClose={(e) => {
                                updateMyPresence({ focusedId: null, type: 'custom' })
                            }}
                            isDisabled={disabled}
                        />
                    </VFRenderedFieldWrapper>
                }
                {!isInternalControlEffectiveness && 'High'}

            </TableCell>
            <TableCell sx={{ ...cellStyles, width: '135px', ...(index > 0 ? cellBorderStyles : '') }}>
                {rmm}
            </TableCell>
        </TableRow>
        <TableRow>
            <TableCell colSpan={8} sx={{ ...cellStyles, padding: '0px 0px 0px 68px' }}>
                <Collapse in={isExpanded} data-test="collapse-component">
                    <Grid container sx={{ mb: '35px', mt: '35px' }}>
                        <Grid item xs={3} sx={{ ...riskGridCell }}> {CONSTANT_TEXT.RELEVANT_ASSERTIONS} </Grid>
                        <Grid item xs={9} sx={{ ...riskGridCell }}>{risk?.assertion?.map(a => a.assertionName).join(', ')}  </Grid>
                        <Grid item xs={3} sx={{ ...riskGridCell }}> {CONSTANT_TEXT.FINANCIAL_STATEMENT_LEVEL_RISK} </Grid>
                        <Grid item xs={9} sx={{ ...riskGridCell, ...financialSelectStyles }}>
                            <VFRenderedFieldWrapper
                                className={styles.field__wrapper}
                                isLockedByUser={(others.find((user) => user.presence.focusedId === `FinancialStatementProjectRisk-${risk.projectRiskId}-${scotabd.projectScopeAuditAreaId}-${scotabd.projectScopeAuditAreaSCOTABDId}`))}
                                isLocked={(others.find((user) => user.presence.focusedId === `FinancialStatementProjectRisk-${risk.projectRiskId}-${scotabd.projectScopeAuditAreaId}-${scotabd.projectScopeAuditAreaSCOTABDId}`)) && isExpanded}
                            >
                                <CLASelect
                                    defaultValues={riskFinancialStatement}
                                    onChange={async (values) => {
                                        if (!financialRisks.length) return;

                                        const selected = values?.filter((i) => i);
                                        const fRisks = financialRisks.filter(f => selected.includes(f.RiskName));
                                        let financialStatementProjectRisk = [];
                                        let requestBody = [];
                                        if (fRisks.length) {
                                            financialStatementProjectRisk = fRisks.map(f => ({
                                                financialStatementProjectRiskId: f.ProjectRiskId,
                                                projectRiskAuditAreaId: risk.projectRiskAuditAreaId,
                                                riskName: f.RiskName
                                            }));

                                            requestBody = fRisks.map(f => ({
                                                FinancialStatementProjectRiskId: f.ProjectRiskId,
                                                ProjectRiskAuditAreaId: risk.projectRiskAuditAreaId
                                            }))
                                        }

                                        if (isArrayEqual(risk?.financialStatementProjectRisk, financialStatementProjectRisk)) {
                                            updateMyPresence({ focusedId: null, type: 'custom' })
                                            return;
                                        };

                                        setRiskFinancialStatement(financialStatementProjectRisk);

                                        try {
                                            await multiOpProjectRiskAuditAreaFinancialStatementProjectRisk(projectFormId, risk.projectRiskAuditAreaId, scotabd.projectScopeAuditAreaSCOTABDId, requestBody);
                                            setSuccessProjectRiskAssessmentSummary(true);
                                            setConcurrencyEventReceived(false)
                                        }
                                        catch {
                                            setError(true);
                                        } finally {
                                            updateMyPresence({ focusedId: null, type: 'custom' })
                                        }
                                    }}
                                    onOpen={() => {
                                        setCurrentUserIsActive(true)
                                        updateMyPresence({ focusedId: `FinancialStatementProjectRisk-${risk.projectRiskId}-${scotabd.projectScopeAuditAreaId}-${scotabd.projectScopeAuditAreaSCOTABDId}`, type: 'custom' })
                                    }}
                                    menuItems={financialRisks.map(f => f.RiskName)}
                                    isDisabled={disabled}
                                    listenOnDefaultValueChanges={!currentUserIsActive}
                                />
                            </VFRenderedFieldWrapper>
                        </Grid>
                        <Grid item xs={3} sx={{ ...riskGridCell }}> {CONSTANT_TEXT.WORKPAPER_REFERENCE_FORM_NAME} </Grid>
                        <Grid item xs={9} sx={{ ...riskGridCell }}>{risk?.projectFormName ? risk?.projectFormName : risk.workpaperReference}  </Grid>
                        <Grid item xs={3} sx={{ ...riskGridCell }}> {CONSTANT_TEXT.UNITS} </Grid>
                        <Grid item xs={9} sx={{ ...riskGridCell }}> {risk?.units?.map(u => u.projectUnitTitle).join(', ')} </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', paddingTop: '33px' }}>
                            <Box sx={{ paddingRight: '10px' }}>
                                <Typography noWrap sx={{ fontWeight: 700, fontSize: '14px' }}>
                                    {CONSTANT_TEXT.PROCEDURES_ADDRESS_RISK}
                                </Typography>
                            </Box>
                            <Box sx={{ flexGrow: 1 }}>
                                <Typography sx={{ fontWeight: 700, fontSize: '14px' }}>
                                    {getProcedures(risk)}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Collapse>
            </TableCell>
        </TableRow>
        <CustomToast
            error={error}
            success={successProjectRiskAssessmentSummary}
            loading={loading}
        />
    </>
    )
};

export default CLARiskCollapsible;