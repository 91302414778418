import { Box } from '@mui/material';
import { useState, useEffect } from 'react';
import { UNDERSTANDING_OF_ENTITY } from '@ais/constants';
import styles from './UOEAuditAreaCollapsibles.module.css';
import { ConcurrentInternalControlTextbox } from '../../../ConcurrentInternalControlTextbox';
import { useInternalControlsContext } from '@contexts/InternalControls/InternalControlsContext';
import { useUpdateMyPresence } from '@views/InternalControl/RoomProvider.jsx';
import { useOthers } from '@views/InternalControl/store/users.js';
import VFRenderedFieldWrapper from '../../../VFRenderedFieldWrapper.jsx'
import { sortReviewerSignoffAsc } from '@utilities/dateHelpers.js';
import { isSignoffLogicEnabled } from '@utilities/signoffUtility.js';


const UOEConstants = UNDERSTANDING_OF_ENTITY;
const UOEFinancialStatementConstants = UOEConstants.AUDIT_AREAS.FINANCIAL_STEMENT_PROCESS;
const UOEInfoProcessingConstants = UNDERSTANDING_OF_ENTITY.INFORMATION_PROCESSING;
const UOEComments = UOEConstants.COMMENTS;

export const FinanceStatementProcessContent = ({
    financeStatementData,
    isCommentOpen,
    saveInternalControlData,
    isProjectFinalized,
    signOffList
}) => {
    const {
        formattedInternalControls,
        changeFirstLevelField
    } = useInternalControlsContext();

    const [fieldHighlight, setFieldHighlight] = useState({
        FinancialStatementICInfoProcessingAndControlActivity1: false,
        FinancialStatementICInfoProcessingAndControlActivity2: false,
        FinancialStatementICInfoProcessingAndControlActivity3: false,
        FinancialStatementICInfoProcessingAndControlActivity4: false,
        FinancialStatementICInfoProcessingAndControlActivity5: false,
        FinancialStatementICInfoProcessingAndControlActivity6: false
    })

    const financialStatementICComment = formattedInternalControls?.FinancialStatementICComment;

    const updateMyPresence = useUpdateMyPresence();
    const others = useOthers();

    const handleBlur = (event, key) => {
        updateMyPresence({ focusedId: null, type: 'custom' })
        if (financeStatementData[key] === event.target.value) return;
        const newInternalControlData = changeFirstLevelField(key, event.target.value)
        saveInternalControlData(newInternalControlData)
    };

    const handleCommentBlur = (e) => {
        updateMyPresence({ focusedId: null, type: 'custom' })
        if (financialStatementICComment === e.target.value) return;
        const newInternalControlsData = changeFirstLevelField(
            UOEFinancialStatementConstants.COMMENT_FIELD,
            e.target.value
        );

        saveInternalControlData(newInternalControlsData);
    }

    const FinancialStatementICInfoProcessingAndControl = [
        'FinancialStatementICInfoProcessingAndControlActivity1',
        'FinancialStatementICInfoProcessingAndControlActivity2',
        'FinancialStatementICInfoProcessingAndControlActivity3',
        'FinancialStatementICInfoProcessingAndControlActivity4',
        'FinancialStatementICInfoProcessingAndControlActivity5',
        'FinancialStatementICInfoProcessingAndControlActivity6',
    ]

    useEffect(() => {
        const latestReviewersAsc = sortReviewerSignoffAsc(signOffList)
        let shouldHighlight = false
        FinancialStatementICInfoProcessingAndControl.forEach((key) => {
            if(formattedInternalControls?.History?.length > 0){
                if(formattedInternalControls[key] !== formattedInternalControls?.History[0][key]){
                    const dateModified = new Date(formattedInternalControls.ValidFrom).getTime();
                    const isSignoffEnabled = isSignoffLogicEnabled(isProjectFinalized, dateModified);
                    shouldHighlight = new Date(latestReviewersAsc[latestReviewersAsc?.length - 1]?.signOffDate).getTime() < dateModified
                    setFieldHighlight((prev) => {
                        return {
                            ...prev,
                            [key]: isSignoffEnabled && shouldHighlight
                        }
                    })
                }
            }
        })
    }, [formattedInternalControls, signOffList])

    return (
        <Box className={styles.contentContainer}>
            {
                Object.keys(UOEFinancialStatementConstants.LABELS).map((item, index) => (
                    <VFRenderedFieldWrapper
                        className={`${styles.textfieldContainer} ${styles["field__wrapper"]}`}
                        isLockedByUser={others.find((user) => user.presence.focusedId === `financial-statement-${index}`)}
                    >
                        <div className={styles.textfieldContainer} key={`financialStatementInfo-${index}`}>
                            <span className={styles.numberedText}>
                                {UOEFinancialStatementConstants.LABELS[item]}
                            </span>
                            <Box sx={ fieldHighlight[item] ? {borderStyle: 'dashed', borderWidth: '4px', borderColor: 'red', p: "10px"} : {}} >
                                <ConcurrentInternalControlTextbox
                                    label={UOEInfoProcessingConstants.LABEL}
                                    placeholder={UOEInfoProcessingConstants.PLACEHOLDER}
                                    value={financeStatementData[item] || ''}
                                    maxLength={null}
                                    onBlur={(e) => handleBlur(e, item)}
                                    onFocus={() => {
                                        updateMyPresence({ focusedId: `financial-statement-${index}`, type: 'custom' })
                                    }}
                                    customFormObjectId={`financial-statement-${index}`}
                                    disabled={isProjectFinalized || !!others.find((user) => user.presence.focusedId === `financial-statement-${index}`)}
                                />
                            </Box>
                        </div>
                    </VFRenderedFieldWrapper>
                ))
            }
            {
                !!isCommentOpen && <div className={styles.textfieldContainer}>
                    <VFRenderedFieldWrapper
                        className={`${styles.textfieldContainer} ${styles["field__wrapper"]}`}
                        isLockedByUser={others.find((user) => user.presence.focusedId === UOEFinancialStatementConstants.COMMENT_FIELD_KEY)}
                    >
                        <ConcurrentInternalControlTextbox
                            label={UOEComments.LABEL}
                            placeholder={UOEComments.PLACEHOLDER}
                            value={financialStatementICComment}
                            maxLength={4096}
                            nonMultilineLabelFontSize={UOEComments.NON_MULTILINE_LABEL_FONT_SIZE}
                            nonMultilineLabelMarginLeft={UOEComments.NON_MULTILINE_LABEL_MARGIN_LEFT}
                            onBlur={handleCommentBlur}
                            onFocus={() => {
                                updateMyPresence({ focusedId: UOEFinancialStatementConstants.COMMENT_FIELD_KEY, type: 'custom' })
                            }}
                            customFormObjectId={UOEFinancialStatementConstants.COMMENT_FIELD_KEY}
                            disabled={isProjectFinalized || !!others.find((user) => user.presence.focusedId === UOEFinancialStatementConstants.COMMENT_FIELD_KEY)}
                        />
                    </VFRenderedFieldWrapper>
                </div>
            }
        </Box>
    );
}

export default FinanceStatementProcessContent;
