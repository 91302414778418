import { useSocket } from '@ais/providers';
import { SOCKET_EVENT_NAMES, PROJECT_FORM_CUSTOM_LOCK } from '@ais/constants';
import { PROJECT_FORM_USAGE_ACTIONS } from '@constants/projectFormUsageReducer.js'
import { projectFormUsageModalReducer } from '@hooks/ProjectFormInstance/index';
import { useEffect, useReducer, useMemo, useContext } from 'react';
import { ProjectFormInstanceConcurrencyContext } from '@contexts/ProjectFormInstanceConcurrencyContext'
import { getProjectFormUsageByProjectFormId } from '@services/projectFormUsage'
import { useMsal } from "@azure/msal-react";

export const jsonSerializer = (data) => {
    try {
        return JSON.parse(data);    
    } catch (err) {           
        return data;
    }
}

export const useFormInstanceConnection = ({
	projectFormId,
	questionField: _questionField,
	userId,
	projectFormUsageModal,
	handleQuestionField,
	handleProjectFormUsageModal,
	handleAnsweredQuestionField,
	projectId
}) => {
	const { socket: socketConnection, isSocketConnected } = useSocket(); 
	const [userDetails, dispatch] = useReducer(projectFormUsageModalReducer, projectFormUsageModal);
	const { state: concurrencyLockState, dispatchReducer: dispatchConcurrencyLockReducer, setLastEmmitedEvent } = useContext(ProjectFormInstanceConcurrencyContext);
	const { accounts } = useMsal();

	const questionField = useMemo(() => _questionField, [_questionField]);

	const onConnectFormInstanceListener = () => {
		const userId = accounts[0].localAccountId.toUpperCase();
		socketConnection.emit(SOCKET_EVENT_NAMES.EMITS.FORM_INSTANCE, {
			questionId: null,
			projectFormId,
			userId: userId,
			answer: null,
		});
	};

	const onConnectActiveUser = (data) => {
		if (data.projectFormId == projectFormId) {
			dispatch({ type: PROJECT_FORM_USAGE_ACTIONS.UPDATE, projectFormUsage: data });
			handleAnsweredQuestionField({
				questionId: data.questionId,
				answer: jsonSerializer(data.answer),
				prevQuestionId: data.prevQuestionId,
				alternativeName: data.alternativeName,
				projectFormId: data.projectFormId,
				userId,
			});
		}
	};

	// If current questionId is NOT on the list of currently subscribed question ids
	// Then this should be able to receive events 
	const shouldSendOrReceiveEvent = () => {
		const { subscribedQuestionIds } = concurrencyLockState;
		const isQuestionIdSubscribedInConcurrencyLock = subscribedQuestionIds.includes(questionField?.questionId || questionField?.prevQuestionId);
		return !isQuestionIdSubscribedInConcurrencyLock;
	}

	const onConnectReceiveUpdateQuestionListener = (data) => {
		if (shouldSendOrReceiveEvent() && data.projectFormId == projectFormId) {
			dispatch({ type: PROJECT_FORM_USAGE_ACTIONS.UPDATE, projectFormUsage: data });
			const serializedAnswer = jsonSerializer(data.answer);
			handleAnsweredQuestionField({
				questionId: data.questionId,
				answer: typeof serializedAnswer === 'number' ? serializedAnswer.toString() : serializedAnswer,
				prevQuestionId: data.prevQuestionId,
				alternativeName: data.alternativeName,
				projectFormId,
			});
			
			// if (data?.idle) {
			// 	onDisconnectRemoveUserListener({ userId: data.userId })
			// }
		}
	};

	const onConnectUpdateQuestionListener = (data) => { 
		if (!shouldSendOrReceiveEvent()) return; 
		const eventName = SOCKET_EVENT_NAMES.EMITS.QUESTION_UPDATE;
		const eventData = {
			userId: data.userId,
			projectFormId: projectFormId,
			questionId: data.questionId,
			answer: jsonSerializer(data.answer),
			prevQuestionId: data.prevQuestionId,
			alternativeName: data.alternativeName,
			// idle: data?.idle || false
		}
		socketConnection.emit(eventName, data);
		if(data?.isIdle){
			socketConnection.emit(SOCKET_EVENT_NAMES.EMITS.REMOVE_USER, { projectFormId, userId });
		}
		setLastEmmitedEvent({ eventName, data: eventData });
	};

	

	const onDisconnectNotifyListener = () => {
		socketConnection.emit(SOCKET_EVENT_NAMES.EMITS.REMOVE_USER, { projectFormId, userId });
	};

	const onDisconnectRemoveUserListener = (data) => {
		dispatch({ type: PROJECT_FORM_USAGE_ACTIONS.DELETE, userId: data?.userId });
		const userId = accounts[0].localAccountId.toUpperCase();

		if (userId === data?.userId?.toUpperCase()) onConnectFormInstanceListener()
	};

	const resetFormUsages = (activeUsers) => {
		dispatch({ type: PROJECT_FORM_USAGE_ACTIONS.ADD, projectFormUsages: activeUsers });
		dispatchConcurrencyLockReducer({
			type: PROJECT_FORM_CUSTOM_LOCK.INITIALIZE_SESSIONS,
			data: activeUsers,
		})

		const result = activeUsers?.find((form) => form?.userId === userId && form?.questionId);
		if (result && userId === result?.userId) {
			handleQuestionField({
				questionId: result.questionId,
				answer: jsonSerializer(result.answer),
				projectFormId,
			});
		}
	}

	const resetSessions = async () => {
		const { data } = await getProjectFormUsageByProjectFormId(projectFormId, projectId);
		resetFormUsages(data?.data?.projectFormUsages ?? [])
	}

	useEffect(() => {
		resetSessions();
		socketConnection.on(SOCKET_EVENT_NAMES.RECONNECT, resetSessions)
		return () => {
			socketConnection.off(SOCKET_EVENT_NAMES.RECONNECT, resetSessions)
		}
	}, [])

	const onUserSessionIdUpdated = (data) => {
		dispatch({ type: PROJECT_FORM_USAGE_ACTIONS.UPDATE_SOCKET_ID, payload: data });
	}

	const onSessionDrop = (data) => {
		dispatch({ type: PROJECT_FORM_USAGE_ACTIONS.SESSION_DROPPED, data });
		dispatchConcurrencyLockReducer({
			type: PROJECT_FORM_CUSTOM_LOCK.REMOVE_SESSION,
			data,
		})
	} 

	useEffect(() => {
		window.addEventListener('beforeunload', onDisconnectNotifyListener);
		return () => {
			window.removeEventListener('beforeunload', onDisconnectNotifyListener);
		};
	}, []);

	useEffect(() => {
		if (isSocketConnected) {
			return () => {
				onDisconnectNotifyListener();
			};
		}
	}, [isSocketConnected]);

	useEffect(() => {
		const activate = async () => {
			try {
				onConnectFormInstanceListener();
			} catch (err) {
				throw err;
			}
		};

		if (isSocketConnected) {
			activate().catch((err) => console.error(err));
		}
	}, [userId, isSocketConnected]);

	const broadcastData = (data) => {
		if (isSocketConnected) {
			try {
				onConnectUpdateQuestionListener({ projectFormId, userId, ...data });
			} catch (err) {
				console.error(err);
			}
		}
	}; 

	useEffect(() => {
		const activate = async () => {
			try {
				socketConnection.on(SOCKET_EVENT_NAMES.EMITS.UPDATE_SOCKET_ID, onUserSessionIdUpdated);
				socketConnection.on(SOCKET_EVENT_NAMES.EMITS.BROADCAST_QUESTION_UPDATE, onConnectReceiveUpdateQuestionListener);
				socketConnection.on(SOCKET_EVENT_NAMES.EMITS.INITIAL_CONNECTION, onConnectActiveUser);
				socketConnection.on(SOCKET_EVENT_NAMES.EMITS.BROADCAST_REMOVE_USER, onDisconnectRemoveUserListener);
				socketConnection.on(SOCKET_EVENT_NAMES.EMITS.SESSION_DROPPED, onSessionDrop);
			} catch (err) {
				throw err;
			}
		};

		if (isSocketConnected) {
			activate().catch((err) => console.error(err));
		}

		return () => {
			socketConnection.off(SOCKET_EVENT_NAMES.EMITS.UPDATE_SOCKET_ID, onUserSessionIdUpdated);
			socketConnection.off(SOCKET_EVENT_NAMES.EMITS.BROADCAST_QUESTION_UPDATE, onConnectReceiveUpdateQuestionListener);
			socketConnection.off(SOCKET_EVENT_NAMES.EMITS.INITIAL_CONNECTION, onConnectActiveUser);
			socketConnection.off(SOCKET_EVENT_NAMES.EMITS.BROADCAST_REMOVE_USER, onDisconnectRemoveUserListener);
			socketConnection.off(SOCKET_EVENT_NAMES.EMITS.SESSION_DROPPED, onSessionDrop);
		}
	}, [socketConnection, isSocketConnected]);

	useEffect(() => {
		if (isSocketConnected) {
			handleProjectFormUsageModal(userDetails);
		}
	}, [userDetails, isSocketConnected]);

	return [userDetails, { broadcastData }];
};
