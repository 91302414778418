import CLASnackbarWithContent from '@components/CLASnackbarWithContent/CLASnackbarWithContent';
import { useOthers, useOtherUsersStore } from './store/users';
import { useEventListener, useOthersListener } from './RoomProvider';

import { useMsal } from '@azure/msal-react';
import useICF from './hooks/useICF';

export default function ConcurrentUsersBanner() {
    const { accounts } = useMsal()
    const userId = accounts[0].localAccountId.toLowerCase();
    const {
        updateNonCustomFieldAnswer,
        setConcurrencyEventReceived,
        setComment,
        setDataOnBroadcastEvent
    } = useICF();

    const append = useOtherUsersStore((state) => state.append)
    const remove = useOtherUsersStore((state) => state.remove)
    const updateUserPresence = useOtherUsersStore((state) => state.updateUserPresence)

    useOthersListener(({ type, sessionId, data }) => {
        switch (type) {
            case "enter":
                console.log(`User w/ session ${sessionId} has entered the room`)
                append({ sessionId, ...data })
                return
            case "leave":
                console.log(`User w/ session ${sessionId} has left the room`)
                remove(sessionId)
                return
            case "presence":
                console.log(`User w/ session ${sessionId} presence`)
                updateUserPresence(sessionId, data)
            default:
                console.warn(`Invalid event type: `, type)
        }
    })

    useEventListener(({ type, data }) => {
        switch (type) {
            case "updates":
                setConcurrencyEventReceived(true);
                if (data.answer.userId !== userId.toUpperCase()) setDataOnBroadcastEvent(data)
                return
            default:
                console.warn(`Invalid event type: `, type)
        }
    })

    const filterFunc = (user) => {
        if(user.info.userId === userId) {
            return false
        }
        return true
    }

    const users = useOthers()
    return (
        <CLASnackbarWithContent
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            snackBarPostion={'top-center'}
            open={users?.filter(filterFunc)?.length > 0}
            message={
                <>
                    {users?.filter(filterFunc)?.map((item, index) => (
                        <p key={index} style={{ textAlign: 'center', margin: 0 }}>
                            {`${item.info.name} is currently editing this form`}
                        </p>
                    ))}
                </>
            }
        />
    )
}

