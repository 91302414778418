import React, { useContext, useEffect, useState } from 'react';
import { Grid } from '@mui/material';
import { useForm, useWatch } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from "@hookform/resolvers/yup";
import { Form } from "@ais/palette";

import { SCHEMA_ACTION_TYPES } from '@ais/constants';
import {
    DROPZONE_AREAS,
    TRIGGERS,
    DIALOG,
    PROCEDURE_FIELD
} from '@constants/forms';

import { SchemaContext } from '@ais/providers';
import {
    createProcedure,
    updateSection,
    generateFormProcedure
} from '@components/Forms/helpers';
import { BaseDialog } from '.';
import { CLADropdown } from "@ais/forms";

import formServices from '@services/forms/forms';

import styles from './ProcedureDialog.module.css'

export const ProcedureDialog = ({
    visible,
    setVisible,
    additionalData,
    trigger,
}) => {
    const { LABEL, PLACEHOLDER } = DIALOG.SETTINGS;
    const { schema, dispatchSchema } = useContext(SchemaContext);
    const [auditAreas, setAuditAreas] = useState([]);
    const properties = schema?.properties ?? [];
    const formSettings = schema?.formSettings ?? {};
    const methodologyVersionId = formSettings?.methodologyVersionId;
    const initialValues = {
        auditArea: additionalData?.auditArea ?? null
    };

    const schemaValidation = Yup.object().shape({
        auditArea: Yup.number().required()
    })

    const formMethods = useForm({
        defaultValues: initialValues,
        resolver: yupResolver(schemaValidation)
    });

    const watchAuditArea = useWatch({ control: formMethods.control, name: 'auditArea' }) ?? null;

    const handleSubmit = (values) => {
        const selectedAuditArea = auditAreas.find((data) => data.value === values.auditArea);
        if (trigger === TRIGGERS.CANVAS) {
            const newProperty = createProcedure({
                id: additionalData?.id,
                title: 'Procedures',
                auditAreaName: selectedAuditArea?.AuditAreaName,
                text: PROCEDURE_FIELD.PROCEDURE_ENABLED,
                ...values
            });
            const newProperties = [
                ...properties,
                newProperty
            ];
            dispatchSchema({
                type: SCHEMA_ACTION_TYPES.UPDATE_PROPERTIES,
                payload: newProperties,
            });

            generateFormProcedure(newProperty, dispatchSchema);
        } else if (trigger === TRIGGERS.DROPZONE) {
            const oldIndex = additionalData?.index;
            const newProperties = [...properties];
            const newProperty = createProcedure({
                id: additionalData?.id,
                title: 'Procedures',
                auditAreaName: selectedAuditArea?.AuditAreaName,
                text: PROCEDURE_FIELD.PROCEDURE_ENABLED,
                ...values
            });
            if (additionalData?.position === DROPZONE_AREAS.TOP)
                newProperties.splice(oldIndex, 0, newProperty);
            else if (additionalData?.position === DROPZONE_AREAS.BOTTOM)
                newProperties.splice(oldIndex + 1, 0, newProperty);
            dispatchSchema({
                type: SCHEMA_ACTION_TYPES.UPDATE_PROPERTIES,
                payload: newProperties,
            });
            generateFormProcedure(newProperty, dispatchSchema);
        } else if (trigger === TRIGGERS.ELLIPSIS) {
            const newProperty = updateSection(properties, additionalData.id, {
                ...values,
                title: 'Procedures',
                auditAreaName: selectedAuditArea?.AuditAreaName,
                text: PROCEDURE_FIELD.PROCEDURE_ENABLED
            });
            if (newProperty) {
                dispatchSchema({
                    type: SCHEMA_ACTION_TYPES.UPDATE_SECTION,
                    index: newProperty.index,
                    payload: newProperty.value,
                });
                generateFormProcedure(newProperty.value, dispatchSchema);
            }
        }
        setVisible(false);
    }

    useEffect(() => {
        formMethods.reset(initialValues);
        formMethods.trigger();
    }, [visible]);

    const tabs = [
        {
            label: LABEL.SETTINGS,
            render: () => (
                <Grid container rowSpacing={6} columnSpacing={22}>
                    <Grid item xs={12}>
                        <div className={styles['audit-area-settings']}>
                            <label>{LABEL.AUDIT_AREA}</label>
                            <CLADropdown
                                data-test="audit-area-component"
                                name="auditArea"
                                options={auditAreas}
                                placeholder={PLACEHOLDER.BASIC_DROPDOWN}
                                value={watchAuditArea}
                                onChange={(e) => formMethods.setValue('auditArea', e.target.value, { shouldValidate: true })}
                            />
                        </div>
                    </Grid>
                </Grid>
            ),
        }
    ];

    useEffect(async () => {
        if (methodologyVersionId) {
            const response = await formServices.getAuditAreasByMethodologyVersionId(methodologyVersionId);
            let filteredResponse = response?.data ?? [];
            setAuditAreas(filteredResponse?.sort((a, b) => {
                return a.DisplayOrder - b.DisplayOrder;
            }).map((auditArea) => {
                return {
                    ...auditArea,
                    label: auditArea.AuditAreaName,
                    value: auditArea.AuditAreaId
                }
            }));
        }
    }, [methodologyVersionId])

    return (
        <Form form={formMethods}>
            <BaseDialog
                id={additionalData?.id}
                idPrefix={LABEL.SECTION_ID}
                title={LABEL.PROCEDURE}
                visible={visible}
                setVisible={setVisible}
                onSubmit={formMethods.handleSubmit(handleSubmit)}
                tabs={tabs}
            />
        </Form>
    );
};
