import { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { Grid } from '@mui/material';
import { v4 as uuidv4 } from 'uuid';

import { FIELD_TYPES, SCHEMA_ACTION_TYPES } from '@ais/constants';
import { SchemaContext } from '@ais/providers';
import { BasicTextField } from './components';
import { FormInstanceBaseDialog } from '.';
import projectFormServices from '@services/forms/projectforms';

export const AddQuestion = ({
  section,
  projectFormId,
  visible,
  setVisible,
  additionalData,
  editMode,
}) => {
  const { projectId } = useParams();

  const { schema, dispatchSchema } = useContext(SchemaContext);

  const initialValues = {
    label: additionalData?.label ?? '',
    placeholder: additionalData?.placeholder ?? '',
  };

  const formMethods = useForm({ defaultValues: initialValues });

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (values) => {
    try {
      setLoading(true);
      if (editMode) {
        const { rowIndex, ...field } = additionalData;
        const response = await projectFormServices.updateQuestionToProjectFormSchema(
          projectId,
          projectFormId,
          section.id,
          rowIndex,
          { ...field, ...values },
        );
        const newFields = response.data.Section.fields;
        dispatchSchema({
          type: SCHEMA_ACTION_TYPES.UPDATE_SECTION_FIELDS,
          index: section.index,
          payload: newFields,
        });
        await projectFormServices.putProjectFormCustomQuestion(
          projectFormId,
          additionalData.id,
          values.label,
          additionalData.type,
          section.id,
          section.title,
          projectId
        );
      } else {
        const newField = {
          id: uuidv4(),
          type: FIELD_TYPES.INSTANCE_LONG_ANSWER,
          index: 0,
          defaultValue: '',
          ...values,
          width: 100,
          isFormInstanceQuestion: true,
        };
        await projectFormServices.addNewQuestionToProjectFormSchema(
          projectId,
          projectFormId,
          section.id,
          newField,
        );
        const copy = [...schema?.properties[section.index]?.fields];
        copy.push([newField]);
        dispatchSchema({
          type: SCHEMA_ACTION_TYPES.UPDATE_SECTION_FIELDS,
          index: section.index,
          payload: copy,
        });
        await projectFormServices.postProjectFormCustomQuestion(
          projectFormId,
          newField.id,
          values.label,
          newField.type,
          section.id,
          section.title,
          projectId
        );
      }
      setLoading(false);
      setVisible(false);
    } catch (error) {
      // * Show an error here
      setLoading(false);
    }
  };

  return (
    <FormInstanceBaseDialog
      title={editMode ? 'Edit Question' : 'Add a Question'}
      visible={visible}
      setVisible={setVisible}
      loading={loading}
      formMethods={formMethods}
      onSubmit={handleSubmit}
    >
      <Grid container rowSpacing={4}>
        <Grid item xs={12}>
          <BasicTextField
            name="label"
            label="Question Label"
            placeholder="Type Question Here"
            formMethods={formMethods}
            required
          />
        </Grid>
        <Grid item xs={12}>
          <BasicTextField
            name="placeholder"
            label="Placeholder Text"
            placeholder="Text"
            formMethods={formMethods}
          />
        </Grid>
      </Grid>
    </FormInstanceBaseDialog>
  );
};

AddQuestion.defaultProps = {
  editMode: false,
};
