import { useRef } from 'react'
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useWindowScroll } from 'react-use';

const CLASnackbarWithContent = (props) => {
  const { message={}, anchorOrigin={ vertical: 'bottom', horizontal: 'left' }, open=false, snackBarPostion, withScroll} = props;

  const {x,y: scrollPosition} = useWindowScroll();
  const matches = useMediaQuery('(min-width:900px)');
  const marginTop = matches ? 14.2 : 13;
  return (
      <Snackbar
        anchorOrigin={anchorOrigin}
        open={open}
        key={snackBarPostion}
        variant="informational-message"
        style={{
          position: "absolute",
          zIndex: "0",
        }}
      >
        <SnackbarContent
          message={message}
          sx={{
            marginTop: scrollPosition > (marginTop*16) ? '0rem !important' : marginTop + 'rem !important'
          }}/>
      </Snackbar>
  ) 
}
  
export default CLASnackbarWithContent;