import CsvDownloader from 'react-csv-downloader';
import styles from '@components/CLACSVExporter/CLACSVExporter.module.css';

const CLACSVExporter = ({ noHeader, columns, getData, filename, extensionType, buttonText, toggleModalSwitch, customStyle }) => {
    const getFormattedData = async () => {
        toggleModalSwitch();

        const data = await getData();
        if (data && data.length > 0) {
            const [rowData] = data;
            const keys = Object.keys(rowData);
            data.forEach(obj => {
                keys.forEach(key => {
                    obj[key] = '"' + obj[key] + String.fromCharCode(8203) + '"'
                });
            });
        }
        return data;
    }

    return (
        <CsvDownloader
            className={customStyle ?? styles.exportButton}
            noHeader={noHeader}
            extension={extensionType}
            filename={filename}
            text={buttonText}
            columns={columns}
            datas={getFormattedData}
        />
    )

}
export default CLACSVExporter;