import { Button, Box } from '@mui/material';
import useProjectFormInstanceConcurrentLocking from '@hooks/useProjectFormInstanceConcurrentLocking'
import CLAHoveringTooltip from '@components/CLAHoveringTooltip/CLAHoveringTooltip';
import { INTERNAL_CONTROLS } from '@constants/customForm';
import { UNDERSTANDING_OF_ENTITY } from "@ais/constants";

const KEY_CONTROLS_BUTTON_TYPE = {
    OPTIONAL: "Optional",
    REQUIRED: "Required"
}

export const ConcurrentKeyControlsButton = ({
    className,
    onClick,
    customFormObjectId,
    isKeyControlRequired,
    shouldHighlight
}) => {

    const {
        isLocked,
    } = useProjectFormInstanceConcurrentLocking(
        null,
        {
            customFormObjectId: customFormObjectId
        }
    ); 

    const buttonSettings = {
        style: {
            paddingLeft: 25,
            paddingRight: 25
        },
        textStyle: {
            marginRight: 3,
        },
        status: {
            show: isKeyControlRequired !== undefined,
            text: isKeyControlRequired ? KEY_CONTROLS_BUTTON_TYPE.REQUIRED : KEY_CONTROLS_BUTTON_TYPE.OPTIONAL
        },
        text: UNDERSTANDING_OF_ENTITY.SCOTABDS.KEY_CONTROLS,
    }

    return (
        <CLAHoveringTooltip 
            title={isLocked ? INTERNAL_CONTROLS.EN.LABELS.KEY_CONTROLS_BUSY : null}
            followCursor
        >
            <div className={className}>
                <Box sx={shouldHighlight ? {borderStyle: 'dashed', borderWidth: '4px', borderColor: 'red'}: {}}>
                    <Button variant="contained" onClick={onClick} disabled={isLocked} style={buttonSettings.style}>
                        <span style={buttonSettings.textStyle}>{buttonSettings.text}</span>
                        {buttonSettings.status.show && <span>({buttonSettings.status.text})</span>}
                    </Button>
                </Box>
            </div>
        </CLAHoveringTooltip>
    )
}