import { createContext, useReducer, useContext } from 'react';
import { AccountAssertionLevelRiskReducer } from './AccountAssertionLevelRiskReducer';

const AccountAssertionLevelRiskContext = createContext();

export const AccountAssertionLevelRiskProvider = ({ children }) => {
    const [state, dispatch] = useReducer(AccountAssertionLevelRiskReducer, { expandAll: false });

    const handleExpandAll = (isExpandAll) => {
        dispatch({
            type: 'EXPAND_ALL',
            payload: { expandAll: isExpandAll }
        })
    }

    const value = {
        expandAll: state?.expandAll,
        handleExpandAll
    }

    return <AccountAssertionLevelRiskContext.Provider value={value}>{children}</AccountAssertionLevelRiskContext.Provider>;
}

export const useAccountAssertionLevelRiskState = () => {
    const context = useContext(AccountAssertionLevelRiskContext);
    if (!context) {
        throw 'useAccountAssertionLevelRiskState must be used within AccountAssertionLevelRiskContext.';
    }

    return context;
}