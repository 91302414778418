import { useState, useEffect, useRef } from 'react';
import { Autocomplete, Chip, TextField, InputAdornment } from '@mui/material';
import PropTypes from 'prop-types';
import { ClientExternalIcon } from '@ais/forms';

export const CLAComboBox = (props) => {
  // Constants
  const {
    options,
    label,
    isDisabled,
    defaultValue,
    onChange,
    onBlur,
    onInputChange,
    allowMultiSelect,
    onFocus,
    placeholder,
    maxLength,
    allowMultiLineLabel,
    uncontrolled,
    allowExternalAccess,
    answerable,
    answered,
    isIdle,
    enableOnChangeHandler = true
  } = props;

  const ref = useRef(null);
  const inputRef = useRef(null);
  const containerRef = useRef(null);

  const [value, setValue] = useState(allowMultiSelect ? [] : '');
  const [inputValue, setInputValue] = useState('');
  const [open, setOpen] = useState(false); 

  useEffect(() => {
    let modifiedDefaultValue = defaultValue || []

    if(!Array.isArray(modifiedDefaultValue)) {
      modifiedDefaultValue = [modifiedDefaultValue]
    }

    const filteredDefaultValue = modifiedDefaultValue.filter(filteredDefaultValue => filteredDefaultValue)
    
    if (!uncontrolled) {
      setValue(filteredDefaultValue);

      if (allowMultiSelect) {
        setInputValue('');
      } else {
        setInputValue(modifiedDefaultValue);
      }
    }

    if (isIdle) {
      containerRef.current?.classList.remove('Mui-focused');
      inputRef.current?.blur();
      ref.current?.classList.remove('Mui-focused');
      setOpen(false)
      setValue(filteredDefaultValue);
    }
  }, [defaultValue, isIdle]);

  useEffect(() => {
    if (allowMultiSelect) setInputValue('');
    else setInputValue(value);
  }, [value]);

  const onChangeHandler = (event, data, reason) => {
    // onChange();
    let newValue = '';
    // If typed in the event.target.value will contain the value, if selected from option set it will always be 0
    if (event.target.value != 0) {
      newValue = event.target.value;
    } else {
      newValue = event.target.textContent;
    }
    // Null check - Clear event will pass null
    if (!!newValue) {
      // Identify amount of duplicates (will return 1 if no duplicates as it counts the new value too)
      let counter = 0;
      for (let i = 0; i < data.length; i++) {
        const v = data[i]
        if(!v) {
          continue
        }
        if(typeof v !== 'string') {
          continue
        }
        if(typeof newValue !== 'string') {
          continue
        }
        if (v.toLowerCase() === newValue.toLowerCase()) {
          counter++;
        }
      }
      // More than 1 result, means duplicate value being inserted on click. Remove it from the data set.
      if (counter > 1) {
        data.pop(newValue);
      }
      // Selection logic, this resolves the case sensitivity so if someone types a selection in lower case it selects the matching selection
      let indexOfNewValue = options.map((e) => e).indexOf(newValue);
      // If not found, remove case sensitivity to see if it's there
      if (indexOfNewValue == -1) {
        indexOfNewValue = options.map((e) => e.toLowerCase()).indexOf(newValue.toLowerCase());
        // If found in preset options use the preset option that matches the different case manually typed one.
        if (indexOfNewValue != -1) {
          data.pop(newValue);
          newValue = options[indexOfNewValue];
          data.push(newValue);
        }
      }
    }
    setValue(data);
    onChange && onChange(data, (reason === 'createOption' ? ref?.current : event), reason);
  }

  /** Key press for TAB KEY */
  const handleKeyDown = (event) => {
    switch (event.key) {
      case "Tab": {
        if (inputValue.length > 0) {
          onChangeHandler(event, value.concat([inputValue]));
        } 
        break;
      }
      default:
    }
  };

  // Object Construction
  return (
    <Autocomplete
      ref={ref}
      fullWidth
      disabled={!!isDisabled}
      open={open}
      onClose={() => { setOpen(false) }}
      onOpen={() => { setOpen(true) }}
      multiple={allowMultiSelect}
      value={value}
      autoSelect={allowMultiSelect}
      disableCloseOnSelect={allowMultiSelect}
      // Override OnChange - This is so that it executes whatever onChange is passed and also includes the validation to avoid duplicates
      onChange={(event, data, reason) => {
        if (reason === 'removeOption' && !ref.current?.classList.contains('Mui-focused')) {
          ref.current?.focus();
          inputRef.current?.focus();
          containerRef.current?.focus();
        }

        onChangeHandler(event, data, reason)
      }}
      onInputChange={(e, data) => onInputChange && onInputChange(data, e)}
      options={options}
      freeSolo
      renderTags={(value, getTagProps) => {
        if (Array.isArray(value) && allowMultiSelect) {
          return value.map((option, index) => {
            if (!option) {
              return null;
            }
            return <Chip variant="outlined" label={option} {...getTagProps({ index })} />;
          });
        } else return null;
      }}
      onBlur={(e) => {
        const newValue = e.target.value;
        if (newValue && allowMultiSelect) {
          setValue((prevState) => {
            const newState = [...prevState];
            if (!newState.some((ns) => ns === newValue)) newState.push(newValue);
            return newState;
          });
          setInputValue('');
        }
        setOpen(false)
        
        let onBlurValue = value;
      
        if (allowMultiSelect && inputValue?.trim() && !value.some(item => item.trim() === inputValue.trim())) {
          onBlurValue = value.concat([inputValue])
        }

        onBlur && onBlur(onBlurValue);
        if (onBlur && enableOnChangeHandler) 
          if(!allowMultiSelect)
            onChangeHandler(e, onBlurValue);
          else 
            onChangeHandler(e, value.concat([inputValue]));
      }}
      renderInput={(params) => {
        params.inputProps.onKeyDown = handleKeyDown;
        if (allowMultiSelect) {
          return (
            <TextField
              {...params}
              inputRef={inputRef}
              ref={containerRef}
              label={label}
              InputLabelProps={{
                ...params.InputLabelProps,
                shrink: true,
                ...(allowMultiLineLabel ? { variant: 'multiline-label' } : {}),
              }}
              sx={{
                "& .MuiAutocomplete-endAdornment": {
                  right: allowExternalAccess ? '0 !important' : '9px',
                  position: allowExternalAccess ? 'relative' : 'absolute'
                }
              }}
              inputProps={{ ...params.inputProps, value: inputValue }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {params.InputProps.endAdornment}
                    <InputAdornment position="end">
                      <ClientExternalIcon 
                        allowExternalAccess={allowExternalAccess}
                        answerable={answerable}
                        answered={answered}
                      />
                    </InputAdornment>
                  </>
                )
              }}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder={placeholder}
            />
          );
        } else
          return (
            <TextField
              {...params}
              inputRef={inputRef}
              ref={containerRef}
              label={label}
              placeholder={placeholder}
              sx={{
                "& .MuiAutocomplete-endAdornment": {
                  right: allowExternalAccess ? '0 !important' : '9px',
                  position: allowExternalAccess ? 'relative' : 'absolute'
                }
              }}
              InputLabelProps={{
                ...params.InputLabelProps,
                shrink: true,
                ...(allowMultiLineLabel ? { variant: 'multiline-label' } : {}),
              }}
              inputProps={{
                ...params.inputProps,
                value: inputValue,
                maxLength: maxLength
              }}
              onBlur={() => onBlur && onBlur(inputValue)}
              onChange={(e) => { setValue(e.target.value) }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {params.InputProps.endAdornment}
                    <InputAdornment position="end">
                      <ClientExternalIcon
                        allowExternalAccess={allowExternalAccess}
                        answerable={answerable}
                      />
                    </InputAdornment>
                  </>
                )
              }}
            />
          );
      }}
      onKeyDown={(event) => {
        if (event.key === 'Enter') {
          let currentSelections = event.target.parentNode.children;
          let isExisting = false;
          // Checks if exists already in the selected collection set - .some method not supported, have to use for loop
          for (let i = 0; i < currentSelections.length; i++) {
            if (
              currentSelections[i].textContent.toLowerCase() === event.target.value.toLowerCase()
            ) {
              isExisting = true;
            }
          }
          // If exists already, do not add.
          if (isExisting) {
            event.defaultMuiPrevented = true;
          }
        }
      }}
      onFocus={onFocus}
    />
  );
};

CLAComboBox.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  maxLength: PropTypes.number,
  allowMultiLineLabel: PropTypes.bool,
  uncontrolled: PropTypes.bool,
  allowExternalAccess: PropTypes.bool,
  answerable: PropTypes.bool
};

CLAComboBox.defaultProps = {
  options: [],
  placeholder: '',
  tooltip: '',
  isDisabled: false,
  defaultValue: '',
  maxLength: 524200,
  allowMultiLineLabel: false,
  uncontrolled: false,
  allowExternalAccess: false,
  answerable: false
};

export default CLAComboBox;
