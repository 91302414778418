import React from 'react';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

const CLAHoveringTooltip = styled(({ className, children, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }}>
    <div>
      {children}
    </div> 
  </Tooltip>
))(({ theme }) => ({
  '& p': {
    margin: 0,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#373D3F',
    padding: theme.padding,
    lineHeight: theme.lineHeight
  },
}));

export default CLAHoveringTooltip;
