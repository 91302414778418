import React from "react";
import styles from "./styles.module.css";
import clsx from 'clsx'
const IncorporateKeyControlsTable = ({ cols, rows, tableId, noRowsLabel = "No rows.", shouldHighlight }) => {
  return (
    <table className={clsx(styles["custom-table"], shouldHighlight && styles["table-highlighted"])}>
      <thead>
        <tr>
          {cols?.map((col) => {
            const colStyle = { textAlign: col?.otherProps?.align ? col?.otherProps?.align : "left", ...col?.otherProps?.style };
            return (
              <th style={colStyle} {...col?.otherProps} key={`${tableId}-col-${col.id}`}>
                <div className={styles["custom-table__column"]}>
                  {col.text}
                </div>
              </th>
            );
          })}
        </tr>
      </thead>
      <tbody>
        {!rows?.length ? <tr>
          <td colSpan={cols?.length} className={styles["custom-table__no-value-label"]}><span>{noRowsLabel}</span></td>
        </tr> : rows?.map((row) => (
          <React.Fragment key={`kc-${row.projectKeyControlId}`}>
            <tr className={clsx(row.isAddedAfterSignoff && styles["keyControlHighlighted"])}
            >
              {cols?.map((col) => {
                return (
                  <td
                    align={col.otherProps?.align ? col.otherProps?.align : "left"}
                    {...col?.otherProps}
                    key={`kc-${row.projectKeyControlId}-${col.id}`}
                  >
                    <div className={`${col?.rowClassname} ${styles["custom-table__row"]}`}>
                      {typeof row[col.id] === "string" ? (
                        <span>{row[col.id]}</span>
                      ) : (
                        row[col.id]
                      )}
                    </div>
                  </td>
                );
              })}
            </tr>
            <tr className={clsx(row.isAddedAfterSignoff && styles["keyControlContentHighlighted"])}>
              <td colSpan={4} className={clsx(row.isWalkthroughHighlighted && styles["walkthroughHighlighted"])}>{row.subChildren}</td>
            </tr>
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
};

export default IncorporateKeyControlsTable;
