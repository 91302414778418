import React from 'react';
import styles from '@components/CLANotification/CLANotification.module.css';
import CLANotificationItem from "@components/CLANotification/CLANotificationItem";
import { useNotificationContext } from '../../providers/notifications/NotificationProvider';
const CLANotification = () => {
  
  const {notifications} = useNotificationContext();
  
  return (
    <>
      <div style={{backgroundColor: '#f6f6f6', height:'40vh'}}>
        <div className={styles.claNotificationHeader}>
          My Notifications
        </div>
        <div className={styles.claNotificationContainer}>
         {

            (notifications && notifications.filter(notification => !notification.isDelete).length > 0 ) 
              ?
                notifications?.map( (notification, index) =>  
                  <CLANotificationItem 
                    key={notification.JobInstanceId}
                    notification={notification}
                    index={index}
                    />
                )
              : 
                <p className={styles.claNoNotificationsText}>No notifications yet</p>
          }

        </div>
      </div></>

  );
};

export default CLANotification;