import { useContext, useEffect, memo, useRef } from 'react';
import isEqual from 'lodash/isEqual';
import {CLASelect} from '@ais/forms';
import {
    Box,
    IconButton,
    TextField,
    Typography,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { TailorProcedureContext } from '@components/Forms/state';
import { Comment, CommentResolved } from '@ais/assets'; 
import styles from './SummaryProcedureSection.module.css';
import CLATextField from '@components/Forms/CLATextField/CLATextField';
import { assertionAcronymFormatter } from '@utilities/assertionAcronymFormatter.js'
import useProjectFormInstanceConcurrentLocking from '@hooks/useProjectFormInstanceConcurrentLocking'
import { useState } from 'react';
import { sanitize } from 'dompurify';
import projectFormServices from "@services/forms/projectforms";
import usersServices from '@services/users.js';
import { TAILOR_PROCEDURE_REDUCER } from '@constants/forms';
import {
    PROCEDURE_FIELD
} from '@constants/forms';
import { updateProjectRiskByProjectFormIdProjectFormCustomProcedureId } from "@services/projectRisk";
import ConcurrentLockTooltip from '@components/ConcurrentLockTooltip/ConcurrentLockTooltip'
import { useFinalizedProject } from '@hooks/useProject';
import { formatUtcToLocalTimeZone } from '@utilities/dateHelpers';
import { useCLAProcedureItem } from './CLAProcedureContexProvider';
import {useUserList} from '@hooks/index';

const CustomProcedure = memo(function CustomProcedure (props) {
    const { 
        customProcedure, 
        projectRisks, 
        assertions, 
        userId, 
        auditAreaId, 
        getProjectFormProcedures, 
        questionId,
        sectionId,
        setSuccessToast,
        setErrorToast,
        setLoadingToast
    } = props;
    const {
        ProjectFormCustomProcedureId,
        CustomProcedureName: customProcedureName,
        Description: description,
        PrimaryAssertions: primaryAssertions,
        SecondaryAssertions: secondaryAssertions,
        ProjectRiskProjectFormCustomProcedures: projectRiskProjectFormCustomProcedures
    } = customProcedure;

    const { dispatchTailorProcedures } = useContext(TailorProcedureContext);
    const [openCommentBox, setOpenCommentBox] = useCLAProcedureItem(customProcedure.ProjectFormCustomProcedureId);
    const [workpaperRef, setWorkpaperRef] = useState('');
    const [checked, setChecked] = useState(false);
    const [comment, setComment] = useState('');
    const [initialized, setInitialized] = useState(false);
    const [isCLASelectOpen, setIsCLASelectOpen] = useState(false);
    const [signOff, setSignOff] = useState({
        signOffUser: null,
        signOffDate: null
    });


    const { projectId, projectFormId } = useParams();
    const [selectedRisks, setSelectedRisks] = useState([]);

    const sortedProjectRisks = projectRisks.sort((a, b) => a.RiskName.toLowerCase().localeCompare(b.RiskName.toLowerCase()));
    const CLATextFieldRef = useRef(null);
    const TextFieldCommentRef = useRef(null);
    const CLASelectRef = useRef(null);
    const isProjectFinalized = useFinalizedProject(projectId);
    const {getUserById} = useUserList();

    const getValuesAsConcurrentAnswerObject = () => ({
        workpaperRef,
        checked,
        comment,
        selectedRisks,
        signOff
    });

    function handleCLASelectClick(toggle) {
        setIsCLASelectOpen(toggle)
    }

    function handleIdleForm(origValue) { 
        handleCLASelectClick(false)
        setSelectedRisks([...(origValue?.defaultValues || selectedRisks) ])
        CLASelectRef.current?.classList.remove('Mui-focused')
        CLATextFieldRef.current?.querySelector('div')?.classList.remove('Mui-focused')
        TextFieldCommentRef.current?.querySelector('div')?.classList.remove('Mui-focused')
    }

    useEffect(() => {
        const _defaultValues = projectRiskProjectFormCustomProcedures ? projectRiskProjectFormCustomProcedures.map(projectRisk => projectRisk.ProjectRiskId) : [];
        setSelectedRisks(_defaultValues);
    }, [projectRiskProjectFormCustomProcedures])

    const serviceDispatcher = async (payload) => {
        setLoadingToast(true)
        const { status, data } = await projectFormServices.upsertCustomProcedure(projectId, {
            ...payload,
            AuditAreaId: auditAreaId,
            PrimaryAssertions: primaryAssertions ? primaryAssertions : [],
            SecondaryAssertions: secondaryAssertions ? secondaryAssertions : []
        });
        if (status !== 200 || !data || data && data.length <= 0) {
            setErrorToast(true)
            setSuccessToast(false)
            setLoadingToast(false)
            return;
        }
        dispatchTailorProcedures({
            type: TAILOR_PROCEDURE_REDUCER.UPSERT_CUSTOM_PROCEDURE,
            payload: { 
                customProcedure: data[0],
                sectionId: sectionId
            }
        })

        setErrorToast(false)
        setSuccessToast(true)
        setLoadingToast(false)
    }

    useEffect(async () => {
        if (customProcedure?.Comment)
            setComment(customProcedure.Comment);
        else
            setComment('');

        if (customProcedure?.WorkpaperReference)
            setWorkpaperRef(customProcedure.WorkpaperReference);
        else
            setWorkpaperRef('');

        if (customProcedure?.SignOffUser) {
            const { firstName = '', lastName = '', employeeId = null } = await getUserById(customProcedure.SignOffUser, true);
            setSignOff({
                signOffUser: `${firstName} ${lastName ? lastName[0] : ''}.` + (employeeId && (employeeId !== 'null') ? `(${employeeId})` : ''),
                signOffDate: customProcedure?.SignOffDate ? formatUtcToLocalTimeZone(customProcedure.SignOffDate) : null,
            })
        } else {
            setSignOff({
                signOffUser: null,
                signOffDate: null
            })
        }
        setChecked(!!customProcedure?.SignOffUser);
        setInitialized(true);
    }, [customProcedure]);

    const {
        isLocked,
        lockingUser,
        emitLockEvent, 
        emitUnlockEvent, 
        concurrentValue, 
    } = useProjectFormInstanceConcurrentLocking(
        questionId,
        {
            customFormObjectId: ProjectFormCustomProcedureId,
            onIdle: handleIdleForm
        }
    )

    useEffect(() => {
        if(!initialized || concurrentValue === null) return; // Means no change has been made, or concurrentValue has just been initialized

        const { workpaperRef,
            checked,
            comment,
            selectedRisks,
            signOff 
        } = concurrentValue;

        if(!signOff) {
            setSignOff({
                signOffUser: null,
                signOffDate: null
            })
        } else setSignOff(signOff);
        
        setComment(comment);
        setWorkpaperRef(workpaperRef);
        
        setChecked(checked);
        setSelectedRisks(selectedRisks);

    }, [concurrentValue, initialized])

    const separator = secondaryAssertions && primaryAssertions && secondaryAssertions.length > 0 && primaryAssertions.length > 0 ? ', ' : '';

    const handleOnFocusCustomProcedure = () => {
        emitLockEvent(getValuesAsConcurrentAnswerObject());
    }

    async function handleBlur(values) {
        if(!Array.isArray(values) || isEqual(selectedRisks, values)) return;
        
        setLoadingToast(true)

        const currentAnswer = { ...getValuesAsConcurrentAnswerObject(), selectedRisks: values };
        emitUnlockEvent(currentAnswer)
        const res = await updateProjectRiskByProjectFormIdProjectFormCustomProcedureId(projectId, ProjectFormCustomProcedureId, projectFormId, values);
        if (!res || res.status !== 201) {
            setErrorToast(true)
            setSuccessToast(false)
            setLoadingToast(false)
            return;
        }

        setErrorToast(false)
        setSuccessToast(true)
        setLoadingToast(false)
        setSelectedRisks(values);
        getProjectFormProcedures();
    }

    const handleCommentSave = async (e) => {
        const comment = e.target.value;
        const newcustomProcedure = { ...customProcedure };
        newcustomProcedure.Comment = comment;
        emitUnlockEvent(getValuesAsConcurrentAnswerObject())
        await serviceDispatcher(newcustomProcedure);
    };

    const handleWorkpaperRefSave = async (e) => {
        const workpaperRef = e.target.value;
        const newcustomProcedure = { ...customProcedure };
        newcustomProcedure.WorkpaperReference = workpaperRef;
        emitUnlockEvent(getValuesAsConcurrentAnswerObject())
        await serviceDispatcher(newcustomProcedure);
    };

    const handleCheckSave = async (e) => {
        const checked = e.target.checked;
        const oldConcurrentAnswer = getValuesAsConcurrentAnswerObject();
        emitLockEvent({
            ...oldConcurrentAnswer
        })
        if (customProcedure.ProjectFormCustomProcedureId) {
            const newcustomProcedure = { ...customProcedure };
            newcustomProcedure.SignOffUser = checked ? userId.trim() : null
            newcustomProcedure.SignOffDate = checked ? new Date().toISOString().slice(0, 21).replace('T', ' ') : null;

            const signOffData = {
                signOffUser: null,
                signOffDate: null,
            }

            if (checked) {
                const { firstName = '', lastName = '', employeeId = null } = await getUserById(newcustomProcedure.SignOffUser);
                signOffData.signOffUser = `${firstName} ${lastName ? lastName[0] : ''}. ` + (employeeId && (employeeId !== 'null') ? `(${employeeId})` : '');
                signOffData.signOffDate = formatUtcToLocalTimeZone(newcustomProcedure.SignOffDate);
            }

            setSignOff(signOffData)
            setChecked(checked);
            await serviceDispatcher(newcustomProcedure);
            
            emitUnlockEvent({
                ...oldConcurrentAnswer,
                checked,
                signOff: signOffData
            })
        }
    };

    return (
        <ConcurrentLockTooltip lockingUser={lockingUser}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                p: '20px 15px 10px 15px',
            }}>
                <Box sx={{ width: '20%', fontSize: '16px', fontWeigth: '700' }}> {`${customProcedureName}`}</Box>
                <Box sx={{ width: '40%' }}>
                    <CLASelect
                        ref={CLASelectRef}
                        data-test="identifiedRisk-dropdown"
                        placeholder="Select"
                        defaultValues={selectedRisks}
                        menuItems={sortedProjectRisks?.map(projectRisk => ({ ...projectRisk, label: projectRisk.RiskName, value: projectRisk.ProjectRiskId }))}
                        onBlur={handleBlur}
                        onFocus={handleOnFocusCustomProcedure}
                        isDisabled={isLocked || isProjectFinalized}
                        onClose={() => handleCLASelectClick(false)}
                        onOpen={() => handleCLASelectClick(true)}
                        open={isCLASelectOpen}
                    />
                </Box>
                <Box sx={{ width: '40%' }} />
            </Box >
            <Box sx={{ p: '0 15px 10px 15px' }}>
                <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                    <Box sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        pt: '20px',
                        fontSize: '14px',
                        fontWeigth: 700
                    }}>
                        <Box sx={{
                            width: '60%',
                            display: 'flex',
                            flexDirection: 'column',
                        }}>
                            <Box>
                                <div
                                    className="ql-editor"
                                    style={{ paddingRight: '10px' }}
                                    dangerouslySetInnerHTML={{
                                        __html: sanitize(`${description}`, { ADD_ATTR: ['target'] }),
                                    }}
                                />
                            </Box>
                        </Box>
                        <Box className={styles.tableCenter__cell} sx={{textAlign:'center'}}>
                            <Typography sx={{
                                overflowWrap: 'break-word',
                                color: '#4B4B4B'
                            }}>
                                {assertionAcronymFormatter(primaryAssertions, assertions, true) + separator + assertionAcronymFormatter(secondaryAssertions, assertions)}
                            </Typography>
                        </Box>
                        <Box sx={{ display:'flex', width: '10%' }}>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={checked}
                                        onChange={(e) => handleCheckSave(e)}
                                        onBlur={handleBlur}
                                        disabled={isLocked || isProjectFinalized}
                                    />
                                }
                                label={<Typography component='p' sx={{ fontSize: '0.7rem' }}>
                                    {signOff.signOffUser}<br /><span>{signOff.signOffDate}</span>
                                </Typography>}
                                labelPlacement="end"
                            />
                        </Box>
                        <Box sx={{ width: '10%' }}>
                            <CLATextField
                                ref={CLATextFieldRef}
                                label=""
                                onChange={(e) => setWorkpaperRef(e.target.value)}
                                value={workpaperRef}
                                inputLableShrink={true}
                                onBlur={handleWorkpaperRefSave}
                                placeholder={PROCEDURE_FIELD.WORKPAPER_REF} 
                                onFocus={handleOnFocusCustomProcedure} 
                                disabled={isLocked || isProjectFinalized} />
                        </Box>
                        <Box className={styles.tableCenter__cell}
                            sx={{
                                pr: '65px',
                                justifyContent: 'end'
                            }}>
                            <IconButton
                                sx={{ padding: 0 }}
                                onClick={() => setOpenCommentBox((prevOpenCommentBox) => !prevOpenCommentBox)}
                            >
                                {comment ? <CommentResolved /> : <Comment />}
                            </IconButton>
                        </Box>
                    </Box>
                    {
                        openCommentBox && <Box sx={{ p: `20px 65px 10px 0px` }}> 
                        <TextField
                            ref={TextFieldCommentRef}
                            label={PROCEDURE_FIELD.COMMENT}
                            fullWidth
                            multiline
                            placeholder="Enter comment"
                            rows={2}
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                            onBlur={handleCommentSave}
                            InputLabelProps={{
                                shrink: true
                            }}
                            InputProps={{
                                notched: true
                            }}
                            inputProps={{
                                maxLength: 4096
                            }}
                            onFocus={handleOnFocusCustomProcedure}
                            disabled={isLocked || isProjectFinalized}
                        />
                        </Box>
                    }
                </Box>
            </Box>
        </ConcurrentLockTooltip>
    )
});

export default CustomProcedure;
