import { useState, useEffect } from 'react';
import { useFormContext } from "react-hook-form"

import { Grid } from '@mui/material';

import { Select, Box, Chip } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import formServices from '@services/forms/forms';
import { useCLAInitialScopingFramework } from './useCLAInitialScopingFramework';
import { useCLAInitialScopingPerformanceStandards } from './useCLAInitialScopingPerformanceStandards';
import { CLAFrameworkChangeModal } from './CLAFrameworkChangeModal';
import CLAPerformanceStandardsChangeModal from './CLAPerformanceStandardsChangeModal';
import { useParams } from 'react-router-dom';
import { useGetAnswersByProjectFormId } from '@services/forms/projectforms.js';
import { sortReviewerSignoffAsc, getFormattedSignOffDate } from '@utilities/dateHelpers.js';
import { isSignoffLogicEnabled } from '@utilities/signoffUtility.js';
import { useFinalizedProject } from '@hooks/useProject';

import clsx from 'clsx';
import styles from '@components/Forms/CLAInitialScopingConsiderations/CLAInitialScopingConsiderations.module.css'

const menuItemStyle = {
    whiteSpace: 'normal',
    wordWrap: 'break-word',
};

const CLAInitialScopingConsiderations = (props) => {
    const { methodologyVersionId, projectFormId, handCaptureInput, fieldId, fieldValue, disabled, onFocus, signOffList, answerList } = props;
    const { projectId } = useParams();
    const [projectScopeId, setProjectScopeId] = useState();
    const [internalControlOptions] = useState([{ value: 'Yes', label: 'Yes' }, { value: 'No', label: 'No' }]);
    const [isInternaControlHighlighted, setIsInternaControlHighlighted] = useState(false)
    const { data: answers } = useGetAnswersByProjectFormId(projectFormId, projectId)
    const isProjectFinalized = useFinalizedProject(projectId);

    const displayEmpty = true;
    const placeholder = 'Select';

    const formCanvas = useFormContext();

    const {
        handleFrameworkChange,
        handleFrameworkClose,
        handleFrameworkOpen,
        onConfirmFrameworkWarningModal,
        frameworks,
        isFrameworksLoading,
        isFrameworkModalOpen,
        selectedFramework,
        auditAreaFrameworkData,
        hasLoadingAuditAreaFrameworkData,
        setIsFrameworkModalOpen,
        associatedFrameworkData,
    } = useCLAInitialScopingFramework({
        projectFormId,
        projectScopeId,
        methodologyVersionId
    });
    
    const {
        handlePerformanceChange,
        handlePerformanceStandardOpen,
        handlePerformanceStandardOnClose,
        onConfirmPerformanceStandardModal,
        associatedStandardPerformancData,
        isPerformanceStandardModalOpen,
        performanceStandards,
        selectedPerformanceStandard,
        hasLoadingAuditAreaperformanceStandardsData,
        auditAreaperformanceStandardsData,
        setIsPerformanceStandardModalOpen,
        isPerformanceStandardsHighlighted,
        isFrameworkHighlighted
    } = useCLAInitialScopingPerformanceStandards({
        projectFormId,
        projectScopeId,
        methodologyVersionId,
        signOffList
    })

    const retrieveProjectScopeInformation = async () => {
        const projectScopeInfo = await formServices.getProjectScopeByProjectFormId(projectFormId, projectId);
        setProjectScopeId(projectScopeInfo?.data?.ProjectScopeId);
    };

    const handleInternalControlChange = (event) => {
        handCaptureInput(event.target.value);
    }

    useEffect(() => {
        if (!projectScopeId) {
            retrieveProjectScopeInformation();
        }
    }, []);

    useEffect(() => {  
        const latestReviewersAsc = sortReviewerSignoffAsc(signOffList)
        let shouldHighlight = false
        let matchingAnswer = {}
        if(answers?.length){
            matchingAnswer = answers.find((answer) => answer.questionId.toLowerCase() === fieldId.toLowerCase())
        }
        if(matchingAnswer?.lastUpdate){
            const dateModified = new Date(matchingAnswer.lastUpdate).getTime();
            const isSignoffEnabled = isSignoffLogicEnabled(isProjectFinalized, dateModified);
            shouldHighlight = new Date(getFormattedSignOffDate(latestReviewersAsc[latestReviewersAsc?.length - 1]?.signOffDate)).getTime() < dateModified;
            setIsInternaControlHighlighted(isSignoffEnabled && shouldHighlight)
        }
    }, [answers, signOffList]) 

    return (
        <>
            <Grid container gap={6}>
                <Grid item xs={12} className={clsx(isPerformanceStandardsHighlighted && styles.DropdownSelectContainerHighlighted)}>
                    <FormControl fullWidth>
                        <InputLabel id="performanceStandards" shrink sx={{ backgroundColor: 'white' }}>What performance standards apply to this project?</InputLabel>
                        <Select
                            labelId="performanceStandards"
                            id="performanceStandardsDropdown"
                            value={selectedPerformanceStandard}
                            label="What performance standards apply to this project?"
                            multiple
                            onOpen={handlePerformanceStandardOpen}
                            onChange={handlePerformanceChange}
                            onClose={handlePerformanceStandardOnClose}
                            displayEmpty={displayEmpty}
                            disabled={disabled}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, }}>
                                    {selected.map((value) => {
                                        const performanceStandard = performanceStandards.find(x => x.PerformanceStandardId === value);
                                        return <Chip key={value} label={performanceStandard?.PerformanceStandardName} />
                                    })}
                                </Box>
                            )}
                        >
                            {displayEmpty && (
                                <MenuItem disabled={true} value="" sx={menuItemStyle}>
                                    {placeholder}
                                </MenuItem>
                            )}
                            {performanceStandards?.map((option, index) => (
                                <MenuItem
                                    key={index}
                                    value={option?.hasOwnProperty('PerformanceStandardId') ? option.PerformanceStandardId : option}
                                    sx={menuItemStyle}
                                >
                                    {option?.hasOwnProperty('PerformanceStandardName') ? option.PerformanceStandardName : option}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} className={clsx(isFrameworkHighlighted && styles.DropdownSelectContainerHighlighted)}>
                    <FormControl fullWidth>
                        <InputLabel id="financialReportingFrameworks" shrink sx={{ backgroundColor: 'white' }}>What financial reporting frameworks apply to this project?</InputLabel>
                        <Select
                            labelId="financialReportingFrameworks"
                            id="financialReportingFrameworksDropdown"
                            value={selectedFramework}
                            label="What financial reporting frameworks apply to this project?"
                            multiple
                            onOpen={handleFrameworkOpen}
                            onChange={handleFrameworkChange}
                            onClose={handleFrameworkClose}
                            displayEmpty={displayEmpty}
                            disabled={disabled}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => {
                                        const frameworkInfo = frameworks.find(x => x.FrameworkId === value);
                                        return <Chip key={value} label={frameworkInfo.FrameworkName} />
                                    })}
                                </Box>
                            )}
                        >
                            {displayEmpty && (
                                <MenuItem disabled={true} value="" sx={menuItemStyle}>
                                    {placeholder}
                                </MenuItem>
                            )}
                            {!isFrameworksLoading && frameworks.map((option, index) => (
                                <MenuItem
                                    key={index}
                                    value={option?.hasOwnProperty('FrameworkId') ? option.FrameworkId : option}
                                    sx={menuItemStyle}
                                >
                                    {option?.hasOwnProperty('FrameworkName') ? option.FrameworkName : option}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={12} className={clsx(isInternaControlHighlighted && styles.DropdownSelectContainerHighlighted)}>
                    <FormControl fullWidth>
                        <InputLabel id={fieldId} shrink sx={{ backgroundColor: 'white' }}>Will the audit team test the operating effectiveness of internal controls in the current year or rely on evidence obtained from prior year tests of controls?</InputLabel>
                        <Select
                            labelId={fieldId}
                            id="internalControlsDropdown"
                            value={formCanvas.getValues(fieldId) ?? ''}
                            label="Will the audit team test the operating effectiveness of internal controls in the current year or rely on evidence obtained from prior year tests of controls?"
                            onChange={handleInternalControlChange}
                            displayEmpty={displayEmpty}
                            disabled={disabled}
                            onFocus={onFocus}
                        >
                            {displayEmpty && (
                                <MenuItem disabled={true} value="" sx={menuItemStyle}>
                                    {placeholder}
                                </MenuItem>
                            )}
                            {internalControlOptions?.map((option, index) => (
                                <MenuItem
                                    key={index}
                                    value={option?.hasOwnProperty('value') ? option.value : option}
                                    sx={menuItemStyle}
                                >
                                    {option?.hasOwnProperty('label') ? option.label : option}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
            <CLAFrameworkChangeModal 
                isFrameworkModalOpen={isFrameworkModalOpen}
                auditAreaFrameworkData={auditAreaFrameworkData}
                hasLoadingAuditAreaFrameworkData={hasLoadingAuditAreaFrameworkData}
                setIsFrameworkModalOpen={setIsFrameworkModalOpen}
                associatedFrameworkData={associatedFrameworkData}
                onConfirmFrameworkWarningModal={onConfirmFrameworkWarningModal}
            />
            <CLAPerformanceStandardsChangeModal 
                associatedStandardPerformancData={associatedStandardPerformancData}
                isPerformanceStandardModalOpen={isPerformanceStandardModalOpen}
                hasLoadingAuditAreaperformanceStandardsData={hasLoadingAuditAreaperformanceStandardsData}
                auditAreaperformanceStandardsData={auditAreaperformanceStandardsData}
                setIsPerformanceStandardModalOpen={setIsPerformanceStandardModalOpen}
                onConfirmPerformanceStandardModal={onConfirmPerformanceStandardModal}
            />
        </>
    );
}

export default CLAInitialScopingConsiderations;
