import { useState, useEffect, Fragment } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Checkbox,
  Grid,
  OutlinedInput,
} from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import { v4 as uuidv4 } from 'uuid';

import { colors } from '@ais/theme';
import { CLADropdown } from "@ais/forms";
import { CLATooltip } from "@ais/components"
import { ButtonComponent } from '.';

const errorStyles = {
  row: {
    outline: '1px solid red',
  },
  cell: {
    borderTop: '1px solid red',
    borderBottom: '1px solid red',
  },
};

export const AuditAreasTable = (props) => {
  const { defaultAuditAreas, filteredAuditAreas, customErrors, onChange } = props;

  const [_defaultAuditAreas, _setDefaultAuditAreas] = useState([]);
  const [selectedAuditAreas, setSelectedAuditAreas] = useState([]);
  const [customAuditAreas, setCustomAuditAreas] = useState({});
  const [toDelete, setToDelete] = useState([]);
  const [toDeleteCustom, setToDeleteCustom] = useState([]);

  const handleAuditAreaSelect = (e) => {
    const checked = e.target.checked;
    const data = e.target.value;
    if (data) {
      if (checked) {
        if (_defaultAuditAreas.length) {
          const split = data.split('||');
          const id = parseInt(split[0]);
          if (_defaultAuditAreas.some((d) => d.AuditAreaId === id))
            setToDelete((prevState) => {
              const filtered = prevState.filter((p) => p.AuditAreaId !== id);
              return filtered;
            });
          setSelectedAuditAreas((prevState) => {
            return [...prevState, data];
          });
        } else
          setSelectedAuditAreas((prevState) => {
            return [...prevState, data];
          });
      } else {
        if (_defaultAuditAreas.length) {
          const split = data.split('||');
          const filtered = _defaultAuditAreas.filter((d) => d.AuditAreaId === parseInt(split[0]));
          if (filtered.length) setToDelete((prevState) => [...prevState, filtered[0]]);
        }
        setSelectedAuditAreas((prevState) => prevState.filter((p) => p !== data));
      }
    }
  };

  const handleAddCustom = () => {
    setCustomAuditAreas((prevState) => {
      const id = uuidv4();
      const copy = { ...prevState };
      copy[id] = {
        projectScopeAuditAreaId: undefined,
        enabled: true,
        name: '',
        auditAreaId: undefined,
        isAssociatedWithRisk: false,
        isAssociatedWithRiskFactor: false,
      };
      return copy;
    });
  };

  const handleChangeCustom = (id, name, value) => {
    setCustomAuditAreas((prevState) => {
      const copy = { ...prevState };
      const values = copy[id];
      values[name] = value;
      copy[id] = values;
      return copy;
    });
  };

  const handleDeleteExistingCustom = (checked, value) => {
    if (!Number.isNaN(value)) {
      if (!checked) {
        setToDeleteCustom((prevState) => {
          prevState.push(value);
          return [...new Set(prevState)];
        });
      }
    }
  };

  const getAuditAreasNotExistsInFiltered = () => {
    return defaultAuditAreas.filter(auditArea =>
      !filteredAuditAreas.some(
        filteredAuditArea => filteredAuditArea.value === auditArea.AuditAreaId
      )
    );
  };

  useEffect(() => {
    if (Array.isArray(defaultAuditAreas)) {
      const _defaultAuditAreas = defaultAuditAreas.filter((daa) => daa.IsCustom === false);
      setSelectedAuditAreas(_defaultAuditAreas.map((d) => `${d.AuditAreaId}||${d.AuditAreaName}`));
      _setDefaultAuditAreas(_defaultAuditAreas);
      const customAuditAreas = defaultAuditAreas
        .filter((daa) => daa.IsCustom === true)
        .map((daa) => ({
          projectScopeAuditAreaId: daa.ProjectScopeAuditAreaId,
          enabled: true,
          name: daa.AuditAreaName,
          auditAreaId: daa.AuditAreaId,
          isAssociatedWithRisk: daa.IsAssociatedWithRisk,
          isAssociatedWithRiskFactor: daa.IsAssociatedWithRiskFactor,
        }));
      const _customAuditAreas = {};
      customAuditAreas.forEach((caa, i) => {
        _customAuditAreas[i] = caa;
      });
      setCustomAuditAreas(_customAuditAreas);
    }
  }, [defaultAuditAreas]);

  useEffect(() => {
    onChange &&
      onChange(
        selectedAuditAreas.map((s) => {
          const data = s.split('||');
          return {
            value: parseInt(data[0]),
            label: data[1],
          };
        }),
        Object.keys(customAuditAreas).map((id) => ({ id, ...customAuditAreas[id] })),
        toDelete,
        toDeleteCustom
      );
  }, [selectedAuditAreas, customAuditAreas, toDelete, toDeleteCustom]);

  useEffect(() => {
    const auditAreasNotExists = getAuditAreasNotExistsInFiltered();
    if (auditAreasNotExists.length > 0) {
      setToDelete(prev => [...prev, ...auditAreasNotExists])
    }
  }, [defaultAuditAreas, filteredAuditAreas]);

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ tableLayout: 'fixed' }}>
          <TableHead>
            <TableRow sx={{ backgroundColor: colors.gray[30] }}>
              <TableCell width="8%"></TableCell>
              <TableCell width="50%" sx={{ fontWeight: 'bold' }}>
                Audit Area
              </TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredAuditAreas.map((data, i) => {
              const value = `${data.value}||${data.label}`;
              const isSelectedAuditArea = _defaultAuditAreas.find(
                (daa) => daa.AuditAreaId === data.value
              );
              const disabled =
                isSelectedAuditArea &&
                (isSelectedAuditArea?.IsAssociatedWithRisk ||
                  isSelectedAuditArea?.IsAssociatedWithRiskFactor);
              return (
                <TableRow key={i}>
                  <TableCell>
                    <Checkbox
                      disabled={disabled}
                      checked={selectedAuditAreas.includes(value)}
                      value={value}
                      onChange={handleAuditAreaSelect}
                    />
                  </TableCell>
                  <TableCell>{data.label}</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              );
            })}
            {Object.keys(customAuditAreas).map((id, i) => {
              const hasError = customErrors.hasOwnProperty(id);
              const isSelectedAuditArea = customAuditAreas[id];
              const disabled =
                isSelectedAuditArea &&
                (isSelectedAuditArea?.isAssociatedWithRisk ||
                  isSelectedAuditArea?.isAssociatedWithRiskFactor);
              return (
                <Fragment key={i}>
                  <TableRow sx={hasError ? errorStyles.row : {}}>
                    <TableCell sx={hasError ? errorStyles.cell : {}}>
                      <Checkbox
                        disabled={disabled}
                        value={customAuditAreas[id].projectScopeAuditAreaId}
                        checked={customAuditAreas[id].enabled}
                        onChange={(e) => {
                          const checked = e.target.checked;
                          const value = parseInt(e.target.value);
                          handleChangeCustom(id, 'enabled', checked);
                          handleDeleteExistingCustom(checked, value);
                        }}
                      />
                    </TableCell>
                    <TableCell sx={hasError ? errorStyles.cell : {}}>
                      <OutlinedInput
                        sx={{ padding: 0 }}
                        value={customAuditAreas[id].name}
                        onChange={(e) => {
                          const value = e.target.value;
                          handleChangeCustom(id, 'name', value);
                        }}
                        placeholder="Enter text"
                        inputProps={{ maxLength: 256 }}
                        fullWidth
                      />
                    </TableCell>
                    <TableCell sx={hasError ? errorStyles.cell : {}}>
                      <CLADropdown
                        placeholder="Select Procedures Library"
                        value={customAuditAreas[id].auditAreaId}
                        options={filteredAuditAreas}
                        onChange={(e) => {
                          const value = e.target.value;
                          handleChangeCustom(id, 'auditAreaId', value);
                        }}
                      />
                    </TableCell>
                  </TableRow>
                  {hasError && (
                    <TableRow>
                      <TableCell></TableCell>
                      <TableCell sx={{ color: 'red' }}>Audit Area name must be unique</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                  )}
                </Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <Grid container p={3} pb={6}>
        <Grid item xs>
          <ButtonComponent
            label={null}
            buttonName={
              <>
                <span>Add custom audit area</span>
                <CLATooltip
                  title="When adding a custom audit area enter the name and select a procedures library to use as the starting point for tailoring the related audit program"
                  placement="top"
                >
                  <HelpIcon sx={{ ml: 1, fontSize: '18px' }} />
                </CLATooltip>
              </>
            }
            onClick={handleAddCustom}
          />
        </Grid>
      </Grid>
    </>
  );
};
