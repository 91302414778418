import React, { useState, useEffect, Fragment, useRef } from 'react';
import isEqual from 'lodash/isEqual';
import { useParams } from 'react-router-dom';
import {
    Box,
    Divider
} from '@mui/material';

import useProjectFormInstanceConcurrentLocking from '@hooks/useProjectFormInstanceConcurrentLocking'
import {CLASelect} from '@ais/forms';
import { updateProjectRiskByAuditAreaAndProjectFormId } from "@services/projectRisk";
import { colors } from '@ais/theme';
import ConcurrentLockTooltip from '@components/ConcurrentLockTooltip/ConcurrentLockTooltip'
import { useFinalizedProject } from '@hooks/useProject';

const SummaryProcedure = (props) => {
    const { 
        questionId, 
        summaryProcedure, 
        index, 
        children,
        projectRisks, 
        getProjectFormProcedures,
        setSuccessToast,
        setErrorToast,
        setLoadingToast
    } = props;
    const {
        SummaryProcedureName: summaryProcedureName,
        SummaryProcedureId: summaryProcedureId,
        ProjectRiskSummaryProcedure
    } = summaryProcedure;

    const { projectId, projectFormId } = useParams();
    const [defaultValues, setDefaultValues] = useState([]);
    const [initialized, setInitialized] = useState(false);
    const [isCLASelectOpen, setIsCLASelectOpen] = useState(false);
    const isProjectFinalized = useFinalizedProject(projectId);

    const CLASelectRef = useRef(null);

    function handleCLASelectClick(toogle) {
        setIsCLASelectOpen(toogle)
    }

    function handleIdleForm(origValue) { 
        handleCLASelectClick(false)
        setDefaultValues([...(origValue?.defaultValues || defaultValues) ])
        CLASelectRef.current?.classList.remove('Mui-focused')
    }

    const handleOnChange = (values) => {
        handleBlur(values)
    }

    const {
        isLocked,
        lockingUser,
        emitLockEvent, 
        emitUnlockEvent, 
        concurrentValue
    } = useProjectFormInstanceConcurrentLocking(
        questionId,
        {
            summaryProcedureId: summaryProcedure?.SummaryProcedureId,
            onIdle: handleIdleForm
        }
    )

    const sortedProjectRisks = projectRisks.sort((a, b) => a.RiskName.toLowerCase().localeCompare(b.RiskName.toLowerCase()));

    const getValuesAsConcurrentAnswerObject = () => ({
        defaultValues,
        summaryProcedureId
    });

    useEffect(() => {
        const _defaultValues = ProjectRiskSummaryProcedure ? ProjectRiskSummaryProcedure.map(projectRisk => projectRisk.ProjectRiskId) : [];
        setDefaultValues(_defaultValues)
        setInitialized(true);
    }, [ProjectRiskSummaryProcedure])

    useEffect(() => {
        if(!initialized || concurrentValue === null) return; // Means no change has been made, or concurrentValue has just been initialized

        const {
            defaultValues,
            summaryProcedureId:id,
        } = concurrentValue;

        if (summaryProcedure?.SummaryProcedureId !== id) 
            return;

        setDefaultValues(defaultValues);
    }, [concurrentValue, initialized])

    const handleOnFocusProcedure = () => {
        emitLockEvent(getValuesAsConcurrentAnswerObject());
    }

    async function handleBlur(values) {
        const isEqualValue = isEqual(defaultValues, values);
        if (isEqualValue) return;

        setLoadingToast(true)
        try {
            await updateProjectRiskByAuditAreaAndProjectFormId(projectId, summaryProcedureId, projectFormId, values);
            const currentAnswer = { ...getValuesAsConcurrentAnswerObject(), defaultValues: values };
            emitUnlockEvent(currentAnswer);
            setDefaultValues(values); 
            getProjectFormProcedures();
            setErrorToast(false)
            setSuccessToast(true)
            setLoadingToast(false)
        } catch (error) {
            setErrorToast(true)
            setSuccessToast(false)
            setLoadingToast(false)
        }
        
    }

    return (
        <Fragment>
            <ConcurrentLockTooltip lockingUser={lockingUser}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    p: '20px 15px 10px 15px'
                }}>
                    <Box sx={{ width: '20%', fontSize: '16px', fontWeigth: '700' }} data-test="procedureName-text"> {`${index}. ${summaryProcedureName}`}</Box>
                    <Box sx={{ width: '40%' }}>
                        <CLASelect
                            ref={CLASelectRef}
                            data-test="identifiedRisk-dropdown"
                            placeholder="Select"
                            defaultValues={defaultValues}
                            menuItems={sortedProjectRisks?.map(projectRisk => ({ ...projectRisk, label: projectRisk.RiskName, value: projectRisk.ProjectRiskId }))}
                            onChange={handleOnChange}
                            onFocus={handleOnFocusProcedure}
                            isDisabled={isLocked || isProjectFinalized}
                            onClose={() => handleCLASelectClick(false)}
                            onOpen={() => handleCLASelectClick(true)}
                            open={isCLASelectOpen}
                        />
                    </Box>
                    <Box sx={{ width: '40%' }} />
                </Box >
            </ConcurrentLockTooltip>
            <Box sx={{ p: '0 15px 10px 15px' }} data-test="summaryProcedureSteps-container">
                {children}
            </Box>
            <Divider sx={{ backgroundColor: colors.gray[30], borderWidth: '1px' }} />
        </Fragment>
    )
};

export default SummaryProcedure;
