import { createContext, useContext, useEffect, useRef, useState } from "react";
import { io } from "socket.io-client";
import { useMsal } from "@azure/msal-react";
import { stringHelper as string } from "@ais/utilities";
const baseUrl = process.env.REACT_APP_API_FORM_WEBSOCKET;
const proxy = string.stripPath(baseUrl);
const namespace = new URL ("/notifications", baseUrl)
export const socket = io(namespace.href, {
  path: proxy + "/ais",
  transports: ["websocket"],
  autoConnect: false,
  upgrade: false,
});

export const NotificationContext = createContext({});

export const NotificationProvider = ({ children }) => {
  const { accounts } = useMsal();
  const userId = accounts[0].localAccountId.toUpperCase();

  const [notifications, setNotifications] = useState([]);
  const [notificationsRefreshed, setNotificationsrefreshed] = useState(false);
 
  const handleNewNotification = async (newNotification) => {
    // Check if the new notification is already present in the notifications array..
    const isNotificationPresent = notifications.some(
      (notification) =>
        notification.JobInstanceId === newNotification.JobInstanceId
    );
    deletePreviousNotifications(newNotification)
    if (!isNotificationPresent) {

      // Add the new notification to the notifications array
      setNotifications((prevNotifications) => [
        { ...newNotification, isRead: false, isDelete: false },
        ...prevNotifications,
      ]);
      // Update Status for Red dot on notification Bell
      if (!notificationsRefreshed) setNotificationsrefreshed(true);
    }
  };

  const deletePreviousNotifications = (newNotification) => {
    setNotifications((prevNotifications) =>
      prevNotifications.filter((notification) => notification.ReqBody !== newNotification.ReqBody)
    );
  };
const updateNotification = (index, key, value) => {
    setNotifications((prevNotifications) => {
      const newNotifications = [...prevNotifications];
      newNotifications[index][key] = value;
      return newNotifications;
    });
    // Checking if All notifications are read
    if (!notifications.some((notification) => !notification.isRead)) {
      setNotificationsrefreshed(false);
    }
    if (!notifications.some((notification) => !notification.isDelete)) {
      setNotificationsrefreshed(false);
    }
  };


  const onConnect = () =>  {
    socket.emit("subscribe_to_notification", { userId });
  }

  const onDisconnect = () => {
    // Log added for local development
    // console.info("socket notifications disconnected");
  }

 
  useEffect(() => {
    socket.connect();

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on("notify_subscribers", handleNewNotification);

    return () => {
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
      socket.off('notify_subscribers', handleNewNotification);
    };
  }, []);

  return (
    <NotificationContext.Provider
      value={{ socket, notifications, notificationsRefreshed, updateNotification }}
    >
      {children}
    </NotificationContext.Provider>
  );
};

export const useNotificationContext = () => {
  const context = useContext(NotificationContext);
  if (!context) {
    throw new Error("useNotificationContext must be used within NotificationProvider");
  }
  return context;
};