import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import ContainerHeader from "./ContainerHeader";
import IncorporateKeyControlsTable from "./IncorporateKeyControlsTable";
import { ReactComponent as DeleteIcon } from "@assets/delete_icon.svg";
import { Box, IconButton } from '@mui/material';
import { CLADialogConfirm } from "@ais/components";
import { INTERNAL_CONTROLS } from "@constants/customForm";
import { renderLabel } from '@ais/forms';
import styles from "./styles.module.css";
import { useDeleteProjectKeyControl, useUpdateProjectKeyControl } from "@services/customForm/internalControls.js"
import { ConcurrentCheckbox } from '../../ConcurrentCheckbox'
import { ConcurrentInternalControlTextbox } from '../../ConcurrentInternalControlTextbox'
import CustomToast from '@components/CustomToast/CustomToast';
import { useInternalControlsContext } from '@contexts/InternalControls/InternalControlsContext';
import { sortReviewerSignoffAsc } from '@utilities/dateHelpers.js';
import { isSignoffLogicEnabled } from '@utilities/signoffUtility.js';


const IncorporateKeyControls = ({ projectInternalControlProjectKeyControl, isProjectFinalized, signOffList }) => {
  const { projectFormId } = useParams();
  const { MODAL, LABELS, TOOLTIPS, INCORPORATE_KEY_CONTROLS, KEYS } = INTERNAL_CONTROLS.EN;
  const { projectId } = useParams();
  const dialogConfigsInitialValue = { visibility: false, id: undefined };
  const [deleteDialogConfigs, setDeleteDialogConfigs] = useState(dialogConfigsInitialValue);
  const [keyControlsData, setKeyControlsData] = useState({
    [INCORPORATE_KEY_CONTROLS.TABLE.FINANCIAL_STATEMENT_ID]: [],
    [INCORPORATE_KEY_CONTROLS.TABLE.JOURNAL_ID]: []
  })
  const { mutateAsync: deleteProjectKeyControl } = useDeleteProjectKeyControl(projectId, projectFormId);
  const { mutateAsync: updateProjectKeyControl } = useUpdateProjectKeyControl();

  const [loadingIncorporateKeyControls, setLoadingIncorporateKeyControls] = useState(false)
  const [errorIncorporateKeyControls, setErrorIncorporateKeyControls] = useState(false)
  const [successIncorporateKeyControls, setSuccessIncorporateKeyControls] = useState(false)
  const [journalEntryTableHighlighted, setJournalEntryTableHighlighted] = useState(false)
  const [financialStatementTableHighlighted, setFinancialStatementTableHighlighted] = useState(false)

  const { formattedInternalControls, changeJournalFinancialKeyControlField, changeInternalControlsObj } =
    useInternalControlsContext();

  /**
   * 
   * @param {number} projectKeyControlId 
   * @returns {number}
   */
  const getFoundKeyControlIndex = (projectKeyControlId) =>
    formattedInternalControls?.ProjectInternalControlProjectKeyControl?.findIndex((keyControl) => {
      return keyControl.ProjectKeyControl[0].ProjectKeyControlId === projectKeyControlId;
    });
  
  const deleteHandler = async (projectKeyControlId) => {
    const res = await deleteProjectKeyControl({projectKeyControlId})

    if (res) {
      setDeleteDialogConfigs(dialogConfigsInitialValue);
    }
  };

  const changeHandler = async (projectKeyControlId, key, value, itemData, keyControlCategory) => {
    setKeyControlsData((previousValue) => {
      const clonedPrevValue = { ...previousValue };
      clonedPrevValue[keyControlCategory].map((data) => {
        if (parseInt(data.ProjectKeyControlId) === parseInt(itemData.ProjectKeyControlId)) {
          data[key] = value;
        }

        return data;
      });
      return clonedPrevValue;
    });

    const payload =
      key === KEYS.IS_IMPLEMENTED_EFFECTIVELY
        ? { IsImplementedEffectively: value }
        : key === KEYS.IS_DESIGNED_EFFECTIVELY
          ? { IsDesignedEffectively: value }
          : { WalkthroughComment: value };
    payload['RequestProjectFormId'] = projectFormId;

    const foundIndex = getFoundKeyControlIndex(projectKeyControlId);
    const internalControlDataBeforeUpdate = structuredClone(formattedInternalControls);
    changeJournalFinancialKeyControlField(foundIndex, key, value);

    try {
      setLoadingIncorporateKeyControls(true);
      await updateProjectKeyControl({
        ProjectKeyControlId: projectKeyControlId,
        ProjectId: projectId,
        payload,
        projectId
      })
      setSuccessIncorporateKeyControls(true)
      setLoadingIncorporateKeyControls(false)
    } catch (error) {
      changeInternalControlsObj(internalControlDataBeforeUpdate);
      setLoadingIncorporateKeyControls(false);
      setSuccessIncorporateKeyControls(false);
      setErrorIncorporateKeyControls(true);
    }
  };

  const filterAndSortKeyControl = (keyControlData, key, formattedInternalControls, signOffList) => {
    const latestReviewersAsc = sortReviewerSignoffAsc(signOffList)
    if(latestReviewersAsc.length === 0){
      setJournalEntryTableHighlighted(false)
      setFinancialStatementTableHighlighted(false)
    }
    if (!keyControlData || keyControlData.length === 0) return
    let keyControls = keyControlData
      .filter((filterData) => filterData[key])
      .toSorted((a, b) => a?.ProjectKeyControl[0]?.ProjectKeyControlId - b?.ProjectKeyControl[0]?.ProjectKeyControlId)
      .map((data) => data.ProjectKeyControl);
    return keyControls.map((data) => {
      const kc = data[0]
      let isWalkthroughHighlighted = false
      let isDesignedEffectivelyHighlighted = false
      let isImplementedEffectivelyHighlighted = false
      let isAddedAfterSignoff = false

      let journalHistory = []
      let financialHistory = []
      const history = formattedInternalControls?.ProjectKeyControlHistory?.find((kch) => kch.ProjectKeyControlId === kc.ProjectKeyControlId)
      journalHistory = formattedInternalControls?.ProjectKeyControlHistory?.filter((kch) => kch.IsJournalEntry)
      financialHistory = formattedInternalControls?.ProjectKeyControlHistory?.filter((kch) => kch.IsFinancialStatement)

      const kcDateModified = new Date(kc?.ValidFrom + 'Z').getTime();
      let isSignoffEnabled = isSignoffLogicEnabled(isProjectFinalized, kcDateModified);
      if(isSignoffEnabled){
        if(new Date(latestReviewersAsc[latestReviewersAsc?.length - 1]?.signOffDate).getTime() < kcDateModified){
          const journalResultAdd = journalHistory.find((jh) => kc.ProjectKeyControlId === jh.ProjectKeyControlId)
          const financialResultAdd = financialHistory.find((fh) => kc.ProjectKeyControlId === fh.ProjectKeyControlId)
          if(journalResultAdd === undefined && financialResultAdd === undefined){
            isAddedAfterSignoff = true
          }
        }
  
        if(journalHistory.length === 0 && kc?.IsJournalEntry){
          if(new Date(latestReviewersAsc[latestReviewersAsc?.length - 1]?.signOffDate).getTime() < kcDateModified){
            isAddedAfterSignoff = true
          }
        }
        if(financialHistory.length === 0 && kc?.IsFinancialStatement){
          if(new Date(latestReviewersAsc[latestReviewersAsc?.length - 1]?.signOffDate).getTime() < kcDateModified){
            isAddedAfterSignoff = true
          }
        }
      }

      const historyModifiedDate = new Date(history?.ValidTo).getTime();
      isSignoffEnabled = isSignoffLogicEnabled(isProjectFinalized, historyModifiedDate);
      if(isSignoffEnabled && new Date(latestReviewersAsc[latestReviewersAsc?.length - 1]?.signOffDate).getTime() < historyModifiedDate){
        isWalkthroughHighlighted = history.WalkthroughComment !== kc.WalkthroughComment && kc.WalkthroughComment ? true : false
        isDesignedEffectivelyHighlighted = history.IsDesignedEffectively !== kc.IsDesignedEffectively ? true : false
        isImplementedEffectivelyHighlighted = history.IsImplementedEffectively !== kc.IsImplementedEffectively ? true : false
      }

      if(formattedInternalControls?.KeyControlHistory?.length > 0){
        let journalHistory = []
        let financialHistory = []
        let keyControlArray = []
        let deletedKeyControl = []

        journalHistory = formattedInternalControls?.KeyControlHistory.filter((kch) => kch.IsJournalEntry)
        financialHistory = formattedInternalControls?.KeyControlHistory.filter((kch) => kch.IsFinancialStatement)
        keyControlArray = keyControlData.map((kc) => {
          return kc.ProjectKeyControl[0].ProjectKeyControlId
        })
        deletedKeyControl = formattedInternalControls?.ProjectKeyControlHistory.filter((kch) => {
          return !keyControlArray.includes(kch.ProjectKeyControlId)
        })
        deletedKeyControl.forEach((keyControl) => {
          if(keyControl.IsJournalEntry){
            const dateModified = new Date(keyControl?.ValidTo).getTime();
            const isSignoffEnabled = isSignoffLogicEnabled(isProjectFinalized, dateModified);
            const isModifiedAfterSignoff = new Date(latestReviewersAsc[latestReviewersAsc?.length - 1]?.signOffDate).getTime() < dateModified;
            setJournalEntryTableHighlighted(isSignoffEnabled && isModifiedAfterSignoff)
          }
          if(keyControl.IsFinancialStatement){
            const dateModified = new Date(keyControl?.ValidTo).getTime();
            const isSignoffEnabled = isSignoffLogicEnabled(isProjectFinalized, dateModified);
            const isModifiedAfterSignoff = new Date(latestReviewersAsc[latestReviewersAsc?.length - 1]?.signOffDate).getTime() < dateModified;
            setFinancialStatementTableHighlighted(isSignoffEnabled && isModifiedAfterSignoff)
          }
        })
      }
      

      return {
        ...data[0],
        isWalkthroughHighlighted: isWalkthroughHighlighted,
        isDesignedEffectivelyHighlighted: isDesignedEffectivelyHighlighted, 
        isImplementedEffectivelyHighlighted: isImplementedEffectivelyHighlighted,
        isAddedAfterSignoff: isAddedAfterSignoff
      }
    });
  };

  useEffect(() => {
    if (projectInternalControlProjectKeyControl) {
      setKeyControlsData({
        [INCORPORATE_KEY_CONTROLS.TABLE.FINANCIAL_STATEMENT_ID]:
          filterAndSortKeyControl(projectInternalControlProjectKeyControl, KEYS.IS_FINANCIAL_STATEMENT, formattedInternalControls, signOffList) || [],
        [INCORPORATE_KEY_CONTROLS.TABLE.JOURNAL_ID]:
          filterAndSortKeyControl(projectInternalControlProjectKeyControl, KEYS.IS_JOURNAL_ENTRY, formattedInternalControls, signOffList) || []
      });
    }
  }, [projectInternalControlProjectKeyControl, formattedInternalControls, signOffList])
  

  const tableData = [
    {
      tableId: INCORPORATE_KEY_CONTROLS.TABLE.JOURNAL_ID,
      shouldHighlight: journalEntryTableHighlighted,
      cols: [
        {
          id: KEYS.PROJECT_KEY_CONTROL_NAME,
          text: renderLabel(LABELS.KEY_CONTROLS, TOOLTIPS.KEY_CONTROLS_HEADER, null, { fontSize: '17px', marginLeft: '5px', color: "#2e334e" }),
          otherProps: {
            style: {
              width: "507px"
            },
            className: styles['key-control-name']
          }
        },
        {
          id: KEYS.IS_DESIGNED_EFFECTIVELY,
          text: INCORPORATE_KEY_CONTROLS.TABLE.CONTROL_DESIGNED_EFFECTIVELY,
          otherProps: {
            align: "center",
            className: styles['designed-col'],
          },
          rowClassname: styles['designed-row']
        },
        {
          id: KEYS.IS_IMPLEMENTED_EFFECTIVELY,
          text: INCORPORATE_KEY_CONTROLS.TABLE.CONTROL_HAS_BEEN_IMPLEMENTED_EFFECTIVELY,
          otherProps: {
            align: "center"
          },
        },
        {
          id: KEYS.ACTIONS,
          text: "",
          otherProps: {
            align: "right"
          },
        },
      ],
      rows: keyControlsData[INCORPORATE_KEY_CONTROLS.TABLE.JOURNAL_ID].map((data) => {
        return {
          projectKeyControlId: data.ProjectKeyControlId,
          isAddedAfterSignoff: data.isAddedAfterSignoff,
          isWalkthroughHighlighted: data.isWalkthroughHighlighted,
          [KEYS.PROJECT_KEY_CONTROL_NAME]: data.ProjectKeyControlName,
          [KEYS.IS_DESIGNED_EFFECTIVELY]: 
          <Box sx={data.isDesignedEffectivelyHighlighted ? {borderStyle: 'dashed', borderWidth: '4px', borderColor: 'red', width: '46px'}: {}}>
            <ConcurrentCheckbox
              checked={data.IsDesignedEffectively}
              value={data.IsDesignedEffectively}
              onChange={(event) => {
                changeHandler(
                  data.ProjectKeyControlId,
                  KEYS.IS_DESIGNED_EFFECTIVELY,
                  event.target.checked,
                  data,
                  INCORPORATE_KEY_CONTROLS.TABLE.JOURNAL_ID
                );
              }}
              customFormObjectId={`is-designed-effectively-${data.ProjectKeyControlId}`}
              disabled={isProjectFinalized}
            />
          </Box>
          ,
          [KEYS.IS_IMPLEMENTED_EFFECTIVELY]: 
          <Box sx={data.isImplementedEffectivelyHighlighted ? {borderStyle: 'dashed', borderWidth: '4px', borderColor: 'red', width: '46px'}: {}}>
            <ConcurrentCheckbox
              checked={data.IsImplementedEffectively}
              value={data.IsImplementedEffectively}
              onChange={(event) => {
                changeHandler(
                  data.ProjectKeyControlId,
                  KEYS.IS_IMPLEMENTED_EFFECTIVELY,
                  event.target.checked,
                  data,
                  INCORPORATE_KEY_CONTROLS.TABLE.JOURNAL_ID
                );
              }}
              customFormObjectId={`is-implemented-effectively-${data.ProjectKeyControlId}`}
              disabled={isProjectFinalized}
            />
          </Box>
          ,     
          [KEYS.ACTIONS]: (
            <IconButton
              onClick={() => {
                setDeleteDialogConfigs({
                  visibility: true,
                  id: data.ProjectKeyControlId,
                });
              }}
              disabled={isProjectFinalized}
            >
              <DeleteIcon sx={{ fontSize: 40 }} />
            </IconButton>
          ),
          subChildren: (
            <ConcurrentInternalControlTextbox
              value={data.WalkthroughComment}
              onBlur={(event) =>
                changeHandler(
                  data.ProjectKeyControlId,
                  KEYS.WALKTHROUGH_COMMENT,
                  event.target.value,
                  data,
                  INCORPORATE_KEY_CONTROLS.TABLE.JOURNAL_ID
                )
              }
              inputLableShrink={true}
              sx={{
                width: "100%",
              }}
              inputProps={{
                style: { fontSize: 14 }
              }}
              InputLabelProps={{
                shrink: true
              }}
              className={styles["custom-content-text-field"]}
              label={renderLabel(
                INTERNAL_CONTROLS.EN.LABELS.WALKTHROUGH_COMMENT,
                INTERNAL_CONTROLS.EN.TOOLTIPS.TEXTFIELD,
                null,
                { fontSize: "23px", marginLeft: "10px", color: "#2e334e" }
              )}
              placeholder={INTERNAL_CONTROLS.EN.INCORPORATE_KEY_CONTROLS.PLACEHOLDERS.WALKTHROUGH}
              customFormObjectId={`incorporate-data-controls-walkthrough-comment-${data.ProjectKeyControlId}`}
              disabled={isProjectFinalized}
              maxLength={null}
            />
          ),
        }
      }),
      headerName: INCORPORATE_KEY_CONTROLS.TABLE.JOURNAL_ENTRY_HEADER,
    },
    {
      tableId: INCORPORATE_KEY_CONTROLS.TABLE.FINANCIAL_STATEMENT_ID,
      shouldHighlight: financialStatementTableHighlighted,
      cols: [
        {
          id: KEYS.PROJECT_KEY_CONTROL_NAME,
          text: renderLabel(LABELS.KEY_CONTROLS, TOOLTIPS.KEY_CONTROLS_HEADER, null, { fontSize: '17px', marginLeft: '5px', color: "#2e334e" }),
          otherProps: {
            style: {
              width: "507px"
            },
            className: styles['key-control-name-col']
          }
        },
        {
          id: KEYS.IS_DESIGNED_EFFECTIVELY,
          text: INCORPORATE_KEY_CONTROLS.TABLE.CONTROL_DESIGNED_EFFECTIVELY,
          otherProps: {
            align: "center",
            className: styles['designed-col'],
          },
          rowClassname: styles['designed-row']
        },
        {
          id: KEYS.IS_IMPLEMENTED_EFFECTIVELY,
          text: INCORPORATE_KEY_CONTROLS.TABLE.CONTROL_HAS_BEEN_IMPLEMENTED_EFFECTIVELY,
          otherProps: {
            align: "center",
            className: styles['implemented-col']
          },
        },
        {
          id: KEYS.ACTIONS,
          text: "",
          otherProps: {
            align: "right"
          },
        },
      ],
      rows: keyControlsData[INCORPORATE_KEY_CONTROLS.TABLE.FINANCIAL_STATEMENT_ID].map((data) => {
        return {
          projectKeyControlId: data.ProjectKeyControlId,
          isAddedAfterSignoff: data.isAddedAfterSignoff,
          isWalkthroughHighlighted: data.isWalkthroughHighlighted,
          [KEYS.PROJECT_KEY_CONTROL_NAME]: data.ProjectKeyControlName,
          [KEYS.IS_DESIGNED_EFFECTIVELY]: 
          <Box sx={data.isDesignedEffectivelyHighlighted ? {borderStyle: 'dashed', borderWidth: '4px', borderColor: 'red', width: '46px'}: {}}>
            <ConcurrentCheckbox
              checked={data.IsDesignedEffectively}
              value={data.IsDesignedEffectively}
              onChange={(event) => {
                changeHandler(
                  data.ProjectKeyControlId,
                  KEYS.IS_DESIGNED_EFFECTIVELY,
                  event.target.checked,
                  data,
                  INCORPORATE_KEY_CONTROLS.TABLE.FINANCIAL_STATEMENT_ID
                );
              }}
              customFormObjectId={`incorporate-key-controls-is-designed-effectively-${data.ProjectKeyControlId}`}
              disabled={isProjectFinalized}
            />
          </Box>
          ,
          [KEYS.IS_IMPLEMENTED_EFFECTIVELY]: 
          <Box sx={data.isImplementedEffectivelyHighlighted ? {borderStyle: 'dashed', borderWidth: '4px', borderColor: 'red', width: '46px'}: {}}>
            <ConcurrentCheckbox
              checked={data.IsImplementedEffectively}
              value={data.IsImplementedEffectively}
              onChange={(event) => {
                changeHandler(
                  data.ProjectKeyControlId,
                  KEYS.IS_IMPLEMENTED_EFFECTIVELY,
                  event.target.checked,
                  data,
                  INCORPORATE_KEY_CONTROLS.TABLE.FINANCIAL_STATEMENT_ID
                );
              }}
              customFormObjectId={`incorporate-key-controls-is-implemented-effectively-${data.ProjectKeyControlId}`}
              disabled={isProjectFinalized}
            />
          </Box>
          ,
          [KEYS.ACTIONS]: (
            <IconButton
              onClick={() => {
                setDeleteDialogConfigs({
                  visibility: true,
                  id: data.ProjectKeyControlId,
                });
              }}
              disabled={isProjectFinalized}
            >
              <DeleteIcon sx={{ fontSize: 40 }} />
            </IconButton>
          ),
          subChildren: (
            <ConcurrentInternalControlTextbox
              value={data.WalkthroughComment}
              onBlur={(event) =>
                changeHandler(
                  data.ProjectKeyControlId,
                  KEYS.WALKTHROUGH_COMMENT,
                  event.target.value,
                  data,
                  INCORPORATE_KEY_CONTROLS.TABLE.FINANCIAL_STATEMENT_ID
                )
              }
              inputLableShrink={true}
              sx={{
                width: "100%",
              }}
              inputProps={{
                style: { fontSize: 14 }
              }}
              InputLabelProps={{
                shrink: true
              }}
              className={styles["custom-content-text-field"]}
              label={renderLabel(
                INTERNAL_CONTROLS.EN.LABELS.WALKTHROUGH_COMMENT,
                INTERNAL_CONTROLS.EN.TOOLTIPS.TEXTFIELD,
                null,
                { fontSize: "23px", marginLeft: "10px", color: "#2e334e" }
              )}
              placeholder={INTERNAL_CONTROLS.EN.INCORPORATE_KEY_CONTROLS.PLACEHOLDERS.WALKTHROUGH}
              customFormObjectId={`financial-statement-walkthrough-comment-${data.ProjectKeyControlId}`}
              disabled={isProjectFinalized}
              maxLength={null}
              />
          ),
        }
      }),
      headerName: INCORPORATE_KEY_CONTROLS.TABLE.FINANCIAL_STATEMENT_HEADER,
    },
  ];

  return (
    <React.Fragment>
      {tableData.map((data) => {
        if (!data.rows.length) {
          return <React.Fragment key={data.tableId} />
        }

        return (
          <React.Fragment key={data.tableId}>
            <ContainerHeader text={data.headerName} />
            <IncorporateKeyControlsTable
              rows={data.rows}
              cols={data.cols}
              tableId={data.tableId}
              shouldHighlight={data.shouldHighlight}
            />
          </React.Fragment>
        )
      })}
      <CLADialogConfirm
        visible={deleteDialogConfigs.visibility}
        title={MODAL.TITLE}
        cancelText={MODAL.CANCEL}
        confirmText={MODAL.DELETE}
        message={MODAL.BODY}
        onConfirm={() => deleteHandler(deleteDialogConfigs.id)}
        onCancel={() => setDeleteDialogConfigs(dialogConfigsInitialValue)}
      />
      <CustomToast 
        error={errorIncorporateKeyControls} 
        success={successIncorporateKeyControls}
        loading={loadingIncorporateKeyControls}
      />
    </React.Fragment>
  );
};

export default IncorporateKeyControls;
