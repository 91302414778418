import { useEffect, useState } from 'react'
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper } from '@mui/material';

import { useLoading } from "@hooks/index";
import { openUrlInNewTab } from "@utilities/window";

import { INHERENT_RISK_FACTORS } from '@constants/customForm'
import InherentRiskFactorsAccordion from '@views/ProjectFormInstance/InherentRiskFactors/InherentRiskFactorsAccordion';

import { useGetProjectRiskFactor } from "@services/customForm/riskFactor.js";

import styles from '@views/ProjectFormInstance/InherentRiskFactors/InherentRiskFactors.module.css'

const InherentRiskFactors = ({ projectId, projectFormId }) => {
    const { SECTION } = INHERENT_RISK_FACTORS.EN;
    const setLoading = useLoading();
    const [riskFactorData, setRiskFactorData] = useState([]);

    const {
        data: projectRiskFactor,
        isLoading: projectRiskFactorLoading,
        isRefetching: projectRiskFactorRefetching
    } = useGetProjectRiskFactor(null, projectFormId, null, projectId);

    const updateRowDataFormat = (dataArray) => {
        if (!dataArray || (dataArray && dataArray.length < 1)) return [];
        return dataArray.map((value, index) => ({
            ...value,
            action: '',
            id: index,
            riskFactorName: value.RiskFactorName,
        }));
    };

    useEffect(() => {
        setLoading(projectRiskFactorLoading || projectRiskFactorRefetching);
        if (projectRiskFactorLoading || projectRiskFactorRefetching) return;
        setRiskFactorData(updateRowDataFormat(projectRiskFactor))
    }, [projectRiskFactorLoading, projectRiskFactorRefetching]);
    
    const onClickLink = () => {
        const urlPath = `/project-management/${projectId}/inherent-risk-factor-summary/`;
        openUrlInNewTab(urlPath);
    }

    return (
        (riskFactorData.length > 0) &&
        <InherentRiskFactorsAccordion
            title={SECTION.TITLE}
            style={styles}
        >
            <div className={styles.irfMainWrapper}>
                <TableContainer className={styles.irfTableContainerWrapper} component={Paper}>
                    <Table className={styles.irfTableWrapper} aria-label="custom pagination table">
                        <TableBody>
                            <TableRow scope="row">
                                <TableCell component="th" scope="row">
                                    {SECTION.HEADER}
                                </TableCell>
                            </TableRow>
                            {riskFactorData.map((riskFactor, index) => {
                                return (
                                    <TableRow key={index}>
                                        <TableCell component="th" scope="row">
                                            <a href='#' onClick={() => onClickLink()}>{riskFactor.RiskFactorName}</a>
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
        </InherentRiskFactorsAccordion>
    )
}

export default InherentRiskFactors