import React, { useEffect, useState, useContext } from 'react'
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import {
    Paper,
    Typography,
    Button,
    Box,
    Collapse,
    IconButton
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import {CollapseIconComponent, ExpandIconComponent} from "@ais/assets";
import { useLoading } from '@hooks/index';

import style from './CLATailorProcedureTable.module.css'

import projectFormServices from "@services/forms/projectforms";
import riskAssessmentService from '@services/riskassessment';
import { colors } from '@ais/theme';
import { PROJECT_FORM_INSTANCE } from '@constants/index';
import SummaryProcedureCollapse from './SummaryProcedureCollapse';
import { TailorProcedureContext, TailorProcedureReducer } from '@components/Forms/state';
import CustomTailorProcedureTable from './CustomTailorProcedureTable';
import CLATailoringProcedureDialog from './CLATailoringProcedureDialog';
import {CLADialogConfirm} from '@ais/components';
import { TAILOR_PROCEDURE_REDUCER } from '@constants/forms';
import { useFetchProcedureSuggestions } from '@services/tailoringprocedures';
import { TailoringProcedureProvider } from './state/TailoringProcedureContext';
import white_bulb from "@assets/white_bulb.svg";
import CLATailorProcedureFilter from './CLATailorProcedureFilter';
import { TailoringFilterProcedureContextProvider } from './state/TailoringFilterProcedureContext';
import useTailorProceduresConcurrency from '@hooks/useTailorProceduresConcurrency';
import {useAuditAreasbyMethodologyVersionId} from '@services/auditArea';


const CLATailorProcedureTable = (props) => {
    const { tailorProcedure = {}, dispatchTailorProcedures } = useContext(TailorProcedureContext);
    const { auditArea, closeDrawer, sectionId, isVisible } = props;
    const tailorProcedureData = tailorProcedure[`${sectionId}`];
    const [summaryProcedures, setSummaryProcedures] = useState(tailorProcedureData ? tailorProcedureData.summaryProcedures : []);
    const [summaryProceduresUpdated, setSummaryProceduresUpdated] = useState(tailorProcedureData ? tailorProcedureData.summaryProcedures : []);
    const [saveDisabled, setSaveDisabled] = useState(false);
    const [showDialog, setShowDialog] = useState({ action: null })
    const [riskAssessmentSummaries, setRiskAssessmentSummaries] = useState([]);
    const { projectId, projectFormId } = useParams();
    const [isExpanded, setIsExpanded] = useState(true);
    const [isAppliedToRiskAssessment, setIsAppliedToRiskAssessment] = useState(false);
    const { PROCEDURE } = PROJECT_FORM_INSTANCE;
    const [matchedCustomProcedure, setMatchedCustomProcedure] = useState({});
    const [applySuggestion, setApplySuggestion] = useState(false);
    const setLoading = useLoading();
    const {
        emitCloseEvent,
        emitSaveEvent,
    } = useTailorProceduresConcurrency({
        procedureComponentId: sectionId
    })

    const {
        data: suggestedProcedures
    } = useFetchProcedureSuggestions(projectFormId, auditArea?.id, projectId)

    const {
        data: auditAreaData 
    } = useAuditAreasbyMethodologyVersionId(auditArea ? auditArea.methodologyVersionId : undefined, isVisible)

    useEffect(async () => {
        if (projectId && projectFormId) {
            setLoading(true);
            const response = await riskAssessmentService.getRiskAssessmentsByProjectFormId(projectId, projectFormId);
            const data = response?.data ?? [];
            if (data) {
                setRiskAssessmentSummaries(data);
            }
            setLoading(false);
        }
    }, [])

    useEffect(() => {
        if (!auditAreaData || auditAreaData.status !== 200) return;

        const isApplied = auditAreaData.data.find(aa => aa.AuditAreaId === auditArea.id)
        setIsAppliedToRiskAssessment(isApplied ? isApplied.IsAppliedToRiskAssessment : false)
    }, [auditAreaData])

    useEffect(() => {
        let disabled = false;
        for (const summaryProcedure of summaryProceduresUpdated) {
            const { IsRequired: isRequired, SummaryProcedureSteps: steps } = summaryProcedure;
            if (isRequired) {
                let ctr = 0;
                for (const step of steps) {
                    if (step.IsChecked || step.IsRequiredStep) {
                        break;
                    }
                    ctr++;
                }
                if (ctr === steps.length) {
                    disabled = true;
                }
            }
            if (disabled) {
                break;
            }
        }
        setSaveDisabled(disabled);
    }, [summaryProceduresUpdated])

    const closeDialog = () => {
        setMatchedCustomProcedure({});
        setShowDialog({ action: null });
    }

    const openDialog = () => {
        setShowDialog({ action: 'update' })
    }

    const warningDialog = (cp) => {
        setMatchedCustomProcedure(cp);
        setShowDialog({ action: 'delete' })
    }

    const updateConcurrentUsers = (dispatchObject) => {
        // Updates customProcedures for other users
        const updatedState = TailorProcedureReducer(tailorProcedure, dispatchObject)
        emitSaveEvent(updatedState)
    }

    const saveCustomProcedure = async (cp) => {
        let response;
        let dispatchObject;
        try {
            response = await projectFormServices.upsertCustomProcedure(projectId, cp);
            if (response?.status === 200) {
                dispatchObject = {
                    type: TAILOR_PROCEDURE_REDUCER.UPSERT_CUSTOM_PROCEDURE,
                    payload: {
                        customProcedure: response.data[0],
                        sectionId: sectionId
                    }
                }
                dispatchTailorProcedures(dispatchObject)
                updateConcurrentUsers(dispatchObject)
            }
        } catch (err) {
            toast.error(response.message);
        }
        closeDialog()
    }

    const deleteCustomProcedure = async () => {
        const id = matchedCustomProcedure.ProjectFormCustomProcedureId;
        let response;
        try {
            response = id && await projectFormServices.deleteCustomProcedureById(projectId, id);
            if (response.status && response.status === 200) {
                const dispatchObject = {
                    type: TAILOR_PROCEDURE_REDUCER.DELETE_CUSTOM_PROCEDURE,
                    payload: {
                        id,
                        sectionId
                    }
                }
                dispatchTailorProcedures(dispatchObject);
                updateConcurrentUsers(dispatchObject);
            }
            dispatchTailorProcedures({
                type: TAILOR_PROCEDURE_REDUCER.DELETE_CUSTOM_PROCEDURE,
                payload: {
                    id,
                    sectionId
                }
            })
        } catch (err) {
            toast.error(err.message);
        }
        closeDialog();
    }

    const handleSummaryProcedureChange = (sp) => {
        setSummaryProcedures(prevSummaryProcedure => {
            return prevSummaryProcedure.map((spData) => {
                if (spData.SummaryProcedureId === sp.SummaryProcedureId) {
                    return sp;
                }
                return spData;
            })
        });
    }

    const addParentStepId = (summaryProcedureSteps, parentStepIdsWithRequiredChild, parentStepId) => {
        const parentStep = summaryProcedureSteps.find(s => s.SummaryProcedureStepId === parentStepId);

        if (!parentStepIdsWithRequiredChild.includes(parentStepId)) {
            parentStepIdsWithRequiredChild.push(parentStepId)
        }

        if (parentStep && parentStep.ParentSummaryProcedureStepId)
            addParentStepId(summaryProcedureSteps, parentStepIdsWithRequiredChild, parentStep.ParentSummaryProcedureStepId)
    }

    const saveClick = async () => {
        setLoading(true);
        let summaryProcedureSteps = [];
        let parentStepIdsWithRequiredChild = [];

        for (const sp of summaryProceduresUpdated) {
            const { SummaryProcedureSteps } = sp;
            if (SummaryProcedureSteps) {
                for (const step of SummaryProcedureSteps) {
                    const isCheckedOrRequiredStep = step.IsChecked === 1 || step.IsRequiredStep;

                    if (step.ParentSummaryProcedureStepId && isCheckedOrRequiredStep)
                        addParentStepId(SummaryProcedureSteps, parentStepIdsWithRequiredChild, step.ParentSummaryProcedureStepId)

                    summaryProcedureSteps.push({
                        SummaryProcedureStepId: step.SummaryProcedureStepId,
                        IsChecked: isCheckedOrRequiredStep ? true : false
                    })
                }

                for (const parentStepId of parentStepIdsWithRequiredChild) {
                    const parentStep = summaryProcedureSteps.find(s => s.SummaryProcedureStepId === parentStepId)
                    if (parentStep) parentStep.IsChecked = true;
                }
            }
        }
        const body = { SummaryProcedureSteps: summaryProcedureSteps }
        const response = await projectFormServices.putProjectFormSummaryProcedures(projectId, projectFormId, auditArea.id, body)
        if (response.status === 204) {
            try {
                const response = await projectFormServices.getProjectFormProcedures(projectFormId, auditArea.id, projectId);
                const dispatchObject = {
                    type: TAILOR_PROCEDURE_REDUCER.SET_SUMMARY_PROCEDURE,
                    payload: {
                        summaryProcedures: response?.data.SummaryProcedures ?? [],
                        sectionId: sectionId
                    }
                };
                dispatchTailorProcedures(dispatchObject);
                updateConcurrentUsers(dispatchObject);
            } catch (e) {
                toast.error(e.toString());
            }
            setLoading(false);
            closeDrawer();
        }
        else toast.error(response.message);
    }

    const onApplySuggestion = () => {
        setApplySuggestion(true)
    }

    const onCancel = () => {
        emitCloseEvent();
        closeDrawer();
    }

    return (
        <TailoringFilterProcedureContextProvider>
            <Paper sx={{ p: '50px', boxShadow: 0 }} >
                <Box className={style.post}>
                    <Box className={style.wrapper}>
                        <Typography variant="title" sx={{ fontSize: '21px' }} data-test='procedure-title'>
                            {PROCEDURE.TAILOR_PROCEDURES}
                        </Typography>
                        <Box>
                            <Box sx={{ display: "flex", maxHeight: "37px" }}>
                                <CLATailorProcedureFilter />
                                <Button variant='outlined' data-test='cancel-button' className={style.button} onClick={onCancel}>{PROCEDURE.CANCEL}</Button>
                                <Button
                                    variant='contained'
                                    data-test='save-button'
                                    disabled={saveDisabled}
                                    className={style.button} onClick={saveClick}>
                                    {PROCEDURE.SAVE}
                                </Button>
                            </Box>
                            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                <Button sx={{
                                    minWidth: '200px',
                                    whiteSpace: 'nowrap',
                                    marginTop: '40px'
                                }}
                                    variant='contained'
                                    data-test='suggestion-button'
                                    className={style.button}
                                    onClick={onApplySuggestion}
                                    startIcon={<img src={white_bulb} />}
                                >
                                    {PROCEDURE.APPLY_ALL_SUGGESTIONS}
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box className={style.post}>
                    <Box sx={{ p: '15px 0' }}>
                        {riskAssessmentSummaries.map((riskAssessmentSummary, index) => {
                            return (<span key={`riskSummary_` + riskAssessmentSummary} ><Link target="_blank" to={{
                                pathname: `/project-management/${projectId}/project-form-instance/${riskAssessmentSummary.ProjectFormId}`
                            }}
                                style={{ color: '#76D2D3' }}
                            >
                                {riskAssessmentSummary.ProjectFormName}
                            </Link> {(riskAssessmentSummaries.length === index + 1 ? null : <span>, </span>)}</ span>)
                        })}
                    </Box>
                </Box>

                <Box className={!isExpanded ? style.post : ''} data-test='auditarea-container'>
                    <Box sx={{ p: '15px 0' }}>
                        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ width: '3%' }}>
                                <IconButton
                                    sx={{ p: 0 }}
                                    onClick={() => setIsExpanded((prevIsExpanded) => !prevIsExpanded)}>
                                    {isExpanded ? <ExpandIconComponent /> : <CollapseIconComponent />}
                                </IconButton>
                            </div>
                            <div style={{ width: '97%', paddingBottom: '16px' }}>
                                <Typography sx={{ fontSize: '16px', color: '#4B4B4B' }}>
                                    {auditArea?.auditAreaName ?? ""}
                                </Typography>
                            </div>
                        </Box>
                        <Collapse in={isExpanded} collapsedSize={0}>
                            <Box sx={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ width: '3%' }} />
                                <div style={{ width: '97%' }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', backgroundColor: colors.gray[30], p: '15px', marginBottom: '-12px' }}>
                                            <Box sx={{ width: '90%', fontWeight: 'bold' }} data-test='procedurename-header'>{PROCEDURE.PROCEDURE_NAME}</Box>
                                            <Box sx={{ width: '10%', fontWeight: 'bold' }} data-test='assertions-header'>{PROCEDURE.ASSERTIONS}</Box>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                            <TailoringProcedureProvider value={{ suggestedProcedures: suggestedProcedures, applySuggestion: applySuggestion, setApplySuggestion: setApplySuggestion, summaryProceduresUpdated: summaryProceduresUpdated, setSummaryProceduresUpdated: setSummaryProceduresUpdated, tailorProcedureData: tailorProcedureData ? tailorProcedureData.summaryProcedures : [], isAppliedToRiskAssessment: isAppliedToRiskAssessment }}>
                                                <SummaryProcedureCollapse
                                                    handleSummaryProcedureChange={handleSummaryProcedureChange}
                                                    summaryProcedures={summaryProcedures}
                                                    isExpanded={isExpanded}
                                                    setSummaryProcedures={setSummaryProcedures}
                                                    sectionId={sectionId}
                                                />
                                            </TailoringProcedureProvider>
                                        </Box>
                                    </Box>
                                </div>
                            </Box>
                        </Collapse>
                    </Box>
                </Box>
                <Box className={style.post} >
                    <CustomTailorProcedureTable
                        showDialog={showDialog}
                        openDialog={openDialog}
                        warningDialog={warningDialog}
                        customProcedures={tailorProcedure[`${sectionId}`]?.customProcedures}
                        assertions={tailorProcedure[`${sectionId}`]?.assertions}
                        setCustomProcedure={setMatchedCustomProcedure}
                        projectFormId={projectFormId} />
                </Box>
                <Box sx={{ p: '25px 0' }}>
                    <Button
                        sx={{ m: 0, p: 0 }}
                        startIcon={<AddIcon className={style.pageButtonAddFormIcon} />}
                        variant="page-button-add-form"
                        onClick={openDialog}
                    >
                        {PROCEDURE.ADD_CUSTOM_PROCEDURE}
                    </Button>
                </Box>
                <CLATailoringProcedureDialog
                    showDialog={showDialog.action === 'update'}
                    saveCustomProcedure={saveCustomProcedure}
                    closeDialog={closeDialog}
                    assertions={tailorProcedure[`${sectionId}`]?.assertions}
                    auditArea={auditArea}
                    projectFormId={projectFormId}
                    matchedCustomProcedure={matchedCustomProcedure} />

                <CLADialogConfirm
                    visible={showDialog.action === 'delete'}
                    title={'Warning'}
                    cancelText={'Cancel'}
                    confirmText={'Delete'}
                    message={'Are you sure you want to delete this?'}
                    onConfirm={deleteCustomProcedure}
                    onCancel={closeDialog} />

            </Paper >
        </TailoringFilterProcedureContextProvider>
    );
};

export default CLATailorProcedureTable;
