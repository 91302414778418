import clientApi from '@utilities/claApiClient';


const getLedgerTrialbalanceCategories = async (projectId, body) => {
    try {
        const response = await clientApi.post(`/generalledger/trialbalancecategories/${projectId}`, body);
        return response;
    } catch (error) {
        console.error(error)
    }
}

const generalLedgerService = {
    getLedgerTrialbalanceCategories
}

export default generalLedgerService;