import formApi from '@utilities/claApiForm';
export const getQuestionById = async (questionId, methodologyVersionId) => {
    try {
        const response = await formApi.get(`/questions/${questionId}/${methodologyVersionId}`);
        return response;
    }
    catch (error) {
        console.error();
    }
}
export const getQuestionsByLabel = async (searchText, methodologyVersionId) => {
    const params = { searchtext: searchText };
    try {
        const response = await formApi.get(`/questions/${methodologyVersionId}`, { params });
        return response.data;
    }
    catch (error) {
        console.error();
    }
}

export const getRequiredQuestionById = async (questionId, requiredOnly, methodologyVersionId) => {
    try {
        const response = await formApi.get(`/questions/${questionId}/${requiredOnly}/${methodologyVersionId}`);
        return response;
    }
    catch (error) {
        console.error();
    }
}

const questionsService = {
    getQuestionById,
    getQuestionsByLabel,
    getRequiredQuestionById
};
export default questionsService;