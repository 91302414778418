import formApi from "@utilities/claApiForm";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { INTERNAL_CONTROLS_FORM_TYPE_ID } from '@ais/constants';

export const FETCH_PROJECT_INTERNAL_CONTROL = 'fetchProjectInternalControls';

const fetchProjectInternalControls = async (projectFormId, projectId) => {
  try {
    const { data } = await formApi.get(`internal-controls/${projectFormId}/${projectId}`);
    return data;
  } catch (error) {
    console.error(error);
  }
};

const updateInternalControlAuditAreas = async (projectFormId, payload, projectId) => {
  try {
    const { data } = await formApi.post(`/internal-controls/${projectFormId}/audit-areas/${projectId}`, payload);
    return data;
  } catch (error) {
    console.error(error);
    // Do not remove throw;
    // needed for onError method in mutate function
    throw error;
  }
};

const updateFirstLevelInternalControlData = async (projectFormId, payload, projectId) => {
  try {
    const { data } = await formApi.post(`/internal-controls/${projectFormId}/${projectId}`, payload);
    return data;
  } catch (error) {
    console.error(error);
    // Do not remove throw;
    // needed for onError method in mutate function
    throw error;
  }
};

const updateProjectKeyControl = async (projectKeyControlId, body, projectId) => {
  try {
    const { data } = await formApi.put(`/internal-controls/key-control/${projectKeyControlId}/${projectId}`, body);
    return data;
  } catch (error) {
    console.error(error);
  }
};

const deleteProjectKeyControl = async (projectKeyControlId, projectId, projectFormId) => {
  try {
    const { data } = await formApi.delete(`/internal-controls/key-control/${projectKeyControlId}/${projectId}/${projectFormId}`);
    return data;
  } catch (error) {
    console.error(error);
  }
};

const updateIsServiceOrganization = async ({ ProjectScopeAuditAreaId, IsServiceOrganization, projectId, RequestProjectFormId }) => {
  const { data } = await formApi.put(`/projectscopesauditareas/${ProjectScopeAuditAreaId}/${projectId}`, {
    isServiceOrganization: IsServiceOrganization,
    RequestProjectFormId
  });
  return data;
}

const updateProjectScopeAuditAreaSCOTABDFields = async ({payload, projectFormId, projectId}) => {
  const { data } = await formApi.post(`/projectscopesauditareascotabds/${projectFormId}/${projectId}`, payload);
  return data;
} 
const internalControlService = {
  fetchProjectInternalControls
}

export default internalControlService;
// EXPORTS ====================================================================
export const useFetchProjectInternalControls = (projectFormId, formTypeId, projectId) => {
  return useQuery(
    [FETCH_PROJECT_INTERNAL_CONTROL, projectFormId],
    () => fetchProjectInternalControls(projectFormId, projectId),
    {
      enabled: !!projectFormId && formTypeId === INTERNAL_CONTROLS_FORM_TYPE_ID
    }
  );
};

export const useUpdateInternalControlAuditAreas = () => {
  return useMutation(
    ({ projectFormId, payload, projectId }) => updateInternalControlAuditAreas(projectFormId, payload, projectId)
  );
};

export const useUpdateFirstLevelInternalControlData = () => {
  const queryClient = useQueryClient();

  return useMutation(
    ({ projectFormId, payload, projectId }) => updateFirstLevelInternalControlData(projectFormId, payload, projectId),
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(FETCH_PROJECT_INTERNAL_CONTROL);
      }
    }
  );
};

export const useUpdateIsServiceOrganization = () => {
  return useMutation(
    payload => updateIsServiceOrganization(payload)
  );
};

export const useUpdateProjectScopeAuditAreaSCOTABDFields = () => {
  return useMutation(
    payload => updateProjectScopeAuditAreaSCOTABDFields(payload)
  );
};

export const useUpdateProjectKeyControl = () => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ ProjectKeyControlId, payload, projectId }) => updateProjectKeyControl(ProjectKeyControlId, payload, projectId),
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(FETCH_PROJECT_INTERNAL_CONTROL);
      } 
    }
  );
};

export const useDeleteProjectKeyControl = (projectId, projectFormId) => {
  const queryClient = useQueryClient();

  return useMutation(
    ({projectKeyControlId}) => deleteProjectKeyControl(projectKeyControlId, projectId, projectFormId),
    {
      onSuccess: () => {
        return queryClient.invalidateQueries(FETCH_PROJECT_INTERNAL_CONTROL);
      }
    }
  );
};