import CLASnackbarWithContent from '@components/CLASnackbarWithContent/CLASnackbarWithContent';
import { useOthers, useOtherUsersStore } from './store/users';
import { useEventListener, useOthersListener } from './RoomProvider';
import { useMsal } from '@azure/msal-react';

import { useRiskAssessment } from './hooks/useRiskAssessment';
export default function ConcurrentUsersBanner() {
    const { accounts } = useMsal()
    const userId = accounts[0].localAccountId.toLowerCase();
    const append = useOtherUsersStore((state) => state.append)
    const remove = useOtherUsersStore((state) => state.remove)
    const updateUserPresence = useOtherUsersStore((state) => state.updateUserPresence)

    const {
        updateNonCustomFieldAnswer,
        updateFinancialStatementLevelPersuasiveRisk,
        updateAssertionComment,
        updateAuditAreaRisk,
        updateOtherRelevantAssertion,
        setConcurrencyEventReceived,
    } = useRiskAssessment();

    useOthersListener(({ type, sessionId, data }) => {
        switch (type) {
            case "enter":
                append({ sessionId, ...data })
                return
            case "leave":
                remove(sessionId)
                return
            case "presence":
                updateUserPresence(sessionId, data)
                return
            default:
                console.warn(`Invalid event type: `, type)
        }
    })

    useEventListener(({ type, data }) => {
        switch (type) {
            case "updates":
                setConcurrencyEventReceived(true);
                if (data.answer.userId.toUpperCase() !== userId.toUpperCase()) {
                    if ('RiskAssessmentComment' in data.answer) {
                        updateAssertionComment(data.answer);
                    }
                    else if ('risksummary' in data.answer) {
                        const identifiedRisk = data.answer.reqBody.hasOwnProperty('ReferenceForWalkThrough')
                        if (identifiedRisk) {
                            updateAuditAreaRisk(data.answer);
                        } else {
                            updateFinancialStatementLevelPersuasiveRisk(data.answer.risksummary)
                        }
                    }
                    else if ('otherRelevantAssertion' in data.answer) {
                        updateOtherRelevantAssertion(data.id, data.answer)
                    } 
                    else if ('financialStatementProjectAuditAreaRisk' in data.answer) {
                        updateAuditAreaRisk(data.answer);
                    }
                    else {
                        updateNonCustomFieldAnswer(data.id, data.answer.answerValue);
                    }
                }
                return
            default:
                console.warn(`Invalid event type: `, type)
        }
    })

    const getDisplayUsers = (displayUsers, user) => {
        if (user.info.userId !== userId && !displayUsers.some(function (displayUser) { return displayUser.info.name === user.info.name; })) {
            displayUsers.push(user);
        }
        return displayUsers;
    }

    const users = useOthers()
    return (
        <CLASnackbarWithContent
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            snackBarPostion={'top-center'}
            open={users?.reduce(getDisplayUsers, []).length > 0}
            message={
                <>
                    {users?.reduce(getDisplayUsers, []).map((item, index) => (
                        <p key={index} style={{ textAlign: 'center', margin: 0 }}>
                            {`${item.info.name} is currently editing this form`}
                        </p>
                    ))}
                </>
            }
        />
    )
}

