import React from 'react';
import { Typography } from '@mui/material';

import string from "@utilities/stringHelper.js";

import { activateTooltip } from '../helpers';

export const CombinationTextCell = ({ row, value }) => {
    const component = (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '20px 20px 20px 10px'
        }}>
            <Typography sx={{
                fontWeight: 600,
                fontSize: '16px',
                m: '0px 20px 5px 0px'
            }}>
                {value}
            </Typography>
            <div style={{
                display: 'inline-block',
                margin: '0px 20px 20px 10px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
            }}>
                {row.Deficiencies.map((item, index) => {
                    const truncate = string.truncateWithBool(item.DeficiencyId, 30);
                    const component = (
                        <Typography key={index} sx={{
                            fontWeight: 600,
                            fontSize: '14px',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            mt: '5px'
                        }}>
                            {item.DeficiencyName}
                        </Typography>
                    );
                    return (truncate.isTruncated ? activateTooltip(component, item.DeficiencyId) : component)
                })}
            </ div>
        </div>
    );

    const result = string.truncateWithBool(value, 25);

    return (result.isTruncated ? activateTooltip(component, value) : component);
};
