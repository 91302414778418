import React, { useState, useEffect, useRef }  from 'react';
import isEqual from 'lodash/isEqual';
import { useParams } from "react-router-dom";
import {
    Box,   
    Button,
    Grid,
} from '@mui/material';
import { PROJECT_FORM_INSTANCE } from '@constants/index'; 
import {CLASelect} from '@ais/forms';
import {
    PROCEDURE_FIELD,    
} from '@constants/forms';
import { colors } from '@ais/theme';
import styles from './SummaryProcedureSection.module.css'
import ConcurrentLockTooltip from '@components/ConcurrentLockTooltip/ConcurrentLockTooltip'
import useProjectFormInstanceConcurrentLocking from '@hooks/useProjectFormInstanceConcurrentLocking'
import { useFinalizedProject } from '@hooks/useProject';
import { useCLAProcedureGlobalCommentBox } from './CLAProcedureContexProvider';
import { useGetClientSituationAnswersByProjectFormId } from '@services/clientSituationsAnswer';
import { useProceduresContext } from '@contexts/Procedures';
import CustomToast from '@components/CustomToast/CustomToast';

const CLAProcedureTable = ({ field, disabled, handleOnFocus, defaultValues, options, ...props }) => {
    const [isAllClosed, toggleCommentBoxes] = useCLAProcedureGlobalCommentBox();
    const { projectFormId } = useParams();
    const [selectValues, setSelectValues] = useState([]);
    const [isCLASelectOpen, setIsCLASelectOpen] = useState(false);
    const [projectFormProcedureData, setProjectFormProcedureData] = useState(null);
    const CLASelectRef = useRef(null);

    function handleCLASelectClick(toogle) {
        setIsCLASelectOpen(toogle)
    }

    const handleOnChange = (values) => {
        handleOnBlur(values)
    }

    function handleIdleForm(originalValue) {
        if(!originalValue?.values || originalValue.values.every(item => !item)) return;
        setSelectValues([...originalValue?.values]);        
        handleCLASelectClick(false)
        CLASelectRef.current?.classList.remove('Mui-focused')
    }

    const {
        isLocked,
        lockingUser,
        emitLockEvent, 
        emitUnlockEvent, 
        concurrentValue
    } = useProjectFormInstanceConcurrentLocking(
        field.id,
        {
            customFormObjectId: field.id,
            onIdle: handleIdleForm
        }
    );
    const { projectId } = useParams();
    const isProjectFinalized = useFinalizedProject(projectId);
    const { data: clientSituationsAnswer } = useGetClientSituationAnswersByProjectFormId(projectFormId, projectId)
    const { setProjectFormProcedureComponentData, handleProcedureBlur, projectFormProcedureComponentData, setCurrentReqBody, error, loading, success } = useProceduresContext();
    
    const cleanClientSituationAnswers = (stringClientSituationAnswer) => {
        try {
            const clientSituationAnswers = stringClientSituationAnswer.slice(1, -1).split(',');
            if (clientSituationAnswers.length === 0) return '[]';
            const firstAnswer = clientSituationAnswers[0].trim();
            if (!firstAnswer || firstAnswer === 'null' || firstAnswer === 'undefined') {
                clientSituationAnswers.shift();
            }
            return JSON.stringify(clientSituationAnswers.map(answer => +answer));
        } catch {
            return '[]';
        }
    };

    useEffect(() => {
        if(clientSituationsAnswer){
            setProjectFormProcedureComponentData(clientSituationsAnswer)
            setCurrentReqBody(clientSituationsAnswer)
        }
    }, [clientSituationsAnswer])

    useEffect(() => {
        const matchingClientSituationAnswer = projectFormProcedureComponentData.find((data) => data.ProcedureComponentId.toLowerCase() === field.id.toLowerCase())
        if(matchingClientSituationAnswer){
            try{
                const ans = JSON.parse(matchingClientSituationAnswer.ClientSituationAnswer)
                setProjectFormProcedureData(matchingClientSituationAnswer)
                setSelectValues(ans ?? [])
            }catch(err){
                const clientSituationAnswers = cleanClientSituationAnswers(matchingClientSituationAnswer.ClientSituationAnswer);
                const parsedClientSituationAnswers = JSON.parse(clientSituationAnswers);
                if (parsedClientSituationAnswers.length > 0) 
                    setProjectFormProcedureData({...matchingClientSituationAnswer, ClientSituationAnswer: clientSituationAnswers});
                setSelectValues(parsedClientSituationAnswers);
            }
        }else{
            setProjectFormProcedureData({
                ProjectFormProcedureComponentId: null,
                ProjectFormId: projectFormId,
                ProcedureComponentId: field.id,
                ClientSituationAnswer: "[]"
            })
        }
    }, [projectFormProcedureComponentData]);
            
    function validateStringArray(defaultValues) {
        return typeof(defaultValues[0]) === 'string' && defaultValues[0].length > 0;
    }

    useEffect(() => {
        if(!concurrentValue || concurrentValue === null || concurrentValue.values.every(item => !item)) return
        const { values } = concurrentValue;
        if(validateStringArray(values)) {
            const parsedValues = JSON.parse(values);
            if('values' in parsedValues) {
                setSelectValues(parsedValues.values);
            }
        } else {
            setSelectValues(values);
        }
    }, [concurrentValue])

    function handleOnFocusCustomProcedure() {
        handleOnFocus();
        const clientLockValue = defaultValues !== null ? defaultValues: ['']; 
        emitLockEvent({ values: clientLockValue });
    }    

    function handleOnBlur(values) {
        emitUnlockEvent({values});
        setSelectValues(values);
        
        const {ClientSituationAnswer} = projectFormProcedureData;
        const clientSituationAnswers = cleanClientSituationAnswers(ClientSituationAnswer);
        const cleanValues = cleanClientSituationAnswers(JSON.stringify(values));
        const isEqualValue = isEqual(clientSituationAnswers, cleanValues);

        if (!isEqualValue) handleProcedureBlur(projectFormProcedureData, JSON.parse(cleanValues))
    }

    return(
        <Box sx={{ 
            display: 'flex',
            flexDirection: 'column', 
            width: '100%',
            overflowX: 'auto' 
        }}>
            <Grid container justifyContent="space-between" alignItems="center">            
                <Grid item xs={7} style={{'padding-top': '5px'}}>
                    {options.length !== 0 && (
                        <ConcurrentLockTooltip lockingUser={lockingUser}>
                        <CLASelect
                            ref={CLASelectRef}
                            id={field.id}
                            name={field.id}
                            label={PROCEDURE_FIELD.LABEL}
                            defaultValues={selectValues}
                            values={selectValues}
                            onChange={handleOnChange}
                            onClose={() => handleCLASelectClick(false)}
                            onOpen={() => handleCLASelectClick(true)}
                            open={isCLASelectOpen}
                            isDisabled={isProjectFinalized ? true : lockingUser ? isLocked : false}
                            truncateDisplay
                            menuItems={options}
                            onFocus={handleOnFocusCustomProcedure}
                        />
                    </ConcurrentLockTooltip>
                    )}
                </Grid>
                <Grid item xs="auto">
                    <Button variant="link" sx={{ px: '8px' }} onClick={() => toggleCommentBoxes()}>
                        {isAllClosed
                            ? PROJECT_FORM_INSTANCE.PROCEDURE.EXPAND_ALL
                            : PROJECT_FORM_INSTANCE.PROCEDURE.COLLAPSE_ALL}
                    </Button>
                </Grid>
            </Grid>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                mt: '35px',
                border: 1,
                borderColor: colors.gray[30],
                minWidth:'913px'
            }}>
                <Box sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    backgroundColor: colors.gray[30],
                    p: '15px 20px 15px 20px',
                    fontSize: '16px',
                    fontWeight: 'bold'
                }}>
                    <Box sx={{ width: '20%' }} data-test='procedurename-header'>{PROCEDURE_FIELD.PROCEDURE_NAME}</Box>
                    <Box sx={{ width: '40%' }} data-test='identifiedRisks-header'>{PROCEDURE_FIELD.IDENTIFIED_RISKS}</Box>
                    <Box className={styles.table__header} data-test='assertions-header'>{PROCEDURE_FIELD.ASSERTIONS}</Box>
                    <Box className={styles.table__header} data-test='completed-header'>{PROCEDURE_FIELD.COMPLETED}</Box>
                    <Box className={styles.table__header} data-test='workpaper-header'>{PROCEDURE_FIELD.WORKPAPER_REFERENCE}</Box>
                    <Box className={styles.table__header} data-test='comments-header'>{PROCEDURE_FIELD.COMMENTS}</Box>
                </Box>
                {props.children}
            </Box >
            <CustomToast 
                error={error}
                loading={loading}
                success={success}
            />
        </Box>
    )
}

export default CLAProcedureTable;