import { useRef, useState, useEffect, forwardRef } from 'react';
import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  FormHelperText,
  InputAdornment
} from '@mui/material';

import PropTypes from 'prop-types';
import classes from './CLASelect.module.css';
import { CLATooltip } from "@ais/components"
import {ClientExternalIcon} from '@ais/forms';

import { FORM_SETTINGS } from '@ais/constants';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(_ => ({
  selectIcon: {
    position: "relative"
  }
}))

export const CLASelect = forwardRef((props, ref) => {
  const {
    id,
    name,
    placeholder,
    isDisabled,
    onChange,
    onClose,
    onOpen,
    open,
    defaultValues,
    menuItems,
    label,
    error,
    helperText,
    disabledValues,
    truncateDisplay,
    onFocus,
    onBlur,
    onMenuItemClick,
    scaleMenuItemSize,
    allowMultiLineLabel,
    showToolTip,
    enableAllOption,
    formHelperTextProps,
    uncontrolled,
    allowExternalAccess,
    answerable,
    answered,
    isIdle,
    listenOnDefaultValueChanges = true
  } = props;


  const dropdownRef = useRef(null);
  const classesStyles = useStyles();

  const { MultiSelect } = FORM_SETTINGS.EN;
  const [menuItemWidth, setMenuItemWidth] = useState(0);
  const [values, setValues] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);

  const [uncontrolledOpen, setUncontrolledOpen] = useState(false);

  const menuItemStyle = (item) => {
    const style = {
      whiteSpace: 'normal',
      wordWrap: 'break-word',
      color: item?.color ? item.color : {},
    };
    if (scaleMenuItemSize) style.maxWidth = `${menuItemWidth}px`;
    return style;
  };

  const fieldId = 'CLASelect' + id;

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    let newValues;
    if (value.indexOf(MultiSelect.all) > -1) {
      newValues =
        values.length === menuItems.length ? [] : menuItems.map((menuItem) => menuItem.value);
    } else {
      newValues = typeof value === 'string' ? value.split(',') : value;
    }
    setValues(newValues);
  };

  const handleBlur = (event) => {
    const {
      target: { value },
    } = event;
    const newValues = typeof value === 'string' ? value.split(',') : value;
    setValues(newValues);
    onBlur && onBlur(newValues);
  };

  const handleClose = (event) => {
    if (uncontrolled) setUncontrolledOpen(false);
    onChange && onChange(values);
    onClose && onClose(event);
  };

  const handleOpen = (event) => {
    onOpen && onOpen(event);
  };

  const handleMenuItemClick = (event, value) => {
    onMenuItemClick && onMenuItemClick(event, value);
  };

  const joinSelectedText = () => {
    const selectedTexts =
      menuItems &&
      menuItems
        .filter((item) => {
          if (item?.hasOwnProperty('value')) return values.includes(item.value);
          else return values.includes(item);
        })
        .map((item) => {
          if (item?.hasOwnProperty('label')) return item.label;
          else return item;
        });
    return selectedTexts;
  };

  const renderDisplayValues = () => {
    const selectedTexts = joinSelectedText();

    const CLASelectDisplay = () => {
      const styleKey = truncateDisplay ? 'truncate-values' : 'render-values';

      if (!selectedTexts.length) {
        return <div className={`${classes['placeholder']}`}>{placeholder}</div>;
      }

      return (
        <div className={`${selectedTexts.length > 0 ? classes[styleKey] : classes['placeholder']}`}>
          {selectedTexts.join(', ') || placeholder}
        </div>
      );
    };
    return <CLASelectDisplay />;
  };

  const menuItemHMTL =
    menuItems &&
    menuItems.map((item, index) => (
      <MenuItem
        disabled={disabledValues.indexOf(item) >= 0}
        className={classes['cla-item']}
        key={index}
        value={item?.hasOwnProperty('value') ? item.value : item}
        sx={menuItemStyle(item)}
        onClick={(e) => handleMenuItemClick(e, item?.hasOwnProperty('value') ? item.value : item)}
      >
        <Checkbox
          checked={
            values.findIndex((value) => {
              if (item?.hasOwnProperty('value')) return value === item.value;
              else return value === item;
            }) > -1
          }
        />
        <ListItemText primary={item?.hasOwnProperty('label') ? item.label : item} />
      </MenuItem>
    ));

  const newSelect = () => {
    const joinSelected = joinSelectedText();
    return joinSelected.join(', ');
  };

  const handleUncontrolledOpen = () => {
    setUncontrolledOpen(true);
    onFocus && onFocus();
    onOpen && onOpen();
  }

  const renderSelect = () => {
    const selectProps = {
      name: name,
      multiple: true,
      displayEmpty: true,
      value: values,
      label: label,
      error: error,
      onChange: handleChange,
      onClose: handleClose,
      onOpen: handleOpen,
      open: open,
      disabled: isDisabled,
      notched: true,
      renderValue: renderDisplayValues,
      sx: {
        '& .MuiSelect-select': {
          textOverflow: 'ellipsis',
          whiteSpace: 'pre-wrap',
        },
      },
      onFocus: onFocus,
      onBlur: handleBlur,
    };
    const uncontrolledSelectProps = {
      name: name,
      multiple: true,
      displayEmpty: true,
      value: values,
      onChange: handleChange,
      label: label,
      error: error,
      open: uncontrolledOpen,
      onOpen: handleUncontrolledOpen,
      onClose: handleClose,
      disabled: isDisabled,
      notched: true,
      renderValue: renderDisplayValues,
      sx: {
        '& .MuiSelect-select': {
          textOverflow: 'ellipsis',
          whiteSpace: 'pre-wrap',
        },
      },
    };
    return (
      <Select ref={ref} {...(uncontrolled ? uncontrolledSelectProps : selectProps)}
        classes={{
          icon: classesStyles.selectIcon
        }}
        sx={{ paddingRight: '4px' }}
        endAdornment={
          allowExternalAccess ?
            <InputAdornment position="end" sx={{ margin: 0 }}>
              <ClientExternalIcon
                allowExternalAccess={allowExternalAccess}
                answerable={answerable}
                answered={answered}
              />
            </InputAdornment> : <></>
        }
      >
        <MenuItem
          disabled
          value=""
          sx={{
            whiteSpace: 'unset',
            wordBreak: 'break-all',
          }}
        >
          {placeholder}
        </MenuItem>
        {enableAllOption && (
          <MenuItem className={classes['cla-item']} value={MultiSelect.all}>
            <Checkbox checked={isAllSelected} onClick={(e) => e.preventDefault()} />
            <ListItemText primary={MultiSelect.all} />
          </MenuItem>
        )}
        {menuItemHMTL}
      </Select>
    );
  };

  const transformValuesToArray = (values) => {
    return !Array.isArray(values) ? [values] : values;  
  }
  useEffect(() => {
    setMenuItemWidth(dropdownRef.current?.offsetWidth);
  }, [menuItems, onChange, onOpen]);

  useEffect(() => {
    // This is needed for instances where listenOnDefaultValueChanges = false
    // eg. We only want to set the default values once(on mount)
    // and don't want to listen to any changes.
    if(!listenOnDefaultValueChanges) {
      const values = transformValuesToArray(defaultValues);  
      setValues(values)
    }
  }, [])

  useEffect(() => {
    // The prop listenOnDefaultValueChanges allows us to control when to update the values
    // of CLASelect. Normal this is needed to be true in smart forms and custom forms. This is turned off
    // in CLAPriorPeriodSelect due to the fact the we don't need to track default values changes
    if(listenOnDefaultValueChanges) {
      const values = transformValuesToArray(defaultValues);  
      setValues(values)
    }
  }, [ defaultValues ]);

  useEffect(() => {
    if (isIdle && uncontrolled) {
      setUncontrolledOpen(false);
      setValues(defaultValues);

      const divElements = dropdownRef.current.getElementsByTagName('div');

      if (divElements.length > 0) {
        const selectElement = divElements[1].classList;
        selectElement.remove('Mui-focused');
      }
    }
  }, [isIdle]);

  useEffect(() => {
    setIsAllSelected(menuItems.length > 0 && values.length === menuItems.length);
  }, [menuItems, values]);

  return (
    <FormControl ref={dropdownRef} className={classes['cla-select']} error={error} fullWidth>
      <InputLabel
        id={fieldId}
        shrink
        {...(allowMultiLineLabel ? { variant: 'multiline-label' } : {})}
      >
        {label}
      </InputLabel>
      {showToolTip && (
        <CLATooltip title={newSelect()} placement="bottom">
          {renderSelect()}
        </CLATooltip>
      )}
      {!showToolTip && renderSelect()}
      {error && <FormHelperText {...formHelperTextProps}>{helperText}</FormHelperText>}
    </FormControl>
  );
});

CLASelect.propTypes = {
  name: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  error: PropTypes.bool,
  helperText: PropTypes.string,
  onChange: PropTypes.func,
  onClose: PropTypes.func,
  defaultValues: PropTypes.array,
  disabledValues: PropTypes.array,
  isDisabled: PropTypes.bool,
  placeholder: PropTypes.string,
  truncateDisplay: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  scaleMenuItemSize: PropTypes.bool,
  allowMultiLineLabel: PropTypes.bool,
  enableAllOption: PropTypes.bool,
  formHelperTextProps: PropTypes.object,
  uncontrolled: PropTypes.bool,
  allowExternalAccess: PropTypes.bool,
  answerable: PropTypes.bool
};

CLASelect.defaultProps = {
  menuItems: [],
  defaultValues: [],
  disabledValues: [],
  isDisabled: false,
  placeholder: 'Select',
  truncateDisplay: false,
  error: false,
  helperText: '',
  scaleMenuItemSize: true,
  allowMultiLineLabel: false,
  showToolTip: false,
  enableAllOption: false,
  uncontrolled: false,
  allowExternalAccess: false,
  answerable: false
};

export default CLASelect;
