import React, { useCallback, useMemo } from 'react';
import {
    Box,
} from '@mui/material';

import SummaryProcedure from './SummaryProcedure';
import SummaryProcedureStep from './SummaryProcedureStep'

const SummaryProcedureSection = (props) => {

    const {
        questionId,
        summaryProcedure,
        projectRisks,
        filtered,
        userId,
        getProjectFormProcedures,
        sectionId,
        setSuccessToast,
        setErrorToast,
        setLoadingToast
    } = props;
  
    const filteredSummaryProcedure = useMemo(() =>
    (
        summaryProcedure.filter(sp => {
            const { SummaryProcedureSteps = [] } = sp;
            let isChecked = false;
            if (SummaryProcedureSteps.length === 0) return false;
            if (sp.IsRequired) return true;
            for (const summaryStep of SummaryProcedureSteps) {
                if (summaryStep.IsChecked === 1 || summaryStep.IsRequiredStep) {
                    isChecked = true;
                    break;
                }
            }
            if (isChecked) return true;
            return false;
        })
    ), [summaryProcedure]);

    const checkIfChildIsRequiredOrChecked = useCallback((summaryProcedureSteps, ps, isParent = false) => {
        const { SummaryProcedureStepId, SummaryProcedureId } = ps;
        let isRequired = false;
        let childProcedures = [];
        if (SummaryProcedureId && isParent) {
            childProcedures = summaryProcedureSteps.filter((data) => data.SummaryProcedureId === SummaryProcedureId);
        } else {
            childProcedures = filtered ? summaryProcedureSteps.filter(step => step.FilteredParentId === SummaryProcedureStepId) : summaryProcedureSteps.filter((data) => data.ParentSummaryProcedureStepId === SummaryProcedureStepId);
        }
        for (let summaryProcedureStep of childProcedures) {
            if (summaryProcedureStep.IsRequiredStep || summaryProcedureStep.IsChecked === 1) {
                return true;
            }
            isRequired = checkIfChildIsRequiredOrChecked(summaryProcedureSteps, summaryProcedureStep);
            if (isRequired) return true;
        }
        return isRequired;
    }, [summaryProcedure])

    const renderSummaryProcedureSteps = useCallback((parentSteps = [], summaryProcedureSteps, _index, padding, isParent, isChildren, sp) => {
        let stepCtr = 0;
        return parentSteps.map((summaryProcedureStep) => {
            const { SummaryProcedureStepId: id, IsChecked, ProjectFormProcedureStepId } = summaryProcedureStep;
            const currentIndex = `${_index}.${stepCtr + 1}`;
            const isRequiredOrChecked = checkIfChildIsRequiredOrChecked(summaryProcedureSteps, summaryProcedureStep);
            const isRequired = summaryProcedureStep.IsRequiredStep;
            const isLast = isChildren > 6
            const childrenSteps = filtered ? summaryProcedureSteps.filter(step => step.FilteredParentId === id) : summaryProcedureSteps.filter(step => step.ParentSummaryProcedureStepId === id);
            const childProcedureSteps = renderSummaryProcedureSteps(childrenSteps, summaryProcedureSteps, currentIndex, padding + 30, false, isChildren + 1)
            if ((ProjectFormProcedureStepId && (isRequiredOrChecked || isRequired || IsChecked === 1) && !isLast)) stepCtr++;
            return (
                ((ProjectFormProcedureStepId && (isRequiredOrChecked || isRequired || IsChecked === 1)) && !isLast) ?
                    <SummaryProcedureStep
                        key={id}
                        questionId={questionId}
                        currentIndex={currentIndex}
                        padding={padding}
                        summaryProcedure={sp}
                        summaryProcedureStep={summaryProcedureStep}
                        isParent={isParent}
                        childProcedureSteps={childProcedureSteps}
                        checkIfChildIsRequiredOrChecked={checkIfChildIsRequiredOrChecked}
                        userId={userId}
                        sectionId={sectionId}
                        setSuccessToast={setSuccessToast}
                        setErrorToast={setErrorToast}
                        setLoadingToast={setLoadingToast}
                    /> : null)
        })
    }, [summaryProcedure]);

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <>
                {
                    filteredSummaryProcedure.map((sp, index) => {
                        const { SummaryProcedureSteps: summaryProcedureSteps, IsRequired: isRequired } = sp;
                        const summaryProcedureStepsOrdered = summaryProcedureSteps.sort((a, b) => a.DisplayOrder - b.DisplayOrder);
                        const parentSteps = filtered ? summaryProcedureStepsOrdered.filter(data => !data?.FilteredParentId) : summaryProcedureStepsOrdered.filter(data => !data?.ParentSummaryProcedureStepId)
                        const procedureSummarySteps = renderSummaryProcedureSteps(parentSteps, summaryProcedureStepsOrdered, index + 1, 30, true, 1, sp);
                        const isRequiredOrChecked = checkIfChildIsRequiredOrChecked(summaryProcedureSteps, sp, true);

                        return (
                            (isRequiredOrChecked || isRequired) && procedureSummarySteps.filter(data => data).length !== 0) && (
                                    <SummaryProcedure
                                        questionId={questionId}
                                        getProjectFormProcedures={getProjectFormProcedures}
                                        key={sp.SummaryProcedureId}
                                        summaryProcedure={sp}
                                        projectRisks={projectRisks}
                                        index={index + 1}
                                        setSuccessToast={setSuccessToast}
                                        setErrorToast={setErrorToast}
                                        setLoadingToast={setLoadingToast}
                                    >
                                        {procedureSummarySteps}
                                    </SummaryProcedure>
                            )
                    }
                    )
                }
            </>
        </Box>
    );
};

export default SummaryProcedureSection;
