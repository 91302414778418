import React, { useState, useEffect } from "react";
import { useDeepCompareEffect } from 'react-use';
import { useParams } from "react-router-dom";

import * as Constants from "@constants/index"
import styles from '@components/CLATrialBalanceSelect/CLATrialBalanceSelect.module.css';

import {CLADropdown, CLASelect} from '@ais/forms';
import { useGetProjectTrialBalanceData } from '@services/trialbalance';
import { useGraphContext } from  '@contexts/PlanningAnalytics/GraphContext';
import clsx from 'clsx';
import ExportGLDetailLink from "@components/CLATrialBalanceSelect/ExportGLDetailLink/ExportGLDetailLink";
import { sortReviewerSignoffAsc, getFormattedSignOffDate } from '@utilities/dateHelpers.js';
import { useGetAnswersByProjectFormId } from '@services/forms/projectforms.js';
import { useFinalizedProject } from '@hooks/useProject';
import { isSignoffLogicEnabled } from '@utilities/signoffUtility.js';


const CLATrialBalanceSelect = ({
  handCaptureInput = undefined,
  isDisabled = false,
  field,
  defaultValues,
  formTypeId,
  isSingleSelect = false,
  project,
  isPlanningAnalyticsForm = false,
  onFocus,
  signOffList,
  highlightable = false
}) => {
  const { ID, PLACEHOLDER, PLACEHOLDER_WITH_OPTIONAL, TRIAL_BALANCE } = Constants.TRIAL_BALANCE.EN
  const { required } = field;

  const [menuItems, setMenuItems] = useState([]);
  const [selectedTrialBalances, setSelectedTrialBalances] = useState([]);
  const [projectDetails, setProjectDetails] = useState(null);
  const [tbDefaultValue, setTBDefaultValue] = useState([]);
  const { projectId, projectFormId} = useParams()
  const { setTrialBalances } = useGraphContext()
  const [shouldHighlight, setShouldHighlight] = useState(false)
  const { data: answers } = useGetAnswersByProjectFormId(projectFormId, projectId)
  const isProjectFinalized = useFinalizedProject(projectId);

  const {
    data: trialBalances,
    isLoading: isTrialBalanceLoading,
    isError: isTrialBalanceError,
  } = useGetProjectTrialBalanceData(
    projectDetails?.AppDataInstanceId,
    projectDetails?.FiscalYear,
    projectId
  );

  useDeepCompareEffect(() => {
    if(!project || Object.keys(project).length < 1) return;
    setProjectDetails(project);
  }, [project])

  useEffect(() => {
    if (isTrialBalanceLoading || isTrialBalanceError) return;

    const processedTrialBalanceData = trialBalances
      ?.filter(tb => !!tb.correlationDetailId && !!tb.correlationName && !!tb.correlationNameId)
      .map(({ id, correlationName, correlationDetailId, correlationNameId }) => ({
        value: id,
        label: correlationName,
        correlationDetailId,
        correlationNameId
      }));

    setMenuItems(processedTrialBalanceData)
  }, [isTrialBalanceLoading, isTrialBalanceError])

  useEffect(() => {  
    const latestReviewersAsc = sortReviewerSignoffAsc(signOffList)
    let shouldHighlight = false
    let matchingAnswer = {}
    if(answers?.length){
        matchingAnswer = answers.find((answer) => answer.questionId?.toLowerCase() === field.id.toLowerCase())
    }

    if(matchingAnswer?.lastUpdate){
      const dateModified = new Date(matchingAnswer.lastUpdate).getTime();
      const isSignoffEnabled = isSignoffLogicEnabled(isProjectFinalized, dateModified);
      
      shouldHighlight = new Date(getFormattedSignOffDate(latestReviewersAsc[latestReviewersAsc?.length - 1]?.signOffDate)).getTime() < dateModified
      setShouldHighlight(isSignoffEnabled && shouldHighlight)
    }
}, [answers, signOffList, field]) 


  useDeepCompareEffect(() => {
    if (defaultValues && defaultValues?.length) {
      const selectedValues = menuItems.filter((x) =>
        defaultValues.map((o) => o?.correlationNameId).includes(x?.correlationNameId)
      );

      setTBDefaultValue(selectedValues?.map(x => x?.value))
    }
  }, [defaultValues, menuItems])

  const handleTrialBalanceDropdownSave = (e) => {
    if (isSingleSelect) {
      const value = e.target.value;
      const found = menuItems.find((item) => item.value === value);
      const userInput = [found];
      setSelectedTrialBalances([found.correlationDetailId]);
      handCaptureInput && handCaptureInput(userInput);
      return;
    }

    const userInput = [];
    const selectedItems = menuItems.filter((item) => e.includes(item.value));
    userInput.push(...selectedItems);
    setSelectedTrialBalances(userInput.map(({ correlationDetailId }) => correlationDetailId));
    handCaptureInput && handCaptureInput(userInput);

    if (isPlanningAnalyticsForm && JSON.stringify(defaultValues) !== JSON.stringify(userInput)) {
      setTrialBalances(userInput);
    }
  };

  const correlcationDetailIds = () => {
    const selectedValues = menuItems.filter((x) =>
      defaultValues.map((o) => o?.correlationNameId).includes(x?.correlationNameId)
    );

    return !!selectedTrialBalances.length ? selectedTrialBalances : selectedValues?.map(({correlationDetailId}) => correlationDetailId)
  }

  return (
    <div className={styles.trialBalanceContainer}>
      <div className={clsx(styles.trialBalanceDropdownSelectContainer, shouldHighlight && highlightable && styles.trialBalanceDropdownSelectContainerHighlighted)}>
        {isSingleSelect ? (
          <CLADropdown
            id={ID}
            name={TRIAL_BALANCE}
            label={TRIAL_BALANCE}
            value={tbDefaultValue[0]}
            placeholder={required ? PLACEHOLDER : `${PLACEHOLDER_WITH_OPTIONAL}`}
            onChange={handleTrialBalanceDropdownSave}
            options={menuItems}
            isDisabled={isDisabled}
            onFocus={onFocus}
          />
        ) : (
            <CLASelect
              id={ID}
              name={TRIAL_BALANCE}
              label={TRIAL_BALANCE}
              defaultValues={tbDefaultValue}
              placeholder={required ? PLACEHOLDER : `${PLACEHOLDER_WITH_OPTIONAL}`}
              menuItems={menuItems}
              isDisabled={isDisabled}
              onChange={handleTrialBalanceDropdownSave}
              truncateDisplay
              onFocus={onFocus}
            />
        )}
      </div>
      {/* Comment for now to hide this feature to the user */}
      {/* <div className={styles.trialBalanceExportToCSVContainer}>
        <ExportGLDetailLink
          correlationDetailIds={correlcationDetailIds()}
          visible={formTypeId === 3}
        />
      </div> */}
    </div>
  );  
}

export default CLATrialBalanceSelect