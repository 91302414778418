import formApi from '@utilities/claApiForm';
export const getFormCategories = async () => {
    try {
        const response = await formApi.get(`/formcategories`);
        return response;
    }
    catch (error) {
        console.error();
    }
}
export const getFormsByCategoryId = async (categoryId) => {
    try {
        const response = await formApi.get(`/formcategories/${categoryId}`);
        return response;
    }
    catch (error) {
        console.error();
    }
}
export const getRequiredFormsByCategoryId = async (categoryId, requiredFormsOnly, methodologyVersionID) => {
    try {
        const response = await formApi.get(`/formcategories/${categoryId}/${requiredFormsOnly}${ methodologyVersionID ? "?methodologyVersionId=" + methodologyVersionID : ''}`);
        return response;
    }
    catch (error) {
        console.error();
    }
}
const formCategoriesService = {
    getFormCategories,
    getFormsByCategoryId,
    getRequiredFormsByCategoryId
};
export default formCategoriesService;