import React, { useEffect, useState } from "react";
import { useLoading } from "@hooks/index";
import { useParams } from "react-router-dom";

import { usePostGeneralLedgerCurrentYear } from "@services/customForm/generalLedger.js";
import { CLANestedDataGrid } from '@ais/components';

import * as CustomFormConstants from "@constants/customForm/index";
import style from "./GLCAccountsTable.module.css";

import { format } from "date-fns";
import { formatMoneyToNegativeDashed } from "@utilities/numberHelper";
import { CLATooltip } from "@ais/components"
import string from "@utilities/stringHelper.js";

const GLCAccountsTable = ({ GeneralLedgerId, accountNumber }) => {
  const [ isLoading, setIsLoading ] = useState(true)
  const [generalLedgerTransactionsRowData, setGeneralLedgerTransactionsRowData] = useState([]);
  const { projectId } = useParams()

  const { data: dataTransactionsRowData, isLoading: isLoadingTransactionsRowData, isRefetching } = usePostGeneralLedgerCurrentYear(
    GeneralLedgerId,
    accountNumber,
    projectId
  );

  const columnWithToolTip = CustomFormConstants.GENERAL_LEDGER_COMPLETENESS_TRANSACTION_COLUMN.map((value) =>
    value.field === CustomFormConstants.GENERAL_LEDGER_COMPLETENESS_TRANSACTION_DESCRIPTION
      ? {
          ...value,
          renderCell: (params) => (
            <CLATooltip title={params.value} placement="bottom">
              <span>{string.truncate(params.value, 70)}</span>
            </CLATooltip>
          ),
        }
      : value
  );

  const updateRowDataFormat = (dataArray) => {
    if (!dataArray || (dataArray && dataArray.length < 1)) return [];
    return dataArray.map((value, index) => ({
      id: index,
      TransactionReference: value.transactionId ? value.transactionId : "",
      TransactionDate: !!value.effectiveDate ? format(new Date(value.effectiveDate), "MM/dd/yyyy") : "",
      TransactionDebitAmount: value.amount ? (value.amountCreditDebitIndicator) ? 
      ( value.amountCreditDebitIndicator === CustomFormConstants.TRANSACTION_DEBIT 
        ? formatMoneyToNegativeDashed(value.amount)
        : '---') : "" : "",
      TransactionCreditAmount: value.amount ? (value.amountCreditDebitIndicator) ? 
      (value.amountCreditDebitIndicator === CustomFormConstants.TRANSACTION_CREDIT
      ? formatMoneyToNegativeDashed(value.amount)
      : '---' ) : "" : "",
      TransactionDescription: (value.jeLineDescription) ? value.jeLineDescription : "",
    }));
  };

  useEffect(() => {
    setIsLoading(true);
    if (isLoadingTransactionsRowData || isRefetching) return
    setGeneralLedgerTransactionsRowData(updateRowDataFormat(dataTransactionsRowData?.groups || []));
    setIsLoading(false);

    return () => {
      setGeneralLedgerTransactionsRowData([])
    }
  }, [ isLoadingTransactionsRowData, accountNumber, isRefetching ]);

  return (
    <div className={style["GLC-transaction-container"]}>
      <CLANestedDataGrid
        sx={{
          "&.MuiDataGrid-root .MuiDataGrid-columnHeader": {
            fontWeight: "700",
            fontSize: "14px",
            whiteSpace: "pre-wrap",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            whiteSpace: "pre-wrap"
          },
          
        }}
        columns={columnWithToolTip}
        rows={generalLedgerTransactionsRowData}
        labelRowsPerPage={""}
        rowsPerPageOptions={[10, 20, 50]}
        rowHeight={35}
        loading={isLoading}
      />
      <hr className={style["line-style"]} />
    </div>
  );
};

export default GLCAccountsTable;
