import { create } from "zustand"
import { useShallow } from "zustand/react/shallow"
import { shallow } from "zustand/shallow"

export const useOtherUsersStore = create(
    (set, get) => ({
        users: [],
        initialize: (users) => {
            if(!users) {
                return
            }
            const append = get().append
            for(let x = 0; x < users.length; x++) {
                const user = users[x]
                if(!user) {
                    continue
                }
                append(user)
            }
        },
        append: (user) => {
            const existingUsers = get().users
            const foundUser = existingUsers.find((existingUser) => existingUser.sessionId === user.sessionId)
            if(foundUser) {
                return
            }
            set({ users: [ ...existingUsers, user ]})
        },
        remove: (sessionId) => {
            const existingUsers = get().users
            const foundUserIndex = existingUsers.findIndex((user) => user.sessionId === sessionId)
            if(foundUserIndex > -1) {
                const users = [
                    ...existingUsers.slice(0, foundUserIndex),
                    ...existingUsers.slice(foundUserIndex + 1)
                ]
                set({ users })
            }
        },
        updateUserPresence: (sessionId, presence) => {
            const existingUsers = get().users
            const user = existingUsers.find((user) => user.sessionId === sessionId)
            if(!user) {
                return
            }
            user.presence = presence
            const index = existingUsers.findIndex((user) => user.sessionId === sessionId)
            if(index < 0) {
                return
            }
            const users = [
                ...existingUsers.slice(0, index),
                ...existingUsers.slice(index + 1),
                user
            ]
            set({ users })
        },
        clear: () => {
            set({ users: [] })
        }
    })
)

export const createStoreWithSelectors = (store) => {
    return function useStore(keys) {
        return store((state) => {
            const x = keys.reduce((acc, cur) => {
                acc[cur] = state[cur];
                return acc;
            }, {});
            return x
        }, shallow);
    };
};

export const useOthersStoreWithSelectors = createStoreWithSelectors(useOtherUsersStore);

export const useOthers = () => useOtherUsersStore((state) => state.users)

export const useOthersMapped = (selector) => {
    return useOtherUsersStore(useShallow(selector))
}
