import { createContext, useReducer, useContext } from 'react';
import claDatagridReducer, { initialState } from './claDatagridReducer';
// constants
import * as Constants from "@constants/index";

const CLADatagridContext = createContext();

const {
    TYPE,
    ON_CLICK_AWAY_LIST_VIEW,
    ON_DUPLICATE_ACTION,
    UPDATE_UNIT_SELECTED,
    SAVE_FORM_IN_LIST,
    ADD_FORM_TO_LIST,
} = Constants.CONTEXT_API_METHOD.DATAGRID_CONTEXT;

export const CLADatagridProvider = ({ children }) => {
    const [state, dispatch] = useReducer(claDatagridReducer, initialState);

    const onClickAwayListView = (newApiRef) => {
        dispatch({
            type: ON_CLICK_AWAY_LIST_VIEW,
            payload: {
                apiRefInContext: newApiRef,
            }
        })
    }

    const onDuplicateAction = (projectFormID) => {
        dispatch({
            type: ON_DUPLICATE_ACTION,
            payload: {
                duplicatedProjectFormID: projectFormID,
            }
        })
    }

    const updateUnitSelected = (type, id) => {
        let finalResult;

        if (type === TYPE.ADD) {
            const updatedUnitList = state.unitsSelected.concat(id);
            finalResult = [...new Set(updatedUnitList)]; // eliminate duplicate value
        } else if (type === TYPE.REMOVE) {
            finalResult = state.unitsSelected.filter(unitid => unitid !== id);
        }

        dispatch({
            type: UPDATE_UNIT_SELECTED,
            payload: {
                unitsSelected: finalResult,
            }
        })
    }

    const saveFormInList = (isSave) => {
        dispatch({
            type: SAVE_FORM_IN_LIST,
            payload: {
                isSaveClickInList: isSave,
            }
        })
    }

    const addFormToList = (isAdded, units) => {
        dispatch({
            type: ADD_FORM_TO_LIST,
            payload: {
                addToList: isAdded,
                units: units,
            }
        })
    }

    const onEditMode = (isEditMode, row) => {
        dispatch({
            type: 'ON_EDIT_MODE',
            payload: {
                isEditMode: isEditMode,
                rowData: row,
            }
        })
    }

    const value = {
        apiRefInContext: state.apiRefInContext,
        duplicatedProjectFormID: state.duplicatedProjectFormID,
        isSaveClickInList: state.isSaveClickInList,
        addToList: state.addToList,
        units: state.units,
        unitsSelected: state.unitsSelected,
        onClickAwayListView,
        onDuplicateAction,
        updateUnitSelected,
        saveFormInList,
        addFormToList,
        // below values for demo only
        isEditMode: state.isEditMode,
        rowData: state.rowData,
        onEditMode,
    }

    return <CLADatagridContext.Provider value={value}>{children}</CLADatagridContext.Provider>
}

const useCLADatagrid = () => {
    const context = useContext(CLADatagridContext);

    if (context === undefined) {
        console.log(`useCLADatagrid must be used within CLADatagridContext.`);
    }

    return context;
}

export default useCLADatagrid;