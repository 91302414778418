import React, { memo } from "react";
import {
    Box,
    FormControl,
    InputLabel,
    Typography,
} from "@mui/material";
import { sanitize } from "dompurify";
import { format } from "date-fns";
import { Controller, useFormContext } from "react-hook-form"

import styles from "../FormView.module.css";
import { useVFCanvasProvider, useProjectFormInstanceProvider } from '@providers';
import { Flex } from "@ais/palette";
import { FIELD_TYPES } from "@ais/constants";
import {
    CLACheckBoxGroup,
    CLAComboBox,
    CLACurrencyField,
    CLADatePickerField,
    CLADropdown,
    CLAFormTable,
    CLANumberField,
    CLAPercentageField,
    CLARadioButtonList,
    CLASelect,
    ClientExternalIcon,
    copyObject,
    Formula,
    InstanceLongAnswer,
    parseQuestionLabel,
    renderLabel,
    VFQuestionGroup
} from '@ais/forms';
import CLAProcedureField from '@components/Forms/CLAProcedureField/CLAProcedureField';
import { TbLinkBaseField } from '@components/FormView/components/TbLinkFields';

import { NoValidationInput } from "./TbLinkFields";

import CLAInitialScopingConsiderations from "../../CLAInitialScopingConsiderations/CLAInitialScopingConsiderations";
import PerformanceStandardsDropdown from "../../CLAInitialScopingConsiderations/PerformanceStandardDropdown";
import FrameworksDropdown from "../../CLAInitialScopingConsiderations/FrameworksDropdown";
import VFRenderedFieldWrapper from "../VFRenderedFieldWrapper";
import CLATrialBalanceSelect from "../../CLATrialBalanceSelect/CLATrialBalanceSelect"
import CLAProjectScopeAuditArea from "../../CLAProjectScopeAuditArea/CLAProjectScopeAuditArea"
import { RiskCharacteristics } from "../../RiskCharacteristics/RiskCharacteristics";
import { useOthers } from "../../store/users";
import { useUpdateMyPresence } from "../../RoomProvider";

export const VFFields = memo((props) => {
    const {
        field,
        projectId,
        projectFormId,
        disabled: _disabled,
        isInstance,
        section,
        answerList,
        renderLink,
        getDefaultValue, 
        handleOnFocus,
        methodologyIndustries,
        methodologyVersionId,
        watchRadioList,
        procedureFilter,
        handleBlur,
        formTypeId,
        units,
        isIdle,
        project,
        answered, 
        signOffList
    } = props;
    const updateMyPresence = useUpdateMyPresence()
    const formCanvas = useFormContext();
    const {
        isInitialScopingConsiderationExpanded, 
        isDeterminingSCOTABDSExpanded,
        isCommonIdentifiedRisksExpanded,
        actions: { updateIsFormFocused } } 
        = useProjectFormInstanceProvider();
    const { actions, state } = useVFCanvasProvider();
    const others = useOthers();
    const isLockedByUser = others.find((user) => user.presence.focusedId === field.id)
    const isTrialBalanceOrAuditScopingFieldLock = (ids) => { return !!others.find((user) => ids.includes(user.presence.focusedId)) }
    const disabled = _disabled || field.linkType === 'Read Only' || isLockedByUser !== undefined;
    const handleNoValidationBlur = (answer) => {
        let parsedAnswer;
        try {
            parsedAnswer = JSON.parse(answer);
        } catch {
            parsedAnswer = answer;
        }

        if (typeof parsedAnswer !== "string" || !isInstance) return;

        const formValue = formCanvas.getValues(field.id);

        if (answer !== formValue && isInstance)
            formCanvas.setValue(field.id, answer);
    }

    switch (field.type) {
        case FIELD_TYPES.SHORT_ANSWER:
        case FIELD_TYPES.LONG_ANSWER: {
            let defaultValue = isIdle ? formCanvas.getValues(field.id) : getDefaultValue();

            //only concurrency value from portal to portal has percentage symbol.
            if (field.validation === 'percentage' && isNaN(defaultValue) && defaultValue?.includes('%')) {
                defaultValue = Number(defaultValue.replace('%', '')) / 100;
            }
            return (
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper}
                    isLockedByUser={isLockedByUser}
                >
                    <FormControl fullWidth>
                        {
                            !!field?.label && <InputLabel shrink variant="multiline-label">
                                {renderLabel(field.label, field.tooltip)}
                            </InputLabel>
                        }
                        {field.validation === 'none' && (
                            <Controller
                                control={formCanvas.control}
                                name={field.id}
                                render={({ field: { onChange } }) => {
                                    return (
                                        <NoValidationInput
                                            field={field}
                                            defaultValue={defaultValue}
                                            isFormInstance={isInstance}
                                            disabled={disabled}
                                            onFocus={() => {
                                                handleOnFocus()
                                                updateMyPresence({ focusedId: field.id })
                                            }}
                                            onChange={onChange}
                                            answered={answered}
                                            units={units}
                                            isIdle={isIdle}
                                            onBlur={(answer) => {
                                                handleNoValidationBlur(answer)
                                                updateMyPresence({ focusedId: null })
                                            }}
                                        />
                                    )
                                }} />

                        )}
                        {field.validation === 'numerical' && (
                            <Controller
                                control={formCanvas.control}
                                name={field.id}
                                render={({ field: { onChange } }) => {
                                    return (
                                        <CLANumberField
                                            label={renderLabel(field.label, field.tooltip)}
                                            onChange={(e, value) => {
                                                onChange(value)
                                            }}
                                            placeholder={field.placeholder}
                                            defaultValue={defaultValue}
                                            notched
                                            digitGroupSeparator=","
                                            disabled={disabled}
                                            isInstance={isInstance}
                                            tbLinkEnabled={isInstance}
                                            allowExternalAccess={field.visibleToClient ?? false}
                                            answerable={field.editableByClient ?? false}
                                            answered={answered}
                                            field={field}
                                            tbLinkBaseField={TbLinkBaseField}
                                            units={units}
                                            isIdle={isIdle}
                                            onFocus={() => {
                                                handleOnFocus()
                                                updateMyPresence({ focusedId: field.id })
                                            }}
                                            onBlur={() => {
                                                updateMyPresence({ focusedId: null })
                                            }}
                                        />
                                    )
                                }} />
                        )}
                        {field.validation === 'usCurrency' && (
                            <Controller
                                control={formCanvas.control}
                                name={field.id}
                                render={({ field: { onChange } }) => {
                                    return (
                                        <CLACurrencyField
                                            label={renderLabel(field.label, field.tooltip)}
                                            onChange={(e, value) => {
                                                onChange(value)
                                                // if (isInstance) handleCurrencyValue(value)
                                            }}
                                            placeholder={field.placeholder}
                                            defaultValue={defaultValue} //isInstance ? defaultValue : handleCurrencyValue(defaultValue)
                                            disabled={disabled}
                                            notched
                                            isInstance={isInstance}
                                            tbLinkEnabled={isInstance}
                                            allowExternalAccess={field.visibleToClient ?? false}
                                            answerable={field.editableByClient ?? false}
                                            answered={answered}
                                            field={field}
                                            tbLinkBaseField={TbLinkBaseField}
                                            units={units}
                                            isIdle={isIdle}
                                            onFocus={() => {
                                                handleOnFocus()
                                                updateMyPresence({ focusedId: field.id })
                                            }}
                                            onBlur={() => {
                                                updateMyPresence({ focusedId: null })
                                            }}
                                        />
                                    )
                                }} />
                        )}
                        {field.validation === 'percentage' && (
                            <Controller
                                control={formCanvas.control}
                                name={field.id}
                                render={({ field: { onChange } }) => {
                                    return (
                                        <CLAPercentageField
                                            label={renderLabel(field.label, field.tooltip)}
                                            onChange={(e, value) => {
                                                onChange(value?.toString())
                                            }}
                                            placeholder={field.placeholder}
                                            value={defaultValue}
                                            disabled={disabled}
                                            notched
                                            allowExternalAccess={field.visibleToClient ?? false}
                                            answerable={field.editableByClient ?? false}
                                            answered={answered}
                                            isIdle={isIdle}
                                            onFocus={() => {
                                                handleOnFocus()
                                                updateMyPresence({ focusedId: field.id })
                                            }}
                                            onBlur={() => {
                                                updateMyPresence({ focusedId: null })
                                            }}
                                        />
                                    )
                                }} />
                        )}
                    </FormControl>
                    {renderLink()}
                </VFRenderedFieldWrapper>
            );
        }
        case FIELD_TYPES.INSTANCE_LONG_ANSWER: {
            const defaultValue = isIdle ? formCanvas.getValues(field.id) : getDefaultValue();
            return (
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper}
                    isLockedByUser={isLockedByUser}
                >
                    <InstanceLongAnswer
                        field={field}
                        defaultValue={defaultValue}
                        disabled={disabled}
                        onFocus={() => {
                            handleOnFocus()
                            updateMyPresence({ focusedId: field.id })
                        }}
                        onBlur={() => {
                            updateMyPresence({ focusedId: null })
                        }}
                    />
                    {renderLink()}
                </VFRenderedFieldWrapper>
            );
        }
        case FIELD_TYPES.FREETEXT:
        case FIELD_TYPES.FREETEXT_NO_RULES: {
            return (
                <Box
                    className={styles.field__wrapper}
                >
                    <Flex direction="row" justify="between" align="baseline" className={styles['vf-field__freetext-container']}>
                        <Typography variant={field.type === FIELD_TYPES.FREETEXT && 'freeText'}>
                            <div
                                className="ql-editor"
                                dangerouslySetInnerHTML={{
                                    __html: sanitize(field.text, { ADD_ATTR: ['target'] }),
                                }}
                            />
                        </Typography>
                        <ClientExternalIcon
                            allowExternalAccess={field.visibleToClient ?? false}
                            answerable={field.editableByClient ?? false}
                        />
                    </Flex>
                    {renderLink()}
                </Box>
            );
        }
        case FIELD_TYPES.CHECKBOXGROUP: {
            const defaultValue = getDefaultValue(true);
            return (
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper}
                    isLockedByUser={isLockedByUser}
                >
                    <CLACheckBoxGroup
                        id={field.id}
                        label={renderLabel(field.label, field.tooltip, renderLink)}
                        value={Array.isArray(defaultValue) ? defaultValue.filter(Boolean) : [defaultValue].filter(Boolean)}
                        onChange={(value, otherValue, otherToggled, e) => {
                            const copy = [...value];
                            if (otherValue) copy.push(otherValue)
                            formCanvas.setValue(field.id, copy); 
                            if (!otherToggled && isInstance){
                                actions.handleSubmit();
                            }
                        }}
                        required={field.required}
                        columns={field.columns}
                        options={field.options}
                        allowOtherOption={field.allowOtherOption}
                        isDisabled={disabled}
                        onOtherFieldFocus={() => {
                            handleOnFocus() 
                            updateMyPresence({ focusedId: field.id })                            
                        }}
                        onBlur={() => {
                            updateMyPresence({ focusedId: null })
                        }}
                        isInstance={isInstance}
                        allowExternalAccess={field.visibleToClient ?? false}
                        answerable={field.editableByClient ?? false}
                        answered={answered}
                    />
                </VFRenderedFieldWrapper>
            );
        }
        case FIELD_TYPES.RADIOGROUP: {
            const defaultValue = getDefaultValue();
            return (
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper}
                    isLockedByUser={isLockedByUser}
                >
                    <Controller
                        control={formCanvas.control}
                        name={field.id}
                        render={({ field: { onChange } }) => {
                            return (
                                <CLARadioButtonList
                                    id={field.id}
                                    label={renderLabel(field.label, field.tooltip, renderLink)}
                                    defaultValue={watchRadioList}
                                    value={defaultValue}
                                    onChange={value => {
                                        onChange(value) 
                                    }}
                                    required={field.required}
                                    columns={field.columns}
                                    options={field.options}
                                    allowOtherOption={field.allowOtherOption}
                                    isDisabled={disabled}
                                    onFocus={()=>{
                                        handleOnFocus()       
                                        updateMyPresence({ focusedId: field.id })
                                    }}
                                    onBlur={() => {
                                        updateMyPresence({ focusedId: null });
                                    }}
                                    formTypeId={formTypeId}
                                    allowExternalAccess={field.visibleToClient ?? false}
                                    answerable={field.editableByClient ?? false}
                                    answered={answered}
                                />
                            )
                        }} />
                </VFRenderedFieldWrapper>
            );
        }
        case FIELD_TYPES.DROPDOWN: {
            const defaultValue = getDefaultValue(true);
            return (
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper}
                    isLockedByUser={isLockedByUser}
                >
                    {field.allowOtherOption && (
                        <CLAComboBox
                            label={renderLabel(field.label, field.tooltip)}
                            defaultValue={defaultValue ?? ''}
                            onChange={(values, e, reason) => {
                                formCanvas.setValue(field.id, values)
                                if ((reason === 'removeOption' && e.target.tagName === 'path') && isInstance){
                                    actions.handleSubmit();
                                } 
                            }}
                            onBlur={(values) => {
                                formCanvas.setValue(field.id, values)
                                handleBlur({target: {value: values}}, true)
                                if (isInstance){
                                    actions.handleSubmit();
                                }
                                updateMyPresence({ focusedId: null })
                            }}
                            options={field.options}
                            allowMultiSelect={field.allowMultiSelect}
                            isDisabled={disabled}
                            onFocus={()=>{
                                handleOnFocus()       
                                updateMyPresence({focusedId:field.id})
                            }}
                            allowMultiLineLabel
                            allowExternalAccess={field.visibleToClient ?? false}
                            answerable={field.editableByClient ?? false}
                            answered={answered}
                            isIdle={isIdle}
                            enableOnChangeHandler={false}
                        />
                    )}
                    {!field.allowOtherOption && !field.allowMultiSelect && (
                        <CLADropdown
                            name={field.id}
                            label={renderLabel(field.label, field.tooltip)}
                            options={field.options}
                            value={defaultValue}
                            isDisabled={disabled}
                            onChange={(values) => {
                                formCanvas.setValue(field.id, values?.target?.value)
                            }}
                            onOpen={()=>{
                                handleOnFocus()
                                updateMyPresence({focusedId:field.id})
                            }} 
                            allowMultiLineLabel
                            onClose={(e) => { 
                                handleBlur(e, true)
                                if (isInstance) {
                                    actions.handleSubmit();
                                } 
                                updateMyPresence({focusedId:null})
                            }}
                            allowExternalAccess={field.visibleToClient ?? false}
                            answerable={field.editableByClient ?? false}
                            answered={answered}
                            isIdle={isIdle}
                        />
                    )}
                    {!field.allowOtherOption && field.allowMultiSelect && (
                        <CLASelect
                            id={field.id}
                            name={field.id}
                            label={renderLabel(field.label, field.tooltip)}
                            isDisabled={disabled}
                            defaultValues={
                                Array.isArray(defaultValue) ? defaultValue.filter(Boolean) : [defaultValue].filter(Boolean)
                            }
                            onChange={(values) => {
                                formCanvas.setValue(field.id, values?.filter((i) => i))
                            }}
                            menuItems={field.options}
                            onOpen={()=>{
                                handleOnFocus()
                                updateMyPresence({focusedId:field.id})
                            }}
                            allowMultiLineLabel
                            onClose={(e) => {
                                handleBlur(e, true)
                                updateMyPresence({ focusedId: null })
                            }}
                            allowExternalAccess={field.visibleToClient ?? false}
                            answerable={field.editableByClient ?? false}
                            answered={answered}
                            isIdle={isIdle}
                            uncontrolled
                        />
                    )}
                    {renderLink()}
                </VFRenderedFieldWrapper>
            );
        }
        case FIELD_TYPES.DATE_PICKER: {
            const defaultValue = isIdle ? formCanvas.getValues(field.id) : getDefaultValue();
            return (
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper}
                    isLockedByUser={isLockedByUser}
                >
                    <Controller
                        control={formCanvas.control}
                        name={field.id}
                        render={({ field: { onChange } }) => {
                            return (
                                <CLADatePickerField
                                    label={renderLabel(field.label, field.tooltip)}
                                    onChange={(e) => {
                                        const value = e ?? null;
                                        onChange(value)
                                    }}
                                    onBlur={() => { 
                                        handleBlur(null, true)
                                        updateMyPresence({ focusedId: null })
                                    }}
                                    placeholder={field.placeholder}
                                    disabled={disabled}
                                    defaultValue={defaultValue || formCanvas.getValues(field.id)}
                                    onFocus={() => {
                                        handleOnFocus()
                                        updateMyPresence({ focusedId: field.id })
                                    }}
                                    allowMultiLineLabel
                                    allowExternalAccess={field.visibleToClient ?? false}
                                    answerable={field.editableByClient ?? false}
                                    answered={answered}
                                    isIdle={isIdle}
                                />
                            )
                        }} />
                    {renderLink()}

                </VFRenderedFieldWrapper>

            );
        }
        case FIELD_TYPES.TABLE: {
            const defaultValue = isIdle ? formCanvas.getValues(field.id) : getDefaultValue(true);
            const parsed = parseQuestionLabel(field.label);
            const label = typeof parsed === 'object' ? parsed.questionLabel : parsed;
            return (
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper}
                    isLockedByUser={isLockedByUser}
                >
                    <div className={styles['vf-section-table-container']}>
                        <div className={styles['vf-section-table-label-wrapper']}>
                            <Flex direction="row" justify="between" align="baseline">
                                <InputLabel className={styles['vf-section-table-label']}>
                                    {renderLabel(label, field.tooltip, renderLink)}
                                </InputLabel>
                                <ClientExternalIcon
                                    allowExternalAccess={field.visibleToClient ?? false}
                                    answerable={field.editableByClient ?? false}
                                />
                            </Flex>
                        </div>
                        <CLAFormTable
                            field={field}
                            values={defaultValue}
                            onChange={(e) => {
                                updateMyPresence({ focusedId: null })
                                formCanvas.setValue(field.id, e)
                                if (isInstance){
                                    actions.handleSubmit();
                                }
                                handleBlur({target: {value: e}}, true)
                            }}
                            isInstance={isInstance}
                            disabled={disabled}
                            onFocus={() => {
                                handleOnFocus(); 
                                updateMyPresence({ focusedId: field.id });
                            }}
                        />
                    </div>
                </VFRenderedFieldWrapper>
            );
        }
        case FIELD_TYPES.FORMULA: {
            return (
                <Box
                    className={styles.field__wrapper} 
                >
                    <Formula
                        field={field}
                        shouldRenderFormula={isInstance}
                        label={renderLabel(field.label, field.tooltip, renderLink)}
                        answerList={answerList ? copyObject(answerList) : null}
                        onFocus={handleOnFocus}
                        disabled={disabled}
                    />
                </Box>
            )
        }
        case FIELD_TYPES.TRIAL_BALANCE: {
            const defaultValue = getDefaultValue(true);
            const defaultArrayValue = Array.isArray(defaultValue) ? defaultValue : defaultValue ? [defaultValue] : [];
            return (
                <Box
                    className={styles.field__wrapper} 
                >
                    <div className={styles.field__wrapper_inner}>
                        {isInstance && projectId
                            ? (<CLATrialBalanceSelect
                                field={field}
                                formTypeId={formTypeId}
                                defaultValues={defaultArrayValue}
                                handCaptureInput={(userInput) => {
                                    formCanvas.setValue(field.id, userInput);
                                    actions.handleSubmit();
                                }}
                                onFocus={handleOnFocus}
                                isDisabled={_disabled}
                                project={project}
                                isPlanningAnalyticsForm={true}
                            />)
                            : (<div className={styles["vf-section-placeholder"]}> TRIAL BALANCE COMPONENT PLACEHOLDER  </div>)
                        }
                    </div>
                    {renderLink()}
                </Box>
            );
        }
        case FIELD_TYPES.KPI_TRIAL_BALANCE: {
            const defaultValue = getDefaultValue(true);
            const defaultArrayValue = Array.isArray(defaultValue) ? defaultValue : defaultValue ? [defaultValue] : [];
            return (
                <Box
                    className={styles.field__wrapper} 
                >
                    <div className={styles.field__wrapper_inner}>
                        {isInstance && projectId ? (
                            <CLATrialBalanceSelect
                                defaultValues={defaultArrayValue}
                                formTypeId={formTypeId}
                                handCaptureInput={(userInput) => {
                                    formCanvas.setValue(field.id, userInput);
                                    actions.handleSubmit();
                                }}
                                field={field}
                                isDisabled={_disabled}
                                isSingleSelect
                                project={project}
                                onFocus={handleOnFocus}
                            />
                        ) : (
                            <div className={styles["vf-section-placeholder"]}>
                                Trial Balance
                            </div>
                        )}
                    </div>
                    {renderLink()}
                </Box>
            );
        }
        case FIELD_TYPES.PROJECT_SCOPE_TRIAL_BALANCE: {
            const defaultValue = getDefaultValue(true);
            const defaultArrayValue = Array.isArray(defaultValue) ? defaultValue : defaultValue ? [defaultValue] : [];
            const { fields } = section;
            const projectScopingField = fields[0].find((field => field.type === FIELD_TYPES.PROJECT_SCOPE_AUDIT_AREA));
            const isLock = isTrialBalanceOrAuditScopingFieldLock([field.id, projectScopingField.id]);
            return (
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper}
                    isLockedByUser={isLockedByUser}
                    isLocked={isLockedByUser && isDeterminingSCOTABDSExpanded}
                >
                    <div className={styles.field__wrapper_inner}>
                        {isInstance && projectId ? (
                            <CLATrialBalanceSelect
                                defaultValues={defaultArrayValue}
                                formTypeId={formTypeId}
                                handCaptureInput={(userInput) => {
                                    formCanvas.setValue(field.id, userInput);
                                    actions.handleSubmit();
                                    updateMyPresence({ focusedId: null })
                                }}
                                field={field}
                                isDisabled={_disabled || isLock}
                                project={project}
                                onFocus={() => {
                                    handleOnFocus()
                                    updateMyPresence({ focusedId: field.id })
                                }}
                                signOffList={signOffList}
                                highlightable={true}
                            />
                        ) : (
                            <div className={styles["vf-section-placeholder"]}>
                                Trial Balance
                            </div>
                        )}
                    </div>
                    {renderLink()}
                </VFRenderedFieldWrapper>
            );
        }
        case FIELD_TYPES.PROJECT_SCOPE_TEST_OPERATING_EFFECTIVENESS: {
            return (
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper}
                    isLockedByUser={isLockedByUser}
                    isLocked={isLockedByUser && isInitialScopingConsiderationExpanded}
                >
                    <div className={styles.field__wrapper}>
                        <div className={styles.field__wrapper_inner}>
                            {isInstance && projectId ? (
                                <CLAInitialScopingConsiderations
                                    fieldId={field.id}
                                    handCaptureInput={(userInput) => {
                                        formCanvas.setValue(field.id, userInput);
                                        if (isInstance) {
                                            actions.handleSubmit();
                                        }
                                    }}
                                    methodologyVersionId={methodologyVersionId}
                                    projectFormId={projectFormId}
                                    disabled={_disabled}
                                    onOpen={() => {
                                        handleOnFocus()
                                        updateMyPresence({ focusedId: field.id })
                                    }}
                                    onClose={() => {
                                        updateMyPresence({ focusedId: null })
                                    }}
                                    signOffList={signOffList}
                                    answerList={answerList}
                                />
                            ) : (
                                <div className={styles["vf-section-placeholder"]}>Audit Area</div>
                            )}
                        </div>
                        {renderLink()}
                    </div>
                </VFRenderedFieldWrapper>
            );
        }
        case FIELD_TYPES.PERFORMANCE_STANDARDS: {

            return (
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper}
                    isLockedByUser={isLockedByUser}
                    isLocked={isLockedByUser && isInitialScopingConsiderationExpanded}
                >
                    <div className={styles.field__wrapper}>
                        <div className={styles.field__wrapper_inner}>
                            {isInstance && projectId ? (
                                <PerformanceStandardsDropdown
                                    methodologyVersionId={methodologyVersionId}
                                    projectFormId={projectFormId}
                                    disabled={_disabled}
                                    onFocus={() => {
                                        handleOnFocus()
                                        updateMyPresence({ focusedId: field.id })
                                    }}
                                    onClose={() => {
                                        updateMyPresence({ focusedId: null })
                                    }}
                                    signOffList={signOffList}
                                />
                            ) : (
                                <div className={styles["vf-section-placeholder"]}>Audit Area</div>
                            )}
                        </div>
                        {renderLink()}
                    </div>
                </VFRenderedFieldWrapper>
            );
        }
        case FIELD_TYPES.REPORTING_FRAMEWORKS: {
            return (
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper}
                    isLockedByUser={isLockedByUser}
                    isLocked={isLockedByUser && isInitialScopingConsiderationExpanded}
                >
                    <div className={styles.field__wrapper}>
                        <div className={styles.field__wrapper_inner}>
                            {isInstance && projectId ? (
                                <FrameworksDropdown
                                    methodologyVersionId={methodologyVersionId}
                                    projectFormId={projectFormId}
                                    disabled={_disabled}
                                    onFocus={() => {
                                        handleOnFocus()
                                        updateMyPresence({ focusedId: field.id })
                                    }}
                                    onClose={() => {
                                        updateMyPresence({ focusedId: null })
                                    }}
                                    signOffList={signOffList}
                                />
                            ) : null}
                        </div>
                        {renderLink()}
                    </div>
                </VFRenderedFieldWrapper>
            );
        } 
        case FIELD_TYPES.PROJECT_SCOPE_AUDIT_AREA: {
            if (section) {
                const { fields } = section;
                const trialBalanceField = fields[0].find((field => field.type === FIELD_TYPES.PROJECT_SCOPE_TRIAL_BALANCE));
                const isLock = isTrialBalanceOrAuditScopingFieldLock([field.id, trialBalanceField.id]);
                return (
                    <VFRenderedFieldWrapper
                        className={styles.field__wrapper}
                        isLockedByUser={isLockedByUser}
                        isLocked={isLockedByUser && isDeterminingSCOTABDSExpanded}
                    >
                        <div className={styles.field__wrapper}>
                            <div className={styles.field__wrapper_inner}>
                                {isInstance && projectId ? (
                                    <CLAProjectScopeAuditArea
                                        field={field} 
                                        methodologyIndustries={methodologyIndustries}
                                        trialBalanceField={trialBalanceField}
                                        disabled={_disabled || isLock} 
                                        signOffList={signOffList}
                                    />
                                ) : (
                                    <div className={styles["vf-section-placeholder"]}>Audit Area</div>
                                )}
                            </div>
                            {renderLink()}
                        </div>
                    </VFRenderedFieldWrapper>
                );
            } else {
                return null;
            }
        } 
        case FIELD_TYPES.CLIENT_SITUATIONS: {
            const defaultValue = getDefaultValue(true);
            return (isInstance &&
                <CLAProcedureField
                    section={{ projectId, ...section }}
                    defaultValues={
                        Array.isArray(defaultValue) ? defaultValue : [defaultValue]
                    }
                    procedureFilter={procedureFilter}
                    field={field}
                    disabled={disabled}
                    handleOnFocus={handleOnFocus}
                />);
        } 
        case FIELD_TYPES.CUSTOM_RISK_CHARACTERISTIC_SELECT_GROUP: {
            return (
                <div className={styles.field__wrapper}>
                    <div className={styles.field__wrapper_inner}>
                        <RiskCharacteristics
                            projectFormId={projectFormId}
                            methodologyVersionId={methodologyVersionId}
                            methodologyIndustries={methodologyIndustries}
                            disabled={_disabled}
                            signOffList={signOffList}
                        />
                    </div>
                </div>
            )
        }
        case FIELD_TYPES.QUESTION_GROUP: {
            const defaultValue = isIdle ? formCanvas.getValues(field.id) : getDefaultValue();
            return (
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper}
                    isLockedByUser={isLockedByUser}
                >
                    <VFQuestionGroup
                        field={field}
                        isInstance={isInstance}
                        onChange={(value) => {
                            formCanvas.setValue(field.id, value);
                            handleBlur({ target: { value } }, true);
                            updateMyPresence({ focusedId: null })
                        }}
                        handleOnFocus={() => {
                            handleOnFocus(); 
                            updateMyPresence({ focusedId: field.id });
                        }}
                        answerValues={defaultValue}
                        updateIsFormFocused={updateIsFormFocused}
                        onSubmit={actions.handleSubmit}
                        disabled={disabled}
                    />
                </VFRenderedFieldWrapper>
            );
        } 
        default: {
            return null;
        }
    }
});
