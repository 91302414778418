import { useContext, useState } from 'react'
import { useParams } from 'react-router-dom';
import { PlanningAnalyticsContext } from "@contexts/PlanningAnalytics/PlanningAnalyticsContext"

export const usePlanningAnalytics = () => {

  const {
    concurrencyEventReceived,
    setConcurrencyEventReceived,
    answerList,
    setAnswerList,
    comment,
    setComment
  } = useContext(PlanningAnalyticsContext)

  const updateNonCustomFieldAnswer = (questionId, answer) => {
    setAnswerList((prev) => ({ ...prev, [questionId]: answer }));
  }

  return {
    concurrencyEventReceived,
    setConcurrencyEventReceived,
    answerList,
    setAnswerList,
    updateNonCustomFieldAnswer,
    comment,
    setComment
  }
}