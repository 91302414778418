// React and other libraries
import { useState, useEffect } from 'react';
import clsx from 'clsx';

// MUI
import { Radio, RadioGroup, FormControlLabel, FormControl, Box } from '@mui/material';
import HelpOutlinedIcon from '@mui/icons-material/HelpOutlined';

// CLA Components
import CLATooltip from '@components/CLATooltip/CLATooltip';

// Related files
import { INTERNAL_CONTROLS } from '@constants/customForm';
import { ConcurrentInternalControlTextbox } from '../../ConcurrentInternalControlTextbox';
import { useUpdateFirstLevelInternalControlData } from '@services/customForm/internalControls';
import useProjectFormInstanceConcurrentLocking from '@hooks/useProjectFormInstanceConcurrentLocking'
import CustomToast from '@components/CustomToast/CustomToast';
import styles from './styles.module.css';
import { useParams } from 'react-router-dom';
import { useInternalControlsContext } from '@contexts/InternalControls/InternalControlsContext'; 
import { sortReviewerSignoffAsc } from '@utilities/dateHelpers.js';
import { isSignoffLogicEnabled } from '@utilities/signoffUtility.js';

const {
  SUBSECTION_TITLE,
  FIRST_PARAGRAPH,
  SECOND_PARAGRAPH,
  TOOLTIP,
  IS_ACCORDING_TO_FRAMEWORK: { YES, NO },
  TEXTBOX,
  CUSTOM_FORM_OBJECT_ID
} = INTERNAL_CONTROLS.EN.EVALUATION_AND_CONCLUSION;

const tooltipSx = {
  p: '15px',
  pb: '22.5px',
  maxWidth: '350px',
  width: '350px',
  fontSize: '12px',
  lineHeight: 'normal'
};

const formControlLabelSx = {
  '& .MuiTypography-root': {
    color: '#333',
    fontSize: '18px',
    fontFamily: 'museo-sans',
    fontWeight: 600,
    lineHeight: '33.5px'
  }
};

const EvaluationAndConclusion = ({ internalControlData, isProjectFinalized, changeFirstLevelFieldWithObject, projectFormId, signOffList}) => {

  const [radioValue, setRadioValue] = useState(null);
  const [shouldShowTextbox, setShouldShowTextbox] = useState(false);
  const [textboxValue, setTextboxValue] = useState('');
  const [loadingFrameworkData, setLoadingFrameworkData] = useState(false)
  const [errorFrameworkData, setErrorFrameworkData] = useState(false)
  const [successFrameworkData, setSuccessFrameworkData] = useState(false)
  const { projectId } = useParams();
  const [fieldHighlight, setFieldHighlight] = useState({
    AccordingToFrameworkComment: false,
    IsAccordingToFramework: false
  })

  const { emitUpdateEvent, concurrentValue } = useProjectFormInstanceConcurrentLocking(null,
    {
        customFormObjectId: CUSTOM_FORM_OBJECT_ID
    }
  );

  const {
    formattedInternalControls
  } = useInternalControlsContext();

  const { mutate: updateFirstLevelInternalControlData } = useUpdateFirstLevelInternalControlData();

  const EvaluationAndConclusion = [
    'AccordingToFrameworkComment',
    'IsAccordingToFramework'
  ]

  const getRadioValue = () => {
    if (internalControlData?.IsAccordingToFramework == null) return null;
    else return internalControlData.IsAccordingToFramework ? YES : NO;
  };

  const getTextboxValue = () => {
    if (internalControlData?.AccordingToFrameworkComment == null) return '';
    else return internalControlData.AccordingToFrameworkComment;
  };


  const saveFrameworkData = (comment, radioValue) => {
    setLoadingFrameworkData(true)

    const payload = changeFirstLevelFieldWithObject({
      AccordingToFrameworkComment: comment,
      IsAccordingToFramework: radioValue
    })

    const __radioValue = getRadioValue();
    const __textboxValue = getTextboxValue();

    const currentStates = {
      radioValue: __radioValue,
      shouldShowTextbox: !internalControlData?.IsAccordingToFramework,
      textboxValue: __textboxValue
    };

    updateFirstLevelInternalControlData(
      { projectFormId, payload, projectId },
      {
        onError: () => {
          setRadioValue(currentStates.radioValue);
          setShouldShowTextbox(currentStates.shouldShowTextbox);
          setTextboxValue(currentStates.textboxValue);
          setErrorFrameworkData(true)
          setSuccessFrameworkData(false)
          setLoadingFrameworkData(false)
        },
        onSuccess: () => {
          setErrorFrameworkData(false)
          setSuccessFrameworkData(true)
          setLoadingFrameworkData(false)
        }
      }
    );
  };

  const changeRadioValue = (value) => {
    if (value === YES) {
      setRadioValue(YES);
      setShouldShowTextbox(false);
      setTextboxValue('');

      saveFrameworkData(null, true);
    } else {
      setRadioValue(NO);
      setShouldShowTextbox(true);

      saveFrameworkData(internalControlData?.AccordingToFrameworkComment, false);
    }
  }

  const handleRadioChange = (event) => {
    changeRadioValue(event.target.value)
    emitUpdateEvent({value: event.target.value})
  };

  const handleTextboxBlur = (event) => {
    if (event.relatedTarget?.id === 'eac-yes-radio-button' || event.relatedTarget?.id === 'eac-yes-radio-button-label')
      return;
  
    const newValue = event.target.value;
    if (textboxValue == null && !newValue) return;
    if (textboxValue === newValue) return;
  
    saveFrameworkData(newValue, false);
  };

  useEffect(() => {
    const __radioValue = getRadioValue();
    setRadioValue(__radioValue);

    if(__radioValue === null) {
      setShouldShowTextbox(false);
    } else {
      setShouldShowTextbox(!internalControlData?.IsAccordingToFramework);
    }

    const __textboxValue = getTextboxValue();
    setTextboxValue(__textboxValue);
  }, [internalControlData]);

  useEffect(() => {
    if(!!concurrentValue) changeRadioValue(concurrentValue.value)
  }, [concurrentValue])

  useEffect(() => {
    const latestReviewersAsc = sortReviewerSignoffAsc(signOffList)
    let shouldHighlight = false
    EvaluationAndConclusion.forEach((key) => {
      if(formattedInternalControls?.History?.length > 0){
          if(formattedInternalControls[key] !== formattedInternalControls?.History[0][key]){
              const dateModified = new Date(formattedInternalControls.ValidFrom).getTime();
              const isSignoffEnabled = isSignoffLogicEnabled(isProjectFinalized, dateModified);
              shouldHighlight = new Date(latestReviewersAsc[latestReviewersAsc?.length - 1]?.signOffDate).getTime() < dateModified
              setFieldHighlight((prev) => {
                  return {
                      ...prev,
                      [key]: isSignoffEnabled && shouldHighlight
                  }
              })
          }
      }
    })
}, [formattedInternalControls, signOffList])

  return (
    <>
      <div className={styles.EACSubsectionTitle}>{SUBSECTION_TITLE}</div>

      <p className={clsx(styles.EACParagraph, styles.EACFirstParagraph)}>{FIRST_PARAGRAPH}</p>

      <p className={styles.EACSecondParagraph}>
        <span className={styles.EACParagraph}>{SECOND_PARAGRAPH}</span>
        <CLATooltip
          title={TOOLTIP}
          placement="top"
          componentsProps={{
            tooltip: {
              sx: tooltipSx
            }
          }}
        >
          <HelpOutlinedIcon sx={{ mb: '3px', paddingLeft: '4px', width: '23px', color: '#2e334e', fontSize: 15 }} />
        </CLATooltip>
      </p>

      <FormControl sx={fieldHighlight['IsAccordingToFramework'] ? { ml: '5px', mb: '25px', borderStyle: 'dashed', borderWidth: '4px', borderColor: 'red' }: { ml: '5px', mb: '25px' }} disabled={isProjectFinalized}>
        <RadioGroup
          row
          aria-labelledby="is-according-to-framework-group-label"
          name="is-according-to-framework-group"
          value={radioValue}
          onChange={handleRadioChange}
        >
          <FormControlLabel
            id="eac-yes-radio-button-label"
            tabIndex="-1"
            value={YES}
            control={<Radio id="eac-yes-radio-button" />}
            label={YES}
            sx={{ ...formControlLabelSx, mr: '40px' }}
          />
          <FormControlLabel value={NO} control={<Radio />} label={NO} sx={formControlLabelSx} />
        </RadioGroup>
      </FormControl>

      <Box sx={{ mb: shouldShowTextbox ? '30px' : '172.25px' }}>
        {shouldShowTextbox && (
          <ConcurrentInternalControlTextbox
            value={textboxValue}
            label={TEXTBOX.LABEL}
            isMultilineLabel={true}
            placeholder={TEXTBOX.PLACEHOLDER}
            onBlur={(e) => handleTextboxBlur(e)}
            customFormObjectId={"evaluation-and-conclusion-textbox"}
            disabled={isProjectFinalized}
            shouldHighlight={fieldHighlight['AccordingToFrameworkComment']}
          />
        )}
      </Box>
      <CustomToast 
        error={errorFrameworkData} 
        success={successFrameworkData}
        loading={loadingFrameworkData}
      />
    </>
  );
};

export default EvaluationAndConclusion;