import CLASnackbarWithContent from '@components/CLASnackbarWithContent/CLASnackbarWithContent';
import { useOthers, useOtherUsersStore } from './store/users';
import { useEventListener, useOthersListener } from './RoomProvider';
import { useProjectScope } from './CLAProjectScopeAuditArea/hooks/useProjectScope';
import { useMsal } from '@azure/msal-react';

export default function ConcurrentUsersBanner() {
    const { accounts } = useMsal()
    const userId = accounts[0].localAccountId.toLowerCase();
    const {
        updateAuditAreaData,
        updateNonCustomFieldAnswer,
        setConcurrencyEventReceived
    } = useProjectScope();
    const append = useOtherUsersStore((state) => state.append)
    const remove = useOtherUsersStore((state) => state.remove)
    const updateUserPresence = useOtherUsersStore((state) => state.updateUserPresence)

    useOthersListener(({ type, sessionId, data }) => {
        switch (type) {
            case "enter":
                append({ sessionId, ...data })
                return
            case "leave":
                remove(sessionId)
                return
            case "presence":
                updateUserPresence(sessionId, data)
            default:
                console.warn(`Invalid event type: `, type)
        }
    })

    useEventListener(({ type, data }) => {
        switch (type) {
            case "updates":
                if (data.answer.userId.toUpperCase() !== userId.toUpperCase()) { 
                    setConcurrencyEventReceived(true);
                    if (data.answer.isNonCustomField) {
                        updateNonCustomFieldAnswer(data.id, data.answer.answerValue);
                    } else {
                        updateAuditAreaData(data.id, data.answer);
                    }
                }
                return
            default:
                console.warn(`Invalid event type: `, type)
        }
    })

    const getDisplayUsers = (displayUsers, user) => {
        if (user.info.userId !== userId && !displayUsers.some(function (displayUser) { return displayUser.info.name === user.info.name; })){
            displayUsers.push(user);
        } 
        return displayUsers;
    }

    const users = useOthers()
    return (
        <CLASnackbarWithContent
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            snackBarPostion={'top-center'}
            open={users?.reduce(getDisplayUsers, []).length > 0}
            message={
            <>
                {users?.reduce(getDisplayUsers, []).map((item, index) => (
                    <p key={index} style={{ textAlign: 'center', margin: 0 }}>
                        {`${item.info.name} is currently editing this form`}
                    </p>
                ))}
            </>
            }
        />
    )
}

