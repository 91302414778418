import React from "react";
import {
    useParams
} from "react-router-dom";
import { useMsal } from '@azure/msal-react';

import ProjectScopingForm from "@views/ProjectScopingForm/ProjectScopingForm";
import PlanningAnalytics from "@views/PlanningAnalytics/PlanningAnalytics";
import ProjectFormInstance from "@views/ProjectFormInstance/ProjectFormInstance";
import RiskAssesmentSummaryForm from "@views/RiskAssesmentSummary/RiskAssesmentSummaryForm";

import { SocketProvider } from "@ais/providers";
import { CustomToastProvider } from "@contexts/CustomToast/CustomToastContext"
import { ProjectScopeProvider } from "@contexts/ProjectScope/ProjectScopeContext"
import { PlanningAnalyticsProvider } from "@contexts/PlanningAnalytics/PlanningAnalyticsContext"
import { RiskAssessmentProvider } from "@contexts/RiskAssessment/RiskAssessmentContext"
import { ScopingFrameworkAndPerformanceStandardProvider } from "@contexts/ScopingFrameworkAndPerformanceStandardContext/ScopingFrameworkAndPerformanceStandardContext"
import { InternalControlsContextProvider } from '@contexts/InternalControls/InternalControlsContext';
import { TailorProceduresDrawerProvider } from '@contexts/TailorProcedures/TailorProceduresDrawerContext'
import { KeyControlDrawerProvider } from '@contexts/KeyControlDrawer/KeyControlDrawerContext'
import { ProjectFormInstanceConcurrencyProvider } from '@contexts/ProjectFormInstanceConcurrencyContext'
import { TailorProcedureProvider } from "@components/Forms/state"
import { RoomProvider } from "../ProjectScopingForm/RoomProvider";
import { RoomProvider as PlanningAnalyticsRoomProvider } from "../PlanningAnalytics/RoomProvider";
import { RoomProvider as RiskAssesmentSummaryRoomProvider } from "../RiskAssesmentSummary/RoomProvider";
import { ProjectFormInstanceProvider } from "../../providers"
import { GraphContextProvider } from "../PlanningAnalytics/GraphContext";
import { GraphContextProvider as GraphContext } from '@contexts/PlanningAnalytics/GraphContext';
import loadable from "@loadable/component"
import InternalControlForm from "../InternalControl/InternalControlForm";
import { RoomProvider as ICFRoomProvider } from "../InternalControl/RoomProvider";
import { useGetProjectFormWithSchemaById } from "@services/forms/projectforms";

const UnauthorizedErrorPage = loadable(() => import("@views/UnauthorizedErrorPage/UnauthorizedErrorPage.jsx"))
const ProjectFormRenderer = () => {
    const wsBaseUrl = process.env.REACT_APP_API_FORM_WEBSOCKET;
    const { accounts } = useMsal()
    const { projectId, projectFormId } = useParams();
    const userId = accounts[0].localAccountId.toLowerCase();
    const { data: projectForm, isError, isLoading, isFetching, isFetched} = useGetProjectFormWithSchemaById(projectFormId, projectId);

    if (isError) return <UnauthorizedErrorPage />
    if (isLoading && isFetching && !isFetched) return <></>

    if (projectForm.formTypeId === 2) {
        return (
            <ProjectFormInstanceProvider>
                <CustomToastProvider>
                    <ProjectScopeProvider>
                        <ScopingFrameworkAndPerformanceStandardProvider>
                            <RoomProvider roomId={projectForm.projectFormID} userId={userId}>
                                <ProjectScopingForm />
                            </RoomProvider>
                        </ScopingFrameworkAndPerformanceStandardProvider>
                    </ProjectScopeProvider>
                </CustomToastProvider>
            </ProjectFormInstanceProvider>

        )
    }

    if (projectForm.formTypeId === 3) {
        return (
            <ProjectFormInstanceProvider>
                <PlanningAnalyticsProvider>
                    <GraphContextProvider>
                        <PlanningAnalyticsRoomProvider roomId={projectForm.projectFormID} userId={userId}>
                            <PlanningAnalytics />
                        </PlanningAnalyticsRoomProvider>
                    </GraphContextProvider>
                </PlanningAnalyticsProvider>
            </ProjectFormInstanceProvider>

        )
    }

    if (projectForm.formTypeId === 4) {
        return (
            <ProjectFormInstanceProvider>
                <RiskAssessmentProvider>
                    <RiskAssesmentSummaryRoomProvider roomId={projectForm.projectFormID} userId={userId}>
                        <RiskAssesmentSummaryForm />
                    </RiskAssesmentSummaryRoomProvider>
                </RiskAssessmentProvider>
            </ProjectFormInstanceProvider>
        )
    }

    if (projectForm.formTypeId === 7) {
        return (
            <ProjectFormInstanceProvider>
                <InternalControlsContextProvider>
                    <KeyControlDrawerProvider>
                        <CustomToastProvider>
                            <ICFRoomProvider roomId={projectForm.projectFormID} userId={userId}>
                                <InternalControlForm />
                            </ICFRoomProvider>
                        </CustomToastProvider>
                    </KeyControlDrawerProvider>
                </InternalControlsContextProvider>
            </ProjectFormInstanceProvider>
        )
    }

    return (
        <SocketProvider url={wsBaseUrl}>
            <TailorProcedureProvider>
                <TailorProceduresDrawerProvider>
                    <ProjectFormInstanceConcurrencyProvider>
                        <InternalControlsContextProvider>
                            <KeyControlDrawerProvider>
                                <ProjectFormInstanceProvider>
                                    <GraphContext>
                                        <CustomToastProvider>
                                            <ProjectScopeProvider>
                                                <ScopingFrameworkAndPerformanceStandardProvider>
                                                    <ProjectFormInstance />
                                                </ScopingFrameworkAndPerformanceStandardProvider>
                                            </ProjectScopeProvider>
                                        </CustomToastProvider>
                                    </GraphContext>
                                </ProjectFormInstanceProvider>
                            </KeyControlDrawerProvider>
                        </InternalControlsContextProvider>
                    </ProjectFormInstanceConcurrencyProvider>
                </TailorProceduresDrawerProvider>
            </TailorProcedureProvider>
        </SocketProvider>
    )
}

export default ProjectFormRenderer