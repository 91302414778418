import { createContext, useReducer, useContext, useState } from 'react';
import KeyControlDrawerReducer, { 
	initialState, 
	emptyInternalControlKeyControl,
	emptyAuditAreaSCOTABDSKeyControl,
	emptyProjectInternalControlProjectKeyControl,
	emptyProjectScopeAuditAreaSCOTABDProjectKeyControl
} from './KeyControlDrawerReducer';

const KeyControlDrawerContext = createContext();

export const KeyControlDrawerProvider = ({ children }) => {
  const [state, dispatch] = useReducer(KeyControlDrawerReducer, initialState);

	const openDrawer = (key) => {
		dispatch({
			type: "OPEN_DRAWER",
			payload: {
			}
		});
	}

	const closeDrawer = (newInternalControlData, isRefreshData) => {
		dispatch({
			type: "CLOSE_DRAWER",
			payload: {
				newInternalControlData,
				isRefreshData
			}
		});
	}

	const initializeInternalControl = (keyControlData) => {
		const {
			projectFormId, 
			isJournalEntry,
			isFinancialStatement,
			internalControlData
		} = keyControlData;

		let tempProjectKeyControl = [{
			...emptyProjectInternalControlProjectKeyControl,
			IsJournalEntry: isJournalEntry,
			IsFinancialStatement: isFinancialStatement,
			ProjectKeyControl: [{...emptyInternalControlKeyControl}]
		}];

		if (!internalControlData)
			return;

		const { ProjectInternalControlProjectKeyControl } = internalControlData

		const existingData = isJournalEntry ? ProjectInternalControlProjectKeyControl?.filter((x) => x.IsJournalEntry) 
											: ProjectInternalControlProjectKeyControl?.filter((x) => x.IsFinancialStatement);

		if (existingData && existingData?.length) {
			tempProjectKeyControl = [...existingData?.sort(
					(a, b) => a?.ProjectKeyControl[0]?.ProjectKeyControlId - b?.ProjectKeyControl[0]?.ProjectKeyControlId
				)
			];
		}

		dispatch({
			type: "INITIALIZE_INTERNAL_CONTROL",
			payload: {
				projectFormId, 
				isJournalEntry,
				isFinancialStatement,
				internalControlData,
				tempProjectKeyControl
			}
		})
	}

	const initializeAuditAreaSCOTABDProjectKeyControl = (keyControlData) => {
		const {
			projectFormId, 
			internalControlData,
			projectScopeAuditAreaId,
			projectScopeAuditAreaSCOTABDId,
			projectScopeAuditAreaSCOTABDProjectKeyControl
		} = keyControlData;

		let tempProjectKeyControl = [{
			...emptyProjectScopeAuditAreaSCOTABDProjectKeyControl,
			ProjectScopeAuditAreaSCOTABDId: projectScopeAuditAreaSCOTABDId,
			ProjectKeyControl: [{...emptyAuditAreaSCOTABDSKeyControl}]
		}];

		if (projectScopeAuditAreaSCOTABDProjectKeyControl && projectScopeAuditAreaSCOTABDProjectKeyControl?.length) {
			tempProjectKeyControl = projectScopeAuditAreaSCOTABDProjectKeyControl.map(x => ({
				...x,
				ProjectScopeAuditAreaSCOTABDId: projectScopeAuditAreaSCOTABDId
			}))?.sort(
				(a, b) => a?.ProjectKeyControl[0]?.ProjectKeyControlId - b?.ProjectKeyControl[0]?.ProjectKeyControlId
			);
		}

		dispatch({
			type: "INITIALIZE_AUDITAREA_SCOTABDS",
			payload: {
				projectFormId,
				internalControlData,
				tempProjectKeyControl,
				projectScopeAuditAreaId,
				projectScopeAuditAreaSCOTABDId,
				projectScopeAuditAreaSCOTABDProjectKeyControl
			}
		})
	}

	const getEmptyProjectKey = () => {
		return state.isJournalEntry || state.isFinancialStatement 
				? {
					...emptyProjectInternalControlProjectKeyControl,
					IsJournalEntry: state.isJournalEntry,
					IsFinancialStatement: state.isFinancialStatement,
					ProjectKeyControl: [{...emptyInternalControlKeyControl}]
				}
				: {
					...emptyProjectScopeAuditAreaSCOTABDProjectKeyControl,
					ProjectScopeAuditAreaSCOTABDId: state.projectScopeAuditAreaSCOTABDId,
					ProjectKeyControl: [{...emptyAuditAreaSCOTABDSKeyControl}]
				};
	}

    const value = {
        isOpen: state.isOpen,
		isInitialize: state.isInitialize,
		isJournalEntry: state.isJournalEntry, 
		isFinancialStatement: state.isFinancialStatement,
		isSaveButtonDisable: state.isSaveButtonDisable,
		projectFormId: state.projectFormId,
		projectScopeAuditAreaSCOTABDId: state.projectScopeAuditAreaSCOTABDId,
		projectScopeAuditAreaSCOTABDProjectKeyControl: state.projectScopeAuditAreaSCOTABDProjectKeyControl,
		tempProjectKeyControl: state.tempProjectKeyControl,
		internalControlData: state.internalControlData,
		projectScopeAuditAreaId: state.projectScopeAuditAreaId,
		formReset: state.formReset,
		newInternalControlData: state.newInternalControlData,
		isRefreshData: state.isRefreshData,
		getEmptyProjectKey: getEmptyProjectKey,
        openDrawer,
		closeDrawer,
		initializeInternalControl,
		initializeAuditAreaSCOTABDProjectKeyControl
    }

    return <KeyControlDrawerContext.Provider value={value}>{children}</KeyControlDrawerContext.Provider>
}

export const useKeyControlDrawerContext = () => useContext(KeyControlDrawerContext);

export default useKeyControlDrawerContext;