import { useWatch, useFormContext } from "react-hook-form"
import { useState, useEffect } from "react";
import {
  FormControl,
  FormControlLabel,
  OutlinedInput,
  Checkbox,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

export const BasicTextField = (props) => {
  const {
    name,
    label,
    placeholder,
    showRequired,
    requiredName,
    maxLength,
    disabled,
    helperText,
    multiline
  } = props;

  const { setValue, control, formState } = useFormContext();
  const { errors } = formState;
  const watchValue = useWatch({ control, name }) ?? '';
  const watchRequiredName = useWatch({ control, name: requiredName });

  const [inputValue, setInputValue] = useState(watchValue);

  useEffect(() => {
    setInputValue(watchValue)
  }, [watchValue]);

  return (
    <FormControl fullWidth>
      <Typography
        variant="label"
        sx={{
          marginBottom: '8px',
        }}
      >
        {label}
      </Typography>
      <OutlinedInput
        name={name}
        placeholder={placeholder}
        value={inputValue}
        onChange={(e) => setValue(name, e.target.value, { shouldValidate: true })}
        inputProps={{ maxLength }}
        error={!!errors[name]?.message}
        disabled={disabled}
        multiline={multiline}
        sx={{
          marginBottom: showRequired ? '8px' : 0,
        }}
      />
      {helperText}
      {showRequired && (
        <div style={{ textAlign: 'right' }}>
          <FormControlLabel
            control={
              <Checkbox
                name={requiredName}
                checked={watchRequiredName}
                onChange={(e) => setValue(requiredName, e.target.checked)}
              />
            }
            label="Required"
            sx={{
              m: 0,
              justifyContent: 'flex-end',
            }}
          />
        </div>
      )}
    </FormControl>
  );
};

BasicTextField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
  placeholder: PropTypes.string,
  showRequired: PropTypes.bool,
  requiredName: PropTypes.string,
  maxLength: PropTypes.number,
};

BasicTextField.defaultProps = {
  showRequired: false,
  requiredName: 'required',
  maxLength: 1000,
  disabled: false,
  multiline: true,
};
