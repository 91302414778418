import CLASnackbarWithContent from '@components/CLASnackbarWithContent/CLASnackbarWithContent';
import { useOthers, useOtherUsersStore } from './store/users';
import { useEventListener, useOthersListener } from './RoomProvider';
import { usePlanningAnalytics } from './hooks/usePlanningAnalytics';
import { useGraphContext } from './GraphContext';
import { useMsal } from '@azure/msal-react';

export default function ConcurrentUsersBanner() {
    const { accounts } = useMsal()
    const userId = accounts[0].localAccountId.toLowerCase();
    const {
        updateNonCustomFieldAnswer,
        setConcurrencyEventReceived,
        setComment
    } = usePlanningAnalytics();

    const { setSelectedPriorPeriod, setSelectedGraphs } = useGraphContext()
    const append = useOtherUsersStore((state) => state.append)
    const remove = useOtherUsersStore((state) => state.remove)
    const updateUserPresence = useOtherUsersStore((state) => state.updateUserPresence)

    useOthersListener(({ type, sessionId, data }) => {
        switch (type) {
            case "enter":
                console.log(`User w/ session ${sessionId} has entered the room`)
                append({ sessionId, ...data })
                return
            case "leave":
                console.log(`User w/ session ${sessionId} has left the room`)
                remove(sessionId)
                return
            case "presence":
                console.log(`User w/ session ${sessionId} presence`)
                updateUserPresence(sessionId, data)
            default:
                console.warn(`Invalid event type: `, type)
        }
    })

    useEventListener(({ type, data }) => {
        switch (type) {
            case "updates":
                setConcurrencyEventReceived(true);
                if (data.answer.userId !== userId) {
                    if ('selectedPeriods' in data.answer) {
                        setSelectedPriorPeriod(data.answer.selectedPeriods)
                    } else if ('selectedGraphs' in data.answer) {
                        setSelectedGraphs(data.answer.selectedGraphs)
                    } else if ('comment' in data.answer) {
                        setComment(data.answer.comment) 
                    } else {
                        updateNonCustomFieldAnswer(data.id, data.answer.answerValue);
                    }
                }
                return
            default:
                console.warn(`Invalid event type: `, type)
        }
    })

    const getDisplayUsers = (displayUsers, user) => {
        if (user.info.userId !== userId && !displayUsers.some(function (displayUser) { return displayUser.info.name === user.info.name; })){
            displayUsers.push(user);
        } 
        return displayUsers;
    }

    const users = useOthers()
    return (
        <CLASnackbarWithContent
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            snackBarPostion={'top-center'}
            open={users?.reduce(getDisplayUsers, []).length > 0}
            message={
            <>
                {users?.reduce(getDisplayUsers, []).map((item, index) => (
                    <p key={index} style={{ textAlign: 'center', margin: 0 }}>
                        {`${item.info.name} is currently editing this form`}
                    </p>
                ))}
            </>
            }
        />
    )
}

