import { useContext, useState, useEffect } from 'react';
import { useDebounce, useQueue } from 'react-use'
import { SOCKET_EVENT_NAMES, PROJECT_FORM_CUSTOM_LOCK, WAITING_TIME } from '@ais/constants';
import { ProjectFormInstanceConcurrencyContext } from '@contexts/ProjectFormInstanceConcurrencyContext';
import { useIdle } from 'react-use';
import { useSocket } from '@ais/providers';

const useProjectFormInstanceConcurrentLocking = (questionId, {
    summaryProcedureId,
    projectFormProcedureStepId,
    procedureComponentId,
    customFormObjectId,
    onIdle
}) => {
    const { socket } = useSocket();
    const [isLocked, setIsLocked] = useState(false);
    const [lockingUser, setLockingUser] = useState(null);
    const [concurrentValue, setConcurrentValue] = useState(null);
    const { state, dispatchReducer, setLastEmmitedEvent } = useContext(ProjectFormInstanceConcurrencyContext);
    const [originalValue, setOriginalValue] = useState(null);
    const { 
        add: addToQueue,
        remove: removeToQueue,
        first: queueItem,
    } = useQueue();
    
    useDebounce(() => {
        if(!queueItem) return
        const { eventname, data } = queueItem;

        setLastEmmitedEvent({ eventname, data });
        if(eventname === SOCKET_EVENT_NAMES.EMITS.QUESTION_UPDATE) {
            (async () => {
                return new Promise((resolve) => socket.emit(eventname, data, resolve))
            })()
        } else {
            socket.emit(eventname, data)
        }
        
        removeToQueue();
    }, 150, [queueItem])

    const enqueue = (eventname, data) => {
        addToQueue({eventname, data})
    }

    const isIdle = useIdle(WAITING_TIME.MINS_15);

    // Update isLocked if any of the ongoing session contains the one that used this hook
    useEffect(() => {
        if(!state?.sessionsData) return;
        const lockingSession = state.sessionsData.find(({
            questionId: sessionQuestionId,
            summaryProcedureId: sessionSummaryProcedureId,
            projectFormProcedureStepId: sessionProjectFormProcedureStepId,
            procedureComponentId: sessionProcedureComponentId,
            customFormObjectId: sessionCustomFormObjectId
        }) =>
            questionId === sessionQuestionId &&
            summaryProcedureId == sessionSummaryProcedureId &&
            projectFormProcedureStepId == sessionProjectFormProcedureStepId &&
            procedureComponentId?.toLowerCase() == sessionProcedureComponentId?.toLowerCase() &&
            customFormObjectId == sessionCustomFormObjectId
        );

        const lockingUser = !!lockingSession ? {
                userId: lockingSession.userId,
                alternativeName: lockingSession.alternativeName,
            }
            : null;

        if(!lockingUser || lockingSession?.sessionId === socket.id) {
            setLockingUser(null);
            setIsLocked(false);
        } else {
            setLockingUser(lockingUser)
            setIsLocked(!!lockingUser)
        }
    }, [state?.sessionsData])

    // Update concurrent value if current value is the one that used this hook
    useEffect(() => {
        // should not process if lastSessionUpdate is empty
        if(!state?.lastSessionUpdate) return;
        
        if(state.lastSessionUpdate?.prevQuestionId !== questionId) return;
        const { 
            summaryProcedureId: lastSummaryProcedureId,
            projectFormProcedureStepId: lastProjectFormProcedureStepId,
            procedureComponentId: lastProcedureComponentId,
            customFormObjectId: lastCustomFormObjectId
        } = state.lastSessionUpdate;
        if (
            lastSummaryProcedureId == summaryProcedureId &&
            lastProjectFormProcedureStepId == projectFormProcedureStepId &&
            lastProcedureComponentId == procedureComponentId &&
            lastCustomFormObjectId == customFormObjectId
        ) {
            const answerAsJSON = state?.lastSessionUpdate?.answer;
            const answerAsObject = !!answerAsJSON ? JSON.parse(answerAsJSON) : { }
            setConcurrentValue(answerAsObject);
        }
    }, [state?.lastSessionUpdate]);

    useEffect(() => {
        if (!isIdle) return
        setConcurrentValue(originalValue);
        onIdle?.(originalValue)
    }, [isIdle])

    // Start lock for other users
    const emitLockEvent = async (initialValue) => {
        setOriginalValue(initialValue)
        const eventName = SOCKET_EVENT_NAMES.EMITS.QUESTION_UPDATE;

        const data = {
            userId: state.userId,
            projectFormId: state.projectFormId,
            questionId: questionId,
            projectId: state.projectId,
            prevQuestionId: null,
            summaryProcedureId: summaryProcedureId || null,
            projectFormProcedureStepId: projectFormProcedureStepId || null,
            procedureComponentId: procedureComponentId || null,
            customFormObjectId: customFormObjectId || null,
        }
        enqueue(eventName, data)
    }

    // Ends lock for other users and update their value
    const emitUnlockEvent = async (answer) => {
        setOriginalValue(answer)
        const answerAsJSON = !!answer ? JSON.stringify(answer) : {};

        const eventName = SOCKET_EVENT_NAMES.EMITS.QUESTION_UPDATE;
        const data = {
            userId: state.userId,
            projectFormId: state.projectFormId,
            questionId: null,
            projectId: state.projectId,
            prevQuestionId: questionId,
            answer: answerAsJSON || null,
            summaryProcedureId: summaryProcedureId || null,
            projectFormProcedureStepId: projectFormProcedureStepId || null,
            procedureComponentId: procedureComponentId || null,
            customFormObjectId: customFormObjectId || null,
        }
        enqueue(eventName, data)
    }

    const emitLockCustomFormObjectId = (initialValue, customFormObjectId) => {
        setOriginalValue(initialValue)
        const eventName = SOCKET_EVENT_NAMES.EMITS.QUESTION_UPDATE;
        const data = {
            userId: state.userId,
            projectFormId: state.projectFormId,
            questionId: questionId,
            projectId: state.projectId,
            prevQuestionId: null,
            customFormObjectId: customFormObjectId || null,
        }
        enqueue(eventName, data)
    }

    const emitUnlockCustomFormObjectId = (answer, customFormObjectId) => {
        setOriginalValue(answer)
        const answerAsJSON = !!answer ? JSON.stringify(answer) : {};
        const eventName = SOCKET_EVENT_NAMES.EMITS.QUESTION_UPDATE;
        const data = {
            userId: state.userId,
            projectFormId: state.projectFormId,
            questionId: null,
            projectId: state.projectId,
            prevQuestionId: questionId,
            answer: answerAsJSON || null,
            customFormObjectId: customFormObjectId || null,
        }
        enqueue(eventName, data)
    }

    const emitResetEvent = async () => {
        const eventName = SOCKET_EVENT_NAMES.EMITS.QUESTION_UPDATE;
        const data = {
            userId: state.userId,
            projectFormId: state.projectFormId,
            projectId: state.projectId,
            questionId: null,
            prevQuestionId: null,
            answer: null,
            summaryProcedureId: null,
            projectFormProcedureStepId: null,
            procedureComponentId: null,
            customFormObjectId: null,
        }
        enqueue(eventName, data)
    }

    const emitUpdateEvent = async (answer) => {
        setOriginalValue(answer)
        const answerAsJSON = !!answer ? JSON.stringify(answer) : {};

        const eventName = SOCKET_EVENT_NAMES.EMITS.QUESTION_UPDATE;
        const data = {
            userId: state.userId,
            projectFormId: state.projectFormId,
            projectId: state.projectId,
            questionId: questionId,
            prevQuestionId: questionId,
            answer: answerAsJSON,
            summaryProcedureId: summaryProcedureId || null,
            projectFormProcedureStepId: projectFormProcedureStepId || null,
            procedureComponentId: procedureComponentId || null,
            customFormObjectId: customFormObjectId || null,
        }
        enqueue(eventName, data)
    }

    const selfUnlockProcedureComponent = (procedureComponentId) => {
        dispatchReducer({
            type: 'MANUAL_UNLOCK_PROCEDURE_COMPONENT',
            data: { procedureComponentId }
        })
    }

    return {
        isLocked,
        lockingUser,
        emitLockEvent, 
        emitUnlockEvent, 
        emitResetEvent, 
        emitUpdateEvent,
        concurrentValue,
        emitLockCustomFormObjectId,
        emitUnlockCustomFormObjectId,
        selfUnlockProcedureComponent,
    };
}

export default useProjectFormInstanceConcurrentLocking;