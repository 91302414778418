import { useState, useEffect } from 'react'

import {
    InternalControlTextbox,
} from "@ais/components";

export const ConcurrentInternalControlTextbox = ({
    label,
    placeholder,
    maxLength = 4096,
    nonMultilineLabelFontSize,
    nonMultilineLabelMarginLeft,
    onBlur,
    onFocus,
    value,
    customFormObjectId,
    fieldName,
    ...props
}) => {
    const [content, setContent] = useState('')
    useEffect(() => {
        setContent(value)
    }, [value])

    const handleBlur = async (e) => {
        if(typeof onBlur === 'function') onBlur(e, fieldName || customFormObjectId, null)
    }

    const handleFocus = async (e) => {
        if(typeof onFocus === 'function') onFocus(e, fieldName || customFormObjectId, customFormObjectId)
    }

    const handleOnChange = (event) => {
        setContent(event.target.value)
    }

    return (
        <InternalControlTextbox
            label={label}
            placeholder={placeholder}
            value={content}
            maxLength={maxLength}
            nonMultilineLabelFontSize={nonMultilineLabelFontSize}
            nonMultilineLabelMarginLeft={nonMultilineLabelMarginLeft}
            onBlur={handleBlur}
            onFocus={handleFocus}
            onChange={handleOnChange}
            {...props}
        />
    )
}

export default ConcurrentInternalControlTextbox