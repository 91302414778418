// React and other libraries
import React, { useState, useContext, useEffect } from "react";
import { useParams } from "react-router-dom";
import sortBy from "lodash/sortBy";
import { useDeepCompareEffect } from "react-use";
import { IconButton, Box } from "@mui/material";

import { Comment, CommentResolved } from "@ais/assets";
import { UNDERSTANDING_OF_ENTITY } from "@ais/constants";
import {
  getRowClassName,
  scotabdsColumns,
  scotabdsTableStyles,
  CLANestedDataGrid,
} from "@ais/components";
import { UnderstandingOfEntityContext } from "@ais/contexts";
import { useKeyControlDrawerContext } from "@contexts/KeyControlDrawer/KeyControlDrawerContext";
import { useInternalControlsContext } from "@contexts/InternalControls/InternalControlsContext";
import { ConcurrentInternalControlTextbox } from "../../ConcurrentInternalControlTextbox";
import { ConcurrentKeyControlsButton } from "../../ConcurrentKeyControlsButton.jsx";
import { useUpdateMyPresence } from '@views/InternalControl/RoomProvider.jsx';
import { useOthers } from '@views/InternalControl/store/users.js';
import VFRenderedFieldWrapper from '../../VFRenderedFieldWrapper.jsx'
import { sortReviewerSignoffAsc } from '@utilities/dateHelpers.js';
import "./styles.css";
import { isSignoffLogicEnabled } from '@utilities/signoffUtility.js';


const UOEScotabdsConstants = UNDERSTANDING_OF_ENTITY.SCOTABDS;
const UOEComments = UNDERSTANDING_OF_ENTITY.COMMENTS;
const UOEInfoProcessingConstants =
  UNDERSTANDING_OF_ENTITY.INFORMATION_PROCESSING;

const renderCustomCells = (columnData, setOpenComment, onKeyControlClick, updateMyPresence, others) => {
  const onClick = (scotabdId) => {
    setOpenComment(scotabdId);
  };

  const cellRendering = {
    [UOEScotabdsConstants.COMMENTS_FIELD]: {
      ...columnData,
      renderCell: (props) => {
        const { ICComment, id } = props.row;
        return (
          <IconButton sx={{ p: 0 }} onClick={() => onClick(id)}>
            {!!ICComment && ICComment.length > 0 ? (
              <CommentResolved />
            ) : (
              <Comment />
            )}
          </IconButton>
        );
      },
    },
    [UOEScotabdsConstants.KEY_CONTROLS_FIELD]: {
      ...columnData,
      renderCell: (params) => {
        const isRequired = (assessments = []) => {
          if (assessments.some((assessment) =>            
            /** InherentRisk === Significant && ControlRisk === High */
            assessment.InherentRiskAssessmentId === UOEScotabdsConstants.INHERENT_RISK.SIGNIFICANT &&
            assessment.ControlRiskAssessmentId === UOEScotabdsConstants.CONTROL_RISK.HIGH
          )) {
            return true;
          } else if (assessments.some((assessment) =>
            /** InherentRisk === Significant && ControlRisk === Moderate */            
            assessment.InherentRiskAssessmentId === UOEScotabdsConstants.INHERENT_RISK.SIGNIFICANT &&
            assessment.ControlRiskAssessmentId === UOEScotabdsConstants.CONTROL_RISK.MODERATE
          )) {
            return true;
          } else if (assessments.some((assessment) =>
            /** InherentRisk === Significant && ControlRisk === Low */
            assessment.InherentRiskAssessmentId === UOEScotabdsConstants.INHERENT_RISK.SIGNIFICANT &&
            assessment.ControlRiskAssessmentId === UOEScotabdsConstants.CONTROL_RISK.LOW
          )) {
            return true;
          } else if (assessments.some((assessment) =>
            /** InherentRisk === High && ControlRisk === High */
            assessment.InherentRiskAssessmentId === UOEScotabdsConstants.INHERENT_RISK.HIGH &&
            assessment.ControlRiskAssessmentId === UOEScotabdsConstants.CONTROL_RISK.HIGH
          )) {
            return true;
          } else if (assessments.some((assessment) =>
            /** InherentRisk === High && ControlRisk === Moderate */
            assessment.InherentRiskAssessmentId === UOEScotabdsConstants.INHERENT_RISK.HIGH &&
            assessment.ControlRiskAssessmentId === UOEScotabdsConstants.CONTROL_RISK.MODERATE
          )) {
            return true;
          } else if (assessments.some((assessment) =>
            /** InherentRisk === High && ControlRisk === Low */
            assessment.InherentRiskAssessmentId === UOEScotabdsConstants.INHERENT_RISK.HIGH &&
            assessment.ControlRiskAssessmentId === UOEScotabdsConstants.CONTROL_RISK.LOW
          )) {
            return true;
          } else if (assessments.some((assessment) =>
            /** InherentRisk === Moderate && ControlRisk === Moderate */
            assessment.InherentRiskAssessmentId === UOEScotabdsConstants.INHERENT_RISK.MODERATE &&
            assessment.ControlRiskAssessmentId === UOEScotabdsConstants.CONTROL_RISK.MODERATE
          )) {
            return true;
          } else if (assessments.some((assessment) =>
            /** InherentRisk === Moderate && ControlRisk === Low */
            assessment.InherentRiskAssessmentId === UOEScotabdsConstants.INHERENT_RISK.MODERATE &&
            assessment.ControlRiskAssessmentId === UOEScotabdsConstants.CONTROL_RISK.LOW
          )) {
            return true;
          } else if (assessments.some((assessment) =>
            /** InherentRisk === Low && ControlRisk === Moderate */
            assessment.InherentRiskAssessmentId === UOEScotabdsConstants.INHERENT_RISK.LOW &&
            assessment.ControlRiskAssessmentId === UOEScotabdsConstants.CONTROL_RISK.MODERATE
          )) {
            return true;
          } else if (assessments.some((assessment) =>
            /** InherentRisk === Low && ControlRisk === Low */
            assessment.InherentRiskAssessmentId === UOEScotabdsConstants.INHERENT_RISK.LOW &&
            assessment.ControlRiskAssessmentId === UOEScotabdsConstants.CONTROL_RISK.LOW
          )) {
            return true;
          } else {
            return false;
          }
        };

        const getProjectRiskStatus = (row) => {
          const riskAssessments = row?.ProjectRisks?.map((risk) => { return { InherentRiskAssessmentId: risk.InherentRiskAssessmentId, ControlRiskAssessmentId: risk.ControlRiskAssessmentId }}).filter((risk) => risk);
          const assertionAssessments = row?.OtherRelevantAssertionRisks?.map((assertion) => { return { InherentRiskAssessmentId: assertion.InherentRiskAssessmentId, ControlRiskAssessmentId: assertion.ControlRiskAssessmentId }}).filter((assertion) => assertion);
          const assessments = riskAssessments.concat(assertionAssessments);
          
          return isRequired(assessments);
        };

        const isKeyControlRequired = getProjectRiskStatus(params?.row);

        if(isKeyControlRequired !== null) {
          return (
            <VFRenderedFieldWrapper
              className="field__wrapper rowButton"
              isLockedByUser={others.find((user) => user.presence.focusedId === `${params.id}-key-controls`)}
            >
              <ConcurrentKeyControlsButton
                onClick={() => onKeyControlClick(params)}
                customFormObjectId={`${params.id}-key-controls`}
                isKeyControlRequired={isKeyControlRequired}
                disabled={!!others.find((user) => user.presence.focusedId === `${params.id}-key-controls`)}
              />
            </VFRenderedFieldWrapper>
          );
        }
      },
    },
    default: columnData,
  };

  return cellRendering[columnData.field] || cellRendering.default
};

const getStringedAccountBalanceNames = (accountBalanceTypeList) => {
  const accBalanceNamesArray =
    accountBalanceTypeList?.map((abt) => abt.AccountBalanceName) || [];
  const stringedAccBalanceNames = accBalanceNamesArray.join(", ");

  // attach parentheses at both ends when not empty string
  return stringedAccBalanceNames === ""
    ? stringedAccBalanceNames
    : `(${stringedAccBalanceNames})`;
};

export const UOEScotabdsTable = (props) => {
  const {
    auditAreaIndex,
    auditAreaId,
    scotabds,
    projectScopeAuditAreaId,
    saveProjectScopeAuditAreaFields,
    signOffList = [],
    isProjectFinalized
  } = props;

  const { formattedInternalControls, changeScotabdLevelField } =
    useInternalControlsContext();
  const scotabdsHistory = formattedInternalControls?.History[0]?.ProjectScopeAuditAreaSCOTABDHistory.filter((psaash) => psaash.AuditAreaId === auditAreaId) || [];
  const updateMyPresence = useUpdateMyPresence();
  const others = useOthers();

  const { projectFormId } = useParams();
  const {
    state: { openComment },
    setOpenComment,
  } = useContext(UnderstandingOfEntityContext);

  const [scotabdDataRows, setScotabdDataRows] = useState([]);
  const [modifiedColumns, setModifiedColumns] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);

  const handleAdditionalCollapseTrigger = (newIds) => {
    setExpandedRows(newIds);
  };

  const { openDrawer, initializeAuditAreaSCOTABDProjectKeyControl } =
    useKeyControlDrawerContext();

  const findIndexes = (scotabdId) => {
    const foundAuditAreaIndex =
      formattedInternalControls?.ProjectScopeAuditArea?.findIndex(
        (item) => item.ProjectScopeAuditAreaId === projectScopeAuditAreaId,
      );
    const foundScotabdIndex = scotabds?.findIndex(
      (item) => item.ProjectScopeAuditAreaSCOTABDId === scotabdId,
    );

    return [foundAuditAreaIndex, foundScotabdIndex];
  };

  const handleBlur = (event, scotabdId, key) => {
    const [foundAuditAreaIndex, foundScotabdIndex] = findIndexes(scotabdId);

    // Check for nullish values (null or undefined) first
    if (
      foundAuditAreaIndex == null ||
      foundAuditAreaIndex === -1 ||
      foundScotabdIndex == null ||
      foundScotabdIndex === -1
    ) {
      return;
    }

    const currentCollapsedRecord =
      formattedInternalControls?.ProjectScopeAuditArea[foundAuditAreaIndex]
        .ProjectScopeAuditAreaSCOTABDS[foundScotabdIndex];
    const currentInputValue = currentCollapsedRecord[key];

    if (currentInputValue === event.target.value) return;

    changeScotabdLevelField(
      foundAuditAreaIndex,
      foundScotabdIndex,
      key,
      event.target.value,
    );

    const otherCommentKey =
      key === UNDERSTANDING_OF_ENTITY.KEYS.ICINFOCOMMENT
        ? UNDERSTANDING_OF_ENTITY.KEYS.ICCOMMENT
        : UNDERSTANDING_OF_ENTITY.KEYS.ICINFOCOMMENT;

    const payload = {
      ProjectScopeAuditAreaSCOTABDId: scotabdId,
      [key]: event.target.value === null ? "" : event.target.value,
      [otherCommentKey]:
        currentCollapsedRecord[otherCommentKey] === null
          ? ""
          : currentCollapsedRecord[otherCommentKey],
    };

    const prevInternalControlsData = { ...formattedInternalControls };

    saveProjectScopeAuditAreaFields(payload, prevInternalControlsData);
  };

  const toggleComment = (id) => {
    setOpenComment(id);

    if (!expandedRows.includes(id)) {
      setExpandedRows([...expandedRows, id]);
    }
  };

  const updateScotabdsData = (scotabds) => {
    const latestReviewersAsc = sortReviewerSignoffAsc(signOffList);
    return scotabds.map((scotabd, index) => {
      const shouldHighlight = {
        ICComment: false,
        ICInfoProcessingAndControlActivity: false,
      };
      Object.keys(shouldHighlight).forEach((key) => {
        const _history = scotabdsHistory.find(
          (sh) => sh.ProjectScopeAuditAreaSCOTABDId === scotabd.ProjectScopeAuditAreaSCOTABDId,
        );
        if (scotabdsHistory.length && _history) {
          if (scotabd[key] !== _history[key]) {
            const dateModified =  new Date(formattedInternalControls.ValidFrom).getTime();
            const isSignoffEnabled = isSignoffLogicEnabled(isProjectFinalized, dateModified);
            const _shouldHighlight =
              new Date(latestReviewersAsc[latestReviewersAsc?.length - 1]?.signOffDate).getTime() < dateModified;
            shouldHighlight[key] = _shouldHighlight && isSignoffEnabled;
          }
        }
      });
      return {
        ...scotabd,
        id: `AuditArea-${auditAreaIndex}-Scotabd-${index}`,
        index,
        stringAccountBalanceTypeList: getStringedAccountBalanceNames(
          scotabd.AccountBalanceTypeList,
        ),
        ExpandedPanel: (
          <ExpandedPanel
            index={index}
            scotabd={scotabd}
            auditAreaId={auditAreaId}
            auditAreaIndex={auditAreaIndex}
            handleBlur={handleBlur}
            openComment={openComment}
            shouldHighlight={shouldHighlight}
            {...props}
          />
        )
      }
    });
  };

  const sortScotabdsData = (scotabds) => {
    return sortBy(scotabds, UOEScotabdsConstants.DISPLAY_ORDER);
  };

  const onKeyControlClick = (rowData) => {
    const {
      ProjectScopeAuditAreaSCOTABDId,
      ProjectScopeAuditAreaSCOTABDProjectKeyControl,
    } = rowData?.row;
    updateMyPresence({ focusedId: `${rowData.id}-key-controls`, type: 'custom' })
    openDrawer(`${rowData.id}-key-controls`);
    initializeAuditAreaSCOTABDProjectKeyControl({
      projectFormId,
      projectScopeAuditAreaId,
      internalControlData: formattedInternalControls,
      projectScopeAuditAreaSCOTABDId: ProjectScopeAuditAreaSCOTABDId,
      projectScopeAuditAreaSCOTABDProjectKeyControl:
        ProjectScopeAuditAreaSCOTABDProjectKeyControl,
    });
  };

  // Format SCOTABD rows and columns
  useDeepCompareEffect(() => {
    if (scotabds?.length) {
      const sortedScotabdsData = sortScotabdsData(scotabds);
      const formattedScotabdsData = updateScotabdsData(sortedScotabdsData);
      setScotabdDataRows(formattedScotabdsData);
      const newModifiedColumn = scotabdsColumns.map((columnData) =>
        renderCustomCells(columnData, toggleComment, onKeyControlClick, updateMyPresence, others),
      );

      setModifiedColumns(newModifiedColumn);
    }
  }, [JSON.stringify(scotabds), openComment, signOffList]);

  return (
    <CLANestedDataGrid
      sx={scotabdsTableStyles}
      columns={modifiedColumns}
      rows={scotabdDataRows}
      expandedRow={expandedRows}
      headerHeight={62}
      getRowClassName={(params) =>
        getRowClassName(
          params,
          scotabdDataRows,
          expandedRows,
          2,
          auditAreaIndex,
        )
      }
      handleAdditionalCollapseTrigger={handleAdditionalCollapseTrigger}
      hideFooter={true}
    />
  );
};

const ExpandedPanel = ({
  scotabd,
  auditAreaIndex,
  index,
  handleBlur,
  openComment,
  isProjectFinalized,
  shouldHighlight = {
    ICComment: false,
    ICInfoProcessingAndControlActivity: false,
  }
}) => {
  const updateMyPresence = useUpdateMyPresence();
  const others = useOthers();

  return (
    <Box sx={{ ml: "60px", my: "20px" }}>
      <VFRenderedFieldWrapper
        className="field__wrapper"
        isLockedByUser={others.find((user) => user.presence.focusedId === `AuditArea-${auditAreaIndex}-Scotabd-${index}`)}
      >
        <ConcurrentInternalControlTextbox
          label={UOEInfoProcessingConstants.LABEL}
          placeholder={UOEInfoProcessingConstants.PLACEHOLDER}
          value={scotabd.ICInfoProcessingAndControlActivity || ""}
          maxLength={null}
          onBlur={(e, _, focusedId) => {
            handleBlur(
              e,
              scotabd.ProjectScopeAuditAreaSCOTABDId,
              UNDERSTANDING_OF_ENTITY.KEYS.ICINFOCOMMENT,
            )
            updateMyPresence({ focusedId, type: 'custom' })
          }}
          onFocus={(event, _, focusedId) => updateMyPresence({ focusedId, type: 'custom' })}
          customFormObjectId={`AuditArea-${auditAreaIndex}-Scotabd-${index}`}
          disabled={isProjectFinalized || !!others.find((user) => user.presence.focusedId === `AuditArea-${auditAreaIndex}-Scotabd-${index}`)}
        />
      </VFRenderedFieldWrapper>
      {openComment === `AuditArea-${auditAreaIndex}-Scotabd-${index}` && (
        <VFRenderedFieldWrapper
          className="field__wrapper"
          isLockedByUser={others.find((user) => user.presence.focusedId === `AuditArea-${auditAreaIndex}-Scotabd-${index}-comment`)}
        >
          <ConcurrentInternalControlTextbox
            label={UOEComments.LABEL}
            placeholder={UOEComments.PLACEHOLDER}
            value={scotabd.ICComment || ""}
            nonMultilineLabelFontSize={UOEComments.NON_MULTILINE_LABEL_FONT_SIZE}
            nonMultilineLabelMarginLeft={
              UOEComments.NON_MULTILINE_LABEL_MARGIN_LEFT
            }
            onBlur={(e, _, focusedId) => {
              handleBlur(
                e,
                scotabd.ProjectScopeAuditAreaSCOTABDId,
                UNDERSTANDING_OF_ENTITY.KEYS.ICCOMMENT,
              )
              updateMyPresence({ focusedId, type: 'custom' })
            }}
            onFocus={(event, _, focusedId) => updateMyPresence({ focusedId, type: 'custom' })}
            sx={{
              marginTop: "20px",
            }}
            customFormObjectId={`AuditArea-${auditAreaIndex}-Scotabd-${index}-comment`}
            disabled={isProjectFinalized || !!others.find((user) => user.presence.focusedId === `AuditArea-${auditAreaIndex}-Scotabd-${index}-comment`)}
          />
        </VFRenderedFieldWrapper>
      )}
    </Box>
  );
};

export default UOEScotabdsTable;
