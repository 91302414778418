import formApi from '@utilities/claApiForm';
import { useQuery } from "@tanstack/react-query";

const fetchRiskAssessmentOptions = async (projectId, projectFormId, addRiskFromProjectForm) => {
    try {
        const { data } = await formApi.get(`/riskassessment/options/${projectId}?AddRiskFromProjectForm=${addRiskFromProjectForm}&ProjectFormId=${projectFormId}&ProjectId=${projectId}`);
        return { status: 200, data };
    } catch (exception) {
        return { status: 400, message: exception };
    }
};

// EXPORTS ====================================================================
export const useGetRiskAssessmentOptions = (projectId, projectFormId, addRiskFromProjectForm) => {
    return useQuery(
        ["RiskAssessmentOptions", projectId, projectFormId, addRiskFromProjectForm],
        () => fetchRiskAssessmentOptions(projectId, projectFormId, addRiskFromProjectForm),
        {
            enabled: !!projectId || !!projectFormId
        }
    );
};

export const fetchProjectRisksAuditArea = async (projectFormId, projectId) => {
    try {
        const { data } = await formApi.get(`/riskassessment/auditareas/${projectFormId}/${projectId}`)
        return data?.map(auditArea => ({
            projectScopeAuditAreaId: auditArea.ProjectScopeAuditAreaId,
            projectScopeId: auditArea.ProjectScopeId,
            auditAreaId: auditArea.AuditAreaId,
            auditAreaName: auditArea.AuditAreaName,
            riskAssessmentComment: auditArea.RiskAssessmentComment,
            validFrom: auditArea.ValidFrom
        }));
    }
    catch (error) {
        console.error(error)
    }
}

export const getEnabledInherentRiskAssessments = async () => {
    try {
        const { data } = await formApi.get('/riskassessmentsummaries/enabled-inherent-risk-assessments')
        return data?.map(risk => ({
            inherentRiskAssessmentId: risk.InherentRiskAssessmentId,
            inherentRiskAssessmentName: risk.InherentRiskAssessmentName
        }));
    }
    catch (error) {
        console.error(error)
    }
}


export const getEnabledControlAssessments = async () => {
    try {
        const { data } = await formApi.get('/riskassessmentsummaries/enabled-control-risk-assessments')
        return data?.map(risk => ({
            controlRiskAssessmentId: risk.ControlRiskAssessmentId,
            controlRiskAssessmentName: risk.ControlRiskAssessmentName
        }));
    }
    catch (error) {
        console.error(error)
    }
}

export const getPreliminaryAuditScopingAnswerByProjectFormId = async (projectFormId, projectId) => {
    try {
        const { data } = await formApi.get(`/riskassessmentsummaries/${projectFormId}/display/${projectId}`)
        return data;
    }
    catch (error) {
        console.error(error)
    }
}
const getRiskAssessmentsByProjectFormId = async (projectId, projectFormId) => {
    try {
        const { data } = await formApi.get(`/riskassessmentsummaries/risk-assessments-related-by-project-units/${projectId}/${projectFormId}`);
        return { status: 200, data };
    } catch (exception) {
        return { status: 400, message: exception };
    }
}

const riskAssessmentService = {
    getRiskAssessmentsByProjectFormId
}

export default riskAssessmentService;
