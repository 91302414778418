import React, {
    useState,
    useEffect
} from 'react';
import { useParams } from "react-router-dom";
import { useQuery, useQueryClient } from "@tanstack/react-query"
import deficienciesServices from '@services/deficiencies';
import {
    useGetProjectDeficiencyCombinations,
    useDeleteProjectDeficiencyCombination,
    usePutProjectDeficiencyCombination,
} from '@services/deficiencyCombination';
import CLAEvaluationOfDeficienciesTable from './CLAEvaluationOfDeficienciesTable';
import { DEFICIENCY_FORM_INSTANCE } from '@constants/forms';
import style from "./CLAEvaluationOfDeficiencies.module.css";
import { getColumns, calculateDetermination } from './helpers';
import { useCustomToast } from '@hooks/useCustomToast';
import { useDisabledGlobalQueryLoading } from "@hooks/index";
import { useFinalizedProject } from '@hooks/useProject';
import { useProjectFormInstanceProvider } from '../../../../providers';
import { isSignoffLogicEnabled } from '@utilities/signoffUtility.js';

const CLAEvaluationOfDeficiencies = (props) => {
    useDisabledGlobalQueryLoading()
    const { projectFormId } = useParams();
    const { EVALUATION_OF_DEFICIENCIES } = DEFICIENCY_FORM_INSTANCE.EN;
    const { projectId, signOffList } = props;
    const isProjectFinalized = useFinalizedProject(projectId);
    const [rows, setRows] = useState([]);
    const [deficiencyCombinations, setDeficiencyCombinations] = useState([]);
    const [deficiencies, setDeficiencies] = useState([]);
    const [updateDeficiencyLoading, setUpdateDeficiencyLoading] = useState(false)
    const [updateDeficiencySuccess, setUpdateDeficiencySuccess] = useState(false)
    const [updateDeficiencyError, setUpdateDeficiencyError] = useState(false)
    const [highlightedDeficiencyIds, setHighlightedDeficiencyIds] = useState([])
    const [highlightedDeficiencyCombinationIds, setHighlightedDeficiencyCombinationIds] = useState([])

    const { unitIds } = useProjectFormInstanceProvider();

    const {
        data: deficiencyCombinationsData,
        isError: deficiencyCombinationIsError,
    } = useGetProjectDeficiencyCombinations(projectId, unitIds, projectFormId);

    const queryClient = useQueryClient();

    const {
        mutate: deficiencyCombinationUpdateMutate,
        isSuccess: successDeficiencyCombinationUpdateMutate,
        isLoading: loadingDeficiencyCombinationUpdateMutate
    } = usePutProjectDeficiencyCombination();

    const { data, isError } = useQuery(['deficiencyEvaluationForm', projectId], () => {
        if (projectId) return deficienciesServices.fetchDeficiencies(null, projectId, null, unitIds, projectFormId);
        else return Promise.reject(false);
    });

    const {
        mutate: deficiencyCombinationDeleteMutate, 
    } = useDeleteProjectDeficiencyCombination(projectFormId);

    useCustomToast({
        isLoading: loadingDeficiencyCombinationUpdateMutate || updateDeficiencyLoading,
        isSuccess: successDeficiencyCombinationUpdateMutate || updateDeficiencySuccess,
        isError: deficiencyCombinationIsError || isError || updateDeficiencyError
    })

    const handleCombinationDeleteClick = (projectDeficiencyCombinationId) => {
        deficiencyCombinationDeleteMutate({ projectDeficiencyCombinationId: projectDeficiencyCombinationId, projectId: projectId }, {
            onSuccess: () => queryClient.invalidateQueries(['deficiencyEvaluationForm'])
        });
    };

    const onCheckboxChange = async (isFromCombination, target) => {
        if (!isProjectFinalized) {
            const ProjectDeficiencyId = target.row.ProjectDeficiencyId;
            const updatedRow = {
                ProjectId: projectId,
                ...target.row,
                [target.field]: target.value,
                RequestProjectFormId: projectFormId            
            }

            // Changes on the first two checkboxes resets the dependent checkboxes.
            if (target.field === "IsOccurencePossible" || target.field === "IsMagnitudeMaterial") {
                updatedRow.IsMeritsGovernance = false;
                updatedRow.IsPrudentOfficial = false;
            }
            if (target.field === "IsPrudentOfficial" && updatedRow.IsOccurencePossible) {
                updatedRow.IsMeritsGovernance = false;
            }


            updatedRow.Determination = calculateDetermination(updatedRow);

            if (!updatedRow.ProjectId) updatedRow.ProjectId = projectId;

            if (!isFromCombination) {
                const updatedRows = deficiencies.map((row) => {
                    if (row.ProjectDeficiencyId === updatedRow.ProjectDeficiencyId) {
                        return { ...updatedRow };
                    }
                    return row;
                });

                setDeficiencies(updatedRows);
                try {
                    setUpdateDeficiencyLoading(true);
                    await deficienciesServices.updateDeficiency(ProjectDeficiencyId, updatedRow, projectId);
                    setUpdateDeficiencyLoading(false)
                    setUpdateDeficiencySuccess(true)
                } catch (error) {
                    setUpdateDeficiencyLoading(false)
                    setUpdateDeficiencyError(true)
                }
                queryClient.invalidateQueries(['deficiencyEvaluationForm']);
            } else {
                deficiencyCombinationUpdateMutate({
                    projectDeficiencyCombinationId: updatedRow.ProjectDeficiencyCombinationId,
                    reqBody: updatedRow,
                    projectId: projectId
                }, {
                    onSuccess: () => queryClient.invalidateQueries(['deficiencyEvaluationForm'])
                }
                );
            }
        }
    }

    const handleSignoffs = (signoffs, deficiencies, deficiencyCombinations) => {
        const reviewers = signoffs.filter(signoff => signoff.signOffLevel !== 1);
        const latestSignoffTime = new Date(reviewers[reviewers?.length - 1]?.signOffDate).getTime();

        if (isNaN(latestSignoffTime)){
            setHighlightedDeficiencyIds([])
            setHighlightedDeficiencyCombinationIds([])
            return;
        }
        const highlightedDeficiencyIds = []
        const highlightedDeficiencyCombinationIds = []

        for (const deficiency of deficiencies) {
            const {
                ProjectDeficiencyId,
                Determination,
                IsMagnitudeMaterial,
                IsOccurencePossible,
                IsMeritsGovernance,
                IsPrudentOfficial,
                ProjectDeficiencyHistorical,
                ProjectDeficiencyCombinationProjectDeficiencyHistorical
            } = deficiency;

            const modifiedDate = new Date(deficiency.ValidFrom).getTime();
            let isSignoffEnabled = isSignoffLogicEnabled(isProjectFinalized, modifiedDate); 
            if (!isSignoffEnabled || latestSignoffTime > modifiedDate)
                continue;
            
            if (!ProjectDeficiencyHistorical) highlightedDeficiencyIds.push(ProjectDeficiencyId);

            if (ProjectDeficiencyHistorical && (Determination !== ProjectDeficiencyHistorical.Determination ||
                IsMagnitudeMaterial !== ProjectDeficiencyHistorical.IsMagnitudeMaterial ||
                IsOccurencePossible !== ProjectDeficiencyHistorical.IsOccurencePossible ||
                IsMeritsGovernance !== ProjectDeficiencyHistorical.IsMeritsGovernance ||
                IsPrudentOfficial !== ProjectDeficiencyHistorical.IsPrudentOfficial
            ))
                highlightedDeficiencyIds.push(ProjectDeficiencyId)

            if (!ProjectDeficiencyCombinationProjectDeficiencyHistorical)
                continue;

            const {ProjectDeficiencyCombinationId} = ProjectDeficiencyCombinationProjectDeficiencyHistorical;

            const deficiencyCombination = deficiencyCombinations.find(
                item => item.ProjectDeficiencyCombinationId === ProjectDeficiencyCombinationId
            );

            if (!deficiencyCombination) {
                highlightedDeficiencyIds.push(ProjectDeficiencyId);
                continue
            };

            const isDeficiencyInCombination = deficiencyCombination.Deficiencies.some(def => def.ProjectDeficiencyId === ProjectDeficiencyId)
            
            if (!isDeficiencyInCombination)
                highlightedDeficiencyIds.push(ProjectDeficiencyId);
        }

        for (const combo of deficiencyCombinations) {
            const {
                ProjectDeficiencyCombinationId,
                Determination,
                IsMagnitudeMaterial,
                IsOccurencePossible,
                IsMeritsGovernance,
                IsPrudentOfficial,
                ProjectDeficiencyCombinationHistorical,
                CombinationName,
                CombinationDescription,
                Deficiencies,
                DeficienciesHistory,
            } = combo;

            let dateModified = new Date(combo.ValidFrom).getTime()
            let isSignoffEnabled = isSignoffLogicEnabled(isProjectFinalized, dateModified);
            if (!isSignoffEnabled || latestSignoffTime > dateModified)
                continue;

            const deficiencyIds = Deficiencies.map(x => x.ProjectDeficiencyId);
            const deficiencyHistoryIds = DeficienciesHistory.map(x => x.ProjectDeficiencyId);

            dateModified = new Date(DeficienciesHistory[0].ValidFrom).getTime();
            isSignoffEnabled = isSignoffLogicEnabled(isProjectFinalized, dateModified);
            if (isSignoffEnabled && dateModified > latestSignoffTime) {
                highlightedDeficiencyCombinationIds.push(ProjectDeficiencyCombinationId)
                continue;
            }

            dateModified = new Date(combo.ValidFrom).getTime();
            isSignoffEnabled = isSignoffLogicEnabled(isProjectFinalized, dateModified);
            if (isSignoffEnabled && dateModified > latestSignoffTime && !ProjectDeficiencyCombinationHistorical) {
                highlightedDeficiencyCombinationIds.push(ProjectDeficiencyCombinationId)
            }
            else if (
                ProjectDeficiencyCombinationHistorical &&(Determination !== ProjectDeficiencyCombinationHistorical.Determination ||
                IsMagnitudeMaterial !== ProjectDeficiencyCombinationHistorical.IsMagnitudeMaterial ||
                IsOccurencePossible !== ProjectDeficiencyCombinationHistorical.IsOccurencePossible ||
                IsMeritsGovernance !== ProjectDeficiencyCombinationHistorical.IsMeritsGovernance ||
                IsPrudentOfficial !== ProjectDeficiencyCombinationHistorical.IsPrudentOfficial ||
                CombinationName !== ProjectDeficiencyCombinationHistorical.CombinationName ||
                CombinationDescription !== ProjectDeficiencyCombinationHistorical.CombinationDescription)
            ) {
                highlightedDeficiencyCombinationIds.push(ProjectDeficiencyCombinationId)
            }     
        }
        
        setHighlightedDeficiencyIds(highlightedDeficiencyIds.filter((item, index, list) => list.indexOf(item) == index));
        setHighlightedDeficiencyCombinationIds(highlightedDeficiencyCombinationIds.filter((item, index, list) => list.indexOf(item) == index));
    }

    useEffect(() => {
        if (data && Array.isArray(data?.data)) {
            const { data: deficiencies } = data;
            setDeficiencies(deficiencies);
        }
    }, [data]);

    useEffect(() => {
        if (!deficiencyCombinationsData) return;

        const { status, data } = deficiencyCombinationsData;

        if (status !== 200) {
            setDeficiencyCombinations([]);
            return;
        }

        setDeficiencyCombinations(data);

        const filteredRows = deficiencies.filter(
            deficiency => !data.some(
                combination => combination.Deficiencies.some(
                    comboDeficiency => comboDeficiency.ProjectDeficiencyId === deficiency.ProjectDeficiencyId
                )
            )
        );
        setRows(filteredRows);
        handleSignoffs(signOffList, deficiencies, data);
    }, [deficiencyCombinationsData, deficiencies, signOffList]);

    return (
        <>
            <div className={style['section-header-container']}>
                <div className={style['section-header-title']}>{EVALUATION_OF_DEFICIENCIES.SECTION_HEADER}</div>
            </div>
            <CLAEvaluationOfDeficienciesTable
                columns={getColumns()}
                rows={rows}
                allDeficiencies={deficiencies}
                deficiencyCombinations={deficiencyCombinations}
                onCheckboxChange={onCheckboxChange}
                projectId={projectId}
                onCombinationCheckboxChange={onCheckboxChange}
                onCombinationConfirmDeleteClick={handleCombinationDeleteClick}
                highlightedDeficiencyIds={highlightedDeficiencyIds}
                highlightedDeficiencyCombinationIds={highlightedDeficiencyCombinationIds}
            />
        </>
    )
}

export default CLAEvaluationOfDeficiencies;