import { createContext, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { getFormattedSignOffDate } from '@utilities/dateHelpers';
import projectFormServices from '@services/forms/projectforms.js';
import formServices from '@services/forms/forms';
import { getProjectRiskByAuditAreaAndProjectFormId } from "@services/projectRisk.js";
import { fetchProjectRisksAuditArea } from "@services/riskassessment";
import { isSignoffLogicEnabled } from '@utilities/signoffUtility.js';
import { useFinalizedProject } from '@hooks/useProject';


export const ProjectFormInstanceContext = createContext({});

export const ProjectFormInstanceProvider = ({ children }) => {
  const { projectId, projectFormId } = useParams();
  const isProjectFinalized = useFinalizedProject(projectId);

  const [formTypeId, setFormTypeId] = useState(null);
  const [unitIds, setUnitIds] = useState([]);
  const [isFormFocused, setIsFormFocused] = useState(false);
  const [financialStatementLevelRiskToggle, setFinancialStatementLevelRiskToggle] = useState(false);

  const [financialStatementTableRows, setFinancialStatementTableRows] = useState([]);
  const [loadingProjectRiskAssessmentSummary, setLoadingProjectRiskAssessmentSummary] = useState(false)
  const [errorProjectRiskAssessmentSummary, setErrorProjectRiskAssessmentSummary] = useState(false)
  const [successProjectRiskAssessmentSummary, setSuccessProjectRiskAssessmentSummary] = useState(false)
  const [isTbLinkDrawerOpened, setIsTbLinkDrawerOpened] = useState(false);
  const [isFinancialStatementExpanded, setIsFinancialStatementExpanded] = useState(false);
  const [isAssertionLevelRiskExpanded, setIsAssertionLevelRiskExpanded] = useState(false);
  const [isInitialScopingConsiderationExpanded, setIsInitialScopingConsiderationExpanded] = useState(false);
  const [isDeterminingSCOTABDSExpanded, setIsDeterminingSCOTABDSExpanded] = useState(false);
  const [isCommonIdentifiedRisksExpanded, setIsCommonIdentifiedRisksExpanded] = useState(false);
  const [isGeneralLedgerExpanded, setIsGeneralLedgerExpanded] = useState(false);
  const [isCurrentPeriodExpanded, setIsCurrentPeriodExpanded] = useState(false);
  const [isTrendingByMonthExpanded, setIsTrendingByMonthExpanded] = useState(false);
  const [isNonCustomSectionExpanded, setIsNonCustomSectionExpanded] = useState([]); 
  const [signOffList, setSignOffList] = useState([]);
  const [answerList, setAnswerList] = useState([]);
  const [unitAnswerList, setUnitAnswerList] = useState([]);
  const [modifiedAfterSignOff, setModifiedAfterSignOff] = useState([]);
  const [modifiedRiskAssessmentSummaryAfterSignOff, setModifiedRiskAssessmentSummaryAfterSignOff] = useState([]);
  const [auditAreaScotabdRisk, setAuditAreaScotabdRisk] = useState([]);

  const handleUpdateUnitIds = (unitIds) => {
    setUnitIds(unitIds ?? []);
  };

  const handleUpdateIsFormFocused = (isFormFocused) => {
    setIsFormFocused(isFormFocused);
  };

  const handleUpdateFinancialStatementLevelRiskToggle = () => {
    setFinancialStatementLevelRiskToggle(prev => !prev)
  };
  const handleIsFinancialStatementExpanded = (isExpanded) => {
    setIsFinancialStatementExpanded(isExpanded)
  };
  const handleIsAssertionLevelRiskExpanded = (isExpanded) => {
    setIsAssertionLevelRiskExpanded(isExpanded)
  };
  const handleIsInitialScopingConsiderationExpanded = (isExpanded) => {
    setIsInitialScopingConsiderationExpanded(isExpanded)
  };
  const handleIsDeterminingSCOTABDSExpanded = (isExpanded) => {
    setIsDeterminingSCOTABDSExpanded(isExpanded)
  };
  const handleIsCommonIdentifiedRisksExpanded = (isExpanded) => {
    setIsCommonIdentifiedRisksExpanded(isExpanded)
  };

  const handleIsGeneralLedgerExpanded = (isExpanded) => {
    setIsGeneralLedgerExpanded(isExpanded)
  }
  const handleIsCurrentPeriodExpanded = (isExpanded) => {
    setIsCurrentPeriodExpanded(isExpanded)
  }
  const handleIsTrendingByMonthExpanded= (isExpanded) => {
    setIsTrendingByMonthExpanded(isExpanded)
  }
  const handleIsNonCustomSectionExpanded = (section, isExpanded) => {
    setIsNonCustomSectionExpanded(prevState => {
      const i = prevState.findIndex(item => item.section === section)
      if(i !== -1){
        return [
          ...prevState.slice(0,i),
          { ...prevState[i], isExpanded },
          ...prevState.slice(i+1)
        ]
      }
      else return [...prevState, { section, isExpanded }]
    })
  }
  const handleIsLockedSection = (sectionId) => {
    const section = isNonCustomSectionExpanded.find(item => item.section === sectionId)
    return section ? section.isExpanded : false
  }
  const sortSignOffListAsc = (list) => list.sort((a, b) => new Date(a.signOffDate).getTime() > new Date(b.signOffDate).getTime() ? 1 : -1);

  const riskAssessmentIdentifyModifiedAnswerAfterSignOff = useCallback(async (auditAreaScotabdRiskData = []) => {
    const answerValue = await latestModifiedAnswerAfterSignOff(auditAreaScotabdRiskData);

    const signOffResult = await projectFormServices.getSignOffByProjectFormId(projectFormId, projectId);
    const sortSignOff = sortSignOffListAsc(signOffResult || []);

    let lastSignOffDate = null;
    if (sortSignOff && sortSignOff?.length > 0) {
      const reviewerSignOffs = sortSignOff.filter(
        (signOff) => signOff.signOffLevel !== 1
      );
      lastSignOffDate =
        getFormattedSignOffDate(reviewerSignOffs[reviewerSignOffs?.length - 1]?.signOffDate);
    }

    listOfModifiedAnswerAfterSignOff(lastSignOffDate, answerValue)
  }, [signOffList]);

  const latestModifiedAnswerAfterSignOff = async (auditAreaScotabdRiskData) => {
    if (formTypeId === 4) {
      let answerResult = [];

      const { data } = await getProjectRiskByAuditAreaAndProjectFormId(projectFormId, projectId);
      const projectRisks = data ?? [];
      answerResult = projectRisks.filter((projectrisk) => { return projectrisk.IsPervasive === true });

      const projectAuditAreaRisk = await fetchProjectRisksAuditArea(projectFormId, projectId) ?? [];
      answerResult.push(...projectAuditAreaRisk);

      if (!!auditAreaScotabdRiskData.length) {
        const { riskInfo } = await formServices.getProjectScopeAuditAreaSCOTABDRiskList(auditAreaScotabdRiskData, projectId);
        const riskInfoData = riskInfo ?? [];
        answerResult.push(...riskInfoData);

        const { otherRelevantAssertions } = await formServices.getBatchProjectScopeAuditAreaSCOTABDOtherRelevantAssertions(auditAreaScotabdRiskData, projectId);
        const otherRelevantAssertionData = otherRelevantAssertions ?? [];
        answerResult.push(...otherRelevantAssertionData);
      }

      return answerResult;
    }
  }

  const listOfModifiedAnswerAfterSignOff = (lastSignOffDate, value) => {
    if (Array.isArray(value) && value.length > 0) {
      const modifiedList = () => {
        let result = [];
        if (!lastSignOffDate) return result;
        if (formTypeId === 4) {
          result = value
            .filter(
              (answer) =>
                answer.validFrom &&
                new Date(getFormattedSignOffDate(lastSignOffDate)).getTime() <
                new Date(getFormattedSignOffDate(answer.validFrom)).getTime() &&
                isSignoffLogicEnabled(isProjectFinalized, new Date(getFormattedSignOffDate(answer.validFrom)).getTime())
            );
        }

        return result;
      }

      setModifiedRiskAssessmentSummaryAfterSignOff(modifiedList);

    }
  }

  const handleIsTbLinkDrawerOpened = (value) => {
    setIsTbLinkDrawerOpened(value);
  }

  return (
    <ProjectFormInstanceContext.Provider
      value={{
        unitIds,
        isFormFocused,
        financialStatementLevelRiskToggle,
        signOffList,
        answerList,
        unitAnswerList,
        modifiedAfterSignOff,
        modifiedRiskAssessmentSummaryAfterSignOff,
        formTypeId,
        auditAreaScotabdRisk,
        financialStatementTableRows,
        isFinancialStatementExpanded,
        isAssertionLevelRiskExpanded,
        isInitialScopingConsiderationExpanded,
        isDeterminingSCOTABDSExpanded,
        isCommonIdentifiedRisksExpanded,
        isGeneralLedgerExpanded,
        isCurrentPeriodExpanded,
        isTrendingByMonthExpanded,
        isNonCustomSectionExpanded,
        loadingProjectRiskAssessmentSummary,
        successProjectRiskAssessmentSummary,
        errorProjectRiskAssessmentSummary,
        isTbLinkDrawerOpened,
        actions: {
          riskAssessmentIdentifyModifiedAnswerAfterSignOff,
          updateUnitIds: handleUpdateUnitIds,
          updateIsFormFocused: handleUpdateIsFormFocused,
          updateFinancialStatementLevelRiskToggle: handleUpdateFinancialStatementLevelRiskToggle,
          updateIsFinancialStatementExpanded: handleIsFinancialStatementExpanded,
          updateIsAssertionLevelRiskExpanded: handleIsAssertionLevelRiskExpanded, 
          updateIsInitialScopingConsiderationExpanded: handleIsInitialScopingConsiderationExpanded, 
          updateIsDeterminingSCOTABDSExpanded: handleIsDeterminingSCOTABDSExpanded, 
          updateIsCommonIdentifiedRisksExpanded: handleIsCommonIdentifiedRisksExpanded, 
          updateIsGeneralLedgerExpanded: handleIsGeneralLedgerExpanded,
          updateIsCurrentPeriodExpanded: handleIsCurrentPeriodExpanded,
          updateIsTrendingByMonthExpanded: handleIsTrendingByMonthExpanded,
          updateIsTbLinkDrawerOpened: handleIsTbLinkDrawerOpened,
          updateIsNonCustomSectionExpanded: handleIsNonCustomSectionExpanded,
        },
        setStates: {
          setSignOffList,
          setAnswerList,
          setUnitAnswerList,
          setModifiedAfterSignOff,
          setModifiedRiskAssessmentSummaryAfterSignOff,
          setFormTypeId,
          setAuditAreaScotabdRisk,
          setFinancialStatementTableRows,
          setLoadingProjectRiskAssessmentSummary,
          setSuccessProjectRiskAssessmentSummary,
          setErrorProjectRiskAssessmentSummary
        }
      }}
    >
      {children}
    </ProjectFormInstanceContext.Provider>
  )
};