import React, { useEffect, useState, useRef } from 'react';
import {
  Button,
  Grid,
  Stack,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Box
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import * as Constants from '@constants/index';
import { colors } from '@ais/theme';
import {CLACurrencyField} from '@ais/forms';

import styles from '../CLAProjectScopeAuditArea.module.css';
import SCOTABDsDefaultTable from './SCOTABDsDefaultTable';
import { sortReviewerSignoffAsc, getLatestAddedData, getLatestDeletedData } from '@utilities/dateHelpers.js';
import { isSignoffLogicEnabled } from '@utilities/signoffUtility.js';
import { useFinalizedProject } from '@hooks/useProject';

const { EN, BUTTON } = Constants.TRIAL_BALANCE;

const { AUDIT_AREA, FINANCIAL_STATEMENT, SCOTABDS } = EN;

const editButtonStyles = {
  textDecoration: 'underline',
  fontSize: '16px',
  color: colors.riptide[500],
  '&:hover': {
    textDecoration: 'underline',
    backgroundColor: 'transparent',
  },
};

const CurrencyFormat = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

export const ButtonComponent = (props) => {
  const { label, buttonName, onClick, disabled, shouldHighlight } = props;

  return (
    <div className={styles.button__wrapper}>
      <label>{label}</label>
      <Box sx={shouldHighlight ? { borderStyle: 'dashed', borderWidth: '4px', borderColor: 'red' }: {}}>
      <Button
        onClick={onClick}
        sx={{
          color: disabled ? '#969696' : 'black',
          fontWeight: 'bold',
        }}
        startIcon={
          <AddIcon
            sx={{
              color: disabled ? '#969696 !important' : '',
            }}
            className={styles.pageButtonAddFormIcon}
          />
        }
        variant="page-button-add-form"
        disabled={!!disabled}
      >
        {buttonName}
      </Button>
      </Box>
    </div>
  );
};

export const AuditAreaRow = (props) => {
  const {
    projectId,
    trialBalances,
    auditAreaData,
    scotabdsData,
    setVisible,
    setSelectedProjectScopeAuditAreaId,
    setSelectedAuditAreaId,
    isLast,
    handleCurrentAuditArea,
    fetchProjectScopeAuditAreaData,
    financialStatements,
    disabled,
    signOffList,
    deletedFinancialStatements
  } = props;

  const isProjectFinalized = useFinalizedProject(projectId);

  const [fs, setFs] = useState([]);
  const [isRedDotVisible, setIsRedDotVisible] = useState(false)
  const [tableBodyHeight, setTableBodyHeight] = useState(0);

  const fsTable = useRef(null);
  const [financialTableRedVisible, setFinancialTableRedVisible] = useState(false)
  const [financialTableButtonHighlightVisible, setFinancialTableButtonHighlightVisible] = useState(false)
  const [isScotabdHighlighted, setIsScotabdHighlighted] = useState(false)

  const handleScotabdClick = () => {
    if (setSelectedProjectScopeAuditAreaId && setSelectedAuditAreaId && auditAreaData) {
      setSelectedProjectScopeAuditAreaId(auditAreaData.ProjectScopeAuditAreaId);
      setSelectedAuditAreaId(auditAreaData.AuditAreaId);
      setVisible.scotabds(true);
    }
  };

  useEffect(() => {
    if (auditAreaData) { 
      const selectedFinancial = financialStatements?.find(
        (financial) => financial.ProjectScopeAuditAreaId === auditAreaData.ProjectScopeAuditAreaId
      );
      if (JSON.stringify(selectedFinancial) !== JSON.stringify(fs)) { 
        setFs(selectedFinancial?.FinancialStatementLineItems);
      }
    }
  }, [financialStatements, auditAreaData, signOffList]);

  useEffect(() => {
    const latestReviewersAsc = sortReviewerSignoffAsc(signOffList)
    let shouldHighlight = false
    let dateModified = new Date();
    let isSignoffEnabled = false;
    if(scotabdsData?.length){
      dateModified = getLatestAddedData(scotabdsData);
      isSignoffEnabled = isSignoffLogicEnabled(isProjectFinalized, dateModified);
      shouldHighlight = new Date(latestReviewersAsc[latestReviewersAsc?.length - 1]?.signOffDate).getTime() < dateModified
      setIsScotabdHighlighted(isSignoffEnabled && shouldHighlight)
    }

    if((deletedFinancialStatements?.length >= 0)  && (fs?.length >= 0)){
      dateModified = getLatestDeletedData(deletedFinancialStatements);
      isSignoffEnabled = isSignoffLogicEnabled(isProjectFinalized, dateModified);
      shouldHighlight = new Date(latestReviewersAsc[latestReviewersAsc?.length - 1]?.signOffDate).getTime() < dateModified
      const dfs = deletedFinancialStatements?.filter((deletedFinancial) => deletedFinancial.ProjectScopeAuditAreaId === auditAreaData.ProjectScopeAuditAreaId)
      setFinancialTableButtonHighlightVisible((fs.length === 0) && (dfs.length > 0) && shouldHighlight && isSignoffEnabled)
    }

    dateModified = new Date(auditAreaData?.ValidFrom).getTime();

    shouldHighlight = new Date(latestReviewersAsc[latestReviewersAsc?.length - 1]?.signOffDate).getTime() < dateModified
    isSignoffEnabled = isSignoffLogicEnabled(isProjectFinalized, dateModified);
    setIsRedDotVisible(isSignoffEnabled && shouldHighlight)

    if(fs?.length){
      dateModified =  getLatestAddedData(fs);
      isSignoffEnabled = isSignoffLogicEnabled(isProjectFinalized, dateModified);
      shouldHighlight = new Date(latestReviewersAsc[latestReviewersAsc?.length - 1]?.signOffDate).getTime() < dateModified
      setFinancialTableRedVisible(isSignoffEnabled && shouldHighlight)
    }
  }, [deletedFinancialStatements, fs, auditAreaData, scotabdsData, signOffList]) 

  useEffect(() => {
    if (fsTable?.current?.children?.length)
      setTableBodyHeight(
        60 + fsTable.current.children[0]?.offsetHeight + fsTable.current.children[1]?.offsetHeight
      );
  }, [fs]);

  return (
    <>
      <Grid
        container
        sx={
          auditAreaData
            ? {
              borderBottom: !isLast ? `1px solid ${colors.gray[40]}` : 0,
              paddingBottom: '30px',
              marginBottom: '30px',
            }
            : {}
        }
      >
        <Grid item container xs={12} columnSpacing={6} sx={{ marginBottom: '50px' }}>
          <Grid item xs={6}>
            {auditAreaData ? (
              <Stack sx={isRedDotVisible ? { borderStyle: 'dashed', borderWidth: '4px', borderColor: 'red' }: {}}>
                <Typography variant="label" sx={{ fontSize: '14px', fontWeight: 600 }}>
                  {AUDIT_AREA}
                </Typography>
                <Grid container>
                  <Grid item xs>
                    {auditAreaData?.AuditAreaName}
                  </Grid>
                  <Grid>
                    <Button disabled={disabled} sx={editButtonStyles} onClick={() => setVisible.auditArea(true)}>
                      Edit
                    </Button>
                  </Grid>
                </Grid>
              </Stack>
            ) : (
              <ButtonComponent
                disabled={disabled}
                data-test="audit-area-button"
                label={AUDIT_AREA}
                buttonName={BUTTON.AUDIT_AREA}
                onClick={() => setVisible.auditArea(true)}
              />
            )}
          </Grid>
          <Grid item xs={6}>
            {fs?.length >= 1 ? (
              <>
                <Grid container>
                  <Grid item xs>
                    <Typography variant="label" sx={{ fontSize: '14px', fontWeight: 600 }}>
                      {FINANCIAL_STATEMENT}
                    </Typography>
                  </Grid>
                  <Grid>
                    <Button
                      disabled={disabled}
                      sx={{
                        textDecoration: 'underline',
                        fontSize: '16px',
                        color: colors.riptide[500],
                        '&:hover': {
                          textDecoration: 'underline',
                          backgroundColor: 'transparent',
                        },
                      }}
                      onClick={async () => {
                        await fetchProjectScopeAuditAreaData(auditAreaData.ProjectScopeAuditAreaId);
                        handleCurrentAuditArea(auditAreaData);
                        setVisible.financialStatements(true);
                      }}
                    >
                      Edit
                    </Button>
                  </Grid>
                </Grid>
                <TableContainer
                  sx={ financialTableRedVisible ? {
                    border: `1px solid ${colors.gray[35]}`,
                    mt: '10px',
                    maxHeight: `${tableBodyHeight}px`, 
                    borderStyle: 'dashed', borderWidth: '4px', borderColor: 'red' 
                  }:{
                    border: `1px solid ${colors.gray[35]}`,
                    mt: '10px',
                    maxHeight: `${tableBodyHeight}px`
                  }}
                >
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow variant="fs-line-table-cell">
                        <TableCell width="40%">{EN.CATEGORY}</TableCell>
                        <TableCell width="24%">{EN.BALANCE}</TableCell>
                        <TableCell width="36%">{EN.NUMBER_OF_TRANSACTION}</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody ref={fsTable}>
                      {fs.map((data, i) => { 
                        return (
                          <TableRow key={i}>
                            <TableCell width="40%">{data.financialStatementCategoryName}</TableCell>
                            <TableCell width="24%">
                              <CLACurrencyField
                                useAsDisplay
                                placeholder="---"
                                allowDecimalPadding
                                emptyInputBehavior="null"
                                defaultValue={data?.balance}
                              />
                            </TableCell>
                            <TableCell width="36%">{data?.transactionCount ?? '0'}</TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ) : (
              <ButtonComponent
                data-test="financial-button"
                label={FINANCIAL_STATEMENT}
                buttonName={BUTTON.FINANCIAL_STATEMENT}
                disabled={!auditAreaData || disabled}
                shouldHighlight={financialTableButtonHighlightVisible}
                onClick={async () => {
                  await fetchProjectScopeAuditAreaData(auditAreaData.ProjectScopeAuditAreaId);
                  handleCurrentAuditArea(auditAreaData);
                  setVisible.financialStatements(true);
                }}
              />
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          {scotabdsData.length ? (
            <Stack>
              <Grid container>
                <Grid item xs>
                  <Typography variant="label" sx={{ fontSize: '14px', fontWeight: 600 }}>
                    {SCOTABDS}
                  </Typography>
                </Grid>
                <Grid>
                  <Button disabled={disabled} sx={editButtonStyles} onClick={handleScotabdClick}>
                    Edit
                  </Button>
                </Grid>
              </Grid>
              <Grid container>
                <SCOTABDsDefaultTable defaultScotabds={scotabdsData} shouldHighlight={isScotabdHighlighted} />
              </Grid>
            </Stack>
          ) : (
            <ButtonComponent
              data-test="scotabds-button"
              label={SCOTABDS}
              buttonName={BUTTON.SCOTABDS}
              onClick={handleScotabdClick}
              disabled={!!!auditAreaData || disabled}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};

AuditAreaRow.defaultProps = {
  auditAreaData: undefined,
  scotabdsData: [],
  isLast: true,
  disabled: false,
};
