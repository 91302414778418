import { useState } from 'react';
import { useWatch, useFormContext } from "react-hook-form"
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  OutlinedInput,
  Checkbox,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import get from 'lodash/get';

import CLARichTextEditor from '@components/Forms/CLARichTextEditor/CLARichTextEditor';
import { checkQuillTextHasValue, convertArrayToStringOptions } from '@components/Forms/helpers';
import { colors } from '@ais/theme';

export const ToggleableTextarea = (props) => {
  const {
    name,
    label,
    placeholder,
    toggleable,
    maxLength,
    minRows,
    maxRows,
    showFormatText,
    advancedDefault = false
  } = props;

  const { setValue, control, formState, trigger } = useFormContext();
  const { errors } = formState;
  const watchValue = useWatch({ control, name }) ?? '';
  const [inputValue, setInputValue] = useState(watchValue);
  const [toggled, setToggled] = useState(checkQuillTextHasValue(inputValue));
  const [pastedOptions, setPastedOptions] = useState({ original: null, formatted: null });

  const handleQuillChange = (value) => {
    setValue(name, value, { shouldValidate: true })
  };

  const handleChangeEvent = (event) => {
    const newValue = event.target.value;
    const updatedValue = newValue.replace(pastedOptions.original, pastedOptions.formatted);
    const _value = updatedValue;
    setValue(name, _value)
    setInputValue(updatedValue)
    // Validating the input on every change
    trigger()
  }

  const handlePasteEvent = (event) => {
    const { clipboardData } = event;
    const clipboardText = clipboardData.getData('text');
    const updatedClipboardText = clipboardText.replaceAll('\r', "");
    const arrayOptions = updatedClipboardText.split('\r').join('\t').split('\n').join('\t').split('\t').filter((i) => i);
    const stringOptions = convertArrayToStringOptions(arrayOptions);
    setPastedOptions({
      original: updatedClipboardText,
      formatted: stringOptions
    });
  }

  const handleBlur = (event) => {
    const _value = event.target.value;
    setValue(name, _value, { shouldValidate: true });
  }

  const renderTextarea = () => (
    <>
      <OutlinedInput
        multiline
        minRows={minRows}
        maxRows={maxRows}
        name={name}
        placeholder={placeholder}
        value={inputValue}
        onChange={(e) => handleChangeEvent(e)}
        onPaste={handlePasteEvent}
        onBlur={handleBlur}
        inputProps={{ maxLength }}
        error={!!errors[name]?.message}
      />
      <FormHelperText
        sx={{
          fontSize: '12px',
          color: colors.scarlett[400],
          m: 0,
          mt: 1,
        }}
      >
        {get(errors[name], 'types.message') ?? get(errors[name], 'type.message') ?? get(errors[name], 'message')}
      </FormHelperText>
    </>
  );

  const renderRichTextEditor = () => (
    <CLARichTextEditor
      placeholder={placeholder}
      value={inputValue}
      onChange={handleQuillChange}
    />
  );

  const handleToggle = (e) => {
    setToggled(e.target.checked);
    if (!!control && !e.target.checked) {
      setValue(name, '');
    }
  };

  if (toggleable)
    return (
      <FormControl fullWidth={toggled}>
        <FormControlLabel
          control={
            <Checkbox
              checked={toggled}
              onChange={handleToggle}
            />
          }
          label={label}
          sx={{
            marginBottom: '8px',
          }}
          disabled={advancedDefault}
        />
        {toggled && renderRichTextEditor()}
      </FormControl>
    );
  else
    return (
      <FormControl 
        fullWidth 
        disabled={advancedDefault}>
        <Typography
          variant="label"
          component="label"
          sx={{
            mb: '8px',
          }}
        >
          {label}
        </Typography>
        {showFormatText ? renderRichTextEditor() : renderTextarea()}
      </FormControl>
    );
};

ToggleableTextarea.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.node,
  placeholder: PropTypes.string,
  toggleable: PropTypes.bool,
  maxLength: PropTypes.number,
  minRows: PropTypes.number,
  maxRows: PropTypes.number,
  showFormatText: PropTypes.bool,
  advancedDefault: PropTypes.bool
};

ToggleableTextarea.defaultProps = {
  toggleable: false,
  showFormatText: true,
  advancedDefault: false,
  maxLength: 4096,
  minRows: 3,
  maxRows: 9,
};
