import React, { useState } from "react"
import GraphsDrawer from "./GraphsDrawer"
import Button from '@mui/material/Button';
import { useProjectDetails } from "@services/customForm/trendingByMonth"
import icon_add_dark from "@assets/icon_add_dark.svg"
import { GRAPHS_DRAWER } from "@constants"
import { useGraphContext } from  '@contexts/PlanningAnalytics/GraphContext';

const GraphsManager = (props) => {

  const { formValues, projectId, projectFormId, sectionId, schema, questionId, disabled } = props
  const { GRAPH_SECTION_TITLE } = GRAPHS_DRAWER.EN
  const [ drawerVisible, setDrawerVisible ] = useState(false)
  const { data } = useProjectDetails(projectId)
  const { selectedGraphs } = useGraphContext()

  const toggleGraphsDrawer = () => {
    setDrawerVisible((prev) => !prev)
  }

  const onOpen = () => {
  }

  return (
    <div>
        <Button
          sx={{ color: "#2e334e" }}
          startIcon={<img src={icon_add_dark} />}
          onClick={toggleGraphsDrawer}
          disabled={disabled}
        >
          { GRAPH_SECTION_TITLE }
        </Button>
        <GraphsDrawer
          projectId={data ? data.CLCProjectId : undefined}
          projectFormId={projectFormId}
          sectionId={sectionId}
          questionId={questionId}
          formValues={formValues}
          visible={drawerVisible}
          schemaSelectedGraphs={selectedGraphs}
          schema={schema}
          onClose={toggleGraphsDrawer}
          onOpen={onOpen}
        />
    </div>
  )
}

export default GraphsManager
