import React, { useState, useEffect } from "react";
import { 
    TextField, 
    IconButton, 
    Select, 
    Box,
    MenuItem, 
    InputLabel, 
    FormControl,
} from "@mui/material";
import { Controller  } from 'react-hook-form';

import CLACheckBoxField from "@components/Forms/CLACheckBoxField/CLACheckBoxField";
import CLATextField from '@components/Forms/CLATextField/CLATextField';
import deleteIcon from "@assets/delete_icon.svg";

import { UNDERSTANDING_OF_ENTITY } from '@ais/constants';

import styles from "./KeyControlDrawer.module.css";
import * as MuiStyles from "./KeyControlMuiStyles.js";

const { Flex, FlexRow } = MuiStyles;
const { PLACEHOLDERS, LABELS, OTHER_RELEVANT_ASSERTIONS } = UNDERSTANDING_OF_ENTITY.KEY_CONTROL_DRAWER;
const { LABEL, OPTION_VALUE: OTHER_RELEVANT_ASSERTIONS_OPTION_VALUE } = OTHER_RELEVANT_ASSERTIONS;

const KeyControlDrawerContent = ({
    tempId,
    control,
    projectKeyControl,
    projectRisks,
    setValue,
    isScotabdsDrawer,
    onDeleteKeyControl,
    isProjectFinalized,
    IsCoversOtherRelativeAssertion
}) => {

    const {
        ProjectKeyControlName,
        ProjectKeyControlDescription,
        ProjectKeyControlProjectRisk,
        IsITApplicationAssociated,
        ITApplicationAssociatedComment,
    } = projectKeyControl;

    const [isCheckboxTextFieldVisible, setIsCheckboxTextFieldVisible] = useState(IsITApplicationAssociated);
    const [misstatementOptions, setMisstatementOptions] = useState([]);

    useEffect(() => {
        if (!ProjectKeyControlProjectRisk?.length && !projectRisks?.length)
            return;

        const filteredProjectRisks = projectRisks?.filter(risk => !!risk?.ProjectRiskId);
        const assertions = projectRisks
            ?.filter(risk => !risk?.ProjectRiskId)
            ?.map(({OtherRelevantAssertions}) => JSON.parse(OtherRelevantAssertions))
            ?.at()
            ?.reduce((acc, val, i) => i === 0 ? `${acc}: ${val.AssertionName}` : `${acc}, ${val.AssertionName}`, LABEL);

        const options = []

        options.push(...filteredProjectRisks?.map(risk => ({
            label: risk?.RiskName,
            value: risk?.ProjectRiskId
        })));

        if(!!assertions) options.push({
            label: assertions,
            value: OTHER_RELEVANT_ASSERTIONS_OPTION_VALUE
        })

        const keyControlRiskValues = ProjectKeyControlProjectRisk?.map(x => x.ProjectRiskId) ?? [];

        // If the key control risk values includes the other relevant assertions from backend
        // Then add it as default selected values
        if(!!IsCoversOtherRelativeAssertion) {
            keyControlRiskValues.push(OTHER_RELEVANT_ASSERTIONS_OPTION_VALUE)
        }

        setMisstatementOptions(options);
        setValue(`fields.${tempId}.NewProjectKeyControlProjectRisk`, keyControlRiskValues ?? [])
    }, [projectRisks])

    const onCheckBoxChange = (event) => {
        const { checked } = event.target;
        setValue(`fields.${tempId}.ITApplicationAssociatedComment`, null);
        setIsCheckboxTextFieldVisible(checked);
    }

    return (
        <FlexRow>
            <div className={styles[`control-container`]}>
                <Flex>
                    <Controller
                        control={control}
                        name={`fields.${tempId}.ProjectKeyControlName`}
                        defaultValue={ProjectKeyControlName}
                        rules={{ required: true }}
                        render={({ field }) => <CLATextField 
                                                    label={LABELS.KEY_CONTROL_NAME}
                                                    placeholder={PLACEHOLDERS.KEY_CONTROL_NAME}
                                                    inputLableShrink={true}
                                                    disabled={isProjectFinalized}
                                                    {...field}/>}
                    />
                    <Controller
                        control={control}
                        name={`fields.${tempId}.ProjectKeyControlDescription`}
                        defaultValue={ProjectKeyControlDescription}
                        rules={{ required: true }}
                        render={({ field }) => <TextField
                                                    label={LABELS.KEY_CONTROL_DESCRIPTION}
                                                    placeholder={PLACEHOLDERS.KEY_CONTROL_DESCRIPTION}
                                                    multiline
                                                    rows={3}
                                                    inputProps={{
                                                        maxLength: 4096
                                                    }}
                                                    InputLabelProps={{ shrink: true }}
                                                    disabled={isProjectFinalized}
                                                    {...field}/>}
                    />
                    {
                        isScotabdsDrawer &&
                        <Controller
                            control={control}
                            name={`fields.${tempId}.NewProjectKeyControlProjectRisk`}
                            defaultValue={[]}
                            rules={{ required: true }}
                            render={({ field }) =>
                                        <>
                                            <FormControl fullWidth className={styles['smaller-gap-bottom']}>
                                                <InputLabel id="ProjectKeyControlProjectRiskLabel" shrink sx={{ fontSize: '12px', backgroundColor: 'white' }}>{LABELS.PROJECT_RISKS}</InputLabel>
                                                <Select
                                                    labelId="ProjectKeyControlProjectRiskLabel"
                                                    multiple
                                                    displayEmpty={true}
                                                    renderValue={(selected) => ((
                                                    <Box className={styles['dropdown-text-overflow']}>
                                                        {selected?.map((value, index) => {
                                                            const risk = misstatementOptions.find(x => x?.value === (value?.ProjectRiskId ?? value));
                                                            return <span key={`select-${value}`} style={{marginRight: '5px'}}>{risk?.label}{selected.length === 1 || selected.length === (index + 1) ? "" : ","}</span>;
                                                        })}
                                                    </Box>))}
                                                    disabled={isProjectFinalized}
                                                    {...field}
                                                >
                                                    {misstatementOptions?.map((option, index) => (
                                                        <MenuItem
                                                            className={styles['dropdown-text-values']}
                                                            key={`select-option-${index}`}
                                                            value={option?.value}
                                                            fullWidth={false}
                                                            sx={{
                                                                whiteSpace: 'normal',
                                                                wordWrap: 'break-word',
                                                            }}
                                                        >
                                                            {option?.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </>
                                    }
                        />
                    }
                    <Controller
                        control={control}
                        name={`fields.${tempId}.IsITApplicationAssociated`}
                        defaultValue={IsITApplicationAssociated}
                        render={({ field : { onChange, value } }) => 
                                                <CLACheckBoxField
                                                    label={LABELS.IS_IT_APPLICATION}
                                                    checked={value}
                                                    onChange={(e) => {
                                                        onCheckBoxChange(e);
                                                        onChange(e.target.checked);
                                                    }}
                                                    disabled={isProjectFinalized}
                                                />}
                    />
                    {
                        isCheckboxTextFieldVisible &&  <Controller
                                                            control={control}
                                                            name={`fields.${tempId}.ITApplicationAssociatedComment`}
                                                            defaultValue={ITApplicationAssociatedComment}
                                                            rules={{ required: true }}
                                                            render={({ field }) =>
                                                                                    <TextField
                                                                                        className={styles['smaller-gap-top']}
                                                                                        placeholder={PLACEHOLDERS.IT_APPLICATION_COMMENT}
                                                                                        multiline
                                                                                        rows={3}
                                                                                        inputProps={{
                                                                                            maxLength: 4096
                                                                                        }}
                                                                                        disabled={isProjectFinalized}
                                                                                        {...field}
                                                                                    />}
                                                        />
                    }
                </Flex>
            </div>
            <Flex className={styles[`delete-container`]}>
                <IconButton size="medium" onClick={onDeleteKeyControl} disabled={isProjectFinalized}>
                    <img src={deleteIcon} alt='deleteIcon' className={styles[`delete-icon`]} />
                </IconButton>
            </Flex>
        </FlexRow>
    )
}

export default KeyControlDrawerContent;