import React, { useEffect, useState, useContext } from 'react';
import { getFeatureFlags } from '@services/config';
import { useNavigate } from 'react-router-dom';
import { ROUTE_PATH } from '@constants/index';

const FeatureFlagContext = React.createContext({});

const useStaticContext = () => useContext(FeatureFlagContext);

export const FeatureFlagProvider = ({ children }) => {
    const [flags, setFlags] = useState({});
    const [flagsLoaded, setFlagsLoaded] = useState(false);

    useEffect(async () => {
        try {
            const response = await getFeatureFlags();
            setFlags(response.data);
        } catch (err) {
            console.error(err);
        }

        setFlagsLoaded(true);
    }, []);

    return (
        <FeatureFlagContext.Provider value={flags}>
            {flagsLoaded ? children : null}
        </FeatureFlagContext.Provider>
    );
};

export const FeatureFlag = ({ feature, children, route }) => {
    const context = useStaticContext();
    const navigate = useNavigate();

    useEffect(() => {
        if (route && feature && !context[feature]) {
            navigate(`/${ROUTE_PATH.noaccess}`);
        }
    }, [])

    return context[feature] || !feature ? <>{children}</> : null;
};

export const useFeature = (featureId, defaultValue = false) => {
    const [isEnabled, setEnabled] = useState(defaultValue);
    const context = useStaticContext();

    useEffect(() => {
        if (!!context[featureId]) {
            setEnabled(context[featureId]);
        } else {
            setEnabled(false);
        }
    }, [context]);

    return isEnabled;
}; 