import { useState, useEffect, useCallback } from 'react';
import { useFormContext } from "react-hook-form"

import { Grid } from '@mui/material';

import { Select, Box, Chip } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';

import formServices from '@services/forms/forms';
import { useCLAInitialScopingPerformanceStandards } from './useCLAInitialScopingPerformanceStandards';
import CLAPerformanceStandardsChangeModal from './CLAPerformanceStandardsChangeModal';
import { useParams } from 'react-router-dom';
import { useProjectScope } from '../CLAProjectScopeAuditArea/hooks/useProjectScope';
import clsx from 'clsx';
import styles from '@views/ProjectScopingForm/CLAInitialScopingConsiderations/CLAInitialScopingConsiderations.module.css'

const menuItemStyle = {
    whiteSpace: 'normal',
    wordWrap: 'break-word',
};

const PerformanceStandardsDropdown = (props) => {
    const { methodologyVersionId, projectFormId, disabled, onFocus, onClose, signOffList } = props;

    const [projectScopeId, setProjectScopeId] = useState();
    const displayEmpty = true;
    const placeholder = 'Select';

    const { projectId } = useParams();
    const { 
        performanceStandards: fromConcurrencyPS
    } = useProjectScope();

    const {
        handlePerformanceChange,
        handlePerformanceStandardOpen,
        handlePerformanceStandardOnClose,
        onConfirmPerformanceStandardModal,
        associatedStandardPerformancData,
        isPerformanceStandardModalOpen,
        performanceStandards,
        selectedPerformanceStandard,
        hasLoadingAuditAreaperformanceStandardsData,
        auditAreaperformanceStandardsData,
        setIsPerformanceStandardModalOpen, 
        handlePerformanceStandards,
        isPerformanceStandardsHighlighted,
        handleExistingPerformanceStandards
    } = useCLAInitialScopingPerformanceStandards({
        projectFormId,
        projectScopeId,
        methodologyVersionId,
        signOffList
    })

    const retrieveProjectScopeInformation = async () => {
        const projectScopeInfo = await formServices.getProjectScopeByProjectFormId(projectFormId, projectId);
        setProjectScopeId(projectScopeInfo?.data?.ProjectScopeId);
    };

    useEffect(() => {
        if (!projectScopeId) {
            retrieveProjectScopeInformation();
        }
    }, []);

    useEffect(() => {
        handleExistingPerformanceStandards(fromConcurrencyPS)

        const performanceStandardIds = fromConcurrencyPS.map(ps => ps.PerformanceStandardId)
        if (performanceStandardIds?.length !== 0) {
            if(typeof performanceStandardIds[0] === 'number'){ 
                handlePerformanceStandards(performanceStandardIds);
            } 
            // NOTE: Commented for now, we still need to determine what scenario can fall on this condition
            // else { 
            //     handlePerformanceStandards(performanceStandardIds.map(ps => ps.PerformanceStandardId));
            // }
        } 
    }, [fromConcurrencyPS]);

    const getKey = useCallback((performanceStandardId) => {
        const result = selectedPerformanceStandard.some(value => value === performanceStandardId);
        return result;
    }, [selectedPerformanceStandard])

    return (
        <>
            <Grid item xs={12} className={clsx(isPerformanceStandardsHighlighted && styles.DropdownSelectContainerHighlighted)}>
                <FormControl fullWidth>
                    <InputLabel id="performanceStandards" shrink sx={{ backgroundColor: 'white' }}>What performance standards apply to this project?</InputLabel>
                    <Select
                        labelId="performanceStandards"
                        id="performanceStandardsDropdown"
                        value={selectedPerformanceStandard}
                        label="What performance standards apply to this project?"
                        multiple
                        onOpen={() => {
                            handlePerformanceStandardOpen()
                            onFocus()
                        }}
                        onChange={handlePerformanceChange}
                        onClose={async () =>{
                            onClose()
                            await handlePerformanceStandardOnClose()
                        }} 
                        displayEmpty={displayEmpty}
                        disabled={disabled}
                        renderValue={(selected) => (
                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                {selected.map((value) => {
                                    const performanceStandard = performanceStandards.find(x => x.PerformanceStandardId === value);
                                    return <Chip key={value} label={performanceStandard?.PerformanceStandardName} />
                                })}
                            </Box>
                        )}
                    >
                        {displayEmpty && (
                            <MenuItem disabled={true} value="" sx={menuItemStyle}>
                                {placeholder}
                            </MenuItem>
                        )}
                        {performanceStandards?.map((option, index) => {
                            const optionId = option?.hasOwnProperty('PerformanceStandardId') ? option.PerformanceStandardId : option;
                            return(
                                <MenuItem
                                    key={`${index}-${getKey(optionId)}`}
                                    value={optionId}
                                    sx={menuItemStyle}
                                >
                                    {option?.hasOwnProperty('PerformanceStandardName') ? option.PerformanceStandardName : option}
                                </MenuItem>
                            )
                        })}
                    </Select>
                </FormControl>
                <CLAPerformanceStandardsChangeModal
                    associatedStandardPerformancData={associatedStandardPerformancData}
                    isPerformanceStandardModalOpen={isPerformanceStandardModalOpen}
                    hasLoadingAuditAreaperformanceStandardsData={hasLoadingAuditAreaperformanceStandardsData}
                    auditAreaperformanceStandardsData={auditAreaperformanceStandardsData}
                    setIsPerformanceStandardModalOpen={setIsPerformanceStandardModalOpen}
                    onConfirmPerformanceStandardModal={onConfirmPerformanceStandardModal}
                />
            </Grid>
        </>
    );
}

export default PerformanceStandardsDropdown;
