import React, { useEffect, useState } from "react"
import { useForm, useFieldArray } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import { Button } from "@mui/material";

import {
    CLADialogConfirm,
    formatProjectInternalControlProjectKeyControl,
    formatProjectScopeAuditAreaSCOTABDProjectKeyControl
} from '@ais/components';
import { UNDERSTANDING_OF_ENTITY } from '@ais/constants';

import styles from "./KeyControlDrawer.module.css";
import * as MuiStyles from "./KeyControlMuiStyles.js";
import KeyControlDrawerContent from "./KeyControlDrawerContent";

const { Flex } = MuiStyles;
const { BUTTONS, DELETE_BODY } = UNDERSTANDING_OF_ENTITY.KEY_CONTROL_DRAWER;

const KeyControlDrawerList = ({
    tempProjectKeyControl,
    projectRisks,
    emptyProjectKey,
    isScotabdsDrawer,
    onHandleSubmit,
    onDeleteKeyControlById,
    isProjectFinalized
}) => {

    const { handleSubmit, control, watch, setValue, setFocus, formState: { isValid } } = useForm({
        defaultValues: {
            fields: tempProjectKeyControl ?? []
        }
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'fields',
    });

    const [isDeleteDialogVisible, setIsDeleteDialogVisible] = useState(false);
    const [keyControlIndex, setKeyControlIndex] = useState(-1);

    const onSubmit = (data) => {
        if (isScotabdsDrawer && projectRisks) {
            onHandleSubmit(formatProjectScopeAuditAreaSCOTABDProjectKeyControl(data.fields, projectRisks));
            return
        }

        onHandleSubmit(formatProjectInternalControlProjectKeyControl(data.fields));
    }

    const onAddKeyControl = () => append(emptyProjectKey);

    const onDeleteKeyControl = (index) => {
        setKeyControlIndex(index);
        setIsDeleteDialogVisible(true);
    }

    const onCancelDialog = () => {
        setIsDeleteDialogVisible(false);
        setKeyControlIndex(-1);
    }

    const onConfirmDialog = () => {
        remove(keyControlIndex);

        const field = fields?.length ? fields.at(keyControlIndex) : null;
        const projecyKeyControlId = field?.ProjectKeyControl[0]?.ProjectKeyControlId;
        if (projecyKeyControlId) {
            onDeleteKeyControlById(projecyKeyControlId)
        }

        onCancelDialog();
    }

    useEffect(() => {
        if (!fields.length) {
            onAddKeyControl()
        }
    }, [fields]);

    return (
        <>
            <Button
                variant="contained"
                className={styles[`confirm-button`]}
                onClick={handleSubmit(onSubmit)}
                disabled={!isValid || isProjectFinalized}>
                {BUTTONS.SAVE}
            </Button>

            <Flex className={styles[`content-container`]}>
                {
                    fields.map((field, index) => (
                        <KeyControlDrawerContent
                            key={field.id}
                            tempId={index}
                            watch={watch}
                            setFocus={setFocus}
                            setValue={setValue}
                            control={control}
                            projectKeyControl={field?.ProjectKeyControl[0]}
                            projectRisks={projectRisks}
                            isScotabdsDrawer={isScotabdsDrawer}
                            onDeleteKeyControl={() => onDeleteKeyControl(index)}
                            isProjectFinalized={isProjectFinalized}
                            IsCoversOtherRelativeAssertion={field?.IsCoversOtherRelativeAssertion}
                        />
                    ))
                }
            </Flex>

            <Button
                className={styles[`button-key-control`]}
                startIcon={<AddIcon className={styles[`add-icon`]} />}
                variant="page-button-add-key-control"
                onClick={onAddKeyControl}
                disabled={isProjectFinalized}>
                {BUTTONS.ADD_KEY_CONTROL}
            </Button>

            <CLADialogConfirm
                visible={isDeleteDialogVisible}
                title={'Warning'}
                cancelText={'Cancel'}
                confirmText={'Delete'}
                message={DELETE_BODY}
                onConfirm={onConfirmDialog}
                onCancel={onCancelDialog} />
        </>
    )
}

export default KeyControlDrawerList;