import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
    Collapse,
    IconButton,
    Grid,
    TableRow,
    TableCell,
    TextField,
    Box,
    Typography
} from '@mui/material';
import { sanitize } from 'dompurify';
import {
    updateProjectRiskAssessmentSummary,
    createProjectRiskAuditAreaFinancialStatementProjectRisk,
    deleteProjectRiskAuditAreaFinancialStatementProjectRiskById
} from "@services/projectRisk.js";
import { useAccountAssertionLevelRiskState } from './AccountAssertionLevelRiskContext';
import {CLADropdown, CLASelect} from '@ais/forms';
import { CLATooltip } from "@ais/components";
import HelpIcon from "@mui/icons-material/Help";
import { isArrayEqual } from "@utilities/arrayHelpers";

import {CollapseIconComponent, ExpandIconComponent} from "@ais/assets";
import styles from './CLAAccountAssertionLevelRisk.module.css';
import {
    cellBorderStyles,
    wrapCellStyles,
    cellStyles,
    riskGridCell,
    txtReferenceStyles,
    selectStyles,
    financialSelectStyles
} from './muiStyles';

import { useProjectFormInstanceProvider } from '@providers';
import { RISK_ASSESSMENT_FORM_INSTANCE } from '@constants/forms';
import { PROJECT_FORM_INSTANCE } from '@constants/index';
import CustomToast from '@components/CustomToast/CustomToast';

const CLARiskCollapsible = (props) => {
    const {
        ACCOUNT_ASSERTION_LEVEL_RISK: CONSTANT_TEXT,
        RISK_LEVEL_INHERIT_RISK,
        RISK_LEVEL_CONTROL_RISK,
        RISK_MATERIAL_MISSTATEMENT_MATRIX: MATRIX,
        RISKS_KEYVALUE_INHERENT,
        RISKS_KEYVALUE_CONTROL,
        RISKS_KEYVALUE_RMM,
        COMMON_IDENTIFIED_RISK_TOOLTIP
    } = RISK_ASSESSMENT_FORM_INSTANCE.EN;
    const HIGHLIGHT_BORDER = PROJECT_FORM_INSTANCE.EN.HIGHLIGHT_BORDER;
    const {
        inherentRiskOptions,
        controlRiskOptions,
        isInternalControlEffectiveness,
        risk,
        riskFactorInfo,
        index,
        financialRisks,
        disabled
    } = props;
    const { projectId, projectFormId } = useParams();
    const { expandAll } = useAccountAssertionLevelRiskState();

    const [isExpanded, setIsExpanded] = useState(false);
    const [inherentRisk, setInherentRisk] = useState(risk?.inherentRiskAssessmentName);
    const [controlRisk, setControlRisk] = useState(
        !isInternalControlEffectiveness ? 'High' : risk?.controlRiskAssessmentName
    );
    const [rmm, setRmm] = useState(risk?.riskOfMaterialMisstatementName); 
    const [loading, setLoading] = useState(false)   
    const [error, setError] = useState(false) 
    const [successProjectRiskAssessmentSummary, setSuccessProjectRiskAssessmentSummary] = useState(false)    
    const [isInheritChanged, setIsInheritChanged] = useState(false)
    const [shouldHighlight, setShouldHighlight] = useState();

    const {
        modifiedRiskAssessmentSummaryAfterSignOff,
        auditAreaScotabdRisk,
        actions: {
            riskAssessmentIdentifyModifiedAnswerAfterSignOff
        }
    } = useProjectFormInstanceProvider();

    const riskCharacteristicsTooltip = `${COMMON_IDENTIFIED_RISK_TOOLTIP} (${risk.riskCharacteristics?.map(riskCharacteristic => riskCharacteristic.riskCharacteristicName).join(', ')})`;
    const joinEventConditionList = (condition) => Array.isArray(condition) ? condition?.map(e => e.eventConditionName).join(', ') : '';
    const riskFactorValue = () => riskFactorInfo?.map((r, index) =>
        <CLATooltip title={
            <div
                className="ql-editor"
                dangerouslySetInnerHTML={{ __html: sanitize(`<p><b>${r.riskFactorName}</b></p><p>${joinEventConditionList(r.eventCondition)}</p>`, { ADD_ATTR: ['target'] }) }}
            />
        } placement="bottom">
            <p key={index} className={styles['wrap-text']}>
                {r.riskFactorName}<br />
                {joinEventConditionList(r.eventCondition)}
            </p>
        </CLATooltip>
    );
    useEffect(() => {
        setIsExpanded(expandAll);
    }, [expandAll]);

    const handleRiskDropdownChanges = async (materialMisstatementRisk) => {
        const inherentRiskId = RISKS_KEYVALUE_INHERENT.find((risk) => risk.key === inherentRisk)?.value;
        const controlRiskId = RISKS_KEYVALUE_CONTROL.find((risk) => risk.key === controlRisk)?.value;
        const materialMisstatementRiskId = RISKS_KEYVALUE_RMM.find((risk) => risk.key === materialMisstatementRisk)?.value;

        const requestBody = {
            ReferenceForWalkThrough: risk.referenceForWalkThrough,
            InherentRiskAssessmentId: inherentRiskId,
            ReferenceForTestOfControl: risk.referenceForTestOfControl,
            ControlRiskAssessmentId: controlRiskId,
            RiskOfMaterialMisstatementId: materialMisstatementRiskId,
            RequestProjectFormId: projectFormId
        }
        riskAssessmentIdentifyModifiedAnswerAfterSignOff(auditAreaScotabdRisk);
        try {
            setLoading(true)
            await updateProjectRiskAssessmentSummary(projectId, risk.projectRiskId, requestBody);
            setSuccessProjectRiskAssessmentSummary(true)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setError(true)
        }
    };

    const applyClass = () => {
        let classList = [];
        if (shouldHighlight === HIGHLIGHT_BORDER.MODIFIED) classList.push(styles['highlight-modified']);
        return classList.join(' ');
    };

    useEffect(() => {
        if (modifiedRiskAssessmentSummaryAfterSignOff) {
            const shouldHighlight = !!modifiedRiskAssessmentSummaryAfterSignOff
                .find(signOff => signOff.projectRiskId === risk.projectRiskId && signOff.projectRiskAuditAreaId === risk.projectRiskAuditAreaId) ? HIGHLIGHT_BORDER.MODIFIED : null;

            setShouldHighlight(shouldHighlight);
        }
    }, [modifiedRiskAssessmentSummaryAfterSignOff]);

    useEffect(() => {
        if(isInheritChanged) riskFunc();
    }, [inherentRisk, controlRisk, isInternalControlEffectiveness]);

    const riskFunc = () => {
        if (inherentRisk && controlRisk) {
            const inherentRiskIndex = RISK_LEVEL_INHERIT_RISK.indexOf(inherentRisk);
            const controlRiskIndex = RISK_LEVEL_CONTROL_RISK.indexOf(controlRisk);
            risk.riskOfMaterialMisstatementName = MATRIX[inherentRiskIndex][controlRiskIndex];
            setRmm(MATRIX[inherentRiskIndex][controlRiskIndex]);
        }
        handleRiskDropdownChanges(risk.riskOfMaterialMisstatementName);
    }

    const handleBlur = async (fieldValue, property) => {
        const High = 2;
        const materialMisstatementRiskId = risk.riskOfMaterialMisstatementName ?
            RISKS_KEYVALUE_RMM.find((risk) => risk.key === risk?.riskOfMaterialMisstatementName)?.value : High;

        const existingRow = {
            ReferenceForWalkThrough: risk.referenceForWalkThrough,
            InherentRiskAssessmentId: risk.inherentRiskAssessmentId,
            ReferenceForTestOfControl: risk.referenceForTestOfControl,
            ControlRiskAssessmentId: risk.controlRiskAssessmentId ?? High,
            RiskOfMaterialMisstatementId: materialMisstatementRiskId,
        }

        const requestBody = {
            ...existingRow,
            [property]: fieldValue,
            RequestProjectFormId: projectFormId
        }
        riskAssessmentIdentifyModifiedAnswerAfterSignOff(auditAreaScotabdRisk);
        try {
            setLoading(true)
            await updateProjectRiskAssessmentSummary(projectId, risk.projectRiskId, requestBody);
            setSuccessProjectRiskAssessmentSummary(true)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setError(true)
        }
    }

    const getProcedures = (risk) => {
        const summaryProcedures = risk?.projectRiskSummaryProcedures?.map(procedure => procedure?.SummaryProcedureName);
        const customProcedures = risk?.customProcedures?.map(customProc => customProc?.customProcedureName);
        const procedures = summaryProcedures.concat(customProcedures);
        return procedures.join(', ');
    }
    return (<>
        <TableRow data-test="tableRowBody" className={applyClass()}>
            <TableCell sx={{ ...cellStyles }}>
                <IconButton
                    sx={{ p: '0' }}
                    onClick={() => { setIsExpanded(prevIsExpanded => !prevIsExpanded) }}>
                    {isExpanded ? <ExpandIconComponent /> : <CollapseIconComponent />}
                </IconButton>
            </TableCell>
            <TableCell title={risk.riskName} sx={{ pl: 0, ...cellStyles, ...wrapCellStyles, ...(index > 0 ? cellBorderStyles : '') }}>
                {risk.riskName} 
                {risk.defaultRiskId && <CLATooltip
                    title={
                        <div
                            className="ql-editor"
                            dangerouslySetInnerHTML={{
                                __html: sanitize(riskCharacteristicsTooltip, {
                                    ADD_ATTR: ['target'],
                                }),
                            }}
                        >
                        </div>
                    }
                    placement="top"
                >
                    <HelpIcon style={{ fontSize: '18px', paddingLeft: '3px' }} />
                </CLATooltip>}
            </TableCell>
            <TableCell sx={{ ...cellStyles, ...wrapCellStyles, ...(index > 0 ? cellBorderStyles : '') }}>
                {riskFactorValue()}
            </TableCell>
            <TableCell sx={{ ...cellStyles, ...(index > 0 ? cellBorderStyles : '') }}>
                <TextField
                    sx={txtReferenceStyles}
                    placeholder={CONSTANT_TEXT.ENTER_REFERENCE_WALKTHROUGH}
                    inputProps={{ maxLength: 256 }}
                    defaultValue={risk.referenceForWalkThrough ?? ''}
                    onBlur={(e) => {
                        const fieldValue = e?.target?.value;
                        handleBlur(fieldValue, 'ReferenceForWalkThrough')
                    }}
                    onChange={(e) => {
                        risk.referenceForWalkThrough = e.target.value;
                    }}
                    disabled={disabled}
                />
            </TableCell>
            <TableCell sx={{ ...cellStyles, width: '175px', ...(index > 0 ? cellBorderStyles : ''), ...selectStyles }}>
                <CLADropdown
                    options={inherentRiskOptions?.map(c => c.inherentRiskAssessmentName)}
                    value={inherentRisk ?? ''}
                    onChange={(e) => {
                        setIsInheritChanged(true)
                        const inherit = inherentRiskOptions?.find(c => c.inherentRiskAssessmentName === e.target.value);
                        if (inherit) {
                            setInherentRisk(inherit.inherentRiskAssessmentName);
                            if (!isInternalControlEffectiveness) {
                                risk.riskOfMaterialMisstatementName = inherit.inherentRiskAssessmentName;
                            }
                            risk.inherentRiskAssessmentId = inherit.inherentRiskAssessmentId;
                            risk.inherentRiskAssessmentName = inherit.inherentRiskAssessmentName
                        }
                    }}
                    isDisabled={disabled}
                />
            </TableCell>
            <TableCell sx={{ ...cellStyles, ...wrapCellStyles, ...(index > 0 ? cellBorderStyles : '') }} >
                {isInternalControlEffectiveness && <TextField
                    sx={txtReferenceStyles}
                    placeholder={CONSTANT_TEXT.ENTER_REFERENCE_TEST}
                    inputProps={{ maxLength: 256 }}
                    defaultValue={risk?.referenceForTestOfControl ?? ''}
                    onBlur={(e) => {
                        const fieldValue = e?.target?.value;
                        handleBlur(fieldValue, 'ReferenceForTestOfControl')
                    }}
                    onChange={(e) => {
                        risk.referenceForTestOfControl = e.target.value;
                    }}
                    disabled={disabled}
                />}
            </TableCell>
            <TableCell sx={{ ...cellStyles, width: '135px', ...(index > 0 ? cellBorderStyles : ''), ...selectStyles }}>
                {isInternalControlEffectiveness && <CLADropdown
                    styles={{ fontSize: '14px', fontWeight: '600' }}
                    options={controlRiskOptions?.map(c => c.controlRiskAssessmentName)
                    }
                    value={controlRisk ?? ''}
                    onChange={(e) => {
                        setIsInheritChanged(true)
                        const control = controlRiskOptions?.find(c => c.controlRiskAssessmentName === e.target.value);
                        if (control) {
                            setControlRisk(control.controlRiskAssessmentName);
                            risk.controlRiskAssessmentId = control.controlRiskAssessmentId;
                            risk.controlRiskAssessmentName = control.controlRiskAssessmentName
                        }
                    }}
                    isDisabled={disabled}
                />}
                {!isInternalControlEffectiveness && 'High'}

            </TableCell>
            <TableCell sx={{ ...cellStyles, width: '135px', ...(index > 0 ? cellBorderStyles : '') }}>
                {rmm}
            </TableCell>
        </TableRow>
        <TableRow>
            <TableCell colSpan={8} sx={{ ...cellStyles, padding: '0px 0px 0px 68px' }}>
                <Collapse in={isExpanded} data-test="collapse-component">
                    <Grid container sx={{ mb: '35px', mt: '35px' }}>
                        <Grid item xs={3} sx={{ ...riskGridCell }}> {CONSTANT_TEXT.RELEVANT_ASSERTIONS} </Grid>
                        <Grid item xs={9} sx={{ ...riskGridCell }}>{risk?.assertion?.map(a => a.assertionName).join(', ')}  </Grid>
                        <Grid item xs={3} sx={{ ...riskGridCell }}> {CONSTANT_TEXT.FINANCIAL_STATEMENT_LEVEL_RISK} </Grid>
                        <Grid item xs={9} sx={{ ...riskGridCell, ...financialSelectStyles }}>
                            <CLASelect
                                defaultValues={risk?.financialStatementProjectRisk?.map(f => f.riskName)}
                                onChange={(values) => {
                                    if (!financialRisks.length) return;

                                    const selected = values?.filter((i) => i);
                                    const fRisks = financialRisks.filter(f => selected.includes(f.RiskName));
                                    let financialStatementProjectRisk = [];

                                    if (fRisks.length) {
                                        financialStatementProjectRisk = fRisks.map(f => ({
                                            financialStatementProjectRiskId: f.ProjectRiskId,
                                            projectRiskAuditAreaId: risk.projectRiskAuditAreaId,
                                            riskName: f.RiskName
                                        }));
                                    }

                                    if (isArrayEqual(risk?.financialStatementProjectRisk, financialStatementProjectRisk)) return;

                                    risk.financialStatementProjectRisk = financialStatementProjectRisk;

                                    setLoading(true);
                                    
                                    financialRisks.forEach(async (f) => {
                                        const isSelected = fRisks.find((item) => item.RiskName.includes(f.RiskName));
                                        const requestBody = {
                                            ProjectRiskAuditAreaId: risk.projectRiskAuditAreaId,
                                            FinancialStatementProjectRiskId: f.ProjectRiskId,
                                            RequestProjectFormId: projectFormId
                                        };

                                        try {
                                            if (!isSelected) {
                                                await deleteProjectRiskAuditAreaFinancialStatementProjectRiskById(
                                                    requestBody.ProjectRiskAuditAreaId, 
                                                    requestBody.FinancialStatementProjectRiskId, 
                                                    projectId,
                                                    projectFormId
                                                );
                                            } else {
                                                await createProjectRiskAuditAreaFinancialStatementProjectRisk(projectId, requestBody);
                                            }
                                            setSuccessProjectRiskAssessmentSummary(true);
                                        } catch (error) {
                                            setError(true);
                                        } finally {
                                            setLoading(false);
                                        }
                                    });
                                }}
                                menuItems={financialRisks.map(f => f.RiskName)}
                                isDisabled={disabled}
                                listenOnDefaultValueChanges={false}
                            />
                        </Grid>
                        <Grid item xs={3} sx={{ ...riskGridCell }}> {CONSTANT_TEXT.WORKPAPER_REFERENCE_FORM_NAME} </Grid>
                        <Grid item xs={9} sx={{ ...riskGridCell }}>{risk?.projectFormName ? risk?.projectFormName : risk.workpaperReference}  </Grid>
                        <Grid item xs={3} sx={{ ...riskGridCell }}> {CONSTANT_TEXT.UNITS} </Grid>
                        <Grid item xs={9} sx={{ ...riskGridCell }}> {risk?.units?.map(u => u.projectUnitTitle).join(', ')} </Grid>
                        <Grid item xs={12} sx={{display: 'flex', paddingTop: '33px'}}>
                            <Box sx={{paddingRight: '10px'}}>
                                <Typography noWrap sx={{fontWeight: 700, fontSize: '14px'}}>
                                    {CONSTANT_TEXT.PROCEDURES_ADDRESS_RISK}
                                </Typography> 
                            </Box>
                            <Box sx={{flexGrow: 1}}>
                                <Typography sx={{fontWeight: 700, fontSize: '14px'}}>
                                    {getProcedures(risk)}
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </Collapse>
            </TableCell>
        </TableRow>
        <CustomToast
            error={error}
            success={successProjectRiskAssessmentSummary}
            loading={loading}
        />
    </>
    )
};

export default CLARiskCollapsible;