import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { DataGridPro } from "@mui/x-data-grid-pro";
import DataGridPagination from "./DataGridPagination";

// CSS/STYLE
import "./CLANestedDataGrid.css";
import dataGridStyle from "./DataGridStyles.js";
import Skeleton from '@mui/material/Skeleton';

export const CLANestedDataGrid = ({
  sx,
  columns,
  rows,
  rowHeight,
  headerHeight,
  rowsPerPageOptions,
  labelRowsPerPage,
  hideFooterPagination,
  initialRowsPerPage,
  handleAdditionalCollapseTrigger,
  expandedRow,
  hideFooterRowCount,
  showFooterContent,
  hideFooterSelectedRowCount,
  style,
  disableColumnReorder,
  hideFooter,
  getRowClassName,
  loading = undefined
}) => {
  const [hasExpandedPanel, setHasExpandedPanel] = useState(false);
  const [panelExpandedRowIds, setPanelExpandedRowIds] = useState([]);
  const getDetailPanelContent = React.useCallback((row) => row.row?.ExpandedPanel, []);

  useEffect(() => {
    setHasExpandedPanel(rows && rows.every((el) => el.hasOwnProperty("ExpandedPanel")));
  }, [rows]);

  const onChangeExpandedPanelRowIds = React.useCallback((newIds) => {
    const rowId = newIds.slice(-1);
    setPanelExpandedRowIds(rowId);
  }, []);

  //   PAGINATION ------------- START
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage || 10);
  const count = rows?.length ?? 0;

  const handleChangePage = (newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const CLADatagridFooter = () => {
    return !hideFooterPagination ? (
      <div className="footer">
        {
          <DataGridPagination
            rows={rows}
            count={count}
            rowsPerPage={rowsPerPage}
            rowsPerPageOptions={rowsPerPageOptions}
            initialRowsPerPage={initialRowsPerPage}
            labelRowsPerPage={labelRowsPerPage}
            page={page}
            setRowsPerPage={setRowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        }
      </div>
    ) : (
      <></>
    );
  };
  
  const LoadingSkeleton = () => (
    <Skeleton animation="wave" variant="rectangular" sx={{ width: "100%", height: "100%" }} />
  )

  return (
    <div className="data-grid-container">
      <DataGridPro
        sx={style || { ...dataGridStyle, ...sx }}
        // content of table
        columns={columns}
        rows={rows}
        // paginations
        pageSize={rowsPerPage}
        page={page}
        pagination={!hideFooterPagination}
        hideFooterRowCount={hideFooterRowCount}
        disableSelectionOnClick={true}
        components={{
          Footer: showFooterContent && CLADatagridFooter,
          LoadingOverlay: loading !== undefined ? LoadingSkeleton : undefined
        }}
        hideFooterSelectedRowCount={hideFooterSelectedRowCount}
        // constants can be change in future
        rowHeight={rowHeight}
        headerHeight={headerHeight}
        disableColumnResize={true}
        autoHeight={true}
        disableColumnMenu={true}
        // for panel/dropdown
        getDetailPanelHeight={() => "auto"}
        getDetailPanelContent={hasExpandedPanel ? getDetailPanelContent : null}
        detailPanelExpandedRowIds={expandedRow || panelExpandedRowIds}
        onDetailPanelExpandedRowIdsChange={handleAdditionalCollapseTrigger || onChangeExpandedPanelRowIds}
        disableColumnReorder={disableColumnReorder}
        hideFooter={hideFooter}
        getRowClassName={getRowClassName ?? null}
        loading={loading}
      />
    </div>
  );
};

CLANestedDataGrid.propTypes = {
  rowHeight: PropTypes.number,
  headerHeight: PropTypes.number,
  hideFooterPagination: PropTypes.bool,
  rowsPerPageOptions: PropTypes.array,
  hideFooterSelectedRowCount: PropTypes.func,
  expandedRow: PropTypes.array,
  style: PropTypes.object,
  hideFooterRowCount: PropTypes.bool,
  showFooterContent: PropTypes.bool,
  hideFooterSelectedRowCount: PropTypes.bool,
  labelRowsPerPage: PropTypes.string,
  disableColumnReorder: PropTypes.bool,
  hideFooter: PropTypes.bool,
  onChangeExpandedRows: PropTypes.func,
};

CLANestedDataGrid.defaultProps = {
  rowHeight: 70,
  headerHeight: 150,
  hideFooterPagination: false,
  rowsPerPageOptions: [5, 10, 20],
  handleAdditionalCollapseTrigger: null,
  expandedRow: null,
  style: null,
  hideFooterRowCount: false,
  showFooterContent: true,
  hideFooterSelectedRowCount: true,
  labelRowsPerPage: "Rows per page",
  headerHeight: 56,
  hideFooterPagination: false,
  rowsPerPageOptions: [5, 10, 20],
  labelRowsPerPage: "Rows per page",
  disableColumnReorder: true,
  hideFooter: false,
  onChangeExpandedRows: null
};

export default CLANestedDataGrid;
