// const cutoffDate = new Date('2024-04-02T23:59:59Z');
// TODO - Use cutoffDate above when custom form signoff functionality is fixed.
const cutoffDate = new Date('2025-04-02T23:59:59Z');

export const isSignoffLogicEnabled = (isProjectFinalized, fieldChangeDate, isNATG = false) => {
    //Separate NATG component checking since the cutoffDate logic should only apply to custom forms.
    if(isNATG){
        return !isProjectFinalized;
    }

    if(isProjectFinalized || !fieldChangeDate || fieldChangeDate < cutoffDate){
        return false;
    }
    return true;
}