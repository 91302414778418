import React, { useState, useEffect } from "react"
import { useQueryClient } from "@tanstack/react-query";
import {
    Box,
    IconButton,
    SwipeableDrawer,
    Paper,
    Typography
} from "@mui/material"
import CloseIcon from "@mui/icons-material/Close"
import { CLADialog } from '@ais/components';
import dialogStyles from "@components/CLADialogConfirm/CLADialogConfirm.module.css";
import { useKeyControlDrawerContext } from '@contexts/KeyControlDrawer/KeyControlDrawerContext' 
import { useGetKeyControlProjectRisk } from "@services/projectRisk";
import { 
    useUpdateInternalControlAuditAreas,
    useDeleteProjectKeyControl,
    FETCH_PROJECT_INTERNAL_CONTROL
}
from '@services/customForm/internalControls';
import { UNDERSTANDING_OF_ENTITY } from '@ais/constants';
import { useLoading } from '@hooks/index';
import styles from "./KeyControlDrawer.module.css";
import * as MuiStyles from "./KeyControlMuiStyles.js";
import KeyControlDrawerList from "./KeyControlDrawerList";
import useProjectFormInstanceConcurrentLocking from '@hooks/useProjectFormInstanceConcurrentLocking'
import { useParams } from 'react-router-dom';

const { Flex, FlexRow, ConfirmButton, CancelButton } = MuiStyles;
const { TITLE, BODY_TEXT, DIALOG_TITLE, DIALOG_BODY, DIALOG_FOOTER_TEXT, BUTTONS, OTHER_RELEVANT_ASSERTIONS } = UNDERSTANDING_OF_ENTITY.KEY_CONTROL_DRAWER;
const { LABEL: OTHER_RELEVANT_ASEERTION_LABEL } = OTHER_RELEVANT_ASSERTIONS

function FormDrawer({
    isJournalEntry,
    isFinancialStatement,
    projectRisks,
    tempProjectKeyControl,
    emptyProjectKey,
    onHandleSubmit,
    onDeleteKeyControlById, 
    isProjectFinalized
}) {
    if (tempProjectKeyControl && (isJournalEntry || isFinancialStatement)) {
        return <KeyControlDrawerList
            isScotabdsDrawer={false}
            tempProjectKeyControl={tempProjectKeyControl}
            emptyProjectKey={emptyProjectKey}
            onHandleSubmit={onHandleSubmit}
            onDeleteKeyControlById={onDeleteKeyControlById}
            isProjectFinalized={isProjectFinalized}
        />
    }

    if (tempProjectKeyControl && projectRisks) {
        return <KeyControlDrawerList
            isScotabdsDrawer
            projectRisks={projectRisks}
            tempProjectKeyControl={tempProjectKeyControl}
            emptyProjectKey={emptyProjectKey}
            onHandleSubmit={onHandleSubmit}
            onDeleteKeyControlById={onDeleteKeyControlById}
            isProjectFinalized={isProjectFinalized}
        />
    }

    return <React.Fragment></React.Fragment>;
}

const KeyControlDrawer = ({ isVisible, isProjectFinalized }) => {
    const queryClient = useQueryClient();
    const { projectId } = useParams();

    const {
        projectFormId,
        projectScopeAuditAreaId,
        projectScopeAuditAreaSCOTABDId,
        closeDrawer,
        internalControlData,
        isJournalEntry,
        isFinancialStatement,
        tempProjectKeyControl,
        getEmptyProjectKey
    } = useKeyControlDrawerContext();

    const { data: projectRisks } = useGetKeyControlProjectRisk(projectId, projectFormId, projectScopeAuditAreaSCOTABDId);
    const { mutateAsync: updateInternalControlAuditAreas } = useUpdateInternalControlAuditAreas();
    const { mutateAsync: deleteProjectKeyControl } = useDeleteProjectKeyControl(projectId, projectFormId);
    
    const setLoading = useLoading();

    const [remainingRisk, setRemaingRisk] = useState([]);
    const [assertionList, setAssertionList] = useState([]);
    const [isWarningDialogOpen, setIsWarningDialogOpen] = useState(false);
    const [formData, setFormData] = useState([]);
    const [hasDataDeleted, setHasDataDeleted] = useState(false);
    const [shouldShowOtherRelevantAssertions, setShouldShowOtherRelevantAssertions] = useState(false);
    const [otherRelevantAssertionList, setOtherRelevantAssertionList] = useState([])

    const isRequiredByRMM = (inherentRiskId, controlRiskId) => {
        if ((inherentRiskId === 3 || inherentRiskId === 4) && controlRiskId === 1) {
            return false;
        }
        if (inherentRiskId === null || controlRiskId === null) {
            return false;
        }
        return true;
    }

    useEffect(() => {
        const withRelevantAssertion = projectRisks?.find(({OtherRelevantAssertions}) => !!OtherRelevantAssertions);

        if(!!withRelevantAssertion?.OtherRelevantAssertions) {
            const otherRelevantAssertionsAsObject = JSON.parse(withRelevantAssertion.OtherRelevantAssertions);
            const otherRelevantAssertionList = otherRelevantAssertionsAsObject
                ?.map(({AssertionName}) => AssertionName)
                ?.sort();

            setOtherRelevantAssertionList(otherRelevantAssertionList);
        }
    }, [projectRisks])

    function handleIdle() {
        closeDrawer(null, hasDataDeleted);
    }

    useProjectFormInstanceConcurrentLocking(null, {
        onIdle: handleIdle
    });

    const onClose = (payload = null, isRefreshData = false) => {
        closeDrawer(payload, isRefreshData);
    }

    const saveKeyControls = async (data = formData) => {
        setLoading(true);

        const payload = {
            ProjectInternalControlId: internalControlData?.ProjectInternalControlId,
            JournalEntryICInfoProcessingAndControlActivity: internalControlData?.JournalEntryICInfoProcessingAndControlActivity,
            JournalEntryICComment: internalControlData?.JournalEntryICComment,
            FinancialStatementICInfoProcessingAndControlActivity1: internalControlData?.FinancialStatementICInfoProcessingAndControlActivity1,
            FinancialStatementICInfoProcessingAndControlActivity2: internalControlData?.FinancialStatementICInfoProcessingAndControlActivity2,
            FinancialStatementICInfoProcessingAndControlActivity3: internalControlData?.FinancialStatementICInfoProcessingAndControlActivity3,
            FinancialStatementICInfoProcessingAndControlActivity4: internalControlData?.FinancialStatementICInfoProcessingAndControlActivity4,
            FinancialStatementICInfoProcessingAndControlActivity5: internalControlData?.FinancialStatementICInfoProcessingAndControlActivity5,
            FinancialStatementICInfoProcessingAndControlActivity6: internalControlData?.FinancialStatementICInfoProcessingAndControlActivity6,
            FinancialStatementICComment: internalControlData?.FinancialStatementICComment,
            PeopleWithinEntityComment: internalControlData?.PeopleWithinEntityComment,
            ManagementComment: internalControlData?.ManagementComment,
            ExternalComment: internalControlData?.ExternalComment,
            AccordingToFrameworkComment: internalControlData?.AccordingToFrameworkComment,
            IsAccordingToFramework: internalControlData?.IsAccordingToFramework,
            ProjectInternalControlProjectKeyControl: isJournalEntry || isFinancialStatement ? data : [],
            ProjectScopeAuditAreaSCOTABDProjectKeyControl: !isJournalEntry && !isFinancialStatement ? data : []
        };

        await updateInternalControlAuditAreas(
            { projectFormId, payload, projectId },
            {
                onError: () => {
                    setLoading(false);
                },
                onSuccess: () => {
                    setLoading(false);
                    onClose(payload, true);

                    return queryClient.invalidateQueries(FETCH_PROJECT_INTERNAL_CONTROL);
                }
            }
        );
    }

    const onDialogSave = async () => {
        setIsWarningDialogOpen(false);
        await saveKeyControls(formData);
    }

    const onDeleteKeyControl = async (projectKeyControlId) => {
        setLoading(true);
        await deleteProjectKeyControl(
            {projectKeyControlId},
            {
                onError: () => {
                    setLoading(false);
                },
                onSuccess: () => {
                    setLoading(false);
                    setHasDataDeleted(true);
                }
            }
        );
    }

    const onSubmit = async (data) => {

        if (!data)
            return;

        if (!isJournalEntry && !isFinancialStatement) {
            setFormData(data)
            const selectedRisks = data.map((keyControl) => keyControl.ProjectKeyControl.map((projectRisk) => projectRisk.ProjectKeyControlProjectRisk.map((risk) => risk.ProjectRiskId))).flat(2);
            
            // Only warn about with significant or high rmm from unselected risks
            const currentRemainingRequiredRisk = projectRisks
                ?.filter(({ProjectRiskId}) => !!ProjectRiskId)
                ?.filter((risk) => !selectedRisks.includes(risk.ProjectRiskId))
                ?.filter(({InherentRiskAssessmentId, ControlRiskAssessmentId}) => isRequiredByRMM(InherentRiskAssessmentId, ControlRiskAssessmentId)) ?? []
            
            let shouldOpenWarningDialog  = false;

            // Check if other relevant assertions is selected
            const isOtherRelevantAssertionSelected = data
                .map((keyControl) => keyControl.ProjectKeyControl)
                .flat(1)
                .some(({IsCoversOtherRelativeAssertion}) => !!IsCoversOtherRelativeAssertion)

            if(!isOtherRelevantAssertionSelected) {
                const projectScopeAuditArea = internalControlData
                    ?.ProjectScopeAuditArea
                    ?.find(({ProjectScopeAuditAreaId}) => ProjectScopeAuditAreaId === projectScopeAuditAreaId)
                    
                const otherRelevantAssertionRisk = projectScopeAuditArea
                    ?.ProjectScopeAuditAreaSCOTABDS
                    ?.find(({ProjectScopeAuditAreaSCOTABDId}) => ProjectScopeAuditAreaSCOTABDId === projectScopeAuditAreaSCOTABDId)
                    ?.OtherRelevantAssertionRisks
                    ?.at()

                // if other relevant assertion is required
                const isOtherRelevantAssertionRequired = isRequiredByRMM(
                    otherRelevantAssertionRisk?.InherentRiskAssessmentId, 
                    otherRelevantAssertionRisk?.ControlRiskAssessmentId
                )

                shouldOpenWarningDialog = isOtherRelevantAssertionRequired
                setShouldShowOtherRelevantAssertions(isOtherRelevantAssertionRequired);
            } else {
                shouldOpenWarningDialog = false
                setShouldShowOtherRelevantAssertions(false)
            }

            if (!!currentRemainingRequiredRisk?.length) {
                shouldOpenWarningDialog = true

                const remainingRisk = Array.from(currentRemainingRequiredRisk, (risk) => {
                    const assertionList = JSON.parse(risk.OtherRelevantAssertions)?.map(
                        (assertion) => assertion.AssertionName
                    );
                    return {
                        RiskName: risk?.RiskName,
                        AssertionList: assertionList,
                    };
                });

                const assertionList = remainingRisk.map((assertion) => assertion.OtherRelevantAssertions).flat(1);

                const uniqueAssertions = assertionList?.filter(
                    (value, index) => assertionList.indexOf(value) === index
                )

                // Because of other relevant assertion, there is a empty risk
                // here without a risk name, so we need to filter it out
                setRemaingRisk(remainingRisk?.map((risk) => risk?.RiskName));
                setAssertionList(uniqueAssertions);
            } else {
                setRemaingRisk([]);
            }

            if(shouldOpenWarningDialog) {
                setIsWarningDialogOpen(true);
                return;
            }
        }

        await saveKeyControls(data);
    }

    return (
        <SwipeableDrawer
            anchor="right"
            open={isVisible}
            onClose={() => { }}
            sx={MuiStyles.MainContainer}
        >
            <Flex>
                <Box sx={MuiStyles.CloseButton}>
                    <IconButton
                        variant="close-drawer-button"
                        sx={{ right: 0 }}
                        onClick={() => onClose(null, hasDataDeleted)}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>

                <FlexRow className={styles[`modal-title-bar`]}>
                    <Typography variant="title" sx={{ fontSize: "17px" }}>{TITLE}</Typography>
                </FlexRow>

                <FlexRow className={styles[`modal-title-body-bar`]}>
                    <Typography variant="body2" sx={{ fontSize: "12.5px" }}>{BODY_TEXT}</Typography>
                </FlexRow>

                <Paper elevation={0} className={styles[`main-container`]}>
                    <form>
                        <Flex>
                            <FormDrawer
                                projectRisks={projectRisks}
                                isJournalEntry={isJournalEntry}
                                isFinancialStatement={isFinancialStatement}
                                tempProjectKeyControl={tempProjectKeyControl}
                                emptyProjectKey={getEmptyProjectKey()}
                                onHandleSubmit={onSubmit}
                                onDeleteKeyControlById={onDeleteKeyControl}
                                isProjectFinalized={isProjectFinalized} />
                        </Flex>
                    </form>
                </Paper>
            </Flex>
            <CLADialog
                visible={isWarningDialogOpen}
                title={DIALOG_TITLE}
                dialogStyle={{
                    '& .MuiDialog-paper': {
                        width: 625,
                        height: 415,
                        padding: "5px 20px 0px 20px"
                    }
                }}
                customActionButton={
                    <>
                        <CancelButton
                            className={dialogStyles.cancelButton}
                            variant="outlined"
                            disableElevation
                            onClick={() => setIsWarningDialogOpen(false)}>
                            {BUTTONS.CANCEL}
                        </CancelButton>
                        <ConfirmButton
                            className={dialogStyles.confirmButton}
                            variant="contained"
                            disableElevation
                            onClick={onDialogSave}
                        >
                            {BUTTONS.SAVE}
                        </ConfirmButton>
                    </>}>
                <Flex className={styles[`dialog-container`]}>
                    <Typography variant="body" className={styles[`dialog-body-text`]}>{DIALOG_BODY}</Typography>
                    {
                        assertionList &&
                        <FlexRow className={styles[`projectrisks-container`]}>
                            {
                                assertionList?.map((assertion, index) =>
                                    <Typography
                                        key={`assertion-${index}`}
                                        variant="body"
                                        className={styles[`dialog-body-text-bold`]}>
                                        {assertion}{assertionList.length === 1 || assertionList.length === (index + 1) ? "" : ","}
                                    </Typography>)
                            }
                        </FlexRow>
                    }
                    {
                        remainingRisk &&
                        <FlexRow className={styles[`projectrisks-container`]}>
                            <Typography variant="body" className={styles[`dialog-body-text-bold`]}>
                                {remainingRisk.join(', ')}
                            </Typography>
                        </FlexRow>
                    }
                    {
                        shouldShowOtherRelevantAssertions && 
                        <FlexRow className={styles[`projectrisks-container`]}>
                            <Typography variant="body" className={styles[`dialog-body-text-bold`]}>
                                {OTHER_RELEVANT_ASEERTION_LABEL}: {otherRelevantAssertionList?.join(", ")}
                            </Typography>
                        </FlexRow>
                    }
                    <Typography variant="body" className={styles[`dialog-body-text`]}>{DIALOG_FOOTER_TEXT}</Typography>
                </Flex>
            </CLADialog>
        </SwipeableDrawer>
    )
}

export default KeyControlDrawer;