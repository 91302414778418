import { useState, useEffect } from 'react'
import { useParams } from "react-router-dom";
import { useLoading } from '@hooks/index';

import * as Constants from "@constants/forms"

import { useExportGeneralLedgerTransactionDetails } from "@services/customForm/generalLedger.js";

import {CLADialogConfirm} from '@ais/components';
import CLACSVExporter from '@components/CLACSVExporter/CLACSVExporter';

const STRING_NULL = 'null';

const CurrencyFormat = Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
});

/**
 * Note: Backend will return string 'null' instead of undefined or null when DE endpoint returns undefined or null
 * @template {string | number} T
 * @param {T} value
 * @returns {T | ''}
 */
const getFinalExportValue = (value) => value === STRING_NULL ? '' : value;

const CLAExportGLModalWrapper = ({ correlationDetailIds, toggleModal, openModal }) => {
  const { MODAL_DETAILS, FILE_NAME, FILE_TYPE, COLUMNS } = Constants.EXPORT_GL_DETAILS.EN;
  const setLoading = useLoading();
  const { projectId } = useParams()
  const [totalRecords, setTotalRecords] = useState(0);
  const [isWarning, setIsWarning] = useState(false);
  const { data: getGeneralLedgerTransactionDetails, isLoading } = useExportGeneralLedgerTransactionDetails(correlationDetailIds, projectId);

  useEffect(() => {
      setIsWarning(false)
      setTotalRecords(getGeneralLedgerTransactionDetails?.length ?? 0);
      if (getGeneralLedgerTransactionDetails) {
          if (getGeneralLedgerTransactionDetails.length >= 1000000) { setIsWarning(true) }
      }
  }, [getGeneralLedgerTransactionDetails])

  const getDataToExport = () => {
      setLoading(true);
      const exportData = [];
      if (!isLoading && getGeneralLedgerTransactionDetails) {
          getGeneralLedgerTransactionDetails.forEach((detail) => {
              const finalDebitAmountValue =
                  detail.TransactionDebitAmount === STRING_NULL ? '' : CurrencyFormat.format(detail.TransactionDebitAmount);
              const finalCreditAmountValue =
                  detail.TransactionCreditAmount === STRING_NULL ? '' : CurrencyFormat.format(detail.TransactionCreditAmount);

              if (detail.AccountNumber !== STRING_NULL) {
                  exportData.push({
                      TrialBalanceName: getFinalExportValue(detail.TrialBalanceName),
                      AccountNumber: getFinalExportValue(detail.AccountNumber),
                      AccountDescription: getFinalExportValue(detail.AccountDescription),
                      TransactionDate: getFinalExportValue(detail.TransactionDate),
                      TransactionReference: getFinalExportValue(detail.TransactionReference),
                      TransactionDescription: getFinalExportValue(detail.TransactionDescription),
                      TransactionDebitAmount: finalDebitAmountValue,
                      TransactionCreditAmount: finalCreditAmountValue
                  });
              }
          });
      }
      setLoading(false);
      return exportData;
  };
  
  return (
    <>
      {
        getGeneralLedgerTransactionDetails && 
          <CLADialogConfirm
              visible={openModal}
              title={MODAL_DETAILS.TITLE}
              cancelText={MODAL_DETAILS.BUTTON.CANCEL}
              message={`${totalRecords} ${MODAL_DETAILS.MESSAGE}`}
              warningMessage={isWarning && MODAL_DETAILS.WARNING_MESSAGE}
              customButton={
                  <CLACSVExporter
                      noHeader={false}
                      columns={COLUMNS}
                      filename={FILE_NAME}
                      extensionType={FILE_TYPE}
                      buttonText={MODAL_DETAILS.BUTTON.CONFIRM}
                      getData={getDataToExport}
                      toggleModalSwitch={toggleModal} />
              }
              onCancel={() => toggleModal()}
            />
      }
    </>
  )
}

export default CLAExportGLModalWrapper