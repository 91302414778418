import { useRef, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useDrop } from 'react-dnd';
import { ButtonGroup, Button } from '@mui/material';

import { DRAG_TYPES, FIELD_TYPES, SCHEMA_ACTION_TYPES, INTERNAL_CONTROLS_FORM_TYPE_ID} from '@ais/constants';
import { SchemaContext } from '@ais/providers';
import { PROJECT_FORM_INSTANCE } from '@constants/index';
import { DeleteIconComponent } from '@ais/assets';
import { ReactComponent as DeleteDisabledIcon } from '@assets/form_instance_delete_disabled.svg';
import { ReactComponent as DraggableArrowIcon } from '@assets/arrow_draggable.svg';
import projectFormServices from '@services/forms/projectforms';

import styles from './FormView.module.css';

const excludeFromBlur = [FIELD_TYPES.DROPDOWN, FIELD_TYPES.DATE_PICKER, FIELD_TYPES.TABLE, FIELD_TYPES.QUESTION_GROUP];

export const VFDroppableExistingField = (props) => {
  const {
    isEditMode,
    field,
    rules,
    handleBlur,
    renderField,
    projectFormId,
    section,
    sectionIndex,
    rowIndex,
    handleDeleteField,
    shouldHighlight,
    isInstance,
    formTypeId,
    sectionForViewFormInternalControl,
    onLinkClick,
  } = props;

  const HIGHLIGHT_BORDER = PROJECT_FORM_INSTANCE.EN.HIGHLIGHT_BORDER;
  const { projectId } = useParams();
  const { schema, dispatchSchema } = useContext(SchemaContext);

  const dropRef = useRef(null);

  const applyClass = () => {
    let classList = [styles['rendered-field']];
    if (isEditMode) classList.push(styles['edit-mode']);
    if (onLinkClick) classList.push(styles['with-link']);
    if (shouldHighlight === HIGHLIGHT_BORDER.RELATED) classList.push(styles['highlight-related']);
    else if (shouldHighlight === HIGHLIGHT_BORDER.MODIFIED)
      classList.push(styles['highlight-modified']);
    return classList.join(' ');
  };

  const saveFields = async (newRowIndex, fieldToSave) => {
    try {
      await projectFormServices.updateQuestionToProjectFormSchema(
        projectId,
        projectFormId,
        section.id,
        newRowIndex,
        fieldToSave
      );
    } catch (error) {
      // * Show an error here
    }
  };

  const [, drop] = useDrop({
    accept: DRAG_TYPES.RENDERED_FIELD,
    hover: (item, monitor) => {
      if (!dropRef.current) return;
      const dragIndex = item.rowIndex;
      const hoverIndex = rowIndex;
      if (dragIndex === hoverIndex) return;
      const hoverBoundingRect = dropRef.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;
      const fields = [...schema?.properties[sectionIndex]?.fields];
      const dragged = fields[dragIndex];
      const hovered = fields[hoverIndex];
      fields.splice(hoverIndex, 1, dragged);
      fields.splice(dragIndex, 1, hovered);
      saveFields(hoverIndex, dragged[0]);
      dispatchSchema({
        type: SCHEMA_ACTION_TYPES.UPDATE_SECTION_FIELDS,
        index: sectionIndex,
        payload: fields,
      });
      item.rowIndex = hoverIndex;
    },
  });

  drop(dropRef);

  const sectionType = section?.sectionType ?? section?.type;
  const formIsInternalControl = formTypeId === INTERNAL_CONTROLS_FORM_TYPE_ID;
  const VFICSectionType = sectionForViewFormInternalControl?.sectionType ?? sectionForViewFormInternalControl?.type;

  const getWrapperClass = (type) => {
    if (!isInstance && formIsInternalControl && VFICSectionType === DRAG_TYPES.SECTION_BLANK) {
      return null;
    }

    switch (type) {
      case DRAG_TYPES.SECTION_BLANK: {
        if (formIsInternalControl) return null;
        return styles['vf-section-blank-wrapper'];
      }
      case DRAG_TYPES.SECTION_CUSTOM:
        return styles['vf-section-custom-wrapper'];
      default:
        return styles['vf-rendered-field-wrapper'];
    }
  };

  return (
    <div
      onBlur={excludeFromBlur.includes(field?.type) ? null : handleBlur}
      className={getWrapperClass(sectionType)}
      style={{ width: `${field.width}%` }}
    >
      <div ref={dropRef} className={applyClass()}>
        {renderField}
        {isEditMode && <div className={styles['field-overlay']}></div>}
        {isEditMode && field?.isFormInstanceQuestion && (
          <DraggableArrowIcon className={styles['vf-rendered-field-drag-icon']} />
        )}
      </div>
      {isEditMode && (
        <div className={styles['vf-rendered-field-buttons']}>
          <ButtonGroup
            sx={{ borderColor: 'gray' }}
            variant="outlined"
            aria-label="medium button group"
          >
            <Button
              sx={{ borderColor: 'gray' }}
              disabled={!!rules?.canNotBeDeleted}
              onClick={handleDeleteField}
            >
              {!!rules?.canNotBeDeleted ? <DeleteDisabledIcon /> : <DeleteIconComponent />}
            </Button>
          </ButtonGroup>
        </div>
      )}
    </div>
  );
};
