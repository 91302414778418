import formApi from '@utilities/claApiForm';
import { useQuery, useQueryClient, useMutation, useQueries } from "@tanstack/react-query"

import * as Constants from "@constants/index";
const { DIAGNOSTIC_TYPE } = Constants.DIAGNOSTICS.EN;

const fetchDiagnosticsReport = async (projectId) => {
    try {
        const { data } = await formApi.get(`/diagnostics/reports/${projectId}`)
        return data;
    } catch (error) {
        return { status: 400, message: error };
    }
};

const fetchDiagnosticsReportByDiagnosticTypeId = async (projectId, engagementLeaderId, diagnosticTypeId, refresh = true) => {
    try {
        if(refresh || diagnosticTypeId !== DIAGNOSTIC_TYPE.SUGGESTED_STEPS_NOT_INCLUDED) {
            await formApi.post(`/diagnostics/reports/${projectId}/refresh/${diagnosticTypeId}`, { engagementLeaderId })
        }
        const { data } = await formApi.get(`/diagnostics/reports/${projectId}/${diagnosticTypeId}`)
        return data;
    } catch (error) {
        return { status: 400, message: error };
    }
};

const fetchDiagnosticsTypes = async () => {
    try {
        const { data } = await formApi.get(`/diagnostics/types`)
        return data;
    } catch (error) {
        return { status: 400, message: error };
    }
};

const fetchDiagnosticsCategories = async () => {
    try {
        const { data } = await formApi.get(`/diagnostics/categories`)
        return data;
    } catch (error) {
        return { status: 400, message: error };
    }
};

const fetchDiagnosticAuditProgramsProjectRiskId = async (projectRiskId, diagnosticTypeId, projectId) => {
    try {
        const { data } = await formApi.get(`/diagnostics/audit-programs/${projectId}/${projectRiskId}/diagnostic-type/${diagnosticTypeId}`)
        return data;
    }
    catch (error) {
        return { status: 400, message: error };
    }
}

const fetchAuditProgramsMissingProcedures = async (projectScopeAuditAreaSCOTABDId, projectId) => {
    try {
        const { data } = await formApi.get(
            `/diagnostics/audit-programs/${projectId}/project-scope-audit-area-scotabd/${projectScopeAuditAreaSCOTABDId}`
        );
        return data;
    } catch (error) {
        return { status: 400, message: error };
    }
};

export const useDiagnosticsReport = (projectId, enabled = true) => {
    return useQuery(
        [ "diagnosticsReport", projectId ],
        () => fetchDiagnosticsReport(projectId),
        {
            enabled: !!projectId && !!enabled,
            refetchOnWindowFocus: false,
        }
    )
}

export const useDiagnosticsReportByDiagnosticTypeIds = (projectId, engagementLeaderId, diagnosticTypeIds, enabled = true) => {
    return useQueries({
        queries: diagnosticTypeIds.map((diagnosticTypeId) => ({
            queryKey: ['diagnosticsReport', projectId, diagnosticTypeId],
            queryFn: () => fetchDiagnosticsReportByDiagnosticTypeId(projectId, engagementLeaderId, diagnosticTypeId),
            onError: (err) => console.error(err),
            enabled: !!projectId && !!diagnosticTypeId && (!!engagementLeaderId || engagementLeaderId == "") && !!enabled,
            refetchOnWindowFocus: false,
        }))
    })
}

export const useDiagnosticsCategories = () => {
    return useQuery(
        [ "DiagnosticsCategories"],
        () => fetchDiagnosticsCategories(),
        {
            refetchOnWindowFocus: false,
        }
    )
}

export const useDiagnosticsTypes = () => {
    return useQuery(
        ["DiagnosticsTypes"],
        () => fetchDiagnosticsTypes(),
        {
            refetchOnWindowFocus: false,
        }
    )
}

export const useRefreshDiagnostics = (projectId, engagementLeaderId) => {
    const queryClient = useQueryClient();
    return useMutation(
        async () => await formApi.post(`/diagnostics/reports/${projectId}/refresh`, { engagementLeaderId }),
        {
            onSuccess: () => {
                return queryClient.invalidateQueries("diagnosticsReport");
            }
        }
    )
}

export const useUpdateDiagnostics = (projectId) => {
    const queryClient = useQueryClient();

    return useMutation(
        async (body) => await formApi.put(`/diagnostics/reports/${projectId}`, body),
        {
            onSuccess: () => {
                return queryClient.invalidateQueries(["diagnosticsReport", projectId]);
            }
        }
    )
}

export const useDiagnosticsItemDetailsData = (diagnosticTypeId, neededParamObject, projectId) => {
    const getQueryData = (diagnosticTypeId) => {
        switch (diagnosticTypeId) {
            case DIAGNOSTIC_TYPE.SIGNIFICANT_RISK_UNADDRESSED:
            case DIAGNOSTIC_TYPE.ASSERTION_LEVEL_RISK_UNADDRESSED:
                const { projectRiskId, diagnosticTypeId } = neededParamObject;
                return {
                    fetchFunction: () => fetchDiagnosticAuditProgramsProjectRiskId(projectRiskId, diagnosticTypeId, projectId),
                    queryKeyArray: ["diagnosticAuditProgramsProjectRiskId", projectRiskId, diagnosticTypeId, projectId]
                };
            case DIAGNOSTIC_TYPE.OTHER_RELEVANT_ASSERTIONS_MISSING:
                return {
                    fetchFunction: () =>
                        fetchAuditProgramsMissingProcedures(neededParamObject?.projectScopeAuditAreaSCOTABDId, projectId),
                    queryKeyArray: [
                        "diagnosticsAuditProgramsMissingProcedures",
                        neededParamObject?.projectScopeAuditAreaSCOTABDId,
                        projectId
                    ]
                };
        }
    };
    
    const queryData = getQueryData(diagnosticTypeId);
    
    return useQuery(queryData?.queryKeyArray, () => queryData?.fetchFunction(), {
        enabled: !!diagnosticTypeId && Object.values(neededParamObject).every((val) => !!val),
        refetchOnWindowFocus: false
    });
}

export const useRefreshDiagnosticsMutation = () => {
    const queryClient = useQueryClient();
    return {
        mutate: () => {
            return queryClient.invalidateQueries(["diagnosticsReport"]);
        }
    }
} 

const fraudRiskTypeServices = {
    useDiagnosticsReport,
    useRefreshDiagnostics,
    useRefreshDiagnosticsMutation
};

export default fraudRiskTypeServices;