import {useEffect, useState} from 'react';
import styles from './FormView.module.css'
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import {CLAPerson} from '@ais/components';
import {useUserList} from '@hooks/index';

const VFRenderedFieldWrapperToolTip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} arrow />
))({
    [`& .${tooltipClasses.arrow}`]: {
        color: '#ffd7d4',
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#ffd7d4',
        color: '#bc2142',
        fontWeight: '400',
        fontSize: '.6rem',
        maxWidth: 500
    },
});

export const VFRenderedFieldWrapper = ({ children, isLockedByUser, isLocked = undefined, ...props }) => {
    const [account, setAccount] = useState();
    const {getUserById} = useUserList();

    useEffect(() => {
        const getUserData = async () => {
            const data = await getUserById(isLockedByUser?.info.userId);
            setAccount(data);
        }

        getUserData();
    }, [isLockedByUser])

    
    const renderTitle = () => {
        return (<>
            <div className={styles.field_wrapper_locked_tooltip_title}>
                <span style={{ marginRight: '1rem' }}>
                    <CLAPerson
                        styles={{
                            height: '40px',
                            width: '40px'
                        }}
                        account={account}
                        personCardInteraction='none'
                        className={styles.person}
                    />
                </span>
                <p style={{ margin: 'auto', padding: '.1rem' }}>{isLockedByUser?.info.name} is currently<br />editing this item</p>
            </div>
        </>)
    }

    const renderLockedToolTip = () => {     
        return (
            <VFRenderedFieldWrapperToolTip
                title={renderTitle()}
                open={(typeof isLocked === 'undefined') ? !!isLockedByUser?.info.userId : !!isLocked}
                placement="top"
            >
                <div style={{ pointerEvents: 'none' }}>
                    <div className={props?.className} style={props?.style}>
                        {children}
                    </div>
                </div>
            </VFRenderedFieldWrapperToolTip>)
    }

    return (
        <>
            {isLockedByUser && isLockedByUser?.info.userId ? <>{renderLockedToolTip()}</> :
                <div className={props?.className} style={props?.style}>{children}</div>}
        </>
    )
}

export default VFRenderedFieldWrapper;