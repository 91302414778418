import React, { useEffect, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { formatMoneyDecimalAndComma } from "@utilities/numberHelper";
import * as CustomFormConstants from "@constants/customForm/index";
import { useFormContext } from "react-hook-form"

import { useGetGeneralLedgerCurrentYearSummary } from "@services/customForm/generalLedger.js";
import { useGetProjectSimple } from "@services/project";

import { CLANestedDataGrid } from '@ais/components';
import GLCAccountsTable from "@components/CustomForm/PlanningAnalytics/GLCAccountsTable/GLCAccountsTable";

import { OutlinedInput } from "@mui/material";
import style from "./GLCAccounts.module.css";
import { getTrialBalanceValues } from '@components/CustomForm/PlanningAnalytics/helpers.js';
import { SchemaContext } from '@ais/providers';
import { Flex } from '@ais/palette'

const GLCAccounts = (props) => {
  const { isInstance, disabled, appDataInstanceId, setIsLoadCurrentPeriodSection } = props;
  const [ isLoading, setIsLoading ] = useState(true)
  const [generalLedgerRowData, setGeneralLedgerRowData] = useState([]);
  const [isRolledForwardSuccessfully, setIsRolledForwardSuccessfully] = useState(false);
  const { schema } = useContext(SchemaContext);
  const [trialBalanceIds, setTrialBalanceIds] = useState([]);
  const { projectId } = useParams()
  const {
    data: GeneralLedgerCurrentYearSummary,
    isLoading: GeneralLedgerCurrentYearSummaryLoading,
  } = useGetGeneralLedgerCurrentYearSummary(appDataInstanceId, trialBalanceIds, projectId);
  const [ message, setMessage ] = useState("")

  const formCanvas = useFormContext();
  
  const updateRowDataFormat = (dataArray) => {
    if (!dataArray || (dataArray && dataArray.length < 1)) return [];
    return dataArray.filter(x => x.DifferenceAmount != 0 || x.DifferencePercent != 0).map((value, index) => ({
      ...value,
      id: index,
      PriorYearFinalBalance: value.PriorYearFinalBalance ? formatMoneyDecimalAndComma(value.PriorYearFinalBalance) : "",
      CurrentYearTransactionSummary: value.CurrentYearTransactionSummary ? formatMoneyDecimalAndComma(value.CurrentYearTransactionSummary) : "",
      CurrentYearUnadjustedBalance: value.CurrentYearUnadjustedBalance ? formatMoneyDecimalAndComma(value.CurrentYearUnadjustedBalance) : "",
      DifferenceAmount: value.DifferenceAmount ? formatMoneyDecimalAndComma(value.DifferenceAmount) : "",
      DifferencePercent: value.DifferencePercent ? `${value.DifferencePercent}%` : "",
      ExpandedPanel: <GLCAccountsTable
                        GeneralLedgerId={value.GeneralLedgerId}
                        accountNumber={value.AccountNumber}
                      />
    }));
  };

  const checkGLRecords = data => {
    if (data.length !== 0 &&
        data.every(
        (el) => parseFloat(el.DifferenceAmount) === 0.00 && parseFloat(el.DifferencePercent) === 0.00
      )
    ) {
      setMessage(CustomFormConstants.GL_ROLLFORWARD_MESSAGES.SUCCESS)
    } else if (data.length === 0) {
      setMessage(CustomFormConstants.GL_ROLLFORWARD_MESSAGES.NOT_ENOUGH_DATA)
    } else {
      setMessage("")
    }
  }

  useEffect(() => {
    const getTrialBalance = getTrialBalanceValues(schema, formCanvas.getValues());

    if (!getTrialBalance)
      return;
      
    const _trialBalanceIds = getTrialBalance.map(data => data.correlationDetailId);
    setTrialBalanceIds(_trialBalanceIds);
  }, [formCanvas])

  useEffect(() => {
    if (trialBalanceIds.length === 0) {
      checkGLRecords([])
      setGeneralLedgerRowData([])
      setIsLoading(false)
      return
    }
    setIsLoading(true)
    setMessage("")
  }, [ JSON.stringify(trialBalanceIds) ])

  useEffect(() => {
    if (!isInstance || !appDataInstanceId) return;
    if (GeneralLedgerCurrentYearSummaryLoading || !GeneralLedgerCurrentYearSummary) return
    if (GeneralLedgerCurrentYearSummary.length === 0) {
      checkGLRecords(GeneralLedgerCurrentYearSummary)
      setGeneralLedgerRowData([])
      setIsLoading(false);

      return
    }

    setGeneralLedgerRowData(updateRowDataFormat(GeneralLedgerCurrentYearSummary));
    checkGLRecords(GeneralLedgerCurrentYearSummary)
    setIsLoading(false);

    return () => {
      setGeneralLedgerRowData([])
      setIsLoading(true);
      setMessage("")
    }
  }, [isInstance, GeneralLedgerCurrentYearSummaryLoading, GeneralLedgerCurrentYearSummary, appDataInstanceId]);

  useEffect(() => {
    setIsLoadCurrentPeriodSection(!isLoading)
  }, [ isLoading ])

  return (
    <>
      {isInstance ? (
        message === '' ? (
          <>
            <span className={style.GLCTitle}>Accounts with differences</span>

            <div style={{ marginTop: '20px' }}>
              <CLANestedDataGrid
                sx={{
                  "& .MuiDataGrid-row:hover": {
                    backgroundColor: "transparent !important",
                    cursor: "pointer",
                  },
                  "& .MuiDataGrid-columnHeaderTitle": {
                    whiteSpace: "pre-wrap"
                  },  
                  "&.MuiDataGrid-root .MuiDataGrid-detailPanelToggleCell ": {
                    backgroundColor: "#f7f7f7"
                  }
                }}
                columns={CustomFormConstants.GENERAL_LEDGER_COMPLETENESS_COLUMN}
                rows={generalLedgerRowData}
                headerHeight={80}
                hideFooterPagination={true}
                loading={isLoading}
              />
            </div>
          </>
        ) : (
          <Flex align="center" justify="center" className={style['glc-roll-forward-sucess-container']}>
            <span className={style['glc-roll-forward-sucess']}>{ message }</span>
          </Flex>
        )
      ) : (
        <OutlinedInput
          className={style.GLCtextArea}
          multiline
          rows={3}
          placeholder={CustomFormConstants.GENERAL_LEDGER_COMPLETENESS_PLACEHOLDER}
          inputProps={{
            maxLength: 4096,
          }}
          notched
          disabled={disabled}
        />
      )}
    </>
  );
};

export default GLCAccounts;
