import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';

import CLACheckBoxField from '@components/Forms/CLACheckBoxField/CLACheckBoxField';
import {CLADatePickerField} from '@ais/forms';
import styles from '../CLACommunicationOfDeficiencies.module.css';
import { DEFICIENCY_FORM_INSTANCE } from '@constants/forms';

import { useCommunicationStore } from '../communication';
import { DeficiencyLabelTypography } from './helpers';
import CommunicationOfDeficienciesTextField from './CommunicationOfDeficienciesCells/CommunicationOfDeficienciesTextField';
import { isSignoffLogicEnabled } from '@utilities/signoffUtility.js';
import { useFinalizedProject } from '@hooks/useProject';

const CommunicationOfOtherDeficienciesSubRow = (props) => {
	const { projectId, row, onAutoSave } = props;
	const isProjectFinalized = useFinalizedProject(projectId);
	const { COMMUNICATION_OF_DEFICIENCIES } = DEFICIENCY_FORM_INSTANCE.EN;
	const [deficiency, setDeficiency] = useState(row);
	const [shouldHighlightRow, setShouldHighlightRow] = useState(false);

	const latestSignoffTime = useCommunicationStore(state => state.latestSignoffTime);

	const handleCellChange = (field, value) => {
		setDeficiency((prev) => ({ ...prev, [field]: value }));
	};

	const handleChange = (field, value) => {
		if (onAutoSave) {
			onAutoSave(row, field, value);
		}
	};

	const dateFormat = (value) => (value ?? null);

	const updateShouldHighlightRow = (data, historicalData) => {
		const {
			IsMeritsAttentionOfManagement, 
			IsCommunicatedGovernance,
			IsCommunicatedManagement,
			IsCommunicationWritten,
			IsCommunicationOral,
			PersonCommunicatedTo,
			CommunicationDate,
		} = data;
		
		if (isNaN(latestSignoffTime) || !historicalData) {
				setShouldHighlightRow(false);
		} else if (
			IsMeritsAttentionOfManagement !== historicalData.IsMeritsAttentionOfManagement ||
			IsCommunicatedGovernance !== historicalData.IsCommunicatedGovernance ||
			IsCommunicatedManagement !== historicalData.IsCommunicatedManagement ||
			IsCommunicationWritten !== historicalData.IsCommunicationWritten ||
			IsCommunicationOral !== historicalData.IsCommunicationOral ||
			PersonCommunicatedTo !== historicalData.PersonCommunicatedTo ||
			CommunicationDate !== historicalData.CommunicationDate
		)
				setShouldHighlightRow(true)
		else {
				setShouldHighlightRow(false);
		}
	}
	useEffect(() => {
		const dateModified = new Date(row.ValidFrom).getTime();
		if (latestSignoffTime > dateModified) {
			setShouldHighlightRow(false)
		} else if ('ProjectDeficiencyId' in row && isSignoffLogicEnabled(isProjectFinalized, dateModified)) {
			updateShouldHighlightRow(row, row.ProjectDeficiencyHistorical);
		} else if ('ProjectDeficiencyCombinationId' in row && isSignoffLogicEnabled(isProjectFinalized, dateModified)) {
			updateShouldHighlightRow(row, row.ProjectDeficiencyCombinationHistorical);
		}
	}, [latestSignoffTime, row])

	return (
		<>
			<Box sx={{ p: `20px 65px 10px 65px` }}>
				<div>
					<div className={styles['cood-header-row']}>
						<div className={styles['cood-header-cell']}>
							{COMMUNICATION_OF_DEFICIENCIES.MANAGEMENT_COMMENT_DEFICIENCY.MERITS_MANAGEMENT}
						</div>
						<div className={styles['cood-header-cell']}>
							{COMMUNICATION_OF_DEFICIENCIES.MATERIAL_WEAKNESS.COMMUNICATED_TO}
						</div>
						<div className={styles['cood-header-cell']}>
							{COMMUNICATION_OF_DEFICIENCIES.MATERIAL_WEAKNESS.COMMUNICATED_BY}
						</div>
						<div className={styles['cood-header-cell']}>
							{COMMUNICATION_OF_DEFICIENCIES.MANAGEMENT_COMMENT_DEFICIENCY.NAME_PERSON_COMMUNICATED_TO}
						</div>
						<div className={styles['cood-header-cell']}>
							{COMMUNICATION_OF_DEFICIENCIES.MANAGEMENT_COMMENT_DEFICIENCY.DATE}
						</div>
					</div>
					<div className={styles['cood-body-row']} style={shouldHighlightRow ? {border: '4px dashed red'} : null}>
						<div className={`${styles['cood-body-cell']} ${styles['cood-merit-cell']}`}>
							<CLACheckBoxField
								checked={deficiency.IsMeritsAttentionOfManagement}
								name="IsMeritsAttentionOfManagement"
								onChange={(e) => {
									handleCellChange('IsMeritsAttentionOfManagement', !deficiency.IsMeritsAttentionOfManagement);
									handleChange('IsMeritsAttentionOfManagement', e.target.checked);
								}}
							/>
						</div>
						{deficiency.IsMeritsAttentionOfManagement && (
							<React.Fragment>
								<div className={styles['cood-body-cell']}>
									<CLACheckBoxField
										label={<DeficiencyLabelTypography label={COMMUNICATION_OF_DEFICIENCIES.GOVERNANCE} />}
										checked={deficiency.IsCommunicatedGovernance}
										name="governance"
										onChange={(e) => {
											handleCellChange('IsCommunicatedGovernance', !deficiency.IsCommunicatedGovernance);
											handleChange('IsCommunicatedGovernance', e.target.checked);
										}}
									/>
									<CLACheckBoxField
										label={<DeficiencyLabelTypography label={COMMUNICATION_OF_DEFICIENCIES.MANAGEMENT} />}
										checked={deficiency.IsCommunicatedManagement}
										name="IsCommunicatedManagement"
										onChange={(e) => {
											handleCellChange('IsCommunicatedManagement', !deficiency.IsCommunicatedManagement);
											handleChange('IsCommunicatedManagement', e.target.checked);
										}}
									/>
								</div>
								<div className={styles['cood-body-cell']}>
									<CLACheckBoxField
										label={
											<DeficiencyLabelTypography label={COMMUNICATION_OF_DEFICIENCIES.MATERIAL_WEAKNESS.WRITTEN} />
										}
										checked={deficiency.IsCommunicationWritten}
										name="IsCommunicationWritten"
										onChange={(e) => {
											handleCellChange('IsCommunicationWritten', !deficiency.IsCommunicationWritten);
											handleChange('IsCommunicationWritten', e.target.checked);
										}}
									/>
									<CLACheckBoxField
										label={<DeficiencyLabelTypography label={COMMUNICATION_OF_DEFICIENCIES.MATERIAL_WEAKNESS.ORAL} />}
										checked={deficiency.IsCommunicationOral}
										name="IsCommunicationOral"
										onChange={(e) => {
											handleCellChange('IsCommunicationOral', !deficiency.IsCommunicationOral);
											handleChange('IsCommunicationOral', e.target.checked);
										}}
									/>
								</div>
								{deficiency.IsCommunicationOral && (
									<React.Fragment>
										<div className={`${styles['cood-body-cell']} ${styles['cood-personcommunicatedto-cell']}`}>
											<CommunicationOfDeficienciesTextField
												row={deficiency}
												handlePersonCommunicatedToSave={onAutoSave}
											/>
										</div>
										<div className={styles['cood-body-cell']}>
											<CLADatePickerField
												defaultValue={deficiency?.CommunicationDate}
												onChange={(date) => {
													handleCellChange('CommunicationDate', dateFormat(date));
													handleChange('CommunicationDate', dateFormat(date));
												}}
												placeholder={'Enter Date'}
												allowMultiLineLabel
											/>
										</div>
									</React.Fragment>
								)}
							</React.Fragment>
						)}
					</div>
				</div>
			</Box>
		</>
	);
};

export default CommunicationOfOtherDeficienciesSubRow;
