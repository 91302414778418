import React from "react";
import { Button } from "@mui/material";
import styles from "./DefaultValueDrawer.module.css";
import { colors } from '@ais/theme';
import {CLASimpleDrawer} from "@ais/components";
import PropTypes from "prop-types";

import { DIALOG, FORM_DESIGNER } from "@constants/forms";

const DefaultValueDrawer = (props) => {
  const {
    drawerVisible,
    onClose,
    formComponentName,
    handleSave,
    isValidForm,
    children,
    headerText,
    displayWarning,
  } = props;

  const { DEFAULT_VALUES, WARNING, WARNING_MESSAGE, INDUSTRY, DEFAULT_VALUE } =
    DIALOG.DEFAULT_ANSWERS.GENERAL;
  const { CANCEL, SAVE } = FORM_DESIGNER.BUTTONS;

  const getWarningMessage = () => {
    if (displayWarning) {
      return (
        <>
          <span style={{ color: colors.red[200] }}>{WARNING}</span>{" "}
          {WARNING_MESSAGE}
        </>
      );
    }
    return <></>;
  };

  return (
    <CLASimpleDrawer
      anchor="right"
      isVisible={drawerVisible}
      onClose={onClose}
      headerTitle="Advanced Default Values"
    >
      <div className={styles["def-drawer-wrapper"]}>
        <div className={styles["def-header-container"]}>
          <div className={styles["def-header-title-text"]}>
            {formComponentName ?? "Form component"} {DEFAULT_VALUES}
          </div>
          <div className={styles["def-header-text"]}>{headerText ?? ""}</div>
          <div className={styles["def-header-text"]}>{getWarningMessage()}</div>
        </div>
        <div className={styles["def-button-container"]}>
          <div className={styles["def-buttons"]}>
            <Button variant="outlined" disableElevation onClick={onClose}>
              {CANCEL}
            </Button>
          </div>
          <div className={styles["def-buttons"]}>
            <Button
              disabled={!isValidForm}
              variant="contained"
              disableElevation
              onClick={handleSave}
            >
              {SAVE}
            </Button>
          </div>
        </div>
      </div>
      <div className={styles["column-header-wrapper"]}>
        <div className={styles["column-header-text-column1"]}>
          {DEFAULT_VALUE}
        </div>
        <div className={styles["column-header-text-column2"]}>{INDUSTRY}</div>
      </div>
      {children}
    </CLASimpleDrawer>
  );
};

export default DefaultValueDrawer;

DefaultValueDrawer.propTypes = {
  drawerVisible: PropTypes.bool,
  onClose: PropTypes.func,
  formComponentName: PropTypes.string,
  handleSave: PropTypes.func,
  isValidForm: PropTypes.bool,
  children: PropTypes.node,
  headerText: PropTypes.string,
  displayWarning: PropTypes.bool,
};

DefaultValueDrawer.defaultProps = {
  displayWarning: false,
  headerText: "",
};
