import { useContext, useEffect } from 'react';
import useProjectFormInstanceConcurrentLocking from '@hooks/useProjectFormInstanceConcurrentLocking'
import { TailorProceduresDrawerContext } from '@contexts/TailorProcedures/TailorProceduresDrawerContext';
import { TailorProcedureContext } from '@components/Forms/state';
import { TAILOR_PROCEDURE_REDUCER } from '@constants/forms';
import { useIdle } from 'react-use';
import { WAITING_TIME } from '@ais/constants'; 

const useTailorProceduresConcurrency = ({
    procedureComponentId
}) => {
    const { setShowProcedureDrawer } = useContext(TailorProceduresDrawerContext);
    const { dispatchTailorProcedures } = useContext(TailorProcedureContext);

    // Each Tailor Procedure needs to have one of these
    const {
        isLocked,
        concurrentValue,
        emitLockEvent,
        emitResetEvent, 
        emitUpdateEvent,
        selfUnlockProcedureComponent,

    } = useProjectFormInstanceConcurrentLocking(null, {
        procedureComponentId,
        useTimers: false
    })

    const isIdle = useIdle(WAITING_TIME.MINS_15);

    useEffect(() => {
        if(!isIdle) return;

        setShowProcedureDrawer({ auditArea: null, show: false, sectionId: null })
    }, [isIdle])


    useEffect(() => {
        if(!concurrentValue) return;

        const { tailorProcedure } = concurrentValue; 

        if(!tailorProcedure) return;

        dispatchTailorProcedures({
            type: TAILOR_PROCEDURE_REDUCER.REALIGN_PROCEDURE_FROM_CONCURRENCY,
            payload: {
                sectionId: procedureComponentId,
                summaryProcedures: tailorProcedure.summaryProcedures ?? [],
                customProcedures: tailorProcedure.customProcedures ?? [],
                assertions: tailorProcedure.assertions ?? [],
            }
        })
    }, [concurrentValue])
    

    const emitOpenEvent = (initialProcedures) => {
        emitLockEvent(initialProcedures)
    }

    const emitCloseEvent = () => {
        emitResetEvent();
    }

    const emitSaveEvent = (updatedProcedures) => {
        emitUpdateEvent(updatedProcedures);
    }

    const selfUnlock = () => {
        selfUnlockProcedureComponent(procedureComponentId)
    }

    return {
        isOpened: isLocked, // search for context if it's actually opened
        emitOpenEvent,
        emitCloseEvent, 
        emitSaveEvent,
        emitResetEvent,
        concurrentValue,
        selfUnlock
    }
}

export default useTailorProceduresConcurrency;