import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from "prop-types";
import {
    Box,
    Button,
    Collapse,
    IconButton,
    Typography,
    TextField
} from '@mui/material';
 
import { Comment as NoComment, CommentResolved as WithComment } from '@ais/assets'; 
import {CollapseIconComponent, ExpandIconComponent} from "@ais/assets";

import { useProjectFormInstanceProvider } from '@providers';
import CLASCOTABDsCollapsible from './CLASCOTABDsCollapsible';
import { useAccountAssertionLevelRiskState } from './AccountAssertionLevelRiskContext';
import formServices from '@services/forms/forms';
import styles from './CLAAccountAssertionLevelRisk.module.css';
import { boxAuditAreaName, boxRowStyles, buttonStyles, typoAuditAreaName, typoSCOTABDS } from './muiStyles';
import { RISK_ASSESSMENT_FORM_INSTANCE } from '@constants/forms';
import CustomToast from '@components/CustomToast/CustomToast';
import { PROJECT_FORM_INSTANCE } from '@constants/index';

const CLAAuditAreaCollapsible = (props) => {
    const { ACCOUNT_ASSERTION_LEVEL_RISK: CONSTANT_TEXT } = RISK_ASSESSMENT_FORM_INSTANCE.EN;
    const HIGHLIGHT_BORDER = PROJECT_FORM_INSTANCE.EN.HIGHLIGHT_BORDER;
    const {
        auditArea,
        scotabdList,
        index,
        inherentRiskOptions,
        controlRiskOptions,
        isInternalControlEffectiveness,
        financialRisks,
        disabled,
    } = props;

    const { expandAll } = useAccountAssertionLevelRiskState();
    const [isExpanded, setIsExpanded] = useState(false);
    const [showComment, setShowComment] = useState(false);
    const [commentValue, setCommentValue] = useState(null);
    const [isValueChanged, setIsValueChanged] = useState(false);
    const [loadingProjectScopeAuditArea, setLoadingProjectScopeAuditArea] = useState(false)
    const [successProjectScopeAuditArea, setSuccessProjectScopeAuditArea] = useState(false)
    const [errorProjectScopeAuditArea, setErrorProjectScopeAuditArea] = useState(false)
    const [shouldHighlight, setShouldHighlight] = useState();
    const { projectId, projectFormId } = useParams();

    const {
        auditAreaScotabdRisk,
        modifiedRiskAssessmentSummaryAfterSignOff,
        actions: {
            riskAssessmentIdentifyModifiedAnswerAfterSignOff
        }
    } = useProjectFormInstanceProvider();

    const handleSaveComment = async () => {
        setLoadingProjectScopeAuditArea(true);
        riskAssessmentIdentifyModifiedAnswerAfterSignOff(auditAreaScotabdRisk);
        const data = {
            ProjectScopeAuditAreaId: auditArea.projectScopeAuditAreaId,
            ProjectScopeId: auditArea.projectScopeId,
            AuditAreaId: auditArea.auditAreaId,
            AuditAreaName: auditArea.auditAreaName,
            RiskAssessmentComment: commentValue,
            RequestProjectFormId: projectFormId
        }
        const result = await formServices.updateProjectScopeAuditArea(projectId, auditArea.projectScopeAuditAreaId, data);

        if (result?.status === 200) {
            auditArea.riskAssessmentComment = commentValue;
            setShowComment(false);
            setLoadingProjectScopeAuditArea(false);
            setSuccessProjectScopeAuditArea(true)
        } else {
            setLoadingProjectScopeAuditArea(false);
            setErrorProjectScopeAuditArea(true)
        }
    }

    const applyClass = () => {
        let classList = [styles['comment-box-button-wrapper']];
        if (shouldHighlight === HIGHLIGHT_BORDER.MODIFIED) classList.push(styles['highlight-modified']);
        return classList.join(' ');
    };

    const displayCommentIcon = () => auditArea?.riskAssessmentComment ? <WithComment /> : <NoComment />
    useEffect(() => {
        setIsExpanded(expandAll);
    }, [expandAll]);

    useEffect(() => {
        if (modifiedRiskAssessmentSummaryAfterSignOff) {
            const shouldHighlight = !!modifiedRiskAssessmentSummaryAfterSignOff
                .find(signOff => signOff.auditAreaId === auditArea.auditAreaId && signOff.projectScopeAuditAreaId === auditArea.projectScopeAuditAreaId) ? HIGHLIGHT_BORDER.MODIFIED : null;

            setShouldHighlight(shouldHighlight);
        }
    }, [modifiedRiskAssessmentSummaryAfterSignOff]);

    return (
        <Box>
            <Box sx={boxRowStyles}>
                <div className={styles['collapse-icon-wrapper']}>
                    <IconButton
                        sx={{ p: 0 }}
                        onClick={() => { setIsExpanded(prevIsExpanded => !prevIsExpanded) }}>
                        {isExpanded ? <ExpandIconComponent /> : <CollapseIconComponent />}
                    </IconButton>
                </div>
                <div className={`${styles.column} ${styles['first-auditarea-column']} ${index > 0 && styles['row-border']}`}>
                    <Box sx={{
                        ...(boxRowStyles),
                        width: '100%'
                    }}>
                        <Box sx={boxAuditAreaName}>
                            <Typography sx={typoAuditAreaName}>
                                {auditArea?.auditAreaName} 
                            </Typography>
                        </Box>
                    </Box>
                </div>
                <div className={`${styles.column} ${styles['second-auditarea-column']} ${index > 0 && styles['row-border']}`}>
                    <Box sx={{
                        flexShrink: 0,
                        width: '100px',
                        ml: '30px'
                    }}
                        className={applyClass()}
                    >
                        {!showComment && <IconButton
                            sx={{ padding: 0 }}
                            onClick={() => setShowComment(true)}
                        >
                            {displayCommentIcon()}
                        </IconButton>}
                        {showComment && displayCommentIcon()}

                    </Box>
                </div>
            </Box>
            {
                showComment &&
                <Box sx={{ ...(boxRowStyles), marginLeft: '50px' }}>
                    <div className={styles['comment-wrapper']}>
                        <TextField
                            multiline={true}
                            minRows="3"
                            maxRows="6"
                            label={CONSTANT_TEXT.COMMENTS}
                            placeholder={CONSTANT_TEXT.COMMENT_PLACEHOLDER}
                            defaultValue={auditArea?.riskAssessmentComment}
                            inputProps={{ maxLength: 4096 }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            sx={{ width: '100%' }}
                            onChange={(e) => {
                                setCommentValue(e.target.value);
                                setIsValueChanged(true);
                            }}
                            disabled={disabled}
                        />
                        <Box>
                            <Button disabled={disabled || !isValueChanged} variant='contained' sx={buttonStyles} onClick={() => {handleSaveComment(); setIsValueChanged(false)}}>{CONSTANT_TEXT.SAVE}</Button>
                            <Button variant='outlined' sx={buttonStyles} onClick={() => {setShowComment(false); setIsValueChanged(false);}}>{CONSTANT_TEXT.CANCEL}</Button>
                        </Box>
                    </div>
                </Box>
            }
            <Collapse in={isExpanded} collapsedSize={0} data-test="collapse-component" sx={{ ml: '40px' }}>
                <Box sx={{ ...(boxRowStyles) }}>
                    <div className={styles['scotabd-wrapper']}>
                        <Typography sx={typoSCOTABDS} data-test='scotabds-title'>
                            {CONSTANT_TEXT.SCOTABDS}
                        </Typography>
                    </div>
                </Box>
                {scotabdList && scotabdList.map((scotabd, index) =>
                    <CLASCOTABDsCollapsible
                        key={index}
                        index={index}
                        scotabd={scotabd}
                        controlRiskOptions={controlRiskOptions}
                        inherentRiskOptions={inherentRiskOptions}
                        isInternalControlEffectiveness={isInternalControlEffectiveness}
                        financialRisks={financialRisks} 
                        disabled={disabled}
                    />)}
            </Collapse>
            <CustomToast 
                error={errorProjectScopeAuditArea}
                success={successProjectScopeAuditArea}
                loading={loadingProjectScopeAuditArea}
            />
        </Box >
    );
};

CLAAuditAreaCollapsible.propTypes = {
    auditArea: PropTypes.object,
    index: PropTypes.number,
    inherentRiskOptions: PropTypes.array,
    controlRiskOptions: PropTypes.array,
    isInternalControlEffectiveness: PropTypes.bool,
    financialRisks: PropTypes.array,
    disabled: PropTypes.bool,
}

export default CLAAuditAreaCollapsible;