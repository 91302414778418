import { Link } from 'react-router-dom';
import { MenuList, MenuItem, Paper, Typography } from '@mui/material';

import { colors } from '@ais/theme';

export const CLALinkPanel = (props) => {
  const { fields, title, isInstance, projectId, disabled } = props;

  const generateURL = (form, questions) => {
    const preURLValue = isInstance ? `/project-management/${projectId}/project-form-instance` : `/forms-designer/${form.methodologyVersionId}`;
    return `${preURLValue}/${form.formId}?sectionId=${form.sectionId}&questionIds=${questions}`;
  }

  return (
    <Paper sx={{ p: 4 }}>
      <Typography variant="title" sx={{ fontSize: '24px', pl: 1 }}>
        {title}
      </Typography>
      <MenuList
        sx={{ mt: 2, display: 'flex', flexDirection: 'column', rowGap: 1 }}
      >
        {fields &&
          fields.map((f, index) => {
            const questions = f.questionIds.join(',');
            return (
              <MenuItem
                key={index}
                component={Link}
                to={generateURL(f, questions)}
                target="_blank"
                sx={{
                  px: 1,
                  '&.MuiMenuItem-root': {
                    color: colors.navy[300],
                  },
                }}
                disabled={disabled}
              >
                {`${f.formName} - ${f.sectionName}`}
              </MenuItem>
            );
          })}
      </MenuList>
    </Paper>
  );
};

export default CLALinkPanel;
