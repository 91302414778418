import { useContext, useState, useEffect } from 'react';
import { ProjectFormInstanceConcurrencyContext } from '@contexts/ProjectFormInstanceConcurrencyContext';

// Listens to events that contain the following details
// If an event arrived then return it
const useProjectFormInstanceConcurrentListener = () => {
    const [latestValue, setLatestValue] = useState({});
    const [concurrentValue, setConcurrentValue] = useState({});
    const [listenerObject, setListenerObject] = useState({});
    const { state } = useContext(ProjectFormInstanceConcurrencyContext);

    // Update concurrent value if current value is the one that used this hook
    useEffect(() => {
        // should not process if lastSessionUpdate is empty
        if(!state?.lastSessionUpdate) return;

        const { 
            summaryProcedureId: lastSummaryProcedureId,
            projectFormProcedureStepId: lastProjectFormProcedureStepId,
            procedureComponentId: lastProcedureComponentId,
            customFormObjectId: lastCustomFormObjectId,
            answer
        } = state.lastSessionUpdate

        // get each keys to listener object
        const listenerKeys = Object.keys(listenerObject);

        // foreach listener key check if the current session is the one that used this hook
        listenerKeys.forEach((key) => {
            
            const {
                summaryProcedureId,
                projectFormProcedureStepId,
                procedureComponentId,
                customFormObjectId,
            } = listenerObject[key];

            // Confirm if the last value fetched from concurrency has the same parameters as the listener
            // if so, update concurrent value to hold the latest value
            if(
                summaryProcedureId == lastSummaryProcedureId &&
                projectFormProcedureStepId == lastProjectFormProcedureStepId &&
                procedureComponentId == lastProcedureComponentId &&
                customFormObjectId == lastCustomFormObjectId
            ) {
                const answerAsObject = !!answer ? JSON.parse(answer) : { }
                setConcurrentValue((prev) => ({
                    ...prev,
                    [key]: answerAsObject
                }));
                setLatestValue(({[key]: answerAsObject}))
            }
        }
    )
        
    }, [state?.lastSessionUpdate]);

    const subscribe = (key, {
        questionId,
        summaryProcedureId,
        projectFormProcedureStepId,
        procedureComponentId,
        customFormObjectId,
    }) => {
        setListenerObject((prev) => ({
            ...prev,
            [key]: {
                questionId,
                summaryProcedureId,
                projectFormProcedureStepId,
                procedureComponentId,
                customFormObjectId,
            }
        }));
        setConcurrentValue((prev) => ({
            ...prev,
            [key]: null
        }));
    }

    const unsubscribe = (key) => {
        setListenerObject((prev) => {
            const newListenerObject = { ...prev };
            delete newListenerObject[key];
            return newListenerObject;
        });
    }

    const resetListeners = () => {
        setListenerObject(() => {
            return {}
        });
    }

    return {
        concurrentValue,
        subscribe,
        unsubscribe,
        resetListeners,
        latestValue,
    };
}

export default useProjectFormInstanceConcurrentListener;