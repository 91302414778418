import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useMount, useUnmount } from 'react-use';
import { useFormContext } from "react-hook-form"
import { useQueryClient } from "@tanstack/react-query"

import {
  AuditAreasDrawer,
  SCOTABDsDrawer,
  AuditAreaRow,
  ButtonComponent,
  FinancialStatementLineDrawer,
} from './components'; 

import { WAITING_TIME } from '@ais/constants';
import formServices from '@services/forms/forms';
import projectServices from '@services/project';
import projectScopeAuditAreaCategory from '@services/forms/projectscopeauditareacategory';
import { useRoomIdle, useUpdateMyPresence } from '../RoomProvider';
import { useProjectScope } from './hooks/useProjectScope';
import { sortReviewerSignoffAsc, getLatestDeletedData } from '@utilities/dateHelpers.js';
import { isSignoffLogicEnabled } from '@utilities/signoffUtility.js';
import { useFinalizedProject } from '@hooks/useProject';

const CLAProjectScopeAuditArea = (props) => {

  const updateMyPresence = useUpdateMyPresence();
  const { methodologyIndustries, trialBalanceField, disabled, field, signOffList } = props;
  const { projectId, projectFormId } = useParams();
  const isProjectFinalized = useFinalizedProject(projectId);
  const { id: trialBalanceId } = trialBalanceField;
  const [methodologyVersionId, setMethodologyVersionId] = useState(undefined);
  const [selectedAuditAreaId, setSelectedAuditAreaId] = useState(undefined);
  const [selectedProjectScopeAuditAreaId, setSelectedProjectScopeAuditAreaId] = useState(undefined);
  const [auditAreasOrder, setAuditAreasOrder] = useState([]);
  const [allScotabds, setAllScotabds] = useState([]);
  const [auditAreaDrawerVisible, setAuditAreaDrawerVisible] = useState(false);
  const [scotabdsDrawerVisible, setScotabdsDrawerVisible] = useState(false);
  const [financialStatementLineDrawer, setFinancialStatementDrawerVisible] = useState(false);
  const [currentAuditArea, setCurrentAuditArea] = useState({});
  const [trialBalances, setTrialBalances] = useState([]);
  const [isMount, setIsMount] = useState(false);
  const [addAuditHighlight, setAddAuditHighlight] = useState(false)

  const queryClient = useQueryClient();

  const formCanvas = useFormContext();
  const {
    projectScopeId, 
    auditAreas,
    scotabds,
    financialStatements,
    deletedAuditAreas,
    deletedFinancialStatements,
    fetchProjectScopeAuditAreaData,
    fetchProjectScopeData,
    concurrencyEventReceived,
    setConcurrencyEventReceived
  } = useProjectScope()

  const handleCurrentAuditArea = (auditArea) => {
    setCurrentAuditArea(auditArea);
  };

  useEffect(() => {

    const init = async () => {
      await fetchProjectScopeData();
    }
    if (!concurrencyEventReceived) { 
      init()
    }
  }, [concurrencyEventReceived])
  const isIdle = useRoomIdle()

  useEffect(() => {
      if(isIdle) {      
        const delay = setTimeout(() => { 
          setAuditAreaDrawerVisible(false)
          setScotabdsDrawerVisible(false)
          setFinancialStatementDrawerVisible(false) 
        }, WAITING_TIME.MINS_2);
        return () => {
            clearTimeout(delay);
        }; 
    } 
  }, [ isIdle ])

  useEffect(() => {
    const init = async () => {
      const response = await projectServices.getProjectByProjectId(projectId);
      if (response.status === 200) setMethodologyVersionId(response.data.MethodologyVersionId);
    };
    if (!concurrencyEventReceived) init();
  }, [concurrencyEventReceived]);

  useEffect(() => {
    const init = async () => {
      const response = await formServices.getSCOTABDsByMethodologyVersionId(methodologyVersionId);
      if (response.status === 200) setAllScotabds(response.data);
    };
    if (methodologyVersionId && !concurrencyEventReceived) init();
  }, [methodologyVersionId, concurrencyEventReceived]);

  useMount(() => setIsMount(true));

  useUnmount(() => setIsMount(false));

  useEffect(() => {
    let shouldHighlight = false
    const reviewerSignOffs = sortReviewerSignoffAsc(signOffList)
    if(reviewerSignOffs.length){
      const dateModified = getLatestDeletedData(deletedAuditAreas);
      const isSignoffEnabled = isSignoffLogicEnabled(isProjectFinalized, dateModified);
      shouldHighlight = isSignoffEnabled && new Date(reviewerSignOffs[reviewerSignOffs?.length - 1]?.signOffDate).getTime() < dateModified
    }
    setAddAuditHighlight(shouldHighlight)
  }, [deletedAuditAreas, auditAreas, signOffList])
  
  useEffect(async () => {
    if (!isMount) return;
    const newTrialBalanceValue = formCanvas.getValues(trialBalanceId);
    if (JSON.stringify(newTrialBalanceValue) != JSON.stringify(trialBalances)) {
      try {
        setTrialBalances(newTrialBalanceValue);
        const removeScopes = financialStatements
          .map((financial) => {
            if (financial.FinancialStatementLineItems?.length > 0)
              return projectScopeAuditAreaCategory.saveProjectScopeAuditAreaCategory(
                projectId,
                financial.ProjectScopeAuditAreaId,
                projectFormId,
                []
              );
          })
          .filter((i) => i);
        if (removeScopes && removeScopes.length > 0)
          await Promise.all(removeScopes).then(fetchProjectScopeData);
      } catch (error) {
        console.error(error);
      }
    }
  }, [formCanvas.getValues(trialBalanceId)]);

  useEffect(() => {
    let newTrialBalanceValue = []
    const tbs = formCanvas.getValues(trialBalanceId)
    if(tbs && tbs.length > 0) {
      newTrialBalanceValue = tbs.map((trialBalance) => ({
        id: trialBalance.value,
      }))
    }
    if (JSON.stringify(newTrialBalanceValue) != JSON.stringify(trialBalances))
      setTrialBalances(formCanvas.getValues(trialBalanceId));
  }, [formCanvas.getValues(trialBalanceId)]);
 
  const handleAuditAreaDrawer = (open) => {
    setAuditAreaDrawerVisible(open)
    const focusedId = open ? field.id : null
    updateMyPresence({ focusedId: focusedId })
  }

  const handleFinancialStatementDrawer = (open) => {
    setFinancialStatementDrawerVisible(open)
    const focusedId = open ? field.id : null
    updateMyPresence({ focusedId: focusedId })
  }

  const handleScotabdsDrawer = (open) => {
    setScotabdsDrawerVisible(open)
    const focusedId = open ? field.id : null
    updateMyPresence({ focusedId: focusedId })
  }

  return (
    <>
      {auditAreas.length ? (
        auditAreas
          .sort((a, b) => {
            const finder = (data, dataToTest) => {
              if (dataToTest.IsCustom) return undefined;
              else return data.auditAreaId === dataToTest.AuditAreaId;
            };
            const aSort = auditAreasOrder.find((aao) => finder(aao, a));
            const bSort = auditAreasOrder.find((aao) => finder(aao, b));
            if (aSort && bSort) return aSort.displayOrder - bSort.displayOrder;
            else return 0;
          })
          .map((auditArea, i) => {
            const filteredScotabds = scotabds
              .filter((s) => s.ProjectScopeAuditAreaId === auditArea.ProjectScopeAuditAreaId)
              .map((fs) => ({
                ...fs,
                AccountBalanceTypeList: JSON.parse(fs.AccountBalanceTypeList) ?? [],
                AssertionList: JSON.parse(fs.AssertionList),
              }));
            return (
              <AuditAreaRow
                key={i}
                projectId = {projectId}
                trialBalances={trialBalances}
                auditAreaData={auditArea}
                scotabdsData={filteredScotabds}
                setVisible={{
                  auditArea: handleAuditAreaDrawer,
                  scotabds: handleScotabdsDrawer,
                  financialStatements: handleFinancialStatementDrawer,
                }}
                financialStatements={financialStatements}
                setSelectedAuditAreaId={setSelectedAuditAreaId}
                fetchProjectScopeAuditAreaData={fetchProjectScopeAuditAreaData}
                setSelectedProjectScopeAuditAreaId={setSelectedProjectScopeAuditAreaId}
                isLast={auditAreas.length === i + 1}
                handleCurrentAuditArea={handleCurrentAuditArea}
                disabled={disabled}
                signOffList={signOffList}
                deletedAuditAreas={deletedAuditAreas}
                deletedFinancialStatements={deletedFinancialStatements}
              />
            );
          })
      ) : (
        <AuditAreaRow
          projectId = {projectId}
          setVisible={{
            auditArea: handleAuditAreaDrawer,
            scotabds: handleScotabdsDrawer,
            financialStatements: handleFinancialStatementDrawer, 
          }
        }
          handleCurrentAuditArea={handleCurrentAuditArea}
          disabled={disabled}
        />
      )}
      {auditAreas.length ? (
        <ButtonComponent
          disabled={disabled}
          label={null}
          buttonName="Add another audit area"
          onClick={() => {
            handleAuditAreaDrawer(true)
          }}
          shouldHighlight={addAuditHighlight}
        />
      ) : null}
      <AuditAreasDrawer
        methodologyVersionId={methodologyVersionId}
        projectScopeId={projectScopeId}
        defaultAuditAreas={auditAreas}
        visible={auditAreaDrawerVisible}
        setVisible={handleAuditAreaDrawer}
        methodologyIndustries={methodologyIndustries}
        setAuditAreasOrder={setAuditAreasOrder}
        fetchData={fetchProjectScopeData} 
        setConcurrencyEventReceived={setConcurrencyEventReceived}
      />
      <SCOTABDsDrawer
        methodologyIndustries={methodologyIndustries}
        methodologyVersionId={methodologyVersionId}
        visible={scotabdsDrawerVisible}
        setVisible={handleScotabdsDrawer}
        allScotabds={allScotabds}
        defaultScotabds={scotabds}
        selectedProjectScopeAuditAreaId={selectedProjectScopeAuditAreaId}
        selectedAuditAreaId={selectedAuditAreaId}
        fetchData={fetchProjectScopeData}
        setConcurrencyEventReceived={setConcurrencyEventReceived}
      />
      <FinancialStatementLineDrawer
        trialBalances={trialBalances}
        visible={financialStatementLineDrawer}
        financialStatements={financialStatements}
        setVisible={handleFinancialStatementDrawer}
        currentAuditArea={currentAuditArea}
        fetchData={fetchProjectScopeAuditAreaData}
        setConcurrencyEventReceived={setConcurrencyEventReceived}
      />
    </>
  );
};

export default CLAProjectScopeAuditArea;
