import Container from "@mui/material/Container"
import { useRouteError, isRouteErrorResponse } from "react-router-dom"

export const RouteError = () => {
    const error = useRouteError()
    console.error(error)

    if(isRouteErrorResponse(error)) {
        return (
            <Container>
                <p>Found error from route: { error.data }</p>
            </Container>
        )
    }
    return (
        <Container>
            <p>Error from components/pages: { error.message }</p>
        </Container>
    )
}
