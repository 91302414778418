import React, {
    useState,
    useEffect
} from 'react';

import {
    Button,
    TableContainer,
    Table,
    TableBody,
    TableCell,
    TableFooter,
    TableHead,
    TableRow,
    Typography
} from '@mui/material';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import HelpIcon from '@mui/icons-material/Help';
import AddIcon from '@mui/icons-material/Add';
import { colors } from '@ais/theme';
import { styled } from '@mui/material/styles';

import {CLADialogConfirm} from "@ais/components";
import { DEFICIENCY_FORM_INSTANCE } from '@constants/forms';
import { COMMON } from '@constants'

import CLAEvaluationOfDeficienciesTableRow from './CLAEvaluationOfDeficienciesTableRow';
import {
    AddCombinationDrawer, 
    CLAEvaluationOfDeficienciesCombinationRow,
    EditCombinationDrawer,
} from './DeficiencyCombination';


const CLAEvaluationOfDeficienciesTable = (props) => {
    const { EVALUATION_OF_DEFICIENCIES } = DEFICIENCY_FORM_INSTANCE.EN;
    const { MODAL_HEADER, BUTTON, MODAL_BODY } = COMMON.EN;
    const { 
        rows, 
        columns, 
        onCheckboxChange, 
        projectId, 
        deficiencyCombinations,
        allDeficiencies,
        highlightedDeficiencyIds,
        highlightedDeficiencyCombinationIds,
        onCombinationConfirmDeleteClick,
    } = props;
    const [disabled, setDisabled] = useState(false);
    const [showDrawer, setShowDrawer] = useState(false);
    const [showEditDeficiencyCombinationDrawer, setShowEditDeficiencyCombinationDrawer] = useState(false);
    const [selectedDeficiencyCombination, setSelectedDeficiencyCombination] = useState(null);
    const [isDeleteDeficiencyCombinationModalOpen, setIsDeleteDeficiencyCombinationModalOpen]
        = useState(false);
    const [editCombinationDeficiencySelectItems, setEditCombinationDeficiencySelectItems] = useState([]);

    const CustomWidthTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))({
        [`& .${tooltipClasses.arrow}`]: {
            color: '#373D3F',
        },
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#373D3F',
            maxWidth: 500,
        },
    });

    const handleCombinationEditClick = (row) => {
        setSelectedDeficiencyCombination(row);
        setShowEditDeficiencyCombinationDrawer(true);
    };

    const handleCombinationDeleteClick = (row) => {
        setSelectedDeficiencyCombination(row);
        setIsDeleteDeficiencyCombinationModalOpen(true);
    };

    const handleConfirmDeleteClick = () => {
        onCombinationConfirmDeleteClick(
            selectedDeficiencyCombination.ProjectDeficiencyCombinationId
        );
        setIsDeleteDeficiencyCombinationModalOpen(false);
        setSelectedDeficiencyCombination(null);
    };
    const handleCancelDeleteClick = () => {
        setIsDeleteDeficiencyCombinationModalOpen(false);
        setSelectedDeficiencyCombination(null);
    };

    const handleCancelEditClick = () => {
        setShowEditDeficiencyCombinationDrawer(false);
    };

    const handleSaveEdit = () => {
        setShowEditDeficiencyCombinationDrawer(false);
    };

    const renderTableHeaderCell = () => {
        return columns.map((column, index) => {
            const { headerName, maxWidth, minWidth, attributes } = column;

            const tooltiptext = attributes?.tooltiptext;

            return (
                <TableCell
                    className={'MuiDataGrid-columnHeader MuiDataGrid-columnHeaderTitleContainer'}
                    sx={{
                        pr: 0,
                        pl: 0,
                        display: 'flex',
                        maxWidth: maxWidth,
                        minWidth: minWidth,
                        flexGrow: 1,
                        border: 0
                    }}
                    key={index}
                >
                    <Typography
                        data-test={`${headerName}_component`}
                        className={'MuiDataGrid-columnHeaderTitleContainerContent'}
                        sx={{
                            fontWeight: 700,
                            fontSize: '16px',
                            m: '0 40px',
                            color: colors.navy[400]
                        }}>
                        {headerName}
                        {tooltiptext && (
                            <CustomWidthTooltip
                                placement="top"
                                title={<span style={{ whiteSpace: 'pre-line' }}>{tooltiptext}</span>}
                            >
                                <HelpIcon style={{ fontSize: '18px', color: colors.navy[400], marginLeft: '10px' }} />
                            </CustomWidthTooltip>
                        )}
                    </Typography>
                </TableCell>
            );
        })
    }

    useEffect(() => {
        const selectedDeficiencies = selectedDeficiencyCombination?.Deficiencies ?? [];

        const filteredDeficiencies = allDeficiencies.filter(
            item => (
                rows.some(row => row.ProjectDeficiencyId === item.ProjectDeficiencyId) ||
                selectedDeficiencies.find(selectedDeficiency => selectedDeficiency.ProjectDeficiencyId === item.ProjectDeficiencyId)
            )
        );

        setEditCombinationDeficiencySelectItems(filteredDeficiencies);
    }, [selectedDeficiencyCombination, rows, allDeficiencies]);

    return (
        <>
            <TableContainer sx={{
                flexGrow: 1,
                width: '100%',
                overflow: 'auto'
            }}>
                <Table sx={{ border: '1px solid rgba(224, 224, 224, 1)' }}>
                    <TableHead className={'MuiDataGrid-columnHeaders'}>
                        <TableRow
                            className={'MuiDataGrid-columnHeadersInner'}
                            sx={{
                                p: '0 10px',
                                backgroundColor: colors.gray[25],
                                display: 'grid',
                                gridAutoFlow: 'column',
                                gridAutoColumns: '1fr',
                                borderBottom: '1px solid rgba(224, 224, 224, 1)'
                            }}
                        >
                            {renderTableHeaderCell()}
                        </TableRow>
                    </TableHead>
                    <TableBody className={'MuiDataGrid-columnHeaders'}>
                        {rows?.map((row, index) => (
                            <CLAEvaluationOfDeficienciesTableRow
                                key={index}
                                columns={columns}
                                rows={rows}
                                row={row}
                                handleChange={onCheckboxChange.bind(this, false)}
                                highlightedDeficiencyIds={highlightedDeficiencyIds}
                            />
                        ))}
                        {deficiencyCombinations?.map((row, index) => (
                            <CLAEvaluationOfDeficienciesCombinationRow 
                                key={index}
                                row={row}
                                columns={columns}
                                onCombinationCheckboxChange={onCheckboxChange.bind(this, true)}
                                onCombinationDeleteClick={handleCombinationDeleteClick}
                                onCombinationEditClick={handleCombinationEditClick}
                                highlightedDeficiencyCombinationIds={highlightedDeficiencyCombinationIds}
                            />
                        ))}
                    </TableBody>
                    <TableFooter className={'MuiDataGrid-columnHeaders'}>
                        <TableRow
                            className={'MuiDataGrid-columnHeadersInner'}
                            sx={{
                                p: '0 10px',
                                display: 'flex',
                                height: '80px',
                                alignItems: 'center'
                            }}>
                            <TableCell className={'MuiDataGrid-columnHeader MuiDataGrid-columnHeaderTitleContainer'}
                                sx={{
                                    p: '0',
                                    display: 'flex',
                                    flexGrow: 1,
                                    border: 0
                                }}>
                                <Button
                                    disabled={disabled}
                                    sx={{
                                        color: disabled ? colors.gray[100] : colors.black,
                                        fontWeight: 'bold',
                                        m: '0 40px',
                                        p: 0
                                    }}
                                    startIcon={
                                        <AddIcon
                                            sx={{
                                                color: disabled ? colors.gray[100] : colors.riptide[400]
                                            }}
                                        />
                                    }
                                    variant="page-button-add-form"
                                    onClick={() => { setShowDrawer(true)}}
                                >
                                    {EVALUATION_OF_DEFICIENCIES.ADD_DEFICIENCY_COMBINATION}
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableFooter>
                </Table>
            </TableContainer >

            <AddCombinationDrawer 
            showDrawer={showDrawer}
            setShowDrawer={setShowDrawer}
            rows={rows}
            projectId={projectId}
            />
            {selectedDeficiencyCombination && (
                <EditCombinationDrawer 
                    showDrawer={showEditDeficiencyCombinationDrawer}
                    rows={editCombinationDeficiencySelectItems}
                    projectId={projectId}
                    onCancelEditClick={handleCancelEditClick}
                    deficiencyCombination={selectedDeficiencyCombination}
                    onSave={handleSaveEdit}
                />
            )}
            <CLADialogConfirm
                visible={isDeleteDeficiencyCombinationModalOpen}
                title={MODAL_HEADER.WARNING}
                cancelText={BUTTON.CANCEL}
                confirmText={BUTTON.OK}
                message={MODAL_BODY.DELETE_MESSAGE}
                onConfirm={handleConfirmDeleteClick}
                onCancel={handleCancelDeleteClick}
            />
        </>
    )
}

export default CLAEvaluationOfDeficienciesTable;