import CLACheckBoxField from "@components/Forms/CLACheckBoxField/CLACheckBoxField"
import { useProjectRiskCharacteristics } from "./useProjectRiskCharacteristics"
import {Flex} from '@ais/palette';
import { Heading } from "@palette"
import { Box } from '@mui/material';
import styles from "./RiskCharacteristics.module.css"
import { sortReviewerSignoffAsc } from '@utilities/dateHelpers.js';
import { useState, useEffect } from 'react';

export const RiskCharacteristics = (props) => {

    const { methodologyVersionId, projectFormId, methodologyIndustries, disabled, signOffList } = props

    const [ projectFormAuditAreas, { isSelected, toggleRiskCharacteristic } ] = useProjectRiskCharacteristics(methodologyVersionId, projectFormId, methodologyIndustries, signOffList)


    return (
        <Flex>
            <Heading as="h1" size="3" weight="medium" className={styles["mb-2"]}>Are any of the following Common Identified Risks present in the project?</Heading>
                { projectFormAuditAreas?.map((auditArea) => (
                    <Box sx={auditArea.shouldHighlight ? { borderStyle: 'dashed', borderWidth: '4px', borderColor: 'red' }: {}}>
                        <Flex key={auditArea.id} className={styles["mb-5"]}>
                            <Heading as="h2" size="2" weight="medium" className={styles["mb-2"]}>{ auditArea?.name }</Heading>
                            {auditArea.riskCharacteristics.map((rc) => (
                            <CLACheckBoxField
                                key={rc.id}
                                className={styles["mb-2"]}
                                label={rc.name}
                                value={isSelected(rc)}
                                checked={isSelected(rc)}
                                onChange={(event) => toggleRiskCharacteristic(event, rc)}
                                disabled={disabled}
                            />
                        ))}
                    </Flex>
                    </Box>
                ))}
        </Flex>
    )
}
