import formApi from '@utilities/claApiForm';
import { useQuery } from "@tanstack/react-query"

const getAssertions = async (MethodologyVersionId) => {
    try {
        const { data } = await formApi.get(`/projectform/assertions/${MethodologyVersionId}`);
        return { status: 200, data };
    } catch (exception) {
        return { status: 400, message: exception };
    }
};

const upsertAssertions = async (methodologyVersionId, body) => {
    try {
        const response = await formApi.put(`/methodology/${methodologyVersionId}/lists/assertion`, body);
        return { status: 200, data: response.data };
    } catch (error) {
        const { response } = error;
        return { status: 400, message: response?.data?.message ?? error };
    }
}

export const useAssertions = (methodologyVersionId) => {
    return useQuery(
        ["assertions", methodologyVersionId],
        () => getAssertions(methodologyVersionId),
        {
            enabled: !!methodologyVersionId
        }
    )
}

const assertionServices = {
    useAssertions,
    getAssertions,
    upsertAssertions
}

export default assertionServices;