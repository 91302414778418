import React, {
    useState
} from 'react';
import { useDebounce, useUpdateEffect } from 'react-use';
import CLATextField from '@components/Forms/CLATextField/CLATextField';


const CommunicationOfDeficienciesTextField = (props) => {
    const { row, handlePersonCommunicatedToSave } = props;
    const [personCommunicatedTo, setPersonCommunicatedTo] = useState(row?.PersonCommunicatedTo);
    const [debouncedValue, setDebouncedValue] = useState('');

    useDebounce(
        () => {
            setDebouncedValue(personCommunicatedTo);
        },
        500,
        [personCommunicatedTo]
    );

    useUpdateEffect(() => {
        if (handlePersonCommunicatedToSave) {
            handlePersonCommunicatedToSave(row, 'PersonCommunicatedTo', debouncedValue);
        }
    }, [debouncedValue]);

    return (<>
        <CLATextField
            onChange={(e) => {
                setPersonCommunicatedTo(e.target.value);                
            }}
            value={personCommunicatedTo}
            inputLableShrink={true}
            placeholder="Enter Person's Name"
            maxLength={257}
        />
    </>
    );
}

export default CommunicationOfDeficienciesTextField;