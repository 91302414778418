import CLATailorProcedureTable from '@components/CLATailorProcedureTable/CLATailorProcedureTable'
import {CLASimpleDrawer} from '@ais/components';

const TailorProceduresDrawer = ({
    isVisible,
    auditArea,
    sectionId,
    onClose,
    onCloseDrawer
}) => (
    <CLASimpleDrawer
        anchor="right"
        isVisible={isVisible}
        allowBackDropCloseClick
        onClose={onClose}
        width="80%"
    >
        <CLATailorProcedureTable
            isVisible={isVisible}
            closeDrawer={onCloseDrawer}
            auditArea={auditArea}
            sectionId={sectionId}
        />
    </CLASimpleDrawer>
)

export default TailorProceduresDrawer