import { TAILOR_PROCEDURE_REDUCER } from '@constants/forms';
import map from 'lodash/fp/map'
import flow from 'lodash/fp/flow'
import filter from 'lodash/fp/filter'

function realignCustomProcedures(concurrencyCustomProcedures, oldCustomProcedures) {
    const mapCustomProcedure = map(({ ProjectFormCustomProcedureId }) => ProjectFormCustomProcedureId);

    const incomingCustomProcedureIds = mapCustomProcedure(concurrencyCustomProcedures);
    const oldCustomProcedureIds = mapCustomProcedure(oldCustomProcedures);
    const newCustomProcedureIds = incomingCustomProcedureIds.filter(id => !oldCustomProcedureIds.includes(id));
    const deletedCustomProcedureIds = oldCustomProcedureIds.filter(id => !incomingCustomProcedureIds.includes(id));


    // Delete
    if (oldCustomProcedures.length > 0) {
        oldCustomProcedures = oldCustomProcedures
            .filter(({ ProjectFormCustomProcedureId }) => !deletedCustomProcedureIds.includes(ProjectFormCustomProcedureId));
    }

    // Insert
    newCustomProcedureIds.forEach(id => {
        const newCustomProcedure = concurrencyCustomProcedures.find(({ ProjectFormCustomProcedureId }) => ProjectFormCustomProcedureId === id);
        oldCustomProcedures.push(newCustomProcedure);
    });
}

function realignSummaryProcedureStep(concurrencySteps, newStateSteps) {
    const checkedOrRequiredSteps = flow(
        filter(({IsChecked, IsRequiredStep}) => IsChecked || IsRequiredStep),
        map(({SummaryProcedureStepId}) => SummaryProcedureStepId)
    )
  
    const incomingStepsIds = checkedOrRequiredSteps(concurrencySteps);
    const oldStepsIds = checkedOrRequiredSteps(newStateSteps);
    const newStepIds = incomingStepsIds.filter(id => !oldStepsIds.includes(id));
    const deletedStepIds = oldStepsIds.filter(id => !incomingStepsIds.includes(id));
    
    // Delete
    if (newStateSteps.length > 0) {
        newStateSteps = newStateSteps
            .filter(({ SummaryProcedureStepId }) => !deletedStepIds.includes(SummaryProcedureStepId));
    }

    // Insert
    newStepIds.forEach(id => {
        const newStep = concurrencySteps.find(({ SummaryProcedureStepId }) => SummaryProcedureStepId === id);
        newStateSteps.push(newStep);
    });
}

function realignSummaryProcedures(concurrencySummaryProcedures, oldSummaryProcedures) {
    const mapSummaryProcedures = map(({ SummaryProcedureId }) => SummaryProcedureId);

    const incomingSummaryProcedureIds = mapSummaryProcedures(concurrencySummaryProcedures);
    const oldSummaryProcedureIds = mapSummaryProcedures(oldSummaryProcedures);
    const newSummaryProcedureIds = incomingSummaryProcedureIds.filter(id => !oldSummaryProcedureIds.includes(id));
    const deletedSummaryProcedureIds = oldSummaryProcedureIds.filter(id => !incomingSummaryProcedureIds.includes(id));
    const updatedSummaryProcedureIds = oldSummaryProcedureIds.filter(id => !deletedSummaryProcedureIds.includes(id));

    // Delete
    if (oldSummaryProcedures.length > 0) {
        oldSummaryProcedures = oldSummaryProcedures
            .filter(({ ProjectFormCustomProcedureId }) => !deletedSummaryProcedureIds.includes(ProjectFormCustomProcedureId));
    }

    // Insert
    newSummaryProcedureIds.forEach(id => {
        const newCustomProcedure = concurrencySummaryProcedures.find(({ ProjectFormCustomProcedureId }) => ProjectFormCustomProcedureId === id);
        oldSummaryProcedures.push(newCustomProcedure);
    });

    updatedSummaryProcedureIds.forEach(id => {
        const newStateSummaryProcedureSteps = oldSummaryProcedures.find(({SummaryProcedureId}) => SummaryProcedureId === id).SummaryProcedureSteps;
        const concurrencySummaryProcedureSteps = concurrencySummaryProcedures.find(({SummaryProcedureId}) => SummaryProcedureId === id).SummaryProcedureSteps;
        realignSummaryProcedureStep(concurrencySummaryProcedureSteps, newStateSummaryProcedureSteps)
    });
}

export const TailorProcedureReducer = (state, action) => {
    let newState = { ...state };
    const { type, payload } = action;
    switch (type) {
        case TAILOR_PROCEDURE_REDUCER.LOAD_INIT_TAILOR_PROCEDURE: {
            const {
                sectionId,
                summaryProcedures,
                customProcedures,
                assertions
            } = payload;

            newState[`${sectionId}`] = {
                summaryProcedures: summaryProcedures,
                customProcedures: customProcedures,
                assertions: assertions
            };

            return newState;
        }
        case TAILOR_PROCEDURE_REDUCER.SET_SUMMARY_PROCEDURE: {
            const {
                summaryProcedures,
                sectionId
            } = payload;

            newState[`${sectionId}`].summaryProcedures = summaryProcedures;

            return newState;
        }
        case TAILOR_PROCEDURE_REDUCER.UPDATE_SUMMARY_PROCEDURE: {
            const {
                summaryProcedureStep,
                sectionId
            } = payload
            const indexSummaryProcedure = newState[`${sectionId}`].summaryProcedures.findIndex(item => item.SummaryProcedureId === summaryProcedureStep.SummaryProcedureId);
            const indexSummaryProcedureStep = newState[`${sectionId}`].summaryProcedures[indexSummaryProcedure]?.SummaryProcedureSteps.findIndex(item => item.SummaryProcedureStepId === summaryProcedureStep.SummaryProcedureStepId);

            if (indexSummaryProcedure < 0 || indexSummaryProcedureStep < 0) return newState;

            newState[`${sectionId}`].summaryProcedures[indexSummaryProcedure].SummaryProcedureSteps[indexSummaryProcedureStep] = summaryProcedureStep;
            return newState;
        }
        case TAILOR_PROCEDURE_REDUCER.SET_CUSTOM_PROCEDURES: {
            newState.customProcedures = action.payload;
            return newState;
        }
        case TAILOR_PROCEDURE_REDUCER.UPSERT_CUSTOM_PROCEDURE: {
            const {
                customProcedure,
                sectionId
            } = payload

            if (newState[`${sectionId}`].customProcedures.length < 0) {
                newState[`${sectionId}`].customProcedures.push(customProcedure);
            } else {
                const index = newState[`${sectionId}`].customProcedures.findIndex(cp => cp.ProjectFormCustomProcedureId === customProcedure.ProjectFormCustomProcedureId);
                index >= 0 ? newState[`${sectionId}`].customProcedures[index] = customProcedure : newState[`${sectionId}`].customProcedures.push(customProcedure);
            }
            return newState;
        }
        case TAILOR_PROCEDURE_REDUCER.DELETE_CUSTOM_PROCEDURE: {
            const { id, sectionId } = payload;
            if (newState[`${sectionId}`].customProcedures.length > 0) {
                newState[`${sectionId}`].customProcedures = newState[`${sectionId}`].customProcedures.filter(cp => cp.ProjectFormCustomProcedureId !== id);
            }
            return newState
        }
        case TAILOR_PROCEDURE_REDUCER.REALIGN_PROCEDURE_FROM_CONCURRENCY: {
            const { summaryProcedure, customProcedures, sectionId } = payload;
            realignCustomProcedures(customProcedures, newState[sectionId].customProcedures);
            realignSummaryProcedures(summaryProcedure, newState[sectionId].summaryProcedures, sectionId);
            return newState;
        }
        default: {
            return newState;
        }
    }
};