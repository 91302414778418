import React, { useState, useEffect } from 'react'
import { CLANestedDataGrid } from '@ais/components';
import ScotabdsTable from '../Scotabds/ScotabdsTable.jsx';
import styles from './styles.js'

const AuditAreasTable = ({ projectScopeAuditAreas, isProjectFinalized, signOffList }) => {
  const [rows, setRows] = useState([])
  const [expandedRows, setExpandedRows] = useState([]);

  const handleAdditionalCollapseTrigger = (newIds) => {
    setExpandedRows(newIds);
  };

  useEffect(() => {

    if (!projectScopeAuditAreas || projectScopeAuditAreas?.length === 0) return;
    const __auditAreas = projectScopeAuditAreas?.map((auditArea, index) => ({
      ...auditArea,
      id: index++,
      ExpandedPanel: (
        <ScotabdsTable
          projectScopeAuditAreaId={auditArea.ProjectScopeAuditAreaId}
          scotabds={auditArea?.ProjectScopeAuditAreaSCOTABDS ?? []}
          isProjectFinalized={isProjectFinalized}
          signOffList={signOffList}
        />
      )
    })) || [];

    /** Fetch all audit area with ProjectScopeAuditAreaSCOTABDProjectKeyControl */
    const filteredAuditAreas = __auditAreas.filter((data) => {
      const psaaScotabds = data.ProjectScopeAuditAreaSCOTABDS;

      if (!!psaaScotabds && Array.isArray(psaaScotabds)) {
        const scotabdsWithKeyControl = psaaScotabds.filter(
          (psaaScotabd) => psaaScotabd.ProjectScopeAuditAreaSCOTABDProjectKeyControl && psaaScotabd.ProjectScopeAuditAreaSCOTABDProjectKeyControl.length > 0
        );

        return scotabdsWithKeyControl.length > 0;
      }
      
      return false;
    });

    setRows(filteredAuditAreas)
  }, [projectScopeAuditAreas, signOffList])

  const columns = [
    {
      field: 'AuditAreaName',
      headerName: 'Audit Areas',
      flex: 1,
      minWidth: 280,
      maxWidth: 280,
      editable: false,
      sortable: false,
      filterable: false,
      disableReorder: true,
      renderCell: params => (<span style={{ fontWeight: '700', fontSize: '1.2em' }}>{params.row.AuditAreaName}</span>)
    }
  ]

  return (
    <CLANestedDataGrid
      sx={styles}
      rows={rows}
      columns={columns}
      rowHeight={55}
      hideFooterRowCount={true}
      showFooterContent={false}
      hideFooterPagination={true}
      headerHeight={65}
      hideFooter={true}
      expandedRow={expandedRows}
      handleAdditionalCollapseTrigger={handleAdditionalCollapseTrigger}
    />
  )
}

export default AuditAreasTable