import React, { useState, useEffect } from 'react';
import { Checkbox, Box } from '@mui/material';
import useProjectFormInstanceConcurrentLocking from '@hooks/useProjectFormInstanceConcurrentLocking'

export const ConcurrentCheckbox = ({
    value,
    onChange,
    customFormObjectId,
    shouldHighlight = false,
    ...props
  }) => {
  
    const [isChecked, setIsChecked] = useState(value)
    const {
      emitUpdateEvent, 
      concurrentValue
    } = useProjectFormInstanceConcurrentLocking(
      null,
      {
          customFormObjectId
      }
    );
  
    useEffect(() => {
      if(typeof concurrentValue?.value === 'boolean') setIsChecked(concurrentValue?.value)
    }, [concurrentValue])
  
    const handleOnChange = async (e) => {
      setIsChecked(e.target.checked)
      await emitUpdateEvent({ value: e.target.checked });
      if(typeof onChange === 'function') onChange(e)
    }
  
    return (
      <Box
        sx={
          shouldHighlight
            ? {
                padding: '8px',
                border: '4px dashed red',
                borderBottom: '4px dashed red !important',
              }
            : {}
        }
      >
        <Checkbox
          sx={{
            px: 'auto',
          }}
          {...props}
          checked={isChecked}
          onChange={handleOnChange}
        />
      </Box>
    )
  }

  export default ConcurrentCheckbox;