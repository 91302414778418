import formApi from '@utilities/claApiForm';
import { useQuery } from "@tanstack/react-query"

export const getScotabds = async (projectId, ProjectScopeIds, ProjectScopeAuditAreaId) => {
    try {
        const { data } = await formApi.post(`/projectscopesauditareascotabd/${projectId}`, { ProjectScopeIds, ProjectScopeAuditAreaId });
        return { status: 200, data };
    } catch (exception) {
        return { status: 400, message: exception };
    }
};

export const useScotabds = (projectId, projectScopeIds, auditAreaId) => {
    return useQuery(
        ["scotabds", projectId, projectScopeIds, auditAreaId],
        () => getScotabds(projectId, projectScopeIds, auditAreaId),
        {
            enabled: !!projectId && !!projectScopeIds && projectScopeIds.length > 0 && (!!auditAreaId && Array.isArray(auditAreaId)),
        }
    )
};

const getScotabdsByMethodologyId = async (methodologyVersionId) => {
    try {
        if (!methodologyVersionId) return;
        const { data } = await formApi.get(`/scotabd/${methodologyVersionId}`);
        return { status: 200, data };
    } catch (exception) {
        return { status: 400, message: exception };
    }
};

const getProjectScopeAuditAreaSCOTABDs = async (projectFormId, auditAreaId, projectId) => {
    try {
        if (!auditAreaId) return;
        const response = await formApi.get(`/projectscopesauditareascotabds/${projectFormId}/${auditAreaId}/${projectId}`);
        return { status: 200, data: response.data };
    } catch (exception) {
        return { status: 400, message: exception };
    }
};
export const useProjectScopeAuditAreaSCOTABDs = (projectFormId, auditAreaId, projectId) => {
    return useQuery(
        ["ProjectScopeAuditAreaSCOTABDs", projectFormId, auditAreaId, projectId],
        () => getProjectScopeAuditAreaSCOTABDs(projectFormId, auditAreaId, projectId),
    )
}

const scotabdHooks = {
    useScotabds,
    useProjectScopeAuditAreaSCOTABDs,
    getScotabdsByMethodologyId
}

export default scotabdHooks;
