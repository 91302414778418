import React, { memo, useState, useEffect } from "react";
import {
    FormControl,
    InputLabel,
    Typography,
} from "@mui/material";
import { sanitize } from "dompurify";
import { format } from "date-fns";
import { Controller, useFormContext } from "react-hook-form"

import styles from "../FormView.module.css";
import { useVFCanvasProvider, useProjectFormInstanceProvider } from '@providers';
import { Flex } from "@ais/palette";
import { FIELD_TYPES, INTERNAL_CONTROLS_CONSTANTS } from "@ais/constants";
import { RiskCharacteristics } from "@views/ProjectFormInstance/components/RiskCharacteristics/RiskCharacteristics";
import {
    CLACheckBoxGroup,
    CLAComboBox,
    CLACurrencyField,
    CLADatePickerField,
    CLADropdown,
    CLAFormTable,
    CLANumberField,
    CLAPercentageField,
    CLARadioButtonList,
    CLASelect,
    ClientExternalIcon,
    copyObject,
    Formula,
    InstanceLongAnswer,
    isAnswered,
    parseQuestionLabel,
    renderLabel,
    VFQuestionGroup
} from '@ais/forms';
import { CommunicationOfSignificantsMattersContextProvider } from "@contexts/CommunicationOfSignificantsMattersContext";

import CLATrialBalanceSelect from '@components/CLATrialBalanceSelect/CLATrialBalanceSelect';
import CLAProjectScopeAuditArea from '@components/Forms/CLAProjectScopeAuditArea/CLAProjectScopeAuditArea';
import CLAInitialScopingConsiderations from '@components/Forms/CLAInitialScopingConsiderations/CLAInitialScopingConsiderations';
import CLAFinancialStatementLevelRisk from "@components/Forms/RiskAssessment/FinancialStatementLevelRisk/CLAFinancialStatementLevelRisk";
import CLAAccountAssertionLevelRisk from "@components/Forms/RiskAssessment/AccountAssertionLevelRisk/CLAAccountAssertionLevelRisk";
import VFRenderedFieldWrapper from '@components/FormView/VFRenderedFieldWrapper';
import CLAProcedureField from '@components/Forms/CLAProcedureField/CLAProcedureField';
import CLACommunicationOfDeficiencies from '@components/Forms/Deficiency/CommunicationOfDeficiencies/CLACommunicationOfDeficiencies';
import CLAEvaluationOfDeficiencies from '@components/Forms/Deficiency/EvaluationOfDeficiencies/CLAEvaluationOfDeficiencies';
import { TbLinkBaseField } from '@components/FormView/components/TbLinkFields';

// Planning Analytics
import GLCAccounts from "@components/CustomForm/PlanningAnalytics/GLCAccounts/GLCAccounts";
import CPPPTable from "@components/CustomForm/PlanningAnalytics/CPPPTable/CPPPTable";
import GraphsDisplayManager from "@components/CustomForm/PlanningAnalytics/Graphs/GraphsDisplayManager";

// Internal Control
import { UnderstandingOfEntityProvider } from "@ais/contexts";
import PurposeObjectiveProcedure from "@components/CustomForm/InternalControl/PurposeObjectiveProcedure/PurposeObjectiveProcedure";
import EvaluatingTheDesign from "@components/CustomForm/InternalControl/EvaluatingTheDesign/EvaluatingTheDesign";
import UOEAuditAreaTable from "@components/CustomForm/InternalControl/UnderstandingOfEntity/UOEAuditAreaTable";
import CommunicationOfSiginificantMatters from "@components/CustomForm/InternalControl/CommunicationOfSiginificantMatters/CommunicationOfSiginificantMatters";

import { NoValidationInput } from "./TbLinkFields";

export const VFFields = memo((props) => {
    const {
        field,
        projectId,
        projectFormId,
        disabled: _disabled,
        isInstance,
        section,
        answerList,
        renderLink,
        getDefaultValue,
        handleCurrencyValue,
        isLockedByUser,
        handleOnFocus,
        methodologyIndustries,
        methodologyVersionId,
        watchRadioList,
        procedureFilter,
        handleBlur,
        formTypeId,
        units,
        isIdle,
        project,
        answered,
        onResetAnswerQuestionField,
        signOffList,
        onUnlockConcurrentField
    } = props;
    const formCanvas = useFormContext();
    const { isNonCustomSectionExpanded ,actions: { updateIsFormFocused } } = useProjectFormInstanceProvider();
    const { actions , state  } = useVFCanvasProvider();
    const disabled = _disabled || field.linkType === 'Read Only';
    
    const handleIsLockedSection = (section) => {
        if (!isInstance) return false;

        const {id} = section;

        const targetSection = isNonCustomSectionExpanded.find(item => item.section === id)
        return targetSection ? targetSection.isExpanded : false
    }
      
    const handleNoValidationBlur = (answer) => {
        let parsedAnswer;
        try {
            parsedAnswer = JSON.parse(answer);
        } catch {
            parsedAnswer = answer;
        }

        if (typeof parsedAnswer !== "string" || !isInstance) return;

        const formValue = formCanvas.getValues(field.id);

        if (answer !== formValue && isInstance)
            formCanvas.setValue(field.id, answer);
    }

    switch (field.type) {
        case FIELD_TYPES.SHORT_ANSWER:
        case FIELD_TYPES.LONG_ANSWER: {
            let defaultValue = isIdle ? formCanvas.getValues(field.id) : getDefaultValue();

            //only concurrency value from portal to portal has percentage symbol.
            if (field.validation === 'percentage' && isNaN(defaultValue) && defaultValue?.includes('%')) {
                defaultValue = Number(defaultValue.replace('%', '')) / 100;
            }

            return (
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper}
                    isLockedByUser={isLockedByUser}
                    isLocked={handleIsLockedSection(section)}
                    isInstance
                >
                    <FormControl fullWidth>
                        {
                            !!field?.label && <InputLabel shrink variant="multiline-label">
                                {renderLabel(field.label, field.tooltip)}
                            </InputLabel>
                        }
                        {field.validation === 'none' && (
                            <Controller
                                control={formCanvas.control}
                                name={field.id}
                                render={({ field: { onChange } }) => {
                                    return (
                                        <NoValidationInput
                                            field={field}
                                            defaultValue={defaultValue}
                                            isFormInstance={isInstance}
                                            disabled={disabled}
                                            onFocus={handleOnFocus}
                                            onChange={onChange}
                                            answered={answered}
                                            units={units}
                                            isIdle={isIdle}
                                            onBlur={(answer) => handleNoValidationBlur(answer)}
                                            onUnlockConcurrentField={onUnlockConcurrentField}
                                        />
                                    )
                                }} />

                        )}
                        {field.validation === 'numerical' && (
                            <Controller
                                control={formCanvas.control}
                                name={field.id}
                                render={({ field: { onChange } }) => {
                                    return (
                                        <CLANumberField
                                            label={renderLabel(field.label, field.tooltip)}
                                            onChange={(e, value) => {
                                                onChange(value)
                                            }}
                                            placeholder={field.placeholder}
                                            defaultValue={defaultValue}
                                            notched
                                            digitGroupSeparator=","
                                            disabled={disabled}
                                            isInstance={isInstance}
                                            tbLinkEnabled={isInstance}
                                            allowExternalAccess={field.visibleToClient ?? false}
                                            answerable={field.editableByClient ?? false}
                                            answered={answered}
                                            field={field}
                                            tbLinkBaseField={TbLinkBaseField}
                                            units={units}
                                            onFocus={handleOnFocus}
                                            isIdle={isIdle}
                                            onUnlockConcurrentField={onUnlockConcurrentField}
                                        />
                                    )
                                }} />
                        )}
                        {field.validation === 'usCurrency' && (
                            <Controller
                                control={formCanvas.control}
                                name={field.id}
                                render={({ field: { onChange } }) => {
                                    return (
                                        <CLACurrencyField
                                            label={renderLabel(field.label, field.tooltip)}
                                            onChange={(e, value) => {
                                                onChange(value)
                                                if (isInstance) handleCurrencyValue(value)
                                            }}
                                            placeholder={field.placeholder}
                                            defaultValue={isInstance ? defaultValue : handleCurrencyValue(defaultValue)}
                                            disabled={disabled}
                                            notched
                                            onFocus={handleOnFocus}
                                            isInstance={isInstance}
                                            tbLinkEnabled={isInstance}
                                            allowExternalAccess={field.visibleToClient ?? false}
                                            answerable={field.editableByClient ?? false}
                                            answered={answered}
                                            field={field}
                                            tbLinkBaseField={TbLinkBaseField}
                                            units={units}
                                            isIdle={isIdle}
                                            onUnlockConcurrentField={onUnlockConcurrentField}
                                        />
                                    )
                                }} />
                        )}
                        {field.validation === 'percentage' && (
                            <Controller
                                control={formCanvas.control}
                                name={field.id}
                                render={({ field: { onChange } }) => {
                                    return (
                                        <CLAPercentageField
                                            label={renderLabel(field.label, field.tooltip)}
                                            onChange={(e, value) => {
                                                onChange(value?.toString())
                                            }}
                                            placeholder={field.placeholder}
                                            value={defaultValue}
                                            disabled={disabled}
                                            notched
                                            onFocus={handleOnFocus}
                                            allowExternalAccess={field.visibleToClient ?? false}
                                            answerable={field.editableByClient ?? false}
                                            answered={answered}
                                            isIdle={isIdle}
                                        />
                                    )
                                }} />
                        )}
                    </FormControl>
                    {renderLink()}
                </VFRenderedFieldWrapper>
            );
        }
        case FIELD_TYPES.INSTANCE_LONG_ANSWER: {
            const defaultValue = isIdle ? formCanvas.getValues(field.id) : getDefaultValue();
            return (
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper}
                    isLockedByUser={isLockedByUser}
                    isLocked={handleIsLockedSection(section)}
                    isInstance
                >
                    <InstanceLongAnswer
                        field={field}
                        defaultValue={defaultValue}
                        disabled={disabled}
                        onFocus={handleOnFocus}
                        isIdle={isIdle}
                    />
                    {renderLink()}
                </VFRenderedFieldWrapper>
            );
        }
        case FIELD_TYPES.FREETEXT:
        case FIELD_TYPES.FREETEXT_NO_RULES: {
            return (
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper}
                    isLockedByUser={isLockedByUser}
                    isLocked={handleIsLockedSection(section)}
                    isInstance
                >
                    <Flex direction="row" justify="between" align="baseline" className={styles['vf-field__freetext-container']}>
                        <Typography variant={field.type === FIELD_TYPES.FREETEXT && 'freeText'}>
                            <div
                                className="ql-editor"
                                dangerouslySetInnerHTML={{
                                    __html: sanitize(field.text, { ADD_ATTR: ['target'] }),
                                }}
                            />
                        </Typography>
                        <ClientExternalIcon
                            allowExternalAccess={field.visibleToClient ?? false}
                            answerable={field.editableByClient ?? false}
                        />
                    </Flex>
                    {renderLink()}
                </VFRenderedFieldWrapper>
            );
        }
        case FIELD_TYPES.CHECKBOXGROUP: {
            const defaultValue = getDefaultValue(true);
            return (
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper}
                    isLockedByUser={isLockedByUser}
                    isLocked={handleIsLockedSection(section)}
                    isInstance
                >
                    <CLACheckBoxGroup
                        id={field.id}
                        label={renderLabel(field.label, field.tooltip, renderLink)}
                        value={Array.isArray(defaultValue) ? defaultValue.filter(Boolean) : [defaultValue].filter(Boolean)}
                        onChange={(value, otherValue, otherToggled, e) => {
                            const copy = [...value];
                            if (otherValue) copy.push(otherValue)
                            formCanvas.setValue(field.id, copy); 
                            if (!otherToggled && isInstance){
                                actions.handleSubmit();
                            }                    
                            onResetAnswerQuestionField(field.id)
                            if (e) handleBlur(e, true)
                        }}
                        required={field.required}
                        columns={field.columns}
                        options={field.options}
                        allowOtherOption={field.allowOtherOption}
                        isDisabled={disabled}
                        onFocus={handleOnFocus}
                        isInstance={isInstance}
                        allowExternalAccess={field.visibleToClient ?? false}
                        answerable={field.editableByClient ?? false}
                        answered={answered}
                    />
                </VFRenderedFieldWrapper>
            );
        }
        case FIELD_TYPES.RADIOGROUP: {
            const defaultValue = getDefaultValue();
            return (
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper}
                    isLockedByUser={isLockedByUser}
                    isLocked={handleIsLockedSection(section)}
                    isInstance
                >
                    <Controller
                        control={formCanvas.control}
                        name={field.id}
                        render={({ field: { onChange } }) => {
                            return (
                                <CLARadioButtonList
                                    id={field.id}
                                    label={renderLabel(field.label, field.tooltip, renderLink)}
                                    defaultValue={watchRadioList}
                                    value={defaultValue}
                                    onChange={value => {
                                        onChange(value)
                                        if (isInstance) actions.handleSubmit();
                                    }}
                                    required={field.required}
                                    columns={field.columns}
                                    options={field.options}
                                    allowOtherOption={field.allowOtherOption}
                                    isDisabled={disabled}
                                    onFocus={handleOnFocus}
                                    formTypeId={formTypeId}
                                    allowExternalAccess={field.visibleToClient ?? false}
                                    answerable={field.editableByClient ?? false}
                                    answered={answered}
                                />
                            )
                        }} />
                </VFRenderedFieldWrapper>
            );
        }
        case FIELD_TYPES.DROPDOWN: {
            const defaultValue = getDefaultValue(true);
            return (
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper}
                    isLockedByUser={isLockedByUser}
                    isLocked={handleIsLockedSection(section)}
                    isInstance
                >
                    {field.allowOtherOption && (
                        <CLAComboBox
                            label={renderLabel(field.label, field.tooltip)}
                            defaultValue={defaultValue ?? ''}
                            onChange={(values, e, reason) => {
                                formCanvas.setValue(field.id, values)
                                if ((reason === 'removeOption' && e.target.tagName === 'path') && isInstance){
                                    actions.handleSubmit();
                                } 
                            }}
                            onBlur={(values) => {
                                formCanvas.setValue(field.id, values)
                                onResetAnswerQuestionField(field.id)
                                handleBlur({target: {value: values}}, true)
                                if (isInstance){
                                    actions.handleSubmit();
                                }
                            }}
                            options={field.options}
                            allowMultiSelect={field.allowMultiSelect}
                            isDisabled={disabled}
                            onFocus={handleOnFocus}
                            allowMultiLineLabel
                            allowExternalAccess={field.visibleToClient ?? false}
                            answerable={field.editableByClient ?? false}
                            answered={answered}
                            isIdle={isIdle}
                            enableOnChangeHandler={false}
                        />
                    )}
                    {!field.allowOtherOption && !field.allowMultiSelect && (
                        <CLADropdown
                            name={field.id}
                            label={renderLabel(field.label, field.tooltip)}
                            options={field.options}
                            value={defaultValue}
                            isDisabled={disabled}
                            onChange={(values) => {
                                formCanvas.setValue(field.id, values?.target?.value)
                                onResetAnswerQuestionField(field.id)
                            }}
                            onOpen={handleOnFocus}
                            allowMultiLineLabel
                            onClose={(e) => {
                                handleBlur(e, false)
                            }}
                            allowExternalAccess={field.visibleToClient ?? false}
                            answerable={field.editableByClient ?? false}
                            answered={answered}
                            isIdle={isIdle}
                        />
                    )}
                    {!field.allowOtherOption && field.allowMultiSelect && (
                        <CLASelect
                            id={field.id}
                            name={field.id}
                            label={renderLabel(field.label, field.tooltip)}
                            isDisabled={disabled}
                            defaultValues={
                                Array.isArray(defaultValue) ? defaultValue.filter(Boolean) : [defaultValue].filter(Boolean)
                            }
                            onChange={(values) => {
                                formCanvas.setValue(field.id, values?.filter((i) => i))
                            }}
                            menuItems={field.options}
                            onOpen={handleOnFocus}
                            allowMultiLineLabel
                            onClose={(e) => { 
                                onResetAnswerQuestionField(field.id)
                                handleBlur(e, true)
                            }}
                            allowExternalAccess={field.visibleToClient ?? false}
                            answerable={field.editableByClient ?? false}
                            answered={answered}
                            isIdle={isIdle}
                            uncontrolled
                        />
                    )}
                    {renderLink()}
                </VFRenderedFieldWrapper>
            );
        }
        case FIELD_TYPES.DATE_PICKER: {
            const defaultValue = isIdle ? formCanvas.getValues(field.id) : getDefaultValue();
            return (
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper}
                    isLockedByUser={isLockedByUser}
                    isLocked={handleIsLockedSection(section)}
                    isInstance
                >
                    <Controller
                        control={formCanvas.control}
                        name={field.id}
                        render={({ field: { onChange } }) => {
                            return (
                                <CLADatePickerField
                                    label={renderLabel(field.label, field.tooltip)}
                                    onChange={(e) => {
                                        const value = e ?? null;
                                        onChange(value)
                                    }}
                                    onBlur={() => { 
                                        handleBlur(null, true)
                                    }}
                                    placeholder={field.placeholder}
                                    disabled={disabled}
                                    defaultValue={defaultValue || formCanvas.getValues(field.id)}
                                    onFocus={handleOnFocus}
                                    allowMultiLineLabel
                                    allowExternalAccess={field.visibleToClient ?? false}
                                    answerable={field.editableByClient ?? false}
                                    answered={answered}
                                    isIdle={isIdle}
                                />
                            )
                        }} />
                    {renderLink()}

                </VFRenderedFieldWrapper>

            );
        }
        case FIELD_TYPES.TABLE: {
            const defaultValue = isIdle ? formCanvas.getValues(field.id) : getDefaultValue(true);
            const parsed = parseQuestionLabel(field.label);
            const label = typeof parsed === 'object' ? parsed.questionLabel : parsed;
            return (
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper}
                    isLockedByUser={isLockedByUser}
                    isLocked={handleIsLockedSection(section)}
                    isInstance
                >
                    <div className={styles['vf-section-table-container']}>
                        <div className={styles['vf-section-table-label-wrapper']}>
                            <Flex direction="row" justify="between" align="baseline">
                                <InputLabel className={styles['vf-section-table-label']}>
                                    {renderLabel(label, field.tooltip, renderLink)}
                                </InputLabel>
                                <ClientExternalIcon
                                    allowExternalAccess={field.visibleToClient ?? false}
                                    answerable={field.editableByClient ?? false}
                                />
                            </Flex>
                        </div>
                        <CLAFormTable
                            field={field}
                            values={defaultValue}
                            onChange={(e) => {
                                formCanvas.setValue(field.id, e)
                                if (isInstance){
                                    actions.handleSubmit();
                                }
                                handleBlur({target: {value: e}}, true)
                                onResetAnswerQuestionField(field.id)
                            }}
                            isInstance={isInstance}
                            disabled={disabled}
                            onFocus={handleOnFocus}
                            isIdle={isIdle}
                        />
                    </div>
                </VFRenderedFieldWrapper>
            );
        }
        case FIELD_TYPES.FORMULA: {
            return (
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper}
                    isLockedByUser={isLockedByUser}
                    isLocked={handleIsLockedSection(section)}
                    isInstance
                >
                    <Formula
                        field={field}
                        shouldRenderFormula={isInstance}
                        label={renderLabel(field.label, field.tooltip, renderLink)}
                        answerList={answerList ? copyObject(answerList) : null}
                        onFocus={handleOnFocus}
                        disabled={disabled}
                    />
                </VFRenderedFieldWrapper>
            )
        }
        case FIELD_TYPES.TRIAL_BALANCE: {
            const defaultValue = getDefaultValue(true);
            const defaultArrayValue = Array.isArray(defaultValue) ? defaultValue : defaultValue ? [defaultValue] : [];
            return (
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper}
                    isLockedByUser={isLockedByUser}
                    isLocked={handleIsLockedSection(section)}
                    isInstance
                >
                    <div className={styles.field__wrapper_inner}>
                        {isInstance && projectId
                            ? (<CLATrialBalanceSelect
                                field={field}
                                formTypeId={formTypeId}
                                defaultValues={defaultArrayValue}
                                handCaptureInput={(userInput) => {
                                    formCanvas.setValue(field.id, userInput);
                                    actions.handleSubmit();
                                }}
                                onFocus={handleOnFocus}
                                isDisabled={_disabled}
                                project={project}
                                isPlanningAnalyticsForm={true}
                            />)
                            : (<div className={styles["vf-section-placeholder"]}> TRIAL BALANCE COMPONENT PLACEHOLDER  </div>)
                        }
                    </div>
                    {renderLink()}
                </VFRenderedFieldWrapper>
            );
        }
        case FIELD_TYPES.KPI_TRIAL_BALANCE: {
            const defaultValue = getDefaultValue(true);
            const defaultArrayValue = Array.isArray(defaultValue) ? defaultValue : defaultValue ? [defaultValue] : [];
            return (
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper}
                    isLockedByUser={isLockedByUser}
                    isLocked={handleIsLockedSection(section)}
                    isInstance
                >
                    <div className={styles.field__wrapper_inner}>
                        {isInstance && projectId ? (
                            <CLATrialBalanceSelect
                                defaultValues={defaultArrayValue}
                                formTypeId={formTypeId}
                                handCaptureInput={(userInput) => {
                                    formCanvas.setValue(field.id, userInput);
                                    actions.handleSubmit();
                                }}
                                field={field}
                                isDisabled={_disabled}
                                isSingleSelect
                                project={project}
                                onFocus={handleOnFocus}
                                signOffList={signOffList}
                                highlightable={true}
                            />
                        ) : (
                            <div className={styles["vf-section-placeholder"]}>
                                Trial Balance
                            </div>
                        )}
                    </div>
                    {renderLink()}
                </VFRenderedFieldWrapper>
            );
        }
        case FIELD_TYPES.PROJECT_SCOPE_TRIAL_BALANCE: {
            const defaultValue = getDefaultValue(true);
            const defaultArrayValue = Array.isArray(defaultValue) ? defaultValue : defaultValue ? [defaultValue] : [];
            return (
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper}
                    isLockedByUser={isLockedByUser}
                    isLocked={handleIsLockedSection(section)}
                    isInstance
                >
                    <div className={styles.field__wrapper_inner}>
                        {isInstance && projectId ? (
                            <CLATrialBalanceSelect
                                defaultValues={defaultArrayValue}
                                formTypeId={formTypeId}
                                handCaptureInput={(userInput) => {
                                    formCanvas.setValue(field.id, userInput);
                                    actions.handleSubmit();
                                }}
                                field={field}
                                isDisabled={_disabled}
                                project={project}
                                onFocus={handleOnFocus}
                                signOffList={signOffList}
                                highlightable={true}
                            />
                        ) : (
                            <div className={styles["vf-section-placeholder"]}>
                                Trial Balance
                            </div>
                        )}
                        </div>    
                    {renderLink()}
                </VFRenderedFieldWrapper>
            );
        }
        case FIELD_TYPES.PROJECT_SCOPE_TEST_OPERATING_EFFECTIVENESS: {
            return (
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper}
                    isLockedByUser={isLockedByUser}
                    isLocked={handleIsLockedSection(section)}
                    isInstance
                >
                    <div className={styles.field__wrapper}>
                        <div className={styles.field__wrapper_inner}>
                            {isInstance && projectId ? (
                                <CLAInitialScopingConsiderations
                                    fieldId={field.id}
                                    handCaptureInput={(userInput) => {
                                        formCanvas.setValue(field.id, userInput);
                                        if (isInstance){
                                            actions.handleSubmit();
                                        }
                                    }}
                                    methodologyVersionId={methodologyVersionId}
                                    projectFormId={projectFormId}
                                    disabled={_disabled}
                                    onFocus={handleOnFocus}
                                    signOffList={signOffList}
                                    answerList={answerList}
                                />
                            ) : (
                                <div className={styles["vf-section-placeholder"]}>Audit Area</div>
                            )}
                        </div>
                        {renderLink()}
                    </div>
                </VFRenderedFieldWrapper>
            );
        }
        case FIELD_TYPES.PROJECT_SCOPE_AUDIT_AREA: {
            if (section) {
                const { fields } = section;
                const trialBalanceField = fields[0].find((field => field.type === FIELD_TYPES.PROJECT_SCOPE_TRIAL_BALANCE));
                return (
                    <VFRenderedFieldWrapper
                        className={styles.field__wrapper}
                        isLockedByUser={isLockedByUser}
                        isLocked={handleIsLockedSection(section)}
                        isInstance
                    >
                        <div className={styles.field__wrapper}>
                            <div className={styles.field__wrapper_inner}>
                                {isInstance && projectId ? (
                                    <CLAProjectScopeAuditArea
                                        methodologyIndustries={methodologyIndustries}
                                        trialBalanceField={trialBalanceField}
                                        disabled={_disabled}
                                        signOffList={signOffList}
                                    />
                                ) : (
                                    <div className={styles["vf-section-placeholder"]}>Audit Area</div>
                                )}
                            </div>
                            {renderLink()}
                        </div>
                    </VFRenderedFieldWrapper>
                );
            } else {
                return null;
            }
        }
        case FIELD_TYPES.GENERAL_LEDGER: {
            return (
                <div className={styles.field__wrapper}>
                    <div className={styles.field__wrapper_inner}>
                        <GLCAccounts
                            isInstance={isInstance}
                            disabled={_disabled}
                            appDataInstanceId={project?.AppDataInstanceId}
                            setIsLoadCurrentPeriodSection={actions.handleSetIsLoadCurrentPeriodSection}
                        />
                    </div>
                    {renderLink()}
                </div>
            );
        }
        case FIELD_TYPES.CURRENT_PERIOD_TO_PRIOR: {
            const loadSection = state.isLoadCurrentPeriodSection
            return (
                <div className={styles.field__wrapper}>
                    <div className={styles.field__wrapper_inner}>
                        {isInstance ? (
                            <div style={{ marginTop: '50px' }}>
                                <CPPPTable
                                    projectId={projectId}
                                    sectionId={section?.id}
                                    isLoadCurrentPeriodSection={state.isLoadCurrentPeriodSection}
                                />
                            </div>
                        ) : (
                            <div className={styles["vf-section-placeholder"]}>
                                Current period to prior Component Placeholder
                            </div>
                        )}
                    </div>
                    {renderLink()}
                </div>
            );
        }
        case FIELD_TYPES.TRENDING_BY_MONTH: {
            const formValues = formCanvas.getValues()
            return (
                <div className={styles.field__wrapper}>
                    <div className={styles.field__wrapper_inner}>
                        {isInstance && (
                            <GraphsDisplayManager
                                formValues={formValues}
                                projectId={projectId}
                                projectFormId={projectFormId}
                                sectionId={section.id}
                                project={project}
                            />
                        )}
                    </div>
                    {renderLink()}
                </div>
            );
        }
        case FIELD_TYPES.KPIS_RATIOS: {
            return (
                <></>
                // TODO: Enable once KPI's are implemented
                // Disabled based on US-3181 AC 8
                // <div className={styles.field__wrapper}>
                //     <div className={styles.field__wrapper_inner}>
                //         <div className={styles['vf-section-placeholder']}>
                //             KPIS RATIOS Component Placeholder
                //         </div>
                //     </div>
                //     {renderLink()}
                // </div>
            );
        }
        case FIELD_TYPES.CLIENT_SITUATIONS: {
            const defaultValue = getDefaultValue(true);
            return (isInstance &&
                <CLAProcedureField
                    section={{ projectId, ...section }}
                    defaultValues={
                        Array.isArray(defaultValue) ? defaultValue : [defaultValue]
                    }
                    procedureFilter={procedureFilter}
                    field={field}
                    disabled={disabled}
                    handleOnFocus={handleOnFocus}
                />);
        }
        case FIELD_TYPES.FINANCIAL_STATEMENT_LEVEL_RISK: {
            return (
                <div className={styles.field__wrapper}>
                    <div className={styles.field__wrapper_inner}>
                        <CLAFinancialStatementLevelRisk
                            projectFormId={projectFormId}
                            disabled={_disabled} />
                    </div>
                </div>
            );
        }
        case FIELD_TYPES.ACCOUNT_AND_ASSERTION_LEVEL_RISK: {
            return (
                <div className={styles.account__assertion__wrapper}>
                    <div className={styles.field__wrapper_inner}>
                        <CLAAccountAssertionLevelRisk
                            projectFormId={+projectFormId}
                            projectId={projectId}
                            disabled={_disabled}
                        />
                    </div>
                </div>
            );
        }
        case FIELD_TYPES.EVALUATION_OF_DEFICIENCIES_IN_INTERNAL_CONTROL: {
            return (
                <div className={styles.field__wrapper}>
                    <div className={styles.field__wrapper_inner}>
                        <CLAEvaluationOfDeficiencies projectFormId={projectFormId} projectId={projectId} signOffList={signOffList} />
                    </div>
                </div>
            );
        }
        case FIELD_TYPES.COMMUNICATION_OF_DEFICIENCIES_IN_INTERNAL_CONTROL: {
            return (
                <div className={styles.field__wrapper}>
                    <div className={styles.field__wrapper_inner}>
                        <CLACommunicationOfDeficiencies signOffList={signOffList}/>
                    </div>
                </div>
            );
        }
        case FIELD_TYPES.CUSTOM_RISK_CHARACTERISTIC_SELECT_GROUP: {
            return (
                <div className={styles.field__wrapper}>
                    <div className={styles.field__wrapper_inner}>
                        <RiskCharacteristics
                            projectFormId={projectFormId}
                            methodologyVersionId={methodologyVersionId}
                            methodologyIndustries={methodologyIndustries}
                            disabled={_disabled}
                            signOffList={signOffList}
                        />
                    </div>
                </div>
            )
        }
        case FIELD_TYPES.QUESTION_GROUP: {
            const defaultValue = isIdle ? formCanvas.getValues(field.id) : getDefaultValue();
            return (
                <VFRenderedFieldWrapper
                    className={styles.field__wrapper}
                    isLockedByUser={isLockedByUser}
                    isLocked={handleIsLockedSection(section)}
                    isInstance
                >
                    <VFQuestionGroup
                        field={field}
                        isInstance={isInstance}
                        onChange={(value, isOnBlur) => {
                            formCanvas.setValue(field.id, value);
                            if(isOnBlur){
                                handleBlur({ target: { value } }, true);
                                onResetAnswerQuestionField(field.id);
                            }
                        }}
                        onDropDownClose={() => {
                            const value = getDefaultValue();
                            handleBlur({ target: { value } }, true)
                        }}
                        handleOnFocus={handleOnFocus}
                        answerValues={defaultValue}
                        updateIsFormFocused={updateIsFormFocused}
                        onSubmit={actions?.handleSubmit}
                        disabled={disabled}
                    />
                </VFRenderedFieldWrapper>
            );
        }
        case FIELD_TYPES.PURPOSE_OBJECTIVE_PROCEDURE: {
            return <PurposeObjectiveProcedure />;
        }
        // case FIELD_TYPES.COMMUNICATION_OF_SIGNIFICANT_MATTERS: 
        case FIELD_TYPES.UNDERSTANDING_OF_ENTITY: {
            return (
                <div className={styles.field__wrapper}>
                    <div className={styles.field__wrapper_inner}>
                        {isInstance ? (
                            <UnderstandingOfEntityProvider>
                                <UOEAuditAreaTable signOffList={signOffList} />
                            </UnderstandingOfEntityProvider>
                        ) : (
                            <div className={styles['vf-section-placeholder']}>
                                {INTERNAL_CONTROLS_CONSTANTS.CUSTOM_COMPONENT_ENABLED}
                            </div>
                        )}
                    </div>
                </div>
            );
        }
        case FIELD_TYPES.EVALUATING_KEY_CONTROL: {
            return (
                <>
                    {isInstance ? (
                        <EvaluatingTheDesign projectFormId={projectFormId} signOffList={signOffList}/>
                    ) : (
                        <div className={styles['vf-section-placeholder']}>
                            {INTERNAL_CONTROLS_CONSTANTS.CUSTOM_COMPONENT_ENABLED}
                        </div>
                    )}
                </>
            );
        }
        case FIELD_TYPES.COMMUNICATION_OF_SIGNIFICANT_MATTERS: {
            return (
                <>
                    {isInstance ? (
                        <CommunicationOfSignificantsMattersContextProvider>
                            <CommunicationOfSiginificantMatters signOffList={signOffList} />
                        </CommunicationOfSignificantsMattersContextProvider>
                    ) : (
                        <div className={styles['vf-section-placeholder']}>
                            {INTERNAL_CONTROLS_CONSTANTS.CUSTOM_COMPONENT_ENABLED}
                        </div>
                    )}
                </>
            );
        }
        default: {
            return null;
        }
    }
});
