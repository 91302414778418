import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from "prop-types";
import {
    Box,
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    TextField
} from '@mui/material';
import { sanitize } from 'dompurify';
import {CLADropdown} from "@ais/forms";
import { CLATooltip } from "@ais/components"
import styles from './CLAAccountAssertionLevelRisk.module.css';
import {
    boxColumnStyles,
    wrapCellStyles,
    cellStyles,
    boxHeaderRowRiskStyles,
    boxHeaderColStyles,
    boxRiskContainer,
    txtReferenceStyles,
    selectStyles
} from './muiStyles';

import { useProjectFormInstanceProvider } from '@providers';
import formServices from '@services/forms/forms';
import { RISK_ASSESSMENT_FORM_INSTANCE } from '@constants/forms';
import { PROJECT_FORM_INSTANCE } from '@constants/index';
import CustomToast from '@components/CustomToast/CustomToast';

const CLAOtherRelevantAssertions = (props) => {
    const { projectId, projectFormId } = useParams();
    const {
        ACCOUNT_ASSERTION_LEVEL_RISK: CONSTANT_TEXT,
        RISK_LEVEL_INHERIT_RISK,
        RISK_LEVEL_CONTROL_RISK,
        RISK_MATERIAL_MISSTATEMENT_MATRIX: MATRIX,
        RISKS_KEYVALUE_INHERENT,
        RISKS_KEYVALUE_CONTROL,
        RISKS_KEYVALUE_RMM
    } = RISK_ASSESSMENT_FORM_INSTANCE.EN;
    const HIGHLIGHT_BORDER = PROJECT_FORM_INSTANCE.EN.HIGHLIGHT_BORDER;
    const {
        inherentRiskOptions,
        controlRiskOptions,
        isInternalControlEffectiveness,
        projectAuditAreaOtherRelevant,
        disabled,
    } = props;
    const { otherRelevantAssertion, riskFactorInfo } = projectAuditAreaOtherRelevant;
    const [inherentRisk, setInherentRisk] = useState(
        otherRelevantAssertion?.inherentRiskAssessmentName
    );
    const [controlRisk, setControlRisk] = useState(
        !isInternalControlEffectiveness
            ? 'High'
            : otherRelevantAssertion?.controlRiskAssessmentName
    );
    const [rmm, setRmm] = useState(otherRelevantAssertion?.riskOfMaterialMisstatementName); 
    
    const [loading, setLoading] = useState(false)   
    const [error, setError] = useState(false) 
    const [successOtherRelevantAssertionAuditAreaSCOTABD, setSuccessOtherRelevantAssertionAuditAreaSCOTABD] = useState(false)
    const [isRiskChanged, setIsRiskChanged] = useState(false)  
    const [shouldHighlight, setShouldHighlight] = useState();

    const {
        modifiedRiskAssessmentSummaryAfterSignOff,
        auditAreaScotabdRisk,
        actions: {
            riskAssessmentIdentifyModifiedAnswerAfterSignOff
        }
    } = useProjectFormInstanceProvider();

    const applyClass = () => {
        let classList = [];
        if (shouldHighlight === HIGHLIGHT_BORDER.MODIFIED) classList.push(styles['highlight-modified']);
        return classList.join(' ');
    };

    const joinEventConditionList = (condition) => Array.isArray(condition) ? condition?.map(e => e.eventConditionName).join(', ') : '';
    const riskFactorValue = () => riskFactorInfo?.map((r, i) =>
        <CLATooltip title={
            <div
                className="ql-editor"
                dangerouslySetInnerHTML={{ __html: sanitize(`<p><b>${r.riskFactorName}</b></p><p>${joinEventConditionList(r.eventCondition)}</p>`, { ADD_ATTR: ['target'] }) }}
            />
        } placement="bottom">
            <p key={i} className={styles['wrap-text']}>
                {r.riskFactorName}<br />
                {joinEventConditionList(r.eventCondition)}
            </p>
        </CLATooltip>);

    const handleRiskDropdownChanges = async (materialMisstatementRisk) => {
        const inherentRiskId = RISKS_KEYVALUE_INHERENT.find((risk) => risk.key === inherentRisk)?.value;
        const controlRiskId = RISKS_KEYVALUE_CONTROL.find((risk) => risk.key === controlRisk)?.value;
        const materialMisstatementRiskId = RISKS_KEYVALUE_RMM.find((risk) => risk.key === materialMisstatementRisk)?.value;

        const requestBody = {
            RiskAssessmentReferenceForWalkThrough: otherRelevantAssertion.referenceForWalkThrough,
            RiskAssessmentInherentRiskAssessmentId: inherentRiskId,
            RiskAssessmentReferenceForTestOfControl: otherRelevantAssertion.referenceForTestOfControl,
            RiskAssessmentControlRiskAssessmentId: controlRiskId,
            RiskAssessmentRiskOfMaterialMisstatementId: materialMisstatementRiskId,
            RequestProjectFormId: projectFormId
        }
        riskAssessmentIdentifyModifiedAnswerAfterSignOff(auditAreaScotabdRisk);
        try {
            setLoading(true)
            await formServices.updateOtherRelevantAssertionAuditAreaSCOTABD(projectId, otherRelevantAssertion.projectScopeAuditAreaSCOTABDId, requestBody);
            setLoading(false)
            setSuccessOtherRelevantAssertionAuditAreaSCOTABD(true)
        } catch (error) {
            setError(true)
            setLoading(false)
            throw error
        }
    };

    useEffect(() => {
        if (modifiedRiskAssessmentSummaryAfterSignOff) {
            const shouldHighlight = !!modifiedRiskAssessmentSummaryAfterSignOff
                .find(signOff => signOff.projectScopeAuditAreaSCOTABDId === otherRelevantAssertion.projectScopeAuditAreaSCOTABDId) ? HIGHLIGHT_BORDER.MODIFIED : null;

            setShouldHighlight(shouldHighlight);
        }
    }, [modifiedRiskAssessmentSummaryAfterSignOff]);

    useEffect(() => {
        if(isRiskChanged) riskFunc();
    }, [inherentRisk, controlRisk, isInternalControlEffectiveness]);

    const riskFunc = () => {
        if (inherentRisk && controlRisk) {
            const inherentRiskIndex = RISK_LEVEL_INHERIT_RISK.indexOf(inherentRisk);
            const controlRiskIndex = RISK_LEVEL_CONTROL_RISK.indexOf(controlRisk);
            otherRelevantAssertion.riskOfMaterialMisstatementName = MATRIX[inherentRiskIndex][controlRiskIndex];

            setRmm(MATRIX[inherentRiskIndex][controlRiskIndex]);
        }
        handleRiskDropdownChanges(otherRelevantAssertion.riskOfMaterialMisstatementName);
    }

    const handleBlur = async (fieldValue, property) => {
        setLoading(true)
        const High = 2;
        const materialMisstatementRiskId = otherRelevantAssertion.riskOfMaterialMisstatementName ?
            RISKS_KEYVALUE_RMM.find((risk) => risk.key === otherRelevantAssertion?.riskOfMaterialMisstatementName)?.value : High;

        const existingRow = {
            RiskAssessmentReferenceForWalkThrough: otherRelevantAssertion.referenceForWalkThrough,
            RiskAssessmentInherentRiskAssessmentId: otherRelevantAssertion.inherentRiskAssessmentId,
            RiskAssessmentReferenceForTestOfControl: otherRelevantAssertion.referenceForTestOfControl,
            RiskAssessmentControlRiskAssessmentId: otherRelevantAssertion.controlRiskAssessmentId ?? High,
            RiskAssessmentRiskOfMaterialMisstatementId: materialMisstatementRiskId,
        }

        const requestBody = {
            ...existingRow,
            [property]: fieldValue,
            RequestProjectFormId: projectFormId
        };
        riskAssessmentIdentifyModifiedAnswerAfterSignOff(auditAreaScotabdRisk);
        try {
            await formServices.updateOtherRelevantAssertionAuditAreaSCOTABD(projectId, otherRelevantAssertion.projectScopeAuditAreaSCOTABDId, requestBody);
            setLoading(false)
            setSuccessOtherRelevantAssertionAuditAreaSCOTABD(true)
        } catch (error) {
            setError(true)
            setLoading(false)
        }
    }

    return (
        <Box sx={{ ...boxColumnStyles, ...boxRiskContainer }}>
            <CustomToast 
                success={successOtherRelevantAssertionAuditAreaSCOTABD} 
                error={error} 
                loading={loading}
            />
            <TableContainer >
                <Table data-test="tableContainer">
                    <TableHead data-test="tableHeader" sx={boxHeaderRowRiskStyles}>
                        <TableRow>
                            <TableCell sx={boxHeaderColStyles} data-test="other-relevant-header">{CONSTANT_TEXT.OTHER_RELEVANT_ASSERTIONS}</TableCell>
                            <TableCell sx={boxHeaderColStyles} data-test="inherent-risk-factor-header">{CONSTANT_TEXT.INHERENT_RISK_FACTOR}</TableCell>
                            <TableCell sx={boxHeaderColStyles} data-test="reference-walkthrough-header">{CONSTANT_TEXT.REFERENCE_FOR_WALKTHROUGH}</TableCell>
                            <TableCell sx={boxHeaderColStyles} data-test="inherent-risk-assessment-header">{CONSTANT_TEXT.INHERENT_RISK_ASSESSMENT}</TableCell>
                            <TableCell sx={boxHeaderColStyles} data-test="test-controls-header">{CONSTANT_TEXT.REFERENCE_FOR_TEST_OF_CONTROLS_IF_PERFORMED}</TableCell>
                            <TableCell sx={boxHeaderColStyles} data-test="control-risk-assessment-header">{CONSTANT_TEXT.CONTROL_RISK_ASSESSMENT}</TableCell>
                            <TableCell sx={boxHeaderColStyles} data-test="risk-material-header">{CONSTANT_TEXT.RISK_OF_MATERIAL_MISSTATEMENT}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody data-test="tableBody">
                        <TableRow data-test="tableRowBody" className={applyClass()}>
                            <TableCell title={Array.isArray(otherRelevantAssertion?.assertionList) && otherRelevantAssertion?.assertionList?.map(a => a.assertionName).join(', ')} sx={{ ...cellStyles, ...wrapCellStyles }}>{otherRelevantAssertion?.assertionList?.map(a => a.assertionName).join(', ')}</TableCell>
                            <TableCell sx={{ ...cellStyles, ...wrapCellStyles }}>
                                {riskFactorValue()}
                            </TableCell>
                            <TableCell sx={cellStyles}>
                                <TextField
                                    sx={txtReferenceStyles}
                                    placeholder={CONSTANT_TEXT.ENTER_REFERENCE_WALKTHROUGH}
                                    inputProps={{ maxLength: 256 }}
                                    defaultValue={otherRelevantAssertion?.referenceForWalkThrough ?? ''}
                                    onBlur={(e) => {
                                        const fieldValue = e?.target?.value;
                                        handleBlur(fieldValue, 'RiskAssessmentReferenceForWalkThrough');
                                    }}
                                    onChange={(e) => {
                                        otherRelevantAssertion.referenceForWalkThrough = e.target.value;
                                    }}
                                    disabled={disabled}
                                />
                            </TableCell>
                            <TableCell sx={{ ...cellStyles, ...selectStyles }}>
                                <CLADropdown
                                    options={inherentRiskOptions?.map(c => c.inherentRiskAssessmentName)}
                                    value={inherentRisk ?? ''}
                                    onChange={(e) => {
                                        setIsRiskChanged(true)
                                        const inherit = inherentRiskOptions?.find(c => c.inherentRiskAssessmentName === e.target.value);
                                        if (inherit) {
                                            setInherentRisk(inherit.inherentRiskAssessmentName);
                                            if (!isInternalControlEffectiveness) {
                                                otherRelevantAssertion.riskOfMaterialMisstatementName = inherit.inherentRiskAssessmentName;
                                            }
                                            otherRelevantAssertion.inherentRiskAssessmentId = inherit.inherentRiskAssessmentId;
                                            otherRelevantAssertion.inherentRiskAssessmentName = inherit.inherentRiskAssessmentName
                                        }
                                    }}
                                    isDisabled={disabled}
                                />
                            </TableCell>
                            <TableCell sx={{ ...cellStyles, ...wrapCellStyles }} >
                                {isInternalControlEffectiveness && <TextField
                                    sx={txtReferenceStyles}
                                    placeholder={CONSTANT_TEXT.ENTER_REFERENCE_TEST}
                                    inputProps={{ maxLength: 256 }}
                                    defaultValue={otherRelevantAssertion?.referenceForTestOfControl ?? ''}
                                    onBlur={(e) => {
                                        const fieldValue = e?.target?.value;
                                        handleBlur(fieldValue, 'RiskAssessmentReferenceForTestOfControl');
                                    }}
                                    onChange={(e) => {
                                        otherRelevantAssertion.referenceForTestOfControl = e.target.value;
                                    }}
                                    disabled={disabled}
                                />}
                            </TableCell>
                            <TableCell sx={{ ...cellStyles, width: '135px', ...selectStyles }}>
                                {isInternalControlEffectiveness && <CLADropdown
                                    styles={{ fontSize: '14px', fontWeight: '600' }}
                                    options={controlRiskOptions?.map(c => c.controlRiskAssessmentName)
                                    }
                                    value={controlRisk ?? ''}
                                    onChange={(e) => {
                                        setIsRiskChanged(true)
                                        const control = controlRiskOptions?.find(c => c.controlRiskAssessmentName === e.target.value);
                                        if (control) {
                                            setControlRisk(control.controlRiskAssessmentName);
                                            otherRelevantAssertion.controlRiskAssessmentId = control.controlRiskAssessmentId;
                                            otherRelevantAssertion.controlRiskAssessmentName = control.controlRiskAssessmentName
                                        }
                                    }}
                                    isDisabled={disabled}
                                />}
                                {!isInternalControlEffectiveness && 'High'}

                            </TableCell>
                            <TableCell sx={{ ...cellStyles, width: '135px' }}>
                                {rmm}
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

CLAOtherRelevantAssertions.propTypes = {
    inherentRiskOptions: PropTypes.array,
    controlRiskOptions: PropTypes.array,
    isInternalControlEffectiveness: PropTypes.bool,
    projectAuditAreaOtherRelevant: PropTypes.object,
    disabled: PropTypes.bool,
}

export default CLAOtherRelevantAssertions;