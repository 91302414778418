import { useState, useEffect, useCallback } from "react"
import moment from "moment"
import { useTrialBalances } from "@services/trialbalance"
import { useProjectTrialBalances } from "@services/trialbalance"
import useProjectContext from "@contexts/ProjectContext"
import * as Constants from "@constants/index"
const { ACTUAL_SIGN } = Constants.TRIAL_BALANCE.EN

export const useTrialBalanceData = (selectedTrialBalanceIds, selectedType, selectedFundLevel, selectedPeriod, projectId) => {
    const [loading, setLoading] = useState(false)
    const [trialBalances, setTrialBalances] = useState([])
    const [groupAccountOptions, setGroupAccountOptions] = useState([])
    const [balanceTypes, setBalanceTypes] = useState([])
    const [recentPeriods, setRecentPeriods] = useState([])
    const [fundIndexes, setFundIndexes] = useState([])
    const [foundIndexes, setFoundIndexes] = useState(false)
    const { project } = useProjectContext()
    const { data: balances, isFetching } = useProjectTrialBalances(project?.AppDataInstanceId, projectId)

    const trialBalancesQrys = useTrialBalances(balances?.map((balance) => balance.id), projectId)

    useEffect(() => {
        let periods = [];
        if (isFetching) {
            return
        }
        if (!balances) {
            return
        }
        if (!selectedTrialBalanceIds?.length) {
            periods = balances.map((trialBalance) => ({ startDate: moment(trialBalance.startDate), endDate: moment(trialBalance.endDate) }))
        }
        else {
            let selectedTrialBalances = balances.filter(balance => selectedTrialBalanceIds.includes(balance.id));
            let selectedNames = selectedTrialBalances.map(selectedBalance => { return selectedBalance.correlationName });
            const result = balances.filter(trialBalance => selectedNames.includes(trialBalance.correlationName));
            periods = result.filter((tb) => tb.startDate || tb.endDate).map((trialBalance) => ({ startDate: moment(trialBalance.startDate), endDate: moment(trialBalance.endDate) }))
        }

        let formattedPeriods = periods.sort((a, b) => {
            return a.startDate.isAfter(b.startDate) ? 0 : -1
        })

        const fp = []
        let reducedValue = []
        formattedPeriods.forEach((period) => {
            if (period.startDate.isValid() && period.endDate.isValid()) {
                const formattedPeriod = `${period.startDate.format("L")} - ${period.endDate.format("L")}`
                fp.push({ value: formattedPeriod, label: formattedPeriod })
            }
        })

        reducedValue = fp.reduce((pp, current) => {
            if (pp.findIndex((period) => period.label === current.label) < 0) {
                return [...pp, current]
            }
            return [...pp]
        }, []).reverse().slice(0, 5)

        setRecentPeriods(reducedValue)
    }, [balances, isFetching, selectedTrialBalanceIds])

    useEffect(() => {
        if (isFetching) {
            return
        }
        if (!balances) {
            return
        }
        if (!selectedPeriod) {
            return
        }
        let formattedBalances = balances.map((trialBalance) => ({ ...trialBalance, startDate: moment(trialBalance.startDate), endDate: moment(trialBalance.endDate) }))
        formattedBalances = formattedBalances.map((trialBalance) => {
            const formattedPeriod = `${trialBalance.startDate.format("L")} - ${trialBalance.endDate.format("L")}`
            return { ...trialBalance, formattedPeriod }
        })
        formattedBalances = formattedBalances.filter((trialBalance) => {
            if (trialBalance.formattedPeriod === selectedPeriod) {
                return true
            }
            return false
        })
        const uniqueBalanceTypes = []
        formattedBalances.forEach((trialBalance) => {
            if (uniqueBalanceTypes.findIndex((tb) => tb.value === trialBalance.trialBalanceType) > -1) {
                return
            }
            uniqueBalanceTypes.push({ value: trialBalance.trialBalanceType, label: trialBalance.trialBalanceType })
        })

        const cleanedUniqueBalanceTypes = uniqueBalanceTypes.filter((tb) => tb.label && tb.value)
        setBalanceTypes(cleanedUniqueBalanceTypes)

    }, [balances, isFetching, selectedPeriod])

    useEffect(() => {
        if (!trialBalancesQrys) {
            return
        }
        if (trialBalancesQrys.some(({ isFetching }) => isFetching)) {
            setLoading(true)
            return
        }
        if (!loading && trialBalances.length !== trialBalancesQrys.length) {
            setLoading(true)
            return
        }
        if (!loading) {
            return
        }
        const trialBalanceQrysData = trialBalancesQrys.map((trialBalance) => trialBalance.data);
        if (!trialBalanceQrysData.includes(undefined)) {
            setTrialBalances(trialBalanceQrysData)
        }
        setLoading(false)
    }, [trialBalancesQrys, trialBalances])

    useEffect(() => {
        if (!selectedType || !selectedTrialBalanceIds) {
            setGroupAccountOptions([])
            return
        }
        if (selectedTrialBalanceIds.length < 1) {
            setGroupAccountOptions([])
            setFundIndexes([])
            return
        }
        if (!trialBalances) {
            return
        }
        if (trialBalances.length < 1) {
            return
        }

        const selectedTrialBalances = trialBalances.filter((trialBalance) => selectedTrialBalanceIds.includes(trialBalance.id))
        const trialBalancesGroups = selectedTrialBalances.map((trialBalance) => trialBalance.groups).flat()
        const uniqueGroupAccountOptions = []
        trialBalancesGroups.forEach((group) => {
            let label = group[selectedType]
            if (selectedType === "glAccountNumber") {
                label = `${group["glAccountNumber"]} ${group["glAccountName"]}`
            }
            if (uniqueGroupAccountOptions.findIndex((option) => option.label === label) > -1) {
                return
            }
            if (group[selectedType] === "null" || !group[selectedType]) {
                return;
            }
            uniqueGroupAccountOptions.push({ label: label, value: `${group[selectedType]}` })
        })

        const filteredUniqueGroupAccountOptions = uniqueGroupAccountOptions?.filter((data) => {
            return data.label || data.value || data.label !== "null"
        })
        setGroupAccountOptions(filteredUniqueGroupAccountOptions)
    }, [trialBalances, isFetching, selectedType, selectedTrialBalanceIds])

    useEffect(() => {
        if (!trialBalances) {
            return
        }
        const trialBalancesGroups = trialBalances.map((trialBalance) => trialBalance?.groups).flat();
        const filteredIndexes = trialBalancesGroups.filter((group) => {
            if (group["segment03"] || group["segment04"] || group["segment05"]) {
                return true
            }
            return false
        })
        setFoundIndexes(filteredIndexes.length > 0)
        if (filteredIndexes.length < 1) {
            return
        }
        let formattedIndexes = filteredIndexes.map((group) => {
            let dataKey = ""
            if (selectedFundLevel === "fund") {
                dataKey = "segment03"
            }
            else if (selectedFundLevel === "fundType") {
                dataKey = "segment04"
            }
            else if (selectedFundLevel === "fundSubType") {
                dataKey = "segment05"
            }
            if (dataKey.length < 1) {
                return
            }
            const fundIndex = group[dataKey]
            return { label: fundIndex, value: fundIndex }
        })
        formattedIndexes = formattedIndexes.reduce((indexes, current) => {
            if (indexes.findIndex((idx) => idx?.value === current?.value) < 0) {
                return [...indexes, current]
            }
            return [...indexes]
        }, [])
        setFundIndexes(formattedIndexes.map((formattedIndex) => formattedIndex).filter((findex) => findex !== undefined))
    }, [trialBalances, selectedFundLevel])

    const getTotalAmount = useCallback((criteria) => {
        const selectedCorrelationDetailIds = balances
            .filter(balanceFilter => typeof criteria === 'object' && criteria.correlationNameIds.includes(balanceFilter.correlationNameId))
            .map(balanceData => balanceData.correlationDetailId);

        if (!trialBalancesQrys) {
            return
        }
        const trialBalanceQrysData = trialBalancesQrys.map((trialBalance) => trialBalance.data)
        if (!trialBalanceQrysData) {
            return
        }
        const filteredTrialBalances = trialBalanceQrysData?.filter((trialBalance) => {
            if ((criteria.balanceType || "").toLowerCase() !== (trialBalance?.trialBalanceType || "").toLowerCase()) {
                return false
            }
            const formattedPeriod = `${moment(trialBalance.startDate).format("L")} - ${moment(trialBalance.endDate).format("L")}`
            if ((criteria.period || "").toLowerCase() !== (formattedPeriod || "").toLowerCase()) {
                return false
            }
            if (selectedCorrelationDetailIds && !selectedCorrelationDetailIds.includes(trialBalance.correlationDetailId)) return false
            return true
        })
        let amountEnding = parseFloat(0.0)
        filteredTrialBalances.forEach((trialBalance) => {
            const groups = trialBalance.groups
            if (!groups) {
                return
            }
            const filteredGroups = groups.filter((group) => {
                if ((group[criteria.type] || "").toLowerCase() !== (criteria.groupAccount || "").toLowerCase()) {
                    return false
                }
                return true
            })
            filteredGroups.forEach((group) => {
                amountEnding += parseFloat(group.amountEnding)
            })
        })
        if ((criteria.signOptions || "").toLowerCase() === ACTUAL_SIGN) {
            amountEnding = amountEnding * 1
        }
        else {
            amountEnding = amountEnding * -1
        }
        return amountEnding
    }, [trialBalances, trialBalancesQrys])

    return [groupAccountOptions, balanceTypes, recentPeriods, fundIndexes, foundIndexes, getTotalAmount]
}

export const VALID_TB_DATA = 0
export const NO_PERIOD_EXISTING = 1
export const NO_GROUP_EXISTING = 2
export const NO_TRIAL_BALANCE = 3

export const useTrialBalanceQuery = (criteria, projectId) => {
    const [dataType, setDataType] = useState(-1)
    const [amountEnding, setAmountEnding] = useState()
    const [tbLoaded, setTbLoaded] = useState(false)

    const { project } = useProjectContext()
    const { data: balances, isFetching } = useProjectTrialBalances(project?.AppDataInstanceId, projectId)
    const trialBalancesQrys = useTrialBalances(balances?.map((balance) => balance.id), projectId)

    useEffect(() => {
        if (trialBalancesQrys.length === 0 || trialBalancesQrys.some(tb => !tb.data)) {
            return;
        }
        setTbLoaded(true)
    }, [trialBalancesQrys])

    useEffect(() => {
        if (isFetching || !criteria) {
            return
        }
        if (tbLoaded) {
            const selectedCorrelationDetailIds = balances
                .filter(balanceFilter => typeof criteria === 'object' && criteria.correlationNameIds.includes(balanceFilter.id))
                .map(balanceData => balanceData.correlationDetailId);

            if ((criteria?.trialBalances?.length === 1 && criteria?.trialBalances[0] === '00000000-0000-0000-0000-000000000000') || (criteria?.correlationNameIds?.length === 1 && criteria?.correlationNameIds[0] === '00000000-0000-0000-0000-000000000000')) {
                setDataType(NO_TRIAL_BALANCE);
                return;
            }
            const trialBalanceQrysData = trialBalancesQrys.map((trialBalance) => trialBalance.data)
            if (!trialBalanceQrysData || trialBalanceQrysData.length < 1) {
                return
            }
            const filteredTrialBalances = trialBalanceQrysData.filter((trialBalance) => {
                if ((criteria.balanceType || "").toLowerCase() !== (trialBalance?.trialBalanceType || "").toLowerCase()) {
                    return false
                }
                const formattedPeriod = `${moment(trialBalance?.startDate).format("L")} - ${moment(trialBalance.endDate).format("L")}`
                if ((criteria.period || "").toLowerCase() !== (formattedPeriod || "").toLowerCase()) {
                    return false
                }
                if (selectedCorrelationDetailIds && !selectedCorrelationDetailIds.includes(trialBalance.correlationDetailId)) return false
                return true
            })
            if (filteredTrialBalances.length < 1) {
                setDataType(NO_PERIOD_EXISTING)
                return
            }
            let totalAmount = parseFloat(0.0)
            let hasValidGroup = false
            filteredTrialBalances.forEach((trialBalance) => {
                const groups = trialBalance.groups
                if (!groups) {
                    return
                }
                const filteredGroups = groups.filter((group) => {
                    if ((group[criteria.type] || "").toLowerCase() !== (criteria.groupAccount || "").toLowerCase()) {
                        return false
                    }
                    return true
                })
                if (filteredGroups.length > 0) {
                    hasValidGroup = true
                }
                filteredGroups.forEach((group) => {
                    let groupAmountEnding = parseFloat(group.amountEnding)
                    if (isNaN(groupAmountEnding)) {
                        groupAmountEnding = 0
                    }
                    totalAmount += groupAmountEnding
                })
            })
            if (totalAmount === 0 || isNaN(totalAmount)) {
                hasValidGroup = false
            }
            if (hasValidGroup) {
                if ((criteria.signOptions || "").toLowerCase() === ACTUAL_SIGN) {
                    totalAmount = totalAmount * 1
                }
                else {
                    totalAmount = totalAmount * -1
                }
                setDataType(VALID_TB_DATA)
                setAmountEnding(totalAmount)
                return
            }
            setDataType(NO_GROUP_EXISTING)
        }
    }, [isFetching, criteria, tbLoaded])

    return { dataType, amountEnding }
}