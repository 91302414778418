
import React, { useState, useEffect } from 'react'
import styles from './CPPPCategoryLevel.module.css';
import { useParams } from 'react-router-dom';
import { OutlinedInput, FormControl, InputLabel } from "@mui/material";
import { PRIOR_PERIOD_COMPARISON } from "@constants/forms/planninganalytics.js";
import { CLANestedDataGrid } from '@ais/components';
import customStyle from "./categoryLevelStyles.js"
import columns from './categoryLevelColumns'
import CPPPAccountLevel from '../CPPPAccountLevel/CPPPAccountLevel'
import { toast } from 'react-toastify';
import { useLoading } from '@hooks/index';
import { useInsertProjectFormComments, useUpdateProjectFormComments } from '@services/forms/projectFormComments';
import CustomToast from '@components/CustomToast/CustomToast';

const CPPPCategoryLevel = (
  {
    isCommentSectionExpanded,
    sectionId,
    userId,
    commentPath,
    commentId: _commentId,
    comment: _comment,
    rowData,
  }
) => {

  const [rows, setRows] = useState([]);
  const [commentId, setCommentId] = useState(_commentId);
  const [comment, setComment] = useState(_comment);
  const [expandedRow, setExpandedRow] = useState([]);
  const [expandComment, setExpandComment] = useState(false);
  const handleAdditionalCollapseTrigger = (newIds) => {
    const rowId = newIds.slice(-1);
    setExpandedRow(rowId);
  }
  const { projectId, projectFormId } = useParams();
  const setLoading = useLoading()

  const {
    mutate: createProjectFormComment,
    isLoading: isLoadingCreateComment,
    isError: errorCreateComments,
    isSuccess: successCreateComments
  } = useInsertProjectFormComments(projectId);

  const {
    mutate: updateProjectFormComments,
    isLoading: isLoadingUpdateComments,
    isError: errorUpdateComments,
    isSuccess: successUpdateComments
  } = useUpdateProjectFormComments(projectId);

  useEffect(() => {
    return () => {
      setRows([])
      setExpandedRow([])
    }
  }, [])

  useEffect(() => {
    setLoading(isLoadingCreateComment || isLoadingCreateComment)
    if (rowData?.length) {
      rowData.forEach((item, index) => {
        item.id = index
        rowData[index] = item
      })
      const filteredCategoryData = rowData
        .filter(category => category.CurrentPeriodBalance !== 0 && category.PriorPeriodBalance !== 0)
        .filter(category => category.CurrentPeriodBalance !== null && category.PriorPeriodBalance !== null)
        .map(category => ({
          ...category,
          id: category.id + category.CategoryName,
        }))

      filteredCategoryData.forEach(category => {
        category.ExpandedPanel = <CPPPAccountLevel
          rowData={category.AccountLevel}
        />
      })

      setRows(filteredCategoryData);
    }
  }, [rowData, isLoadingUpdateComments, isLoadingCreateComment]);

  const handleCommentBlur = () => {
    const options = {
      onError: (error) => {
        toast.error(error.toString());
      },
      onSettled: () => {
        setExpandComment(true);
      }
    }

    if (!commentId) {
      options.onSuccess = (res) => {
        setCommentId(res.data.ProjectFormCommentId)
      }

      createProjectFormComment({
        ProjectFormId: projectFormId,
        FormParentAttributeId: sectionId,
        Comment: comment,
        CommentPath: JSON.stringify(commentPath),
        ModifiedUser: userId 
      }, options);
    } else {
      updateProjectFormComments({
        ProjectFormCommentId: commentId,
        Comment: comment,
        CommentPath: JSON.stringify(commentPath),
        ModifiedUser: userId,
        ProjectFormId: projectFormId
      }, options);
    }
  }

  useEffect(() => {
    setComment(_comment);
    setCommentId(_commentId);
  }, [_comment, isCommentSectionExpanded])

  return (
    <div className={styles["cppp-comment-section"]}>
      {
        (isCommentSectionExpanded || expandComment) &&
        <div className={styles["textarea-wrapper"]}>
          <FormControl className={styles["cppp-comment-textarea"]} variant="outlined">
            <InputLabel>{PRIOR_PERIOD_COMPARISON.commentInputLabel}</InputLabel>
            <OutlinedInput
              multiline
              minRows={1}
              maxRows={20}
              rows={1}
              label={PRIOR_PERIOD_COMPARISON.commentInputLabel}
              placeholder={PRIOR_PERIOD_COMPARISON.commentInputLabel}
              onChange={(e) => setComment(e.target.value)}
              onBlur={handleCommentBlur}
              value={comment}
              inputProps={{
                maxLength: 4096,
              }}
            />
          </FormControl>
        </div>
      }
      <CLANestedDataGrid
        className="Sample"
        rows={rows}
        columns={columns}
        hideFooterRowCount={true}
        expandedRow={expandedRow}
        handleAdditionalCollapseTrigger={handleAdditionalCollapseTrigger}
        showFooterContent={false}
        hideFooterPagination={true}
        hideFooter={true}
        style={customStyle}
        headerHeight={0}
      />
      <CustomToast
        error={errorUpdateComments || errorCreateComments}
        success={successUpdateComments || successCreateComments}
        loading={isLoadingUpdateComments || isLoadingCreateComment}
      />
    </div>
  )
}

export default CPPPCategoryLevel
