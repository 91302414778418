import formApi from '@utilities/claApiForm';
import { useQuery } from "@tanstack/react-query"

const getAuditAreas = async (projectId, projectScopeIds) => {
    try {
        const { data } = await formApi.post(`/projectscopesauditareas/${projectId}`, { projectScopeIds });
        return { status: 200, data };
    } catch (exception) {
        return { status: 400, message: exception };
    }
};

const getAuditAreasByProjectFormId = async (projectFormId) => {
    try {
        const { data } = await formApi.get(`/projectforms/${projectFormId}/auditareas`);
        return { status: 200, data };
    } catch (exception) {
        return { status: 400, message: exception };
    }
};

const getAuditAreasByMethodologyVersionId = async (methodologyVersionId) => {
    try {
        const { data } = await formApi.get(`/auditareas/${methodologyVersionId}/methodology`);
        return { status: 200, data };
    } catch (exception) {
        return { status: 400, message: exception };
    }
};

const getProjectScopeAuditAreas = async (projectScopeIds, projectId) => {
    try {
        const { data } = await formApi.get(`/projectscopesauditareas/list/${projectId}?ProjectScopeIds=[${projectScopeIds}]`);
        return { status: 200, data };
    } catch (exception) {
        return { status: 400, message: exception };
    }
};

const getDeficiencyAuditAreas = async(projectId, projectScopeIds) => {
    try {        
        const { data } = await formApi.get(`/project-deficiencies/${projectId}/auditareas?projectScopeIds=[${projectScopeIds}]`);
        return { status: 200, data };
    } catch (exception) {
        return {status: 400, message: exception };
    }
}

const putAuditAreasByMethodologyVersionId = async (methodologyVersionId, body) => {
    try {
        const response = await formApi.put(`/auditareas/${methodologyVersionId}/methodology`, body);
        return { status: 201, data: response.data };
    } catch (error) {
        const { response } = error;
        return { status: 400, message: response?.data?.message ?? error };
    }
};

const auditAreaServices = {
    getAuditAreas,
    getAuditAreasByMethodologyVersionId,
    putAuditAreasByMethodologyVersionId
};

export default auditAreaServices;

export const useAuditAreas = (projectId, projectScopeIds) => {
    return useQuery(
        ["auditAreas", projectScopeIds],
        () => getAuditAreas(projectId, projectScopeIds),
        {
            enabled: !!projectId && !!projectScopeIds && projectScopeIds.length > 0,
        }
    )
}

export const useProjectScopeAuditAreas = (projectScopeIds, projectId) => {
    return useQuery(
        ["projectScopeAuditAreas", projectScopeIds, projectId],
        () => getProjectScopeAuditAreas(projectScopeIds, projectId),
        {
            enabled: !!projectScopeIds && projectScopeIds.length > 0
        }
    )
}

export const useProjectFormAuditAreas = (projectFormId) => {
    return useQuery(
        ["projectFormAuditAreas", projectFormId],
        () => getAuditAreasByProjectFormId(projectFormId),
        {
            enabled: !!projectFormId
        }
    )
}

export const useAuditAreasbyMethodologyVersionId = (methodologyVersionId, isVisible) => {
    return useQuery(
        ["auditAreasByMethodologyVersionId", methodologyVersionId],
        () => getAuditAreasByMethodologyVersionId(methodologyVersionId),
        {
            enabled: isVisible && !!methodologyVersionId
        }
    )
}

export const useDeficiencyAuditAreas = (projectId, projectScopeIds) => {
    return useQuery(
        ["deficiencyAuditAreas", projectId, projectScopeIds],
        () => getDeficiencyAuditAreas(projectId, projectScopeIds),
        {
            enabled: !!projectScopeIds && projectScopeIds.length > 0
        }
    )
}