import { useState, useEffect, useCallback, useContext } from "react"
import { useProjectScopeByProjectForm } from "@services/forms/forms"
import {
    useRiskCharacteristics,
    useDeleteProjectScopeRiskCharacteristicMutation,
    useProjectScopeRiskCharacteristicMutation,
    useProjectScopeRiskCharacteristics
} from "@services/riskCharacteristics"
import { useSelectionState } from "@hooks/useSelectionState"
import { useCustomToast } from '@hooks/useCustomToast';
import { ScopingFrameworkAndPerformanceStandardContext } from "@contexts/ScopingFrameworkAndPerformanceStandardContext/ScopingFrameworkAndPerformanceStandardContext";
import { useParams } from 'react-router-dom';
import { sortReviewerSignoffAsc } from '@utilities/dateHelpers.js';
import { useQueryClient } from "@tanstack/react-query"
import { isSignoffLogicEnabled } from '@utilities/signoffUtility.js';
import { useFinalizedProject } from '@hooks/useProject';


export const useProjectRiskCharacteristics = (methodologyVersionId, projectFormId, methodologyIndustries, signOffList) => {

    const { selectedFrameworks, selectedPerformanceStandards } = useContext(ScopingFrameworkAndPerformanceStandardContext)
    const [ selectedRisks, setSelectedRisks ] = useState([])
    const [ selections, deletedSelections, { selectOne, selectAll, isSelected } ] = useSelectionState("id", selectedRisks, true)
    const { projectId } = useParams();
    const isProjectFinalized = useFinalizedProject(projectId);

    const { data: projectScopes, isFetching: fetchingProjectScopes } = useProjectScopeByProjectForm(projectFormId, projectId)
    const { data: projectFormRiskCharacteristic, isFetching: fetchingProjectFormRiskCharacteristics } = useProjectScopeRiskCharacteristics(projectScopes?.ProjectScopeId, projectId)
    const { data: riskCharacteristics, isFetching: fetchingRiskCharacteristics } = useRiskCharacteristics(methodologyVersionId)
    const { 
        mutateAsync: deleteProjectScopeRiskCharacteristic, 
        isSuccess: deleteProjectScopeRiskCharacteristicSuccess,
        isLoading: deleteProjectScopeRiskCharacteristicLoading,
        isError: deleteProjectScopeRiskCharacteristicError,
    } = useDeleteProjectScopeRiskCharacteristicMutation(projectId)
    const { 
        mutateAsync: createProjectScopeRiskCharacteristic,
        isSuccess: createProjectScopeRiskCharacteristicSuccess,
        isLoading: createProjectScopeRiskCharacteristicLoading,
        isError: createProjectScopeRiskCharacteristicError,
    } = useProjectScopeRiskCharacteristicMutation(projectId)
    const queryClient = useQueryClient();

    const [ projectFormAuditAreas, setProjectFormAuditAreas ] = useState([])
    useCustomToast({
        isLoading: createProjectScopeRiskCharacteristicLoading || deleteProjectScopeRiskCharacteristicLoading,
        isSuccess: createProjectScopeRiskCharacteristicSuccess || deleteProjectScopeRiskCharacteristicSuccess,
        isError: createProjectScopeRiskCharacteristicError || deleteProjectScopeRiskCharacteristicError
    })

    const getLatestRiskCharacteristicsTime = (ValidFrom) => {
        return new Date(ValidFrom).getTime()
    }

    const getShouldHighlightValue = useCallback((formattedList, signOffList) => {
        const latestReviewersAsc = sortReviewerSignoffAsc(signOffList)
        let shouldHighlightAfterAdd = false
        let shouldHighlightAfterDelete = false
        let isAddedAfterSignoff = false
        let isDeletedAfterSignoff = false
        formattedList?.forEach((auditArea) => {
            const latestAddedRiskCharacteristic = getLatestRiskCharacteristicsTime(auditArea.ValidFrom)
            shouldHighlightAfterAdd = new Date(latestReviewersAsc[latestReviewersAsc?.length - 1]?.signOffDate).getTime() < latestAddedRiskCharacteristic
            if(shouldHighlightAfterAdd && isSignoffLogicEnabled(isProjectFinalized, latestAddedRiskCharacteristic)){
                isAddedAfterSignoff = true
            }

            const latestDeletedRiskCharacteristic = getLatestRiskCharacteristicsTime(auditArea.ValidTo)
            shouldHighlightAfterDelete = new Date(latestReviewersAsc[latestReviewersAsc?.length - 1]?.signOffDate).getTime() < latestDeletedRiskCharacteristic
            if(shouldHighlightAfterDelete && isSignoffLogicEnabled(isProjectFinalized, latestDeletedRiskCharacteristic)){
                isDeletedAfterSignoff = true
            }

        })
        return isAddedAfterSignoff || isDeletedAfterSignoff
    }, [signOffList])
    const filter = useCallback(() => {
        if(!projectScopes || !riskCharacteristics || !projectFormRiskCharacteristic) {
            return
        }
        let areas = {}
        const projectScopeAuditAreas = projectScopes.AuditAreas
        const projectScopeScotabds = projectScopes.SCOTABDs
        const preSelected = []
        let filteredRiskCharacteristics = riskCharacteristics.filter((riskCharacteristic) => {
            if(riskCharacteristic.MethodologyIndustries.filter((rca) => methodologyIndustries.includes(rca.MethodologyIndustryId))?.length < 1) {
                return false
            }
            const projectScopeAuditAreasIds = projectScopeAuditAreas.map((psa) => psa.AuditAreaId)
            if(riskCharacteristic.RiskCharacteristicAuditAreas.filter((rca) => projectScopeAuditAreasIds.includes(rca.AuditAreaId))?.length < 1) {
                return false
            }
            const projectScopeScotabdsIds = projectScopeScotabds.map((psa) => psa.SCOTABDId)
            if(riskCharacteristic.SCOTABDs.filter((rca) => projectScopeScotabdsIds.includes(rca.SCOTABDId))?.length < 1) {
                return false
            }
            if(riskCharacteristic.RiskCharacteristicFrameworks.filter((rca) => selectedFrameworks?.includes(rca.FrameworkId))?.length < 1) {
                return false
            }
            if(riskCharacteristic.RiskCharacteristicPerformanceStandards.filter((rca) => selectedPerformanceStandards?.includes(rca.PerformanceStandardId))?.length < 1) {
                return false
            }
            return true
        })
        projectScopeAuditAreas.forEach((auditArea) => {
            let filtered = filteredRiskCharacteristics.filter((riskCharacteristic) => {
                return riskCharacteristic?.RiskCharacteristicAuditAreas?.filter((rca) => rca.AuditAreaId === auditArea.AuditAreaId)?.length > 0
            })
            if(filtered.length < 1) {
                return
            }
            const formattedList = filtered.map((riskCharacteristic) => {
                const deleteProjectScopeRiskCharacteristic = projectScopes.deletedProjectScopeRiskCharacteristics.find((dpsrc) => dpsrc.RiskCharacteristicId === riskCharacteristic.RiskCharacteristicId)
                const projectScopeRiskCharacteristic = projectFormRiskCharacteristic.find((formRiskCharacteristic) => formRiskCharacteristic.RiskCharacteristicId === riskCharacteristic.RiskCharacteristicId)
                return ({
                    id: riskCharacteristic.RiskCharacteristicId,
                    name: riskCharacteristic.RiskCharacteristicName,
                    riskCharacteristicId: riskCharacteristic.RiskCharacteristicId,
                    projectScopeRiskCharacteristicId: projectScopeRiskCharacteristic?.ProjectScopeRiskCharacteristicId,
                    selected: !!projectScopeRiskCharacteristic,
                    ValidFrom: projectScopeRiskCharacteristic?.ValidFrom,
                    ValidTo: deleteProjectScopeRiskCharacteristic?.ValidTo
                })
            })
            formattedList.forEach((item) => {
                if(item.selected) {
                    preSelected.push(item)
                }
            })
            areas[auditArea.AuditAreaId] = {
                id: auditArea.AuditAreaId,
                name: auditArea.AuditAreaName,
                riskCharacteristics: formattedList,
                shouldHighlight: getShouldHighlightValue(formattedList, signOffList)
            }
        })
        if(preSelected.length < 1) {
            selectAll([])
        }
        setSelectedRisks(preSelected)
        setProjectFormAuditAreas(Object.values(areas))
    }, [ projectScopes, riskCharacteristics, projectFormRiskCharacteristic, methodologyIndustries, selectedFrameworks, selectedPerformanceStandards, signOffList ])

    const toggleRiskCharacteristic = useCallback(async (event, riskCharacteristic) => {
        selectOne(riskCharacteristic)
        if(event.target.checked) {
            await createProjectScopeRiskCharacteristic({ 
                RiskCharacteristicId: riskCharacteristic.riskCharacteristicId, 
                ProjectScopeId: projectScopes.ProjectScopeId,
                RequestProjectFormId: projectFormId
            })
            return
        }
        await deleteProjectScopeRiskCharacteristic({ 
            ProjectScopeRiskCharacteristicId: riskCharacteristic.projectScopeRiskCharacteristicId,
            RequestProjectFormId: projectFormId,
            ProjectScopeId: projectScopes.ProjectScopeId
        })
        queryClient.invalidateQueries(['projectScopeForm'])
    }, [ selectOne, projectScopes ])

    useEffect(() => {
        if(fetchingProjectScopes || fetchingRiskCharacteristics || fetchingProjectFormRiskCharacteristics) {
            return
        }
        filter()
    }, [ fetchingProjectScopes, fetchingRiskCharacteristics, fetchingProjectFormRiskCharacteristics, signOffList ])

    return [ projectFormAuditAreas, { selectOne, isSelected, toggleRiskCharacteristic } ]
}
