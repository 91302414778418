import { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import { Grid, InputLabel } from '@mui/material';
import { toast } from 'react-toastify';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
    TextField
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import { CLASelect } from '@ais/forms';
import { useGetDeficiencyEvaluationOptions, usePostProjectDeficiency, usePutProjectDeficiency } from '@services/deficiency';
import { useProjectScopeAuditAreas } from '@services/auditArea';
import styles from '../FormView.module.css';
import * as Yup from 'yup';
import * as Constants from '@constants/customForm/index'
import CLATextField from "@components/Forms/CLATextField/CLATextField"
import { useQueryClient } from "@tanstack/react-query"
import { useParams } from "react-router-dom";
import { useLoading } from '@hooks/index';
import CLATruncatedTextWithTooltip from "@components/CLATruncatedTextWithTooltip/CLATruncatedTextWithTooltip";
export const DeficiencyModal = ({
    section,
    visible,
    setVisible,
    formName,
    deficiencyData,
    isEdit = false
}) => {
    const { EN, API_REQUEST_ACTION_TYPE } = Constants.ADD_DEFICIENCY;
    const setLoading = useLoading();
    const queryClient = useQueryClient();
    const { projectId, projectFormId } = useParams();
    const [deficiencySummaryOptions, setDeficiencySummaryOptions] = useState([]);
    const [editDeficiencyInitialValues, setEditDeficiencyInitialValues] = useState({
        deficiencyName: '',
        deficiencyDescription: '',
        auditAreaList: [],
        projectScopeDeficiencySummary: '',
        workpaperReference: ''
    });
    const [auditAreaOptions, setAuditAreaOptions] = useState([]);
    const [isSavingInProgress, setIsSavingInProgress] = useState(false);
    const [deficiencyNameExist, setDeficiencyNameExist] = useState({
        isExist: false,
        message: ''
    });
    const isExternalWorkpaper = !section;
    const stringRequiredSchema = Yup.string().trim().required();
    const addDeficiencyInitialValues = {
        deficiencyName: '',
        deficiencyDescription: '',
        auditAreaList: [],
        projectScopeDeficiencySummary: [],
        workpaperReference: ''
    };

    const {
        mutate: postDeficiencyMutation
    } = usePostProjectDeficiency();

    const {
        mutate: putProjectDeficiency
    } = usePutProjectDeficiency(deficiencyData?.ProjectDeficiencyId ?? null);

    const getSelectedProjectScopeAuditAreaId = (selectedValues) => {
        if (!selectedValues?.length) return [];
        const selectedProjectScopeAuditAreaIds = selectedValues.map(value => JSON.parse(value)).flatMap(selected => selected);
        return selectedProjectScopeAuditAreaIds ? Array.from(new Set(selectedProjectScopeAuditAreaIds.map((item) => item))) : [];
    };

    const apiRequest = {
        Add: (reqBody, options) => postDeficiencyMutation({ reqBody: reqBody, projectId: projectId }, options),
        Update: (reqBody, options) => putProjectDeficiency({ reqBody: reqBody, projectId: projectId }, options)
        //TODO: Update code here
    };
    
    const executeRequest = async (actionType, reqBody, formik) => {
        setIsSavingInProgress(true);
        const options = {
            onError: (error) => {
                const errResponse = error?.response;
                if (errResponse?.status === 409) {
                    setDeficiencyNameExist({ isExist: true, message: EN.UNIQUE_NAME_REQUIRED });
                } else {
                    setDeficiencyNameExist({ isExist: false, message: '' });
                    toast.error(error.toString());
                }
                setIsSavingInProgress(false);
            },
            onSuccess: () => {
                formik.setSubmitting(false);
                setDeficiencyNameExist({ isExist: false, message: '' });
                setIsSavingInProgress(false);
                setVisible(false);
                formik.handleReset();
                queryClient.invalidateQueries('deficiencyEvaluationForm');
            }
        }

        if (reqBody.length === 0) return
        await apiRequest[actionType](reqBody, options)
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: isEdit ? editDeficiencyInitialValues : addDeficiencyInitialValues,
        validationSchema: Yup.object().shape({
            deficiencyName: stringRequiredSchema,
            deficiencyDescription: stringRequiredSchema,
            ...(isExternalWorkpaper && { workpaperReference: stringRequiredSchema }),
            auditAreaList: Yup.array().min(1),
            projectScopeDeficiencySummary: Yup.array().min(1)
        }),
        onSubmit: async (values) => {
            const {
                deficiencyName,
                deficiencyDescription,
                auditAreaList,
                projectScopeDeficiencySummary,
                workpaperReference
            } = values;
    
            const projectScopeDeficiencySummaries = isEdit ?
                projectScopeDeficiencySummary.map(projectScopeId => {
                    const isExistingDeficiencySummary = deficiencyData?.ProjectScopeDeficiencySummaries?.find(data => data.ProjectScopeId === projectScopeId);
                    let projectScopeDeficiencySummaryId = null;
                    if (isExistingDeficiencySummary) {
                        projectScopeDeficiencySummaryId = isExistingDeficiencySummary.ProjectScopeDeficiencySummaryId;
                    }
    
                    return {
                        ProjectScopeDeficiencySummaryId: projectScopeDeficiencySummaryId,
                        ProjectScopeId: projectScopeId
                    }
                }) : projectScopeDeficiencySummary.map(data => ({ ProjectScopeId: data }));
    
            const _auditAreaList = getSelectedProjectScopeAuditAreaId(auditAreaList);
            const auditAreas = isEdit ?
                _auditAreaList.map(auditArea => {
                    const existingAuditArea = deficiencyData?.AuditAreas?.find(data => data.ProjectScopeAuditAreaId === auditArea);
                    let projectDeficiencyAuditAreaId = null;
                    if (existingAuditArea) {
                        projectDeficiencyAuditAreaId = existingAuditArea.ProjectDeficiencyAuditAreaId
                    }
    
                    return {
                        ProjectDeficiencyAuditAreaId: projectDeficiencyAuditAreaId,
                        ProjectScopeAuditAreaId: auditArea
                    }
                })
                :
                _auditAreaList.map((projectscopeAuditareaId) => { return { ProjectScopeAuditAreaId: projectscopeAuditareaId } })
            const reqBody = {
                ProjectFormId: (projectFormId ?? deficiencyData?.ProjectFormId) || null,
                ProjectId: projectId,
                DeficiencyName: deficiencyName,
                DeficiencyDescription: deficiencyDescription,
                WorkpaperReference: workpaperReference || '',
                SummaryComment: deficiencyData?.SummaryComment ?? '',
                FormSectionId: isEdit ? section?.FormSectionId : section?.id,
                IsMagnitudeMaterial: deficiencyData?.IsMagnitudeMaterial,
                IsOccurencePossible: deficiencyData?.IsOccurencePossible,
                IsPrudentOfficial: deficiencyData?.IsPrudentOfficial,
                IsMeritsGovernance: deficiencyData?.IsMeritsGovernance,
                Determination: deficiencyData?.Determination,
                CommunicationComment: deficiencyData?.CommunicationComment,
                IsCommunicatedGovernance: deficiencyData?.IsCommunicatedGovernance,
                IsCommunicatedManagement: deficiencyData?.IsCommunicatedManagement,
                IsMeritsAttentionOfManagement: deficiencyData?.IsMeritsAttentionOfManagement,
                IsCommunicationWritten: deficiencyData?.IsCommunicationWritten,
                IsCommunicationOral: deficiencyData?.IsCommunicationOral,
                PersonCommunicatedTo: deficiencyData?.PersonCommunicatedTo,
                CommunicationDate: deficiencyData?.CommunicationDate,
                ProjectScopeDeficiencySummaries: projectScopeDeficiencySummaries,
                AuditAreas: auditAreas,
            }
            const actionType = isEdit ? API_REQUEST_ACTION_TYPE.UPDATE : API_REQUEST_ACTION_TYPE.ADD;
    
            setDeficiencyNameExist({ isExist: false, message: '' });
            await executeRequest(actionType, reqBody, formik);
        }
    });

    const {
        values,
        handleChange,
        handleSubmit,
        isValid,
    } = formik;

    const createAuditAreaOptions = (auditAreaData) => {
        let auditAreaOptions = [];
        auditAreaData.forEach(auditArea => {
            const foundIndex = auditAreaOptions.findIndex(option => option.label === auditArea.AuditAreaName);
            if (foundIndex >= 0) {
                auditAreaOptions[foundIndex].value.push(auditArea.ProjectScopeAuditAreaId);
            } else {
                auditAreaOptions.push({ value: [auditArea.ProjectScopeAuditAreaId], label: auditArea.AuditAreaName })
            }
        });

        return auditAreaOptions.map(auditAreaOption => {
            const { value, ...rest } = auditAreaOption;
            value.sort((a, b) => a - b);
            return {
                value: JSON.stringify(value),
                ...rest
            }
        });
    }

    const handleOnCancel = () => {
        setVisible(false)
        formik.handleReset();
        setDeficiencyNameExist({
            isExist: false,
            errMsg: ''
        })
    };

    const createDeficiencyOptions = (deficiencyEvalOptionsData) => {
        const result = deficiencyEvalOptionsData.map((value) => ({
            value: value.ProjectScopeId,
            label: value.ProjectFormUnits
        }));
        result.sort((a, b) => (a.label > b.label) ? 1 : -1);
        return result;
    }

    const getProjectFormId = (externalWorkPaper, projectFormId, deficiencyData) => {
        let projectFormIdParam = null;
        if (!externalWorkPaper) {
            if (!projectFormId && deficiencyData) {
                return deficiencyData.ProjectFormId;
            }
            projectFormIdParam = projectFormId;
        }
        return projectFormIdParam;
    }

    const projectIdParam = isExternalWorkpaper ? projectId : null;
    const projectFormIdParam = getProjectFormId(isExternalWorkpaper, projectFormId, deficiencyData);
    const { data: deficiencyEvalOptionsResult, isFetching: isDeficiencyEvalOptionsFetching } = useGetDeficiencyEvaluationOptions(projectId, projectFormIdParam);
    const { data: auditAreaResult, isFetching: isAuditAreaFetching } = useProjectScopeAuditAreas(values.projectScopeDeficiencySummary, projectId);

    useEffect(() => {
        setLoading(isDeficiencyEvalOptionsFetching || isAuditAreaFetching || isSavingInProgress);
    }, [isDeficiencyEvalOptionsFetching, isAuditAreaFetching, isSavingInProgress]);

    useEffect(() => {
        if (!deficiencyEvalOptionsResult || deficiencyEvalOptionsResult.status !== 200) return;
        setDeficiencySummaryOptions(createDeficiencyOptions(deficiencyEvalOptionsResult.data));
    }, [deficiencyEvalOptionsResult]);

    useEffect(() => {
        setAuditAreaOptions(auditAreaResult?.status === 200 ? createAuditAreaOptions(auditAreaResult?.data) : []);
    }, [auditAreaResult]);

    useEffect(() => {
        const { AuditAreas = [], FormSectionId, ProjectFormId } = deficiencyData ?? {};

        let workpaperReference = deficiencyData?.WorkpaperReference ?? '';

        if (FormSectionId && ProjectFormId) {
            workpaperReference = '';
        }

        setEditDeficiencyInitialValues({
            deficiencyName: deficiencyData?.DeficiencyName ?? '',
            deficiencyDescription: deficiencyData?.DeficiencyDescription ?? '',
            auditAreaList: createAuditAreaOptions(AuditAreas).map(data => data.value),
            projectScopeDeficiencySummary: deficiencyData?.ProjectScopeDeficiencySummaries?.map((deficiency) => deficiency.ProjectScopeId) ?? [],
            workpaperReference: workpaperReference
        })
    }, [deficiencyData])

    return (
        <Dialog open={visible} fullWidth scroll="paper">
            <form onSubmit={handleSubmit}>
                <div className={styles['add-deficiency-header-container']}>
                    <DialogTitle>
                        <Typography variant="title">{EN.IDENTIFIED_DEFICIENCIES}</Typography>
                    </DialogTitle>
                    {
                        !isExternalWorkpaper &&
                        <div className={styles['add-deficiency-form-container']}>
                            <div className={styles['form-info']}>
                                <div className={styles['form-info-label']}>
                                    <InputLabel className={styles['name-input-label-title']}>{EN.FORM}:</InputLabel>
                                </div>
                                <div className={styles['form-info-value']}>
                                    <InputLabel className={styles['name-input-label']}>
                                        {(formName?.length <= 40) ? formName
                                            : <CLATruncatedTextWithTooltip
                                                text={formName}
                                                length={40}
                                            />
                                        }
                                    </InputLabel>
                                </div>
                            </div>
                            <div className={styles['form-info']}>
                                <div className={styles['form-info-label']}>
                                    <InputLabel className={styles['section-input-label-title']}>{EN.SECTION}:</InputLabel>
                                </div>
                                <div className={styles['form-info-value']}>
                                    <InputLabel className={styles['section-input-label']}>
                                        {((section && section?.title && section?.title?.length <= 40
                                            || deficiencyData && deficiencyData?.FormSectionId && deficiencyData?.FormSectionId?.length <= 40))
                                            ? (isEdit ? (section?.FormSectionName || deficiencyData?.FormSectionId) : (section?.title || deficiencyData?.FormSectionId))
                                            : <CLATruncatedTextWithTooltip
                                                text={isEdit ? (section?.FormSectionName || deficiencyData?.FormSectionId) : (section?.title || deficiencyData?.FormSectionId)}
                                                length={40}
                                            />
                                        }
                                    </InputLabel>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                <DialogContent>
                    <Grid container spacing={4}>
                        {
                            isExternalWorkpaper &&
                            <Grid item xs={12}>
                                <CLATextField
                                    id={'workpaperReference'}
                                    name={'workpaperReference'}
                                    label={EN.WORKPAPER_REFERENCE}
                                    onChange={handleChange}
                                    value={values.workpaperReference}
                                    placeholder={EN.ENTER_WORKPAPER_REFERENCE}
                                    inputLableShrink
                                />
                            </Grid>
                        }
                        <Grid item xs={12}>
                            <CLATextField
                                id={'deficiencyName'}
                                name={'deficiencyName'}
                                label={EN.DEFICIENCY_NAME}
                                onChange={handleChange}
                                value={values.deficiencyName}
                                placeholder={EN.ENTER_DEFICIENCY_NAME}
                                error={deficiencyNameExist.isExist}
                                helperText={deficiencyNameExist.message}
                                inputLableShrink
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                id={'deficiencyDescription'}
                                name={'deficiencyDescription'}
                                label={EN.DESCRIBE_DEFICIENCY}
                                onChange={handleChange}
                                value={values.deficiencyDescription}
                                multiline
                                rows={3}
                                placeholder={EN.ENTER_DESCRIBE_DEFICIENCY}
                                inputProps={{
                                    maxLength: 4096,
                                }}
                                fullWidth
                                InputLabelProps={{
                                    shrink: true
                                }
                                }
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <CLASelect
                                id={'projectScopeDeficiencySummary'}
                                name={'projectScopeDeficiencySummary'}
                                label={EN.ASSOCIATE_DEFICIENCY_EVALUATION}
                                placeholder={EN.SELECT}
                                isDisabled={false}
                                truncateDisplay={true}
                                menuItems={deficiencySummaryOptions}
                                onChange={(values) => {
                                    if(values !== formik.values.projectScopeDeficiencySummary){
                                        formik.setFieldValue('auditAreaList', []);
                                    }
                                    formik.setFieldValue('projectScopeDeficiencySummary', values);
                                }}
                                defaultValues={values.projectScopeDeficiencySummary || []}
                                error={false}
                                helperText={''}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <CLASelect
                                id={'auditAreaList'}
                                name={'auditAreaList'}
                                label={EN.AUDIT_AREA}
                                placeholder={EN.SELECT}
                                isDisabled={!values.projectScopeDeficiencySummary?.length}
                                truncateDisplay={true}
                                menuItems={auditAreaOptions}
                                onChange={(values) => {
                                    formik.setFieldValue('auditAreaList', values)
                                }}
                                defaultValues={values?.auditAreaList ?? []}
                                error={false}
                                helperText={''}
                            />
                        </Grid>


                    </Grid>
                </DialogContent>
                <DialogActions
                    sx={{
                        '&.MuiDialogActions-root': {
                            py: 4,
                        },
                    }}
                >
                    <Button variant="outlined" onClick={handleOnCancel}>
                        {EN.CANCEL}
                    </Button>
                    <LoadingButton
                        variant="contained"
                        type="submit"
                        disabled={!isValid || (!isEdit && !formik.dirty) || isSavingInProgress}
                    >
                        {EN.SAVE}
                    </LoadingButton>
                </DialogActions>
            </form>
        </Dialog>
    );
};

DeficiencyModal.propTypes = {
    section: PropTypes.object,
    visible: PropTypes.bool,
    setVisible: PropTypes.func,
    formName: PropTypes.string,
    deficiencyData: PropTypes.object,
    isEdit: PropTypes.bool,
    units: PropTypes.array
};

DeficiencyModal.defaultProps = {
    visible: false,
    isEdit: false,
    units: []
};
