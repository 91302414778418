import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Button, Grid, Stack } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { useCLAInitialScopingFramework } from '@components/Forms/CLAInitialScopingConsiderations/useCLAInitialScopingFramework';
import { useCLAInitialScopingPerformanceStandards } from '@components/Forms/CLAInitialScopingConsiderations/useCLAInitialScopingPerformanceStandards';
import formServices from '@services/forms/forms';
import { CLASimpleDrawer } from '@ais/components';
import { useLoading } from '@hooks/index';
import { AuditAreasTable } from '.';
import { useProjectScope } from '@hooks/index';

export const AuditAreasDrawer = (props) => {
  const {
    methodologyVersionId,
    defaultAuditAreas,
    visible,
    setVisible,
    methodologyIndustries,
    setAuditAreasOrder,
    setConcurrencyEventReceived
  } = props;

  const setLoading = useLoading();
  const { projectId, projectFormId } = useParams();

  const { data } = useQuery(['auditAreas', methodologyVersionId], () => {
    if (methodologyVersionId)
      return formServices.getAuditAreasByMethodologyVersionId(methodologyVersionId);
    else return Promise.reject(false);
  });
  const queryClient = useQueryClient();

  const {
    projectScopeId, 
    fetchProjectScopeData
  } = useProjectScope()

  const [defaultAuditAreaIds, setDefaultAuditAreaIds] = useState([]);
  const [filteredAuditAreas, setFilteredAuditAreas] = useState([]);
  const [auditAreaNames, setAuditAreaNames] = useState([]);
  const [selectedAuditAreas, setSelectedAuditAreas] = useState([]);
  const [customAuditAreas, setCustomAuditAreas] = useState([]);
  const [toDelete, setToDelete] = useState([]);
  const [toDeleteCustom, setToDeleteCustom] = useState([]);
  const [customErrors, setCustomErrors] = useState({});

  const {
    selectedFramework
  } = useCLAInitialScopingFramework({
    methodologyVersionId,
    drawerOpen: visible,
  });

  const {
    selectedPerformanceStandard
  } = useCLAInitialScopingPerformanceStandards({
    methodologyVersionId,
    drawerOpen: visible,
  })

  const checkUniqueCustomNames = () => {
    const errors = {};
    customAuditAreas.forEach((caa) => {
      let occurence = 0;
      auditAreaNames.forEach((aan) => {
        if (aan === caa.name.toLowerCase()) occurence += 1;
      });
      if (occurence > 1) errors[caa.id] = true;
    });
    setCustomErrors(errors);
    if (Object.keys(errors).length) return false;
    else return true;
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const promises = [];
      let toProcess = selectedAuditAreas;
      let toProcessCustom = customAuditAreas;
      if (defaultAuditAreaIds.length) {
        toProcess = selectedAuditAreas.filter(
          (values) => !defaultAuditAreaIds.some((daai) => daai === values.value)
        );
      }
      if (toDelete.length) {
        toDelete.forEach((data) =>
          promises.push(formServices.deleteProjectScopeAuditArea(projectId, data.ProjectScopeAuditAreaId, projectFormId, projectScopeId))
        );
      }
      if (toDeleteCustom.length) {
        toDeleteCustom.forEach((projectScopeAuditAreaId) =>
          promises.push(formServices.deleteProjectScopeAuditArea(projectId, projectScopeAuditAreaId, projectFormId, projectScopeId))
        );
      }
      toProcess
        .map((values) => ({
          ProjectScopeId: projectScopeId,
          AuditAreaName: values.label,
          AuditAreaId: values.value,
          IsCustom: false,
        }))
        .forEach((payload) => promises.push(
          formServices.addProjectScopeAuditArea(
            projectId,
            {...payload, RequestProjectFormId: projectFormId}
          ))
        );
      toProcessCustom
        .filter((values) => values.enabled === true)
        .map((values) => ({
          ProjectScopeAuditAreaId: values.projectScopeAuditAreaId,
          ProjectScopeId: projectScopeId,
          AuditAreaName: values.name,
          AuditAreaId: values.auditAreaId,
          IsCustom: true,
        }))
        .forEach((payload) => {
          const { ProjectScopeAuditAreaId, ...actualPayload } = payload;
          if (ProjectScopeAuditAreaId)
            promises.push(
              formServices.updateProjectScopeAuditArea(
                projectId, 
                ProjectScopeAuditAreaId, 
                {...actualPayload, RequestProjectFormId: projectFormId}
              )
            );
          else promises.push(
            formServices.addProjectScopeAuditArea(
              projectId, {...actualPayload, RequestProjectFormId: projectFormId}
            )
          );
        });
      await Promise.all(promises);
      
      if (!!toDelete.length || !!toDeleteCustom.length) {
        queryClient.invalidateQueries(["projectScopeForm"]);
        queryClient.invalidateQueries(["all", "projectScopeRisks"]);
      }
    } catch (error) {
      // Catch error but don't show to user
    } finally {
      await fetchProjectScopeData();
      setLoading(false);
      setVisible(false);
      setConcurrencyEventReceived(false)
    }
  };

  const handleCancel = () => {
    setVisible(false);
  };

  useEffect(() => {
    if (data && Array.isArray(data?.data)) {
      const dataFiltering = (filterParameters) => {
        const { arr, arrIdentifier, identifier } = filterParameters;
        const results = [];
        arr?.forEach((arrData) =>
          results.push(arrIdentifier?.some((data) => data[identifier] === arrData))
        );
        return results.some((result) => result);
      }
      const { data: actualData } = data;
      const processed = actualData
        .map((data) => {
          const { MethodologyIndustries, ...others } = data;
          return {
            ...others,
            MethodologyIndustries: MethodologyIndustries?.map((mi) => mi.MethodologyIndustryId),
          };
        })
        .filter((auditArea) => {
          const results = [];
          methodologyIndustries?.forEach((mi) =>
            results.push(auditArea?.MethodologyIndustries?.some((MI) => MI === mi))
          );
          return results.some((result) => result === true);
        })
        .filter((auditArea) => {
          const data = {
            arr: selectedPerformanceStandard,
            arrIdentifier: auditArea?.AuditAreaPerformanceStandards,
            identifier: "PerformanceStandardId"
          }
          return dataFiltering(data)
        })
        .filter((auditArea) => {
          const data = {
            arr: selectedFramework,
            arrIdentifier: auditArea?.AuditAreaFrameworks,
            identifier: "FrameworkId"
          }
          return dataFiltering(data)
        })
        .filter((auditArea) => auditArea.IsAppliedToRiskAssessment);

      const refinedAuditAreas = processed
        .sort((a, b) => a.DisplayOrder - b.DisplayOrder)
        .map((auditArea) => ({
          value: auditArea.AuditAreaId,
          label: auditArea.AuditAreaName,
        }))
      const conditionalAuditAreas = selectedFramework.length === 0 || selectedPerformanceStandard.length === 0 ? [] : refinedAuditAreas
      setFilteredAuditAreas(conditionalAuditAreas);
      setAuditAreasOrder(
        processed.map((auditArea) => ({
          auditAreaId: auditArea.AuditAreaId,
          displayOrder: auditArea.DisplayOrder,
        }))
      );
    }
  }, [data, methodologyIndustries, selectedFramework, selectedPerformanceStandard]);

  useEffect(() => {
    if (Array.isArray(defaultAuditAreas)) {
      setDefaultAuditAreaIds(
        defaultAuditAreas.filter((d) => d.IsCustom === false).map((d) => d.AuditAreaId)
      );
      setAuditAreaNames(defaultAuditAreas.map((d) => d.AuditAreaName));
    }
  }, [defaultAuditAreas]);

  useEffect(() => {
    const names = [];
    filteredAuditAreas.forEach((faa) => {
      names.push(faa.label);
    });
    customAuditAreas.forEach((caa) => {
      if (caa.name) names.push(caa.name);
    });
    setAuditAreaNames(names.map((name) => name.toLowerCase()));
  }, [filteredAuditAreas, customAuditAreas]);

  return (
    <CLASimpleDrawer
      anchor="right"
      isVisible={visible}
      onClose={() => setVisible(false)}
      headerTitle="Audit Area"
    >
      <Grid container justifyContent="flex-end" p={3}>
        <Grid item>
          <Stack direction="row" spacing={3}>
            <Button variant="outlined" onClick={handleCancel}>
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                if (checkUniqueCustomNames()) handleSave();
              }}
              disabled={
                !selectedAuditAreas.concat(customAuditAreas.filter((caa) => caa.enabled)).length ||
                customAuditAreas.some((caa) => caa.enabled && (!caa.name || !caa.auditAreaId))
              }
            >
              Save
            </Button>
          </Stack>
        </Grid>
      </Grid>
      <AuditAreasTable
        defaultAuditAreas={defaultAuditAreas}
        filteredAuditAreas={filteredAuditAreas}
        customErrors={customErrors}
        onChange={(selectedAuditAreas, customAuditAreas, toDelete, toDeleteCustom) => {
          setSelectedAuditAreas(selectedAuditAreas);
          setCustomAuditAreas(customAuditAreas);
          setToDelete(toDelete);
          setToDeleteCustom(toDeleteCustom);
        }}
      />
    </CLASimpleDrawer>
  );
};
