import React, { useState, useEffect} from "react"
import GraphsDrawer from "./GraphsDrawer"
import Button from '@mui/material/Button';
import { useProjectDetails } from "@services/customForm/trendingByMonth"
import icon_add_dark from "@assets/icon_add_dark.svg"
import { GRAPHS_DRAWER } from "@constants"
import { useGraphContext } from '../../GraphContext';
import { useUpdateMyPresence } from "@views/PlanningAnalytics/RoomProvider";
import { useOthers } from "@views/PlanningAnalytics/store/users";
import styles from "../FormView.module.css";
import VFRenderedFieldWrapper from "../VFRenderedFieldWrapper";
import { useIdle } from 'react-use'
import { WAITING_TIME } from '@ais/constants';

const GraphsManager = (props) => {

  const { formValues, projectId, projectFormId, sectionId, schema, questionId, disabled, trendingByMonthId } = props
  const { GRAPH_SECTION_TITLE } = GRAPHS_DRAWER.EN
  const [drawerVisible, setDrawerVisible] = useState(false)
  const { data } = useProjectDetails(projectId)
  const { selectedGraphs } = useGraphContext()
  const updateMyPresence = useUpdateMyPresence();
  const others = useOthers();
  const isLockedByUser = others.find((user) => user.presence.focusedId === trendingByMonthId)
  const isIdle = useIdle(WAITING_TIME.MINS_13);
  const toggleGraphsDrawer = () => {
    setDrawerVisible((prev) => !prev)
    if (!drawerVisible)
      updateMyPresence({ focusedId: trendingByMonthId })
    else
      updateMyPresence({ focusedId: null })
  }

  useEffect(() => {
    if (isIdle) {
      const delay = setTimeout(() => {
        setDrawerVisible(false)
      }, WAITING_TIME.MINS_2);
      return () => {
        clearTimeout(delay);
      };
    }  
  }, [isIdle])

  const onOpen = () => {
  }

  return (
    <div>
      <VFRenderedFieldWrapper
        className={styles.field__wrapper}
        isLockedByUser={isLockedByUser}
      >
        <Button
          sx={{ color: "#2e334e" }}
          startIcon={<img src={icon_add_dark} />}
          onClick={toggleGraphsDrawer}
          disabled={disabled || isLockedByUser !== undefined}
        >
          {GRAPH_SECTION_TITLE}
        </Button>
      </VFRenderedFieldWrapper>
      <GraphsDrawer
        projectId={data ? data.CLCProjectId : undefined}
        projectFormId={projectFormId}
        sectionId={sectionId}
        questionId={questionId}
        formValues={formValues}
        visible={drawerVisible}
        schemaSelectedGraphs={selectedGraphs}
        schema={schema}
        onClose={toggleGraphsDrawer}
        onOpen={onOpen}
      />
    </div>
  )
}

export default GraphsManager
