import { useContext, useState, useEffect } from "react";
import { useParams } from "react-router-dom"

import { Box, IconButton, Button } from '@mui/material';
import { Comment, CommentResolved } from '@ais/assets';
import { sortReviewerSignoffAsc } from '@utilities/dateHelpers.js';
import {
    CLAAccordionPro,
} from '@ais/components';
import { UNDERSTANDING_OF_ENTITY } from '@ais/constants';
import { UnderstandingOfEntityContext } from "@ais/contexts";
import { useKeyControlDrawerContext } from '@contexts/KeyControlDrawer/KeyControlDrawerContext' 
import { ConcurrentInternalControlTextbox } from '../../../ConcurrentInternalControlTextbox.jsx';
import { ConcurrentKeyControlsButton } from '../../../ConcurrentKeyControlsButton.jsx';
import { useInternalControlsContext } from '@contexts/InternalControls/InternalControlsContext';

import styles from './UOEAuditAreaCollapsibles.module.css';

import { useUpdateMyPresence } from '@views/InternalControl/RoomProvider.jsx';
import { useOthers } from '@views/InternalControl/store/users.js';
import VFRenderedFieldWrapper from '../../../VFRenderedFieldWrapper.jsx'
import { isSignoffLogicEnabled } from '@utilities/signoffUtility.js';


const UOEConstants = UNDERSTANDING_OF_ENTITY;
const UOEJournalEntriesConstants = UOEConstants.JOURNAL_ENTRIES;
const UOEInfoProcessingConstants = UNDERSTANDING_OF_ENTITY.INFORMATION_PROCESSING;
const UOEComments = UOEConstants.COMMENTS;

export const JournalEntries = ({
    journalEntryInfoProcessing,
    saveInternalControlData,
    isProjectFinalized,
    signOffList = []
}) => {
    const {
        formattedInternalControls,
        changeFirstLevelField
    } = useInternalControlsContext();

    const journalEntryICComment = formattedInternalControls?.JournalEntryICComment;
    const { state: { openComment }, setOpenComment } = useContext(UnderstandingOfEntityContext)
    const isCommentOpen = openComment === UOEJournalEntriesConstants.COMMENT_KEY
    const { projectFormId } = useParams();
    const [journalHighlighted, setJournalHighlighted] = useState({
        JournalEntryICInfoProcessingAndControlActivity: false,
        JournalEntryICComment: false
    })
    const [keyControlButtonHighlighted, setKeyControlButtonHighlighted] = useState(false)
    const updateMyPresence = useUpdateMyPresence();
    const others = useOthers();
    
    const { openDrawer, initializeInternalControl } = useKeyControlDrawerContext();

    const handleBlur = (event, _ = null, focusedId = null) => {
        if ((journalEntryInfoProcessing == null && !event.target.value) || (journalEntryInfoProcessing === event.target.value)) {
            updateMyPresence({ focusedId: null, type: 'custom' })
            return
        }

        const newInternalControlsData = changeFirstLevelField(UOEJournalEntriesConstants.FIELD, event.target.value);
        
        updateMyPresence({ focusedId: null, type: 'custom' })
        saveInternalControlData(newInternalControlsData);
    };

    const handleCommentBlur = (event, _ = null, focusedId = null) => {
        if (journalEntryICComment === event.target.value) {
            updateMyPresence({ focusedId: null, type: 'custom' })
            return;
        }

        const newInternalControlsData = changeFirstLevelField(
            UOEJournalEntriesConstants.COMMENT_FIELD,
            event.target.value
        );
        updateMyPresence({ focusedId: null, type: 'custom' })
        saveInternalControlData(newInternalControlsData);
    };

    const toggleCommentOpen = () => {
        setOpenComment(UOEJournalEntriesConstants.COMMENT_KEY)
    }

    const onKeyControlClick = () => {
        openDrawer('journal-entries-key-controls')
        updateMyPresence({ focusedId: "journal-entries-key-controls", type: 'custom' })
        initializeInternalControl({
            projectFormId,
            isJournalEntry: true,
            isFinancialStatement: false,
            internalControlData: formattedInternalControls
        });
    }

    const journalEntryKeys = [
        'JournalEntryICInfoProcessingAndControlActivity',
        'JournalEntryICComment'
    ]

    useEffect(() => {
        const latestReviewersAsc = sortReviewerSignoffAsc(signOffList)
        let shouldHighlight = false
        journalEntryKeys.forEach((key) => {
            if(formattedInternalControls?.History?.length > 0){
                const dateModified = new Date(formattedInternalControls.ValidFrom).getTime();
                const isSignoffEnabled = isSignoffLogicEnabled(isProjectFinalized, dateModified);
                const isJournalHighlighted = new Date(latestReviewersAsc[latestReviewersAsc?.length - 1]?.signOffDate).getTime() < dateModified;
                if(isSignoffEnabled && isJournalHighlighted){
                    if(formattedInternalControls[key] !== formattedInternalControls?.History[0][key]){
                        setJournalHighlighted((prev) => {
                            return {
                                ...prev,
                                [key]: true
                            }
                        })
                    }
                }
                else{
                    setJournalHighlighted((prev) => {
                        return {
                            JournalEntryICInfoProcessingAndControlActivity: false,
                            JournalEntryICComment: false
                        }
                    })
                }
                }

        })

        if(formattedInternalControls?.KeyControlHistory?.length > 0){
            const journalEntries = formattedInternalControls?.KeyControlHistory.filter((kc) => {
                return kc.IsJournalEntry
            })
            if(journalEntries.length > 0){
                const dateModified = new Date(journalEntries[0].ValidTo).getTime();
                const isSignoffEnabled = isSignoffLogicEnabled(isProjectFinalized, dateModified);
                shouldHighlight = new Date(latestReviewersAsc[latestReviewersAsc?.length - 1]?.signOffDate).getTime() < dateModified;
                setKeyControlButtonHighlighted(isSignoffEnabled && shouldHighlight)
            }
        }else{
            if(formattedInternalControls?.ProjectInternalControlProjectKeyControl?.length > 0){
                const journalEntries = formattedInternalControls.ProjectInternalControlProjectKeyControl.filter((kc) => {
                    return kc.IsJournalEntry
                })
                if(journalEntries.length > 0){
                    const dateModified = new Date(journalEntries[0]?.ValidFrom + "Z").getTime();
                    const isSignoffEnabled = isSignoffLogicEnabled(isProjectFinalized, dateModified);
                    shouldHighlight = new Date(latestReviewersAsc[latestReviewersAsc?.length - 1]?.signOffDate).getTime() < dateModified
                    setKeyControlButtonHighlighted(isSignoffEnabled && shouldHighlight)
                }
            }
        }
    }, [formattedInternalControls, signOffList])

    return (
        <CLAAccordionPro.Root>
            <CLAAccordionPro
                id={UOEJournalEntriesConstants.ACCORDION_ID}
                customClassName={{ container: 'UOEContainer', accordion: 'UOEAccordion' }}
                disableTransitions={true}
                expandedPanel={isCommentOpen ? UOEJournalEntriesConstants.ACCORDION_ID : undefined}
            >
                <CLAAccordionPro.Trigger
                    id={UOEJournalEntriesConstants.ACCORDION_ID}
                    customClassName={{ title: 'title', subtitle: 'flex100' }}
                    styles={{
                        '& .MuiAccordionSummary-expandIconWrapper': {
                            '&.Mui-expanded': {
                                transform: 'none'
                            }
                        }
                    }}
                >
                    <div className={styles.headerContainer}>
                        <div className={styles.gridContainer}>
                            <div className={styles.rowTitle}>Journal Entries</div>
                            <div className={styles.rowIcon}>
                                <IconButton onClick={toggleCommentOpen} sx={{ p: 0 }}>
                                    {
                                        <Box sx={journalHighlighted['JournalEntryICComment'] ? {borderStyle: 'dashed', borderWidth: '4px', borderColor: 'red', p: '4px'}: {}}>
                                        {(!!journalEntryICComment && journalEntryICComment.length > 0)
                                            ? <CommentResolved />
                                            : <Comment />}
                                        </Box>
                                    }
                                </IconButton>
                            </div>
                            <VFRenderedFieldWrapper
                                className={styles["field__wrapper"]}
                                isLockedByUser={others.find((user) => user.presence.focusedId === `journal-entries-key-controls`)}
                            >
                                <ConcurrentKeyControlsButton 
                                    className={styles.rowButton} 
                                    onClick={onKeyControlClick} 
                                    customFormObjectId={`journal-entries-key-controls`}
                                    disabled={!!others.find((user) => user.presence.focusedId === `journal-entries-key-controls`)}
                                    shouldHighlight={keyControlButtonHighlighted}
                                />
                            </VFRenderedFieldWrapper>
                        </div>
                    </div>
                </CLAAccordionPro.Trigger>

                <CLAAccordionPro.Panel
                    panelStyles={{
                        padding: 'auto',
                        paddingRight: 0,
                        backgroundColor: '#FFF'
                    }}
                >
                    <Box sx={{ ml: '45px', my: '20px' }}>
                    <VFRenderedFieldWrapper
                        className={styles["field__wrapper"]}
                        isLockedByUser={others.find((user) => user.presence.focusedId === UOEJournalEntriesConstants.FIELD)}
                    >
                        <Box sx={journalHighlighted['JournalEntryICInfoProcessingAndControlActivity'] ? { borderStyle: 'dashed', borderWidth: '4px', borderColor: 'red', p: '10px'}: {}}>
                            <ConcurrentInternalControlTextbox
                                label={UOEInfoProcessingConstants.LABEL}
                                placeholder={UOEInfoProcessingConstants.PLACEHOLDER}
                                value={journalEntryInfoProcessing || ''}
                                onBlur={handleBlur}
                                onFocus={(event, identifier, focusedId) => updateMyPresence({ focusedId, type: 'custom' })}
                                customFormObjectId={UOEJournalEntriesConstants.FIELD}
                                disabled={isProjectFinalized || !!others.find((user) => user.presence.focusedId === UOEJournalEntriesConstants.FIELD)}
                                maxLength={null}
                            />
                        </Box>
                    </VFRenderedFieldWrapper>
                    {!!isCommentOpen &&
                        <VFRenderedFieldWrapper
                            className={styles["field__wrapper"]}
                            isLockedByUser={others.find((user) => user.presence.focusedId === UOEJournalEntriesConstants.COMMENT_KEY)}
                        >
                            <ConcurrentInternalControlTextbox
                                label={UOEComments.LABEL}
                                placeholder={UOEComments.PLACEHOLDER}
                                value={journalEntryICComment || ''}
                                nonMultilineLabelFontSize={UOEComments.NON_MULTILINE_LABEL_FONT_SIZE}
                                nonMultilineLabelMarginLeft={UOEComments.NON_MULTILINE_LABEL_MARGIN_LEFT}
                                onBlur={handleCommentBlur}
                                onFocus={(event, identifier, focusedId) => updateMyPresence({ focusedId, type: 'custom' })}
                                sx={{
                                    marginTop: '20px'
                                }}
                                customFormObjectId={UOEJournalEntriesConstants.COMMENT_KEY}
                                disabled={isProjectFinalized || !!others.find((user) => user.presence.focusedId === UOEJournalEntriesConstants.COMMENT_KEY)}
                            />
                        </VFRenderedFieldWrapper>
                    }
                    </Box>
                </CLAAccordionPro.Panel>
            </CLAAccordionPro>
        </CLAAccordionPro.Root>
    );
}

export default JournalEntries;