import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useCommunicationOfSignificantsMattersContext } from "@contexts/CommunicationOfSignificantsMattersContext";
import styles from './styles.module.css';
import { useFinalizedProject } from '@hooks/useProject';
import { ConcurrentInternalControlTextbox } from '../../ConcurrentInternalControlTextbox';
import { INTERNAL_CONTROLS } from '@constants/customForm';
import { renderLabel } from '@ais/forms';
import CustomToast from '@components/CustomToast/CustomToast';
import { useUpdateMyPresence } from '@views/InternalControl/RoomProvider.jsx';
import { useOthers } from '@views/InternalControl/store/users.js';
import VFRenderedFieldWrapper from '../../VFRenderedFieldWrapper.jsx'
import { sortReviewerSignoffAsc } from '@utilities/dateHelpers.js';
import { useInternalControlsContext } from '@contexts/InternalControls/InternalControlsContext'; 
import { Box } from '@mui/material';
import { isSignoffLogicEnabled } from '@utilities/signoffUtility.js';


const {
  COMMUNICATION_OF_SIGNIFICANT_MATTERS: {
    LABEL,
    BETWEEN_PEOPLE_LABEL,
    BETWEEN_MANAGEMENT_LABEL,
    EXTERNAL_COMMENT_LABEL,
    PLACEHOLDER,
    PEOPLE_WITH_ENTITY_COMMENT_FIELD_NAME,
    MANAGEMENT_COMMENT_FIELD_NAME,
    EXTERNAL_COMMENT_FIELD_NAME
  }
} = INTERNAL_CONTROLS.EN;

const CommunicationOfSiginificantMatters = (props) => {
  const { signOffList } = props;

  const _useCommunicationOfSignificantsMattersContext = useCommunicationOfSignificantsMattersContext()
  const { internalControls: { ExternalComment, ManagementComment, PeopleWithinEntityComment, History }, actions: { handleSave }, isError, isLoading, isSuccess } = _useCommunicationOfSignificantsMattersContext
  const {
    formattedInternalControls
  } = useInternalControlsContext();
  const [communicationHighlight, setCommunicationHighlight] = useState({
    PeopleWithinEntityComment: false,
    ManagementComment: false,
    ExternalComment: false
  })

  const CommunicationOfSiginificantMatters = [
    'PeopleWithinEntityComment',
    'ManagementComment',
    'ExternalComment'
  ]

  const { projectId } = useParams();
  const isProjectFinalized = useFinalizedProject(projectId);
  const updateMyPresence = useUpdateMyPresence();
  const others = useOthers();


  const handleOnBlurEvent = (event, identifier = null, focusedId = null) => {
    updateMyPresence({ focusedId, type: 'custom' })
    handleSave(identifier, event.target.value);
  };

  const handleOnFocusEvent = (event, identifier = null, focusedId) => {
    updateMyPresence({ focusedId, type: 'custom' })
  }

  const customLabelSx = {
    backgroundColor: '#FFFFFF',
    paddingRight: '3px',
    maxWidth: 'fit-content'
  }

  useEffect(() => {
    const latestReviewersAsc = sortReviewerSignoffAsc(signOffList)
    let shouldHighlight = false
    CommunicationOfSiginificantMatters.forEach((key) => {
        if(formattedInternalControls?.History?.length > 0){
            if(formattedInternalControls[key] !== formattedInternalControls?.History[0][key]){
                const dateModified = new Date(formattedInternalControls.ValidFrom).getTime();
                const isSignoffEnabled = isSignoffLogicEnabled(isProjectFinalized, dateModified);
                shouldHighlight = new Date(latestReviewersAsc[latestReviewersAsc?.length - 1]?.signOffDate).getTime() < dateModified
                setCommunicationHighlight((prev) => {
                    return {
                        ...prev,
                        [key]: isSignoffEnabled && shouldHighlight
                    }
                })
            }
        }
    })
}, [formattedInternalControls, signOffList])

  return (
    <>
      <div className={styles['communcation-container']}>
        <div className={styles['communcation-text']}>{LABEL}</div>
        <div className={styles['communcation-main-content']}>
        <VFRenderedFieldWrapper
            className={styles["field__wrapper"]}
            isLockedByUser={others.find((user) => user.presence.focusedId === "between-people-textbox")}
        >
        <Box sx={communicationHighlight['PeopleWithinEntityComment'] ? {borderStyle: 'dashed', borderWidth: '4px', borderColor: 'red', p: '10px', pt: '1px', width: '100%' }: {width:'100%'}}>
            <ConcurrentInternalControlTextbox
              value={PeopleWithinEntityComment || ''}
              label={renderLabel(BETWEEN_PEOPLE_LABEL, null, null, null, customLabelSx)}
              isMultilineLabel={true}
              placeholder={PLACEHOLDER}
              customFormObjectId={"between-people-textbox"}
              fieldName={PEOPLE_WITH_ENTITY_COMMENT_FIELD_NAME}
              disabled={isProjectFinalized || !!others.find((user) => user.presence.focusedId === "between-people-textbox")}
              onBlur={handleOnBlurEvent}
              onFocus={handleOnFocusEvent}
            />
          </Box>
          </VFRenderedFieldWrapper>
          <VFRenderedFieldWrapper
            className={styles["field__wrapper"]}
            isLockedByUser={others.find((user) => user.presence.focusedId === "between-management-textbox")}
          >
            <Box sx={communicationHighlight['ManagementComment'] ? {borderStyle: 'dashed', borderWidth: '4px', borderColor: 'red', p: '10px', pt: '1px', width: '100%' }: {width: '100%'}}>
            <ConcurrentInternalControlTextbox
              value={ManagementComment || ''}
              label={renderLabel(BETWEEN_MANAGEMENT_LABEL, null, null, null, customLabelSx)}
              isMultilineLabel={true}
              placeholder={PLACEHOLDER}
              fieldName={MANAGEMENT_COMMENT_FIELD_NAME}
              customFormObjectId={"between-management-textbox"}
              disabled={isProjectFinalized || !!others.find((user) => user.presence.focusedId === "between-management-textbox")}
              onBlur={handleOnBlurEvent}
              onFocus={handleOnFocusEvent}
            />
            </Box>
          </VFRenderedFieldWrapper>
          <VFRenderedFieldWrapper
            className={styles["field__wrapper"]}
            isLockedByUser={others.find((user) => user.presence.focusedId === "with-external-parties-textbox")}
          >
            <Box sx={communicationHighlight['ExternalComment'] ? {borderStyle: 'dashed', borderWidth: '4px', borderColor: 'red', p: '10px', pt: '1px', width: '100%' }: {width: '100%'}}>
            <ConcurrentInternalControlTextbox
              value={ExternalComment || ''}
              label={renderLabel(EXTERNAL_COMMENT_LABEL, null, null, null, customLabelSx)}
              isMultilineLabel={true}
              placeholder={PLACEHOLDER}
              fieldName={EXTERNAL_COMMENT_FIELD_NAME}
              customFormObjectId={"with-external-parties-textbox"}
              disabled={isProjectFinalized || !!others.find((user) => user.presence.focusedId === "with-external-parties-textbox")}
              onBlur={handleOnBlurEvent}
              onFocus={handleOnFocusEvent}
            />
            </Box>
          </VFRenderedFieldWrapper>
        </div>
        <CustomToast error={isError} success={isSuccess} loading={isLoading} />
      </div>
    </>
  )
}

export default CommunicationOfSiginificantMatters
