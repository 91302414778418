import { useState, useCallback } from 'react'
import Button from '@mui/material/Button';


import * as Constants from "@constants/forms"
import { ReactComponent as ExportGLDetailIcon } from '@assets/export_gl_detail.svg';
import styles from '@components/CLATrialBalanceSelect/ExportGLDetailLink/ExportGLDetailLink.module.css';


import CLAExportGLModalWrapper from '../CLAExportGLModalWrapper';

const ExportGLDetailLink = ({ correlationDetailIds, visible }) => {
    const { LINK } = Constants.EXPORT_GL_DETAILS.EN;
    const [openModal, setOpenModal] = useState(false);

    const toggleModalSwitch = useCallback(() => {
        setOpenModal(openModal => !openModal);
    }, [openModal])

    if (visible) {
        return (
            <>
                <Button
                    className={styles.exportToCSVButton}
                    startIcon={<ExportGLDetailIcon />}
                    children={LINK}
                    onClick={() => toggleModalSwitch()}
                    disabled
                />
                    { openModal &&
                        <CLAExportGLModalWrapper
                            correlationDetailIds={correlationDetailIds}
                            toggleModal={toggleModalSwitch}
                            openModal={openModal}
                        />
                    }
            </>
        )
    } else {
        return (
            <>
            </>
        )
    }

}

export default ExportGLDetailLink