import formApi from "@utilities/claApiForm";
import clientApi from "@utilities/claApiClient";
import projectServices from "@services/project";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"

const fetchADDGeneralLedger = async (correlationDetailIds) => {
    try {
        const { data } = await clientApi.post("/clc-audit/addGeneralLedger", { correlationDetailIds })

        return data
    }
    catch (error) {
        console.error(error)
    }
}

const fetchCLCProjectId = async (projectId) => {
    try {
        const { data } = await projectServices.getProjectById(projectId)
        return data
    }
    catch (error) {
        console.error(error)
    }
}

const fetchGeneralLedgerSummary = async (GeneralLedgerIds, CorrelationDetailIds) => {
    try {
        const { data } = await clientApi.post("/clc-audit/general-ledger/summary-month-end-totals", {
            ids: GeneralLedgerIds,
            correlationDetailIds: CorrelationDetailIds
        })
        return data
    }
    catch (error) {
        console.error(error)
    }
}

export const useGeneralLedgerSummary = (GeneralLedgerIds, correlationDetailIds) => {
    return useQuery(
        ["generalLedgerSummary", GeneralLedgerIds, correlationDetailIds],
        () => fetchGeneralLedgerSummary(GeneralLedgerIds, correlationDetailIds),
        {
            enabled: !!GeneralLedgerIds 
                && Array.isArray(GeneralLedgerIds) 
                && GeneralLedgerIds.length > 0 
                && Array.isArray(correlationDetailIds) 
                && correlationDetailIds.length > 0,
            refetchOnWindowsFocus: false,
            select: (data) => data.aggregationPeriods
        }
    )
}

export const useProjectDetails = (projectId) => {
    return useQuery(
        ["projectDetails", projectId],
        () => fetchCLCProjectId(projectId),
        {
            enabled: !!projectId,
            staleTime: 5 * 60 * 1000
        }
    )
}

export const useADDGeneralLedger = (correlationDetailIds) => {
    return useQuery(
        ["addGeneralLedger", correlationDetailIds],
        () => fetchADDGeneralLedger(correlationDetailIds),
        {
            enabled: !!correlationDetailIds
                && Array.isArray(correlationDetailIds)
                && correlationDetailIds.length > 0,
            retry: 3,
            retryDelay: 1000
        }
    )
}

export const useGraphSelectionsMutation = (projectFormId, sectionId, projectId) => {
    return useMutation(
        (mutationData) => {
            return formApi.post(`/projectforms/${projectFormId}/schema/${sectionId}/graphs/${projectId}`, { "selectedGraphs": mutationData })
        }
    )
}
export const usePeriodSelectionsMutation = (projectFormId, sectionId, projectId) => {
    return useMutation(
        (mutationData) => {
            return formApi.post(`/projectforms/${projectFormId}/schema/${sectionId}/periods/${projectId}`, { "selectedPeriods": mutationData })
        }
    )
}

const trendingByMonthService = {
    fetchGeneralLedgerSummary
}

export default trendingByMonthService;
