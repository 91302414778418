export default {
  "&.MuiDataGrid-root .MuiDataGrid-columnHeader": {
    fontWeight: "700",
    fontSize: "1.3em",
    fontFamily: "museo-sans, sans-serif",
    lineHeight: "19.2px",
    backgroundColor: "#F6F6F6",
    outline: "none",
  },
  "&.MuiDataGrid-root .MuiDataGrid-columnHeaders": {
    borderTop: "solid 1px #97979740",
    borderLeft: "solid 1px #97979740",
    borderRight: "solid 1px #97979740",
    borderBottom: 0
  },
  "&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus": {
    outline: "none",
    border: "none",
  },
  "&.MuiDataGrid-root .MuiDataGrid-cell:focus": {
    borderTop: "1px solid rgb(224, 224, 224)"
  },
  "&.MuiDataGrid-root .MuiDataGrid-cell": {
    outline: "none",
    borderBottom: 0,
    borderTop: "1px solid rgb(224, 224, 224)"
  },
  "&.MuiDataGrid-root .MuiDataGrid-detailPanelToggleCell ": {
    backgroundColor: "#f7f7f7"
  },
  "button > svg": {
    fontSize: '14.5px'
  }
}